import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BaseService } from 'services/api'

@Component({
  selector: 'app-nyx-catalog-section',
  templateUrl: './nyx-catalog-section.component.html',
  styleUrls: ['./nyx-catalog-section.component.scss']
})
export class NyxCatalogSectionComponent implements OnInit {
  translation: any = {
    'product-selection': ''
  };

  pageID = 'catalog-section';

  currentLang = {name: 'RU', lang: 'rus'};
  currentLangCatalog = 'rus';

  idFolder: any;
  idUnderFolder: any;

  arrSection: any = undefined;
  items: any = [];
  token = '4e3cb8b0-6168-11ec-9afa-d8f3bc3ad36e';

  slideConfig = {
    'infinite': false,
    'vertical': true,
    'arrows': true,
    'dots': false,
    'slidesToShow': 8,
    'slidesToScroll': 1,
    'prevArrow': `<button type="button" class="card_ul_prev"></button>`,
    'nextArrow': `<button type="button" class="card_ul_next"></button>`,
    'responsive': [{
      'breakpoint': 835,
      'settings': 'unslick' // destroys slick
    }]
  };

  routeLink: any = ['/catalogs/nyx'];

  folderHome: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: BaseService,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe( params => {
      this.idFolder = params[ 'folder' ];
      this.idUnderFolder = params[ 'underfolder' ];
      if (!this.idFolder && !this.idUnderFolder) {
        this.router.navigate( [ 'catalog_old' ] );
      }

    });

    this.folderHome = this.idFolder;

    this.api.nyxCatalog().subscribe( ( response_catalog: any ) => {
      this.arrSection = response_catalog.find(el => el.id == this.idFolder).children.find(el => el.id == this.idUnderFolder);
      if (!this.arrSection) {
        this.router.navigate( [ 'catalogs_old/nyx' ] );
      } else {
        this.api.productsNyxCatalog(this.arrSection.id).subscribe( ( response_items: any ) => {
          this.items = response_items;
        });
      }
    });

  }
  goBack() {
    this.router.navigate(['/catalogs_old/nyx']);
  }
}
