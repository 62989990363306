import { Pipe, PipeTransform } from '@angular/core';
import { CalendarInterval } from 'app/typings/calendar';
import * as moment from 'moment';

@Pipe({
  name: 'filterFinish'
})
export class FilterFinishPipe implements PipeTransform {
  transform(intervals: CalendarInterval[], finishValue: number): CalendarInterval[] {
    const findedInterval = intervals.find(v => v.id === finishValue);
    const leftIntervals = intervals.filter(v => moment(v.finish).isAfter(findedInterval?.start, 'minutes'));
    const freeIntervals: CalendarInterval[] = [];

    for (let i = 0; i < leftIntervals.length - 1; i++) {
      const currentInterval = leftIntervals[i];
      const nextInterval = leftIntervals[i + 1];     
 
      if (currentInterval.finish.isSame(nextInterval.start)) {
        freeIntervals.push(currentInterval);
      } else {
        if(i === 0) freeIntervals.push(currentInterval); 
        break
      }    
    }

    const lastInterval = freeIntervals[freeIntervals.length - 1];

    if(lastInterval) {
      intervals.forEach(v => {
        if(lastInterval.finish.isSame(v.start)) {
          freeIntervals.push(v);
        }
      })
    }   

   if(!freeIntervals?.length && intervals.length) {
    freeIntervals.push(intervals[intervals.length - 1])
   }

  
    return freeIntervals
  }
}
