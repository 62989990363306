<div class="custom_dialog game_moderator">
  <div class="container_popup_test">
    <img class="img_background" [src]="question.image ? question.image : 'assets/images/popup_test.png'">
    <div class="button_mobile_alarm">
      <button class="alarm_button" (click)="scrollTo()">Принять вызов</button>
    </div>
    <div class="container_test" id="scroll">
      <div class="container_timer">
        <div class="timer"></div>
        {{timeleft | msToTime}}
      </div>
      <div class="container_button_cells popup">
        <form class="question_form" [formGroup]="form" enctype="multipart/form-data">
          <div class="question_container">
            <div class="question_text">{{question.text}}</div>
            <div class="question">
              <ng-container *ngIf="question.type === 'radio'">
                <mat-radio-group class="options-group" formControlName="{{question.id}}">
                  <mat-radio-button
                    value="{{option.id}}"
                    translate="no"
                    class="options-button"
                    *ngFor="let option of question.options; let i = index"
                  >
                    <span [innerHTML]="option.text | safeHtml"></span>
                  </mat-radio-button>
                </mat-radio-group>
              </ng-container>

              <ng-container *ngIf="question.type === 'checkbox'">
                <div class="options-group" formArrayName="{{question.id}}">
                  <mat-checkbox
                    formControlName="{{i}}"
                    value="{{option.id}}"
                    translate="no"
                    class="options-button"
                    *ngFor="let option of question.options; let i = index"
                  >
                    <span [innerHTML]="option.text | safeHtml"></span>
                  </mat-checkbox>
                </div>
              </ng-container>

              <ng-container *ngIf="question.type === 'sort'">
                <div cdkDropList class="options-group" (cdkDropListDropped)="drop($event, question.id, question.options)">
                  <div
                    translate="no"
                    cdkDrag
                    class="options-button options-DropList"
                    *ngFor="let option of question.options; let i = index"
                  >
                    <span class="number">{{i + 1}}</span>
                    <span class="text" [innerHTML]="option.text | safeHtml"></span>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
        </form>
        <div class="container_coin">
          <div class="button_cells" (click)="answerQuestion()">{{lang === 'ru' ? 'ответить' : 'answer'}}</div>
          <div class="container_coin_alert">
            +{{question.scores}}
            <div class="coin_popup"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
