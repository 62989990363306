import { add } from 'core/api'
import { env } from 'env'

export function createNewsUrls() {
  const news = add(env.rootApiUrl, 'news/')

  return {
    getNewsPreviews: add(news, 'content/'),
    getNewsContent: add(news, 'document/'),
    markNewsAsRead: add(news, 'view/')
  }
}
