import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {Router} from "@angular/router";

@Component({
  selector: 'app-game-moderator-purchase-finish-dialog',
  templateUrl: './game-moderator-purchase-finish-dialog.component.html',
  styleUrls: ['./game-moderator-purchase-finish-dialog.component.scss'],
})

export class GameModeratorPurchaseFinishDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<GameModeratorPurchaseFinishDialogComponent>,
    private router: Router,
  ) {
  }

  mobile = false

  onConfirm(): void {
    this.dialogRef.close()
    this.router.navigate(['main'])
  }

  resize() {
    if (window.innerWidth <= 1024) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngOnInit() {
    this.resize()
  }
}
