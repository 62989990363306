import { AfterViewInit, ChangeDetectorRef, Component, ContentChildren, Input, QueryList, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DestroyRef } from 'core/common';
import { SelectOptionComponent } from './select-option/select-option.component';
import { takeUntil } from 'rxjs/operators';
import { fadeAnimation } from 'core/animations/fade';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    DestroyRef
  ],
  animations: [fadeAnimation]
})
export class SelectComponent implements ControlValueAccessor, AfterViewInit  {
  @Input() placeholder: string;
  @Input() blocked: boolean;
  
  checkedItems: string[] = [];
  
  isOpen = false;

  value: number[] = [];

  onTouched: Function = () => {};
  onChange: Function = () => {};

  @ContentChildren(SelectOptionComponent) radioButtons: QueryList<SelectOptionComponent>;
  
  constructor(
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
  ) { }

  writeValue(value: number[]): void {
    this.value = value ?? [];
    this.cdr.markForCheck();
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  ngAfterViewInit(): void {
    this.initButtons();

    setTimeout(() => {
      if(this.value && this.value.length) {
        this.value.forEach(v => {
          this.radioButtons.forEach(r => {
            if(v === r.value) {
              r.checked = true;
              this.checkedItems.push(r.label)
            }
          })
        })
      }
    }, 0);
  }

  toggleVisibility($event: MouseEvent): void {
    $event.stopPropagation();
    this.isOpen = !this.isOpen;
  }

  private initButtons(): void {
    this.radioButtons.forEach(button => {
      button.add
      .pipe(
        takeUntil(this.destroyRef)
      )
      .subscribe(value => {
        this.checkedItems.push(value.label)
        this.changeVal([value.value, ...this.value])
      });

      button.remove
      .pipe(
        takeUntil(this.destroyRef)
      )
      .subscribe(value => {
        this.checkedItems = this.checkedItems.filter(v => v !== value.label);
        this.changeVal(this.value.filter(v => v !== value.value))
      });
    });
  }

  private changeVal(value: any): void {
    if(value !== this.value) {
      this.value = value;
      this.onChange(value);
    }
  }
}
