import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { env } from 'env'
import { FormControl } from '@angular/forms';
import { CatalogQuery } from './catalogs-query';

@Injectable({
  providedIn: 'root'
})
export class CatalogsSearchService {
  private rootApi: string = env.rootApiUrl;
  private limit: number = 8;
  searchForm: FormControl = new FormControl('');
  
  //whether to show or hide dropdown menu
  private isVisibleSubject:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isVisible$: Observable<boolean> = this.isVisibleSubject.asObservable();

  private offsetSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  
  constructor(private http: HttpClient) {}
  
  // for AutoComplete
   getOptions(value: string): Observable<any[]> {
    return this.http.post<any[]>(this.rootApi + CatalogQuery.autocomplete,{query: value});
  }

  //for Product card
   getProducts(): Observable<any[]> {
      return this.http.post<any[]>(this.rootApi + CatalogQuery.search,{query: this.searchForm.value, limit: this.limit, offset: this.offsetSubject.value});
  }

  // toggle dropdown
  onToggleDropdown(state: boolean): void {
    this.isVisibleSubject.next(state);
  }

  //set pagination offset
  setOffset(offsetType: 'continue' | 'new'): void {
    if(offsetType === 'new') {
      this.offsetSubject.next(0);
    } else {
      this.offsetSubject.next(this.offsetSubject.value + this.limit);
    }
  }
}
