import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BaseService} from "services/api";
import { AlertDialogComponent } from "../alert/alert-dialog.component";
import {DialogService} from "services";
import moment from 'moment';

@Component({
  selector: 'app-booking-dialog',
  templateUrl: './booking-dialog.component.html',
  styleUrls: ['./booking-dialog.component.scss'],
})
export class BookingDialogComponent implements OnInit {

  id: number
  reservation: any;
  disableAll: boolean;

  constructor(
    private dialogRef: MatDialogRef<BookingDialogComponent>,
    private baseService: BaseService,
    private dialogService: DialogService,
    private matDialogService: MatDialog,
    @Inject(MAT_DIALOG_DATA) private dialogData: {id: number,  callback: any},
  ) {
    ({
      id: this.id,
    } = this.dialogData)
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  deleteReservation() {
    this.dialogService.openDialog(AlertDialogComponent, {title: 'Вы действительно хотите удалить бронирование?', confirmButtonText: 'Да', notButtonText: 'Нет'}).subscribe((res: any) => {
      if (res) {
        this.baseService.baseBookingReservationDelete(this.id).subscribe(() => {
          this.dialogService.openDialog(AlertDialogComponent, {title: 'Бронирование успешно удалено', confirmButtonText: 'ОК'}).subscribe(() => {
            this.dialogData.callback();
            this.matDialogService.closeAll()
          })
        }, () => {
          this.dialogService.openDialog(AlertDialogComponent, {title: 'Произошла ошибка', confirmButtonText: 'ОК'})
        })
      }
    })
  }

  ngOnInit() {
    this.baseService.baseBookingReservationGet(this.id).subscribe((response: any) => {
      this.reservation = response;

      this.disableAll = moment(this.reservation.date).isBefore(moment(), 'day') || !response.edit;
    })
  }

}
