import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from 'services';

@Injectable({
  providedIn: 'root'
})
export class FolderResolver implements Resolve<boolean> {

  constructor(private baseService: BaseService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const param = route.params.folderId;
    const section = route.params.section;

    return this.baseService.documentsContent({id: param})
    .pipe(
      map((data: any) => {
        
        if (data.documents?.length) {
          return true
        } else if (data.boxes?.length < 2 && !data.boxes[0]?.center) {

          const boxId = data.boxes[0].id;

          if(data.self.template === 'template_homework') {
            return true
          }
          
          this.router.navigate([section, 'folder', boxId]);
          return false
        } else {
  
          return false
        }
      })
    )
  }
}
