import { Component, OnInit, OnDestroy } from "@angular/core";
import {DialogService, ExtraService, GameModeratorService, StorageService} from "services";
import { BaseService } from "services/api";
import {ActivatedRoute, Router} from "@angular/router";
import { AlertDialogComponent, GameModeratorAlertDialogComponent, GameModeratorAlertFinishDialogComponent} from "components/dialogs";
import { DestroyRef } from "core/common";
import { filter, map, switchMap, takeUntil } from "rxjs/operators";
import { GameModerLiteracyTestService } from "./game-moder-literacy-test.service";
import { GameModerTest, TestQuestion } from "app/api/game-moderator/test";
import { Observable } from "rxjs";

@Component({
  selector: 'app-game-moderator-literacy-test',
  templateUrl: 'game-moderator-literacy-test.component.html',
  styleUrls: ['game-moderator-literacy-test.component.scss'],
  providers: [DestroyRef, GameModerLiteracyTestService]
})

export class GameModeratorLiteracyTestComponent implements OnInit, OnDestroy {
  question: TestQuestion;
  options: Set<number>;
  isNext: boolean;
  counter$: Observable<number>;
  circleArr: number[] = [];

  answers = new Map()

  endTest = false;

  lang: string;

  // Таймер
  timeOut = false;
  time = 0;
  timeleft = this.time;
  timer = 0;
  startMoment = 0;
  endMoment = this.startMoment + this.timeleft * 1000;

  initiateTimer = false;

  alarmQuestions = []
  startAlarmQuestionsLength = 0
  alarmTimer = 0

  constructor(
    private route: ActivatedRoute,
    private storageService: StorageService,
    private baseService: BaseService,
    private dialogService: DialogService,
    private gameModerService: GameModeratorService,
    public extraService: ExtraService,
    private router: Router,
    private destroyRef: DestroyRef,
    private gameModerTestService: GameModerLiteracyTestService
  ) {
  }

  ngOnInit() {
    this.counter$ = this.gameModerTestService.counter$;

    this.listenToQuestion();
    this.listenToAnswers();
    this.listenParams();
  }

  ngOnDestroy() {
    clearInterval(this.alarmTimer)
  }

  private listenToAnswers(): void {
    this.gameModerTestService.answers$
    .pipe(
      filter(v => v !== null),
      takeUntil(this.destroyRef)
    )
    .subscribe({
      next: v => {
        const currentAnswer = v.find(a => a.question_id === this.question.id);

        this.options = currentAnswer.options;
      }
    })
  }

  // show current question
  private listenToQuestion(): void {
    this.gameModerTestService.currentQuestion$
    .pipe(
      switchMap(v => (
        this.gameModerTestService.question$.pipe(map(q => q[v]))
      )),
      takeUntil(this.destroyRef)
    )
    .subscribe({
      next: v => {
       this.question = v;
      }
    })
  }

  //toggle isNext
  private listenToCounter(): void {
    this.gameModerTestService.counter$
    .pipe(
      map(v => {
        const questionLength = this.gameModerTestService.question.length - 1;
        return questionLength === v
      } ),
      takeUntil(this.destroyRef)
    )
    .subscribe({
      next: v => {
        this.isNext = v;
      }
    })
  }

  //listen params to get id
  private listenParams(): void {
    this.route.params
    .pipe(
      map(v => +v.id),
      takeUntil(this.destroyRef)
    )
    .subscribe((id: number) => {
      this.gameModerService.getLang();
      this.lang = this.storageService.getValue('game_moder_lang');
      this.getTest(id);
    })
  }

  private getTest(id: number): void {
    this.baseService.baseGameModerAttemptStart(id)
    .pipe(
      takeUntil(this.destroyRef)
    )
    .subscribe((d: GameModerTest) => {
      this.gameModerTestService.question = d.questions;
      this.gameModerTestService.attemptSubject.next(d.attempt_id);
      this.circleArr = Array(d.questions.length).fill(1);

      const answers = [];

      d.questions.forEach(q => {
        answers.push({question_id: q.id, options: new Set<number>()})
      })

      this.gameModerTestService.answers = answers;

      this.alarmQuestions = d.alarm
      this.startAlarmQuestionsLength = d.alarm.length;

      this.initAlarm(d.time);
      this.listenToCounter();
    })
  }

  //Alarm methods
  private initAlarm(time: number): void {
    if (time) {
      this.time = +time;
      this.timeleft = this.time * 60;
      this.initCountdown();
      this.initAlertCountdown()
    }
  }

  private initCountdown() {
    this.initiateTimer = true;
    this.startMoment = Date.now();
    this.endMoment = this.startMoment + this.timeleft * 1000;
    this.timer = window.setInterval(() => {
      const now = Date.now();
      this.timeleft = Math.round((this.endMoment - now) / 1000);
      if (this.timeleft <= 0) {
        clearInterval(this.timer);
        this.timeleft = this.time;
        this.timeOut = true;
        this.submit();
      }
    }, 1000);
  }

  private initAlertCountdown() {
    if (this.alarmQuestions.length > 0) {
      let time = 0
      if (this.alarmQuestions.length !== 0) {
        time = (30 / this.startAlarmQuestionsLength) + Math.floor(Math.random() * (5 - 1 + 1)) + 1
      } else {
        time = 0
      }
      this.alarmTimer = window.setInterval(() => {
        time -= 1
        if (time <= 0) {
          clearInterval(this.alarmTimer)
          this.questionAlert()
        }
      }, 1000)
    }
  }

  private questionAlert() {
    if (this.alarmQuestions.length > 0) {
      const attempId = this.gameModerTestService.attemptSubject.getValue();
      let question = this.alarmQuestions.shift()
      this.dialogService.openDialog(GameModeratorAlertDialogComponent, {question: question}).subscribe((res: any) => {
        res.attempt_id = attempId;
        this.baseService.baseGameModerAlertSave(res).subscribe((response: any) => {
          this.dialogService.openDialog(GameModeratorAlertFinishDialogComponent, {question: question, right: response.is_right}).subscribe()
        })
        if (!this.endTest) {
          this.initAlertCountdown()
        } else {
          clearInterval(this.alarmTimer)
        }
        if (this.endTest && this.alarmQuestions.length !== 0) {
          this.questionAlert()
        } else if (this.endTest && this.alarmQuestions.length === 0) {
          this.submit()
        }
      })
    }
  }

  pause() {
    clearInterval(this.timer)
    clearInterval(this.alarmTimer)
    this.extraService.gamePaused = true
    if (this.lang === 'ru') {
      this.dialogService.openDialog(AlertDialogComponent, {title: 'Вы поставили игру на паузу', confirmButtonText: 'Продолжить'}).subscribe(() => {
        this.extraService.gamePaused = false
        this.initCountdown()
        this.initAlertCountdown()
      })
    } else {
      this.dialogService.openDialog(AlertDialogComponent, {title: 'You paused the game', confirmButtonText: 'Continue'}).subscribe(() => {
        this.extraService.gamePaused = false
        this.initCountdown()
        this.initAlertCountdown()
      })
    }

  }
  //

  onNext(): void {
    const lastQ = this.gameModerTestService.currentQ;
    const lastCount = this.gameModerTestService.counter;

    this.gameModerTestService.currentQ = lastQ + 1;
    this.gameModerTestService.counter = lastCount + 1;
  }

  goBack() {
    this.router.navigate(['game-moderator','stages']);
  }

  addAnswers(id: number): void {
    if(this.question.type === 'radio') {
      this.radioMode(id);
    } else {
      this.checkboxMode(id);
    }
  }

  private radioMode(id: number): void {
    const answers = this.gameModerTestService.answers;
    const matchedQ = answers.findIndex(q => q.question_id === this.question.id);

    answers[matchedQ].options.clear();
    answers[matchedQ].options.add(id);

    this.gameModerTestService.answers = answers;
  }

  private checkboxMode(id: number): void {
    const answers = this.gameModerTestService.answers;
    const matchedQ = answers.findIndex(q => q.question_id === this.question.id);

    if(answers[matchedQ].options.has(id)) {
      answers[matchedQ].options.delete(id);
    } else {
      answers[matchedQ].options.add(id);
    }

    this.gameModerTestService.answers = answers;
  }

  //submit methods
  submit() {
    this.endTest = true;
    const answers = this.gameModerTestService.answers;
    const attemptId = this.gameModerTestService.attemptSubject.getValue();
    const finalData = answers.map(v => ({question_id: v.question_id, options: [...v.options]}));
    const data = { attempt_id: attemptId, answers: finalData.filter(v => v.options.length)};

    if (this.alarmQuestions.length === 0) {
      this.baseService.baseGameModerAttemptFinish(data).subscribe((response: any) => {
        if (this.lang === 'ru') {
          if (response.result === 100) {
            this.dialogService.openDialog(AlertDialogComponent, {title: 'Задание выполнено + ' + response.scores, confirmButtonText: 'ОК'}).subscribe(() => {
              this.router.navigate(['game-moderator/stages'])
            })
          } else {
            this.dialogService.openDialog(AlertDialogComponent, {title: 'Задание не выполнено. Рекомендуем пройти обучение. Желаем удачи в следующий раз.', confirmButtonText: 'ОК'}).subscribe(() => {
              this.router.navigate(['game-moderator/stages'])
            })
          }
        } else {
          if (response.result === 100) {
            this.dialogService.openDialog(AlertDialogComponent, {title: 'Task completed + ' + response.scores, confirmButtonText: 'ОК'}).subscribe(() => {
              this.router.navigate(['game-moderator/stages'])
            })
          } else {
            this.dialogService.openDialog(AlertDialogComponent, {title: 'Task not completed. We recommend getting trained. We wish you good luck next time.', confirmButtonText: 'ОК'}).subscribe(() => {
              this.router.navigate(['game-moderator/stages'])
            })
          }

        }
      })
    } else {
      this.timeleft = 0
      clearInterval(this.timer)
      this.questionAlert()
    }
  }
}
