import { Component, OnInit } from '@angular/core'

import { animate, state, style, transition, trigger } from '@angular/animations'
import { BaseService } from 'services/api'
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common'
import { ExtraService } from 'services'

@Component({
  selector: 'app-game-stage',
  templateUrl: 'game-stage.component.html',
  styleUrls: ['game-stage.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class GameStageComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private baseService: BaseService,
    public extraService: ExtraService
  ) {
  }

  gameId: number
  stageId: number
  brand: string
  text: string
  backgroundTest: string
  testId: number

  goBack() {
    this.location.back()
  }

  goTest() {
    this.router.navigate([`game/${this.gameId}/${this.brand}/stage/${this.stageId}/test/${this.testId}`])
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.gameId = params.id
      this.brand = params.brand
      this.stageId = params.stageId
      this.route.queryParams.subscribe((query: any) => {
        this.testId = query.testId
      })
      this.baseService.baseGetGameStage(this.stageId).subscribe((response: any) => {
        this.text = response.text
        this.extraService.backgroundTest = response.background
      })
    })
  }

}
