<div class="header_osn">
  <app-icon-loader [name]="'logo'" class="svg_logo"></app-icon-loader>
</div>

<div class="outer auth" *ngIf="!showReg" [ngStyle]="{'backgroundImage': 'url(assets/images/auth_bg.png)'}">
  <div class="form_container" [@show_form]="showForm">

    <form class="form" [formGroup]="auth" *ngIf="page === 'auth' && !showReg" (ngSubmit)="validateForm('auth')">
      <div class="inputs_container">
        <div class="input_container">
          <input type="text" placeholder="Логин" (change)="checkEmptyEmailField()" formControlName="email"/>
        </div>
        <div class="input_container" *ngIf="showPasswordField">
          <input type="{{passwFieldType}}" placeholder="Пароль" formControlName="password"/>
          <div [ngClass]="{eye_open: passwFieldType === 'password', eye_close: passwFieldType === 'text'}" (click)="changeInputPasswordType()"></div>
        </div>
      </div>
      <div class="rules_container">
        <mat-checkbox (change)="changeAgreement($event)"></mat-checkbox>
        <span>Согласие на <span class="underline" (click)="onRules()">обработку данных</span></span>
      </div>
      <div class="button_container">

        <button class="button auth" [disabled]="auth.invalid" type="submit"  *ngIf="showPasswordField" [ngClass]="{inactive: inactiveButton}">Войти по логину</button>
        <button class="button auth" type="submit" *ngIf="showSsoButton" [ngClass]="{inactive: inactiveButton}">Войти через почту @loreal.com</button>
        <button type="submit" class="button" *ngIf="showCheckEmailButton" (click)="checkEmailAuth()">Далее</button>

        <span class="underline restore_passw" (click)="changeGroup('reg')" [class.active]="showReg">Регистрация</span>
        <span class="underline restore_passw" (click)="changePage('update')">Восстановить пароль</span>
        <span class="underline support" >
          <a class="support_a" href="mailto:support@learncpd.loreal.com.ru">Техническая поддержка: support@learncpd.loreal.com.ru</a>
        </span>
      </div>
    </form>

    <form class="form" [formGroup]="update" *ngIf="page === 'update'" (ngSubmit)="validateForm('update')">
      <div class="inputs_container">
        <div class="input_container">
          <input type="text" placeholder="Логин" formControlName="email"/>
        </div>
      </div>
      <div class="button_container">
        <div class="button" (click)="validateForm('update')">{{updateButtonText}}</div>
        <span class="underline restore_passw" (click)="changePage('auth')">Назад</span>
      </div>
    </form>

  </div>
</div>

<div class="outer auth_reg" *ngIf="showReg" [ngStyle]="{'backgroundImage': 'url(assets/images/background_auth_reg.png)'}">
  <div class="form_container reg">
    <div class="icon_back_reg">
      <div class="icon_back" (click)="backAuth('auth')"></div>
      назад
    </div>
    <form class="form"[formGroup]="signUpForm" *ngIf="showReg" (ngSubmit)="signUp(signUpForm)">
      <div class="inputs_container">
        <ng-container *ngFor="let reference of signUpInputsArray">
          <div class="input_container" *ngIf="inputs[reference].input.type != 'select'">
            <input type="text" [placeholder]="inputs[reference].input.label" formControlName="{{reference}}">
          </div>

          <div class="input_container" *ngIf="inputs[reference].input.type == 'select' && inputs[reference].input.widget =='SelectMultiple'">
            <!--            <input type="" [placeholder]="inputs[reference].input.label" formControlName="{{reference}}">-->
            <mat-select
              multiple
              class="reg_select"
              [placeholder]="inputs[reference].input.label"
              formControlName="{{reference}}"
            >
              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="let brand of inputs[reference].choices"
                [value]="brand.id"
              >
                {{brand.title}}
              </mat-option>
            </mat-select>
          </div>
          <div class="input_container" *ngIf="inputs[reference].input.type == 'select' && inputs[reference].input.widget =='Select'">
            <!--            <input type="" [placeholder]="inputs[reference].input.label" formControlName="{{reference}}">-->
            <mat-select
              class="reg_select"
              [placeholder]="inputs[reference].input.label"
              formControlName="{{reference}}"
            >
              <mat-option [value]="null">--</mat-option>
              <mat-option
                *ngFor="let input of inputs[reference].choices"
                [value]="input.id"
              >
                {{input.title}}
              </mat-option>
            </mat-select>
          </div>
        </ng-container>
      </div>
      <div class="button_container" style="margin-top: 45px">
        <button class="button" [disabled]="signUpForm.invalid" type="submit">Зарегистрироваться</button>
      </div>
    </form>
  </div>
</div>
