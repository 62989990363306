import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BaseService } from "services/api";
import { DialogService } from "services";
import { AlertDialogComponent } from "../alert/alert-dialog.component";
import { BookingCreateData, BookingInterval, BookingOwner, BookingQuery, BookingRoom } from 'app/api/profile/booking';
import { MatSelectChange } from '@angular/material/select';
import { DestroyRef } from 'core/common';
import { map, takeUntil, toArray } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import  * as _moment from 'moment';
import { env } from 'env';
import { default as _rollupMoment } from 'moment';
import { CalendarInterval } from 'app/typings/calendar';
import { Observable, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'D MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-booking-create-dialog',
  templateUrl: './booking-create-dialog.component.html',
  styleUrls: ['./booking-create-dialog.component.scss'],
  providers: [
    DestroyRef,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE,MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class BookingCreateDialogComponent implements OnInit {
  owners: BookingOwner[] = [];
  intervals: CalendarInterval[] = [];
  rooms: BookingRoom[] = [];
  form: FormGroup;
  owner: BookingOwner;

  dateFilter = (date: Date): boolean => !moment(date).isBefore(moment(), 'day');

  constructor(
    private dialogRef: MatDialogRef<BookingCreateDialogComponent>,
    private baseService: BaseService,
    private dialogService: DialogService,
    private matDialogService: MatDialog,
    private destroyRef: DestroyRef,
    private http: HttpClient,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DIALOG_DATA) private dialogData: { 
      rooms: BookingRoom[], 
      intervals: CalendarInterval[], 
      owners: BookingOwner[], 
      callback: any 
    },
  ) {
    ({ rooms: this.rooms, intervals: this.intervals, owners: this.owners } = this.dialogData);
  }

  ngOnInit() {
    this.initForm();
    this.setOwner();
    this._adapter.setLocale('ru');
  }

  private setOwner(): void {
    this.owner = this.owners[0];
    this.form.patchValue({owner: this.owners[0].id})
  }

  private getIntervalsRes(room: number, date: number): Observable<BookingInterval[]> {
    return this.http.post<BookingInterval[]>(env.rootApiUrl + BookingQuery.intervals, {filters: {room: room, date: date}});
  }

  private initForm(): void {
    this.form = new FormGroup({
      title: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      date: new FormControl(null, [Validators.required]),
      room: new FormControl(null, [Validators.required]),
      owner: new FormControl(null, [Validators.required]),
      start: new FormControl(null, [Validators.required]),
      finish: new FormControl(null, [Validators.required]),
    })
  }
  
  private successDialog(): void {
    const title = 'Бронирование успешно создано';
    const confirmButtonText = 'ОК';

    this.dialogService.openDialog(AlertDialogComponent, { title, confirmButtonText })
      .pipe(
        takeUntil(this.destroyRef)
      )
      .subscribe(() => {
        this.dialogData.callback();

        this.matDialogService.closeAll()
      })
  }

  private errorDialog(text?: string): void {
    const title = 'Произошла ошибка';
    const confirmButtonText = 'ОК';

    this.dialogService.openDialog(AlertDialogComponent, { title, text, confirmButtonText })
  }

  private buildFinalData(): BookingCreateData {
    const form = this.form.value;

    return {
      title: form.title,
      date: moment(form.date).utc(true).valueOf(),
      room: form.room,
      owner: form.owner,
      intervals: [form.start.id, form.finish]
    }
  }

  private transformToDate(intervals: BookingInterval[]): void {
    from(intervals)
    .pipe(
      map(v => {
        const id = v.id;
        const start = moment(v.start, 'HH:mm');
        const finish = moment(v.finish, 'HH:mm');
  
        return {id, start, finish}
      }),
      toArray()
    )
    .subscribe({
      next: v => {
        this.intervals = v;
      }
    })
  }

  startValueChange(): void {
    this.form.patchValue({ finish: null });
  }

  roomValueChange(): void {
    this.form.patchValue({ date: null, start: null, finish: null })
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  selectSection(event: MatSelectChange) {
    this.owner = this.owners.find(item => item.id === event.value);
  }

  onGetIntervals($event: moment.Moment): void {
    const room = this.form.get('room').value;
    const date = moment($event).utc(true).valueOf();

    this.form.patchValue({ start: null, finish: null});
    this.intervals = [];

    this.getIntervalsRes(room, date).subscribe({next: v => this.transformToDate(v)})
  }

  submit() {
    if (this.form.valid) {
      this.baseService.baseBookingReservationCreate(this.buildFinalData())
        .pipe(
          takeUntil(this.destroyRef)
        )
        .subscribe({
          next: _ => this.successDialog(),
          error: _ => this.errorDialog()
        })
    } else {
      this.errorDialog('Заполните все поля');
    }
  }
}
