import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { animate, state, style, transition, trigger } from '@angular/animations'

import { AuthService, DialogService, StorageService } from 'services'
import { AlertDialogComponent } from 'components/dialogs'
import { env } from 'env'
import { AuthGuard } from 'modules/root/guards'

@Component({
  selector: 'app-auth',
  templateUrl: 'auth.component.html',
  styleUrls: ['auth.component.scss'],
  animations: [
    trigger('show_form', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(600, style({
          opacity: 1,
        })),
      ]),
      transition('none => show', [
        style({
          opacity: 0,
        }),
        animate(600, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})
export class AuthComponent implements OnInit {
  showForm = 'show';
  auth: FormGroup;
  update: FormGroup;
  signUpForm: FormGroup;
  signUpInputsArray: any;
  inputs: any;

  passwFieldType: 'password' | 'text' = 'password';

  page = 'auth';
  endEmail = '';
  showPasswField = false;
  changeEnterButtonText = 'ВХОД';
  updateButtonText = 'ОТПРАВИТЬ';
  enterPhase = true;
  enterPhaseType = 'withoutSso';
  inactiveButton = true;

  showCheckEmailButton = true;
  showPasswordField = false;
  showSsoButton = false;

  showReg: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dialogService: DialogService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private authGuard: AuthGuard
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      if (Object.keys(params).length < 1) {
        return;
      } else {
        if (params.status !== 'False') {
          this.authService.saveNewTokens(params);
          this.router.navigate(['fork']);
        } else {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('incorrect-email'));
        }
      }
    })
    this.auth = new FormGroup({
      email: new FormControl('',  [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });

    this.update = new FormGroup({
      email: new FormControl('',  [Validators.required, Validators.email]),
    })

    if (this.storageService.getValue('email') && this.storageService.getValue('email') !== '') {
      this.auth.get('email').setValue(this.storageService.getValue('email'))
      if (this.auth.get('email').value.split('@')[1] === 'loreal.com' || this.auth.get('email').value.split('@')[1] === 'loreal.ru') {
        this.checkEmptyEmailField(true)
      }
    }

    this.authService.getForm().subscribe((data) => {
      this.inputs = data
      this.initSignUpForm()
    })
  }

  initSignUpForm(): void {
    const signUpForm: any = {};
    this.signUpInputsArray = Object.keys(this.inputs);
    Object.keys(this.inputs).forEach(ref=>{
      const validators: any[] = [Validators.required];

      signUpForm[ref] = ['', validators];
    })

    this.signUpForm = this.formBuilder.group(signUpForm);
  }

  changeInputPasswordType() {
    this.passwFieldType = (this.passwFieldType === 'password') ? 'text' : 'password';
  }

  changeDialogData(type) {
    let data = {
      'confirmButtonText': 'ОК'
    }

    switch (type) {
      case 'incorrect-email':
        data['title'] = 'Введен некорректный email.'
        break
      case 'sso-update-detected':
        data['title'] = 'Пожалуйста, воспользуйтесь корпоративным входом.'
        break
      case 'authentication failed':
        data['title'] = 'Произошла ошибка. Попробуйте еще раз.'
        break
      case 'count exceed':
        data['title'] = 'Превышен лимит неудачных попыток. Попробуйте ещё раз через 30 минут.'
        break
      case 'total exceed':
        data['title'] = 'Превышен лимит неудачных попыток. Пожалуйста обратитесь к администратору или руководителю.'
        break
      case 'suc-change-passw':
        data['title'] = 'Новый пароль отправлен на почту.'
        break
      case 'auth-reg':
        data['title'] = 'Вы успешно зарегистрировались.'
        break
      default:
        data['title'] = 'Произошла ошибка.'
        break
    }

    return data
  }

  changeAgreement(event) {
    if (event.checked) {
      this.inactiveButton = false;
      return;
    }

    this.inactiveButton = true;
  }

  checkEmptyEmailField(ssoEmail?) {
    if (ssoEmail) {
      this.showCheckEmailButton = false
      this.showPasswordField = false
      this.showSsoButton = true
      return
    }

    if (this.auth.get('email').value === '' || this.update.get('email').value === '') {
      this.showCheckEmailButton = true
      this.showPasswordField = false
      this.showSsoButton = false
    }

    const toTrim = this.auth.get('email').value.trim();

    this.auth.patchValue({
      email: toTrim
    })
  }

  checkEmailAuth() {
    let endEmail = ''

    if (this.page === 'auth') {
      endEmail = this.auth.get('email').value.split('@')[1];
    } else {
      endEmail = this.update.get('email').value.split('@')[1];
    }

    if (endEmail) {
      if (endEmail === 'loreal.com' || endEmail === 'loreal.ru') {
        this.showCheckEmailButton = false
        this.showPasswordField = false
        this.showSsoButton = true

        return
      }

      this.showCheckEmailButton = false
      this.showPasswordField = true
      this.showSsoButton = false

      return
    }

    this.showCheckEmailButton = true;
    this.showPasswordField = false;
    this.showSsoButton = false;

    this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('email-error'));
  }

  checkEmail(page) {
    switch (page) {
      case 'auth':
        this.endEmail = this.auth.get('email').value.split('@')[1];
        break
      case 'update':
        this.endEmail = this.update.get('email').value.split('@')[1];
        break
    }
  }

  validateForm(type) {
    if (type === 'auth') {
      if (!this.inactiveButton && this.showPasswordField) {
        this.authService.signIn(this.auth.get('email').value.toLowerCase(), this.auth.get('password').value).subscribe((response: any) => {
          this.authService.saveNewTokens(response);
          this.storageService.setValue('email', this.auth.get('email').value.toLowerCase())
          this.enterWithoutSso();
        }, ((err) => {
          this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(err.error.error));
        }));
      }

      if (!this.inactiveButton && this.showSsoButton) {
        this.enterWithSso()
      }
    } else if (type === 'update') {
      if (this.update.get('email').errors) {
        this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('incorrect-email'))
      } else {
        this.updatePassword();
      }
    }
  }

  changePage(type) {
    this.showCheckEmailButton = true;
    this.showPasswordField = false;
    this.showSsoButton = false;
    this.auth.reset();
    this.update.reset();
    this.page = type;
  }


  enterWithoutSso() {
    this.authService.signIn(this.auth.get('email').value.toLowerCase(), this.auth.get('password').value).subscribe((response: any) => {
      this.authService.saveNewTokens(response);
      const lastLink = this.authGuard.lastLink;

      if (lastLink) {
        this.router.navigateByUrl(lastLink).then(() => {
          this.authGuard.lastLink = null;
        })
      } else {
        this.router.navigate(['fork']);
      }
    }, (err) => {
      this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(err.error.error));
    });
  }

  enterWithSso() {
    location.assign(env.ssoUrl);
  }

  updatePassword() {
    this.checkEmail(this.page);

    if (this.endEmail === 'loreal.com' || this.endEmail === 'loreal.ru') {
      this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('sso-update-detected'))
      return
    }

    this.authService.resetPassword(this.update.get('email').value.toLowerCase()).subscribe(() => {
      this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('suc-change-passw'));
    }, () => {
      this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('error'));
    });
  }

  onRules() {
    this.authService.authGetRules('ru').subscribe((response: any) => {
     let tab = window.open('about:blank', '_blank');
      tab.document.write(response.text);
      tab.document.close();
    })
  }

  changeGroup(group: 'reg' | 'auth') {
    if (group == "reg"){
      this.showReg = true
    } else {
      this.showReg = false
    }
  }

  signUp(signUpForm: FormGroup) {
    const user = {}

    this.signUpInputsArray.forEach(ref=>{
      user[ref] = signUpForm.value[ref]
    })

    this.authService.signUp(user).subscribe( (response: any) => {
      this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData('auth-reg'));
    }, (err) => {
      this.dialogService.openDialog(AlertDialogComponent, this.changeDialogData(err.error.error));
    });

    signUpForm.reset()
    this.showReg = false
  }

  backAuth(group: 'reg' | 'auth') {
    if (group == "auth"){
      this.showReg = false
    } else {
      this.showReg = true
    }
  }
}
