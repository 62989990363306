import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseService } from 'services/api';
import { StorageService } from 'services';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {Location} from "@angular/common";

@Component({
  selector: 'app-catalogs-comparison',
  templateUrl: './catalogs-comparison.component.html',
  styleUrls: ['./catalogs-comparison.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0
      })),
      state('show', style({
        opacity: 1
      })),
      transition('void => show', [
        style({
          opacity: 0
        }),
        animate(500, style({
          opacity: 1
        }))
      ])
    ])
  ]
})
export class CatalogsComparisonComponent implements OnInit {

  showPrev = 'show';

  folderId = undefined;
  catalogId = undefined;

  comparisonInfo = undefined;

  sliderBt = false;
  sliderLength = 4;
  sliderOffset = this.sliderLength;

  sliderProducts = [];
  sliderComparison = [];

  compareSt = false;

  constructor(
    private router: Router,
    private api: BaseService,
    private route: ActivatedRoute,
    private location: Location,
    private storage: StorageService
  ) {}

  goBack() {
    this.router.navigate( [ 'catalogs/' + this.folderId + '/list/' + this.catalogId ] );
  }

  btNp(type) {
    if (type === 'back' && this.sliderProducts[0].id === this.comparisonInfo.products[0].id) {
      console.log('back');
      return;
    } else if (
      type === 'next' &&
      this.sliderProducts[this.sliderProducts.length - 1].id === this.comparisonInfo.products[this.comparisonInfo.products.length - 1].id
    ) {
      console.log('next');
      return;
    } else {
      const sev_comparison = this.sliderComparison;
      this.sliderComparison = [];
      if (type === 'back') {
        this.sliderOffset--;
        this.sliderProducts.unshift(this.comparisonInfo.products[this.sliderOffset - this.sliderLength]);
        this.sliderProducts.pop();
        for (let c = 0; c < this.comparisonInfo.comparison.length; c++) {
          const new_tb = sev_comparison[c].values;
          new_tb.unshift(this.comparisonInfo.comparison[c].values[this.sliderOffset - this.sliderLength]);
          new_tb.pop();
          console.log(new_tb);
          this.sliderComparison.push({title: this.comparisonInfo.comparison[c].title, values: new_tb});
        }
      } else {
        this.sliderProducts.push(this.comparisonInfo.products[this.sliderOffset]);
        this.sliderProducts.shift();
        for (let c = 0; c < this.comparisonInfo.comparison.length; c++) {
          const new_tb = sev_comparison[c].values;
          new_tb.push(this.comparisonInfo.comparison[c].values[this.sliderOffset]);
          new_tb.shift();
          console.log(new_tb);
          this.sliderComparison.push({title: this.comparisonInfo.comparison[c].title, values: new_tb});
        }
        this.sliderOffset++;
      }
    }
    console.log(this.sliderOffset);
  }

  onProduct(info) {
    const index = this.storage.getValue('compare')['products'].map(item => item.id).indexOf(info.id);
    this.router.navigate( [
      'catalogs/' +
      this.storage.getValue('compare')['products'][index]['folder_id'] +
      '/list/' +
      this.storage.getValue('compare')['products'][index]['catalog_id'] +
      '/card/' +
      info.id
    ]);
  }

  delProduct(info) {
    const products = this.storage.getValue('compare')['products'];
    const products_id = this.storage.getValue('compare')['products'].map(item => item.id);
    const index = products_id.indexOf(info.id);
    console.log(index);
    products.splice(index, 1);
    this.storage.setValue('compare', {'products': products});

    const api_products_id = this.comparisonInfo.products.map((item) => item.id).indexOf(info.id);
    console.log(api_products_id);
    this.comparisonInfo.products.splice(this.comparisonInfo.products.map((item) => item.id).indexOf(info.id), 1);
    if (this.comparisonInfo.products.length === 0) {
      this.location.back()
    }
    console.log(api_products_id);
    for (let c = 0; c < this.comparisonInfo.comparison.length; c++) {
      this.comparisonInfo.comparison[c].values.splice(api_products_id, 1);
    }
    console.log(this.comparisonInfo);

    const slider_products_id = this.sliderProducts.map((item) => item.id).indexOf(info.id);
    const ter = this.sliderOffset - this.sliderLength;
    console.log(ter);
    this.sliderProducts.splice(this.sliderProducts.map((item) => item.id).indexOf(info.id), 1);
    console.log(this.comparisonInfo.products[this.sliderOffset - 1]);
    console.log(this.comparisonInfo.products[this.sliderOffset - this.sliderLength - 1]);
    console.log(this.sliderOffset - this.sliderLength - 1);
    for (let c = 0; c < this.comparisonInfo.comparison.length; c++) {
      this.sliderComparison[c].values.splice(slider_products_id, 1);
    }

    if (this.comparisonInfo.products[this.sliderOffset - 1]) {
      this.sliderProducts.push(this.comparisonInfo.products[this.sliderOffset - 1]);

      for (let c = 0; c < this.comparisonInfo.comparison.length; c++) {
        this.sliderComparison[c].values.push(this.comparisonInfo.comparison[c].values[this.sliderOffset - 1]);
      }
    } else if (this.comparisonInfo.products[this.sliderOffset - this.sliderLength - 1]) {
      this.sliderProducts.unshift(this.comparisonInfo.products[this.sliderOffset - this.sliderLength - 1]);
      for (let c = 0; c < this.comparisonInfo.comparison.length; c++) {
        this.sliderComparison[c].values.unshift(this.comparisonInfo.comparison[c].values[this.sliderOffset - this.sliderLength - 1]);
      }
      this.sliderOffset--;
    } else {
      this.sliderLength = this.comparisonInfo.products.length;
      this.sliderOffset--;
    }

  }

  resize() {
    if (window.innerWidth > 1025) {
      this.sliderLength = 4;
    } else if (window.innerWidth <= 1025 && window.innerWidth >= 610) {
      this.sliderLength = 3;
    } else {
      this.sliderLength = 2;
    }
    this.sliderOffset = this.sliderLength;
  }

  ngOnInit() {

    if (!this.storage.getValue('compare') || this.storage.getValue('compare')['products'].length === 0) {
      this.location.back()
    } else {
      this.resize();
      this.route.params.subscribe( params => {

        console.log(params);

        this.folderId = params['folder_id'];
        this.catalogId = params['catalog_id'];

        const products_id = this.storage.getValue('compare')['products'].map(item => item.id);

        this.api.catalogCompare(products_id).subscribe((response: any) => {
          console.log(response);
          this.comparisonInfo = response;

          if (response.products.length > this.sliderLength) {
            this.sliderBt = true;
          } else {
            this.sliderLength = response.products.length;
            this.sliderBt = false;
          }

          for (let n = 0; n < this.sliderLength; n++) {
            this.sliderProducts.push(response.products[n]);
          }
          for (let c = 0; c < response.comparison.length; c++) {
            const new_tb = [];
            for (let n = 0; n < this.sliderLength; n++) {
              new_tb.push(response.comparison[c].values[n])
            }
            this.sliderComparison.push({title: response.comparison[c].title, values: new_tb});
          }
          console.log(this.sliderProducts);
          console.log(this.sliderComparison);
        });

      });
    }

  }
}
