import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Router} from "@angular/router";
import {BaseService} from "services/api";
import {ExtraService, StorageService} from "services";

@Component({
  selector: 'app-store',
  templateUrl: 'store.component.html',
  styleUrls: ['store.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class StoreComponent implements OnInit {

  constructor(
    private router: Router,
    private baseService: BaseService,
    private storageService: StorageService,
    public extraService: ExtraService
  ) {
  }

  account: number
  products: any
  cart = []

  limit = 10
  offset = 0
  endScroll = false

  goBasket() {
    this.router.navigate(['/game-moderator-store/basket']);
  }

  paginationOn() {
    this.offset += this.limit;
    this.baseService.baseGetStoreProductList(this.limit, this.offset).subscribe((response: any) => {
      response.map((item) => {
        item.cart = false
      })
      Array.prototype.push.apply(this.products, response)
      this.checkCart()
      if (response.length < this.limit) {
        this.endScroll = true
      }
    })
  }

  setCart(product) {
    if (!product.cart) {
      this.cart[Object.keys(this.cart).length] = product
      this.storageService.setValue('cart', this.cart)
      this.checkCart()
    }
  }

  checkCart() {
    if (Object.keys(this.cart).length) {
      for (const key in this.cart) {
        this.products.find(item => item.id === this.cart[key].id).cart = true
      }
    }
  }

  ngOnInit() {
    this.extraService.backgroundStore = '/assets/images/game/bg_basket_mobile.png'
    if (this.storageService.getValue('cart')) {
      this.cart = this.storageService.getValue('cart')
    } else {
      this.storageService.setValue('cart', [])
    }
    this.baseService.baseGetStoreAccount().subscribe((response: any) => {
      this.account = response.value
    })
    this.baseService.baseGetStoreProductList(this.limit, this.offset).subscribe((response: any) => {
      response.map((item) => {
        item.cart = false
      })
      this.products = response
      this.checkCart()
    })
  }

}
