import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PersonalRoute } from 'app/constants/personal-routes';
import { DestroyRef } from 'core/common';
import { NgScrollbar } from 'ngx-scrollbar';
import { filter, takeUntil } from 'rxjs/operators';
import { ExtraService } from 'services/extra.service';
import { BaseLayoutService } from './base-layout.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
  providers: [DestroyRef, BaseLayoutService],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        }))
      ])
    ])
  ]
})

export class BaseLayoutComponent implements OnInit, AfterViewInit {
  buttons: any[] = [];
  showPrev: string = 'show';
  mobile: boolean;
  menuOpen: boolean;
  activeLink: string;
  mirror: boolean;

  @ViewChild('scrollbarRef') scrollbar: NgScrollbar;

  constructor
  (
      private extraService: ExtraService,
      private router: Router,
      private route: ActivatedRoute,
      private destroyRef: DestroyRef,
      private baseLayoutService: BaseLayoutService
  ) {}

  ngOnInit() {
    this.resize();
    this.onChangeRoute();

    this.extraService.user$
    .pipe(
      takeUntil(this.destroyRef)
    )
    .subscribe((v) => {
      switch (v.role) {
        case 'Администратор':
          this.buttons.push({
              link: 'profile',
              title: 'ПРОФИЛЬ'
            },
            {
              link: 'statistics',
              title: 'СТАТИСТИКА'
            },
            {
              link: 'team',
              title: 'КОМАНДА'
            },
            {
              link: 'reports',
              title: 'ОТЧЕТЫ'
            },
            {
              link: 'infographics',
              title: 'ИНФОГРАФИКА'
            },
            {
              link: 'booking',
              title: 'БРОНИРОВАНИЕ'
            },
            {
              link: 'schedule',
              title: 'РАСПИСАНИЕ'
            },
            {
              link: 'management',
              title: 'УПРАВЛЕНИЕ'
            });
          break
        case 'Супервайзер':
          this.buttons.push({
              link: 'profile',
              title: 'ПРОФИЛЬ'
            },
            {
              link: 'statistics',
              title: 'СТАТИСТИКА'
            },
            {
              link: 'team',
              title: 'КОМАНДА'
            },
            {
              link: 'reports',
              title: 'ОТЧЕТЫ'
            },
            {
              link: 'booking',
              title: 'БРОНИРОВАНИЕ'
            },
            {
              link: 'schedule',
              title: 'РАСПИСАНИЕ'
            },
            {
              link: 'management',
              title: 'УПРАВЛЕНИЕ'
            }
          );
          break
        case 'Пользователь':
          this.buttons.push({
              link: 'profile',
              title: 'ПРОФИЛЬ'
            },
            {
              link: 'statistics',
              title: 'СТАТИСТИКА'
            },
            {
              link: 'booking',
              title: 'БРОНИРОВАНИЕ'
            },
            {
              link: 'schedule',
              title: 'РАСПИСАНИЕ'
            },);
          break
      }
    })
  }

  ngAfterViewInit(): void {
    this.listenToScroll();
  }

  private listenToScroll(): void {
    this.baseLayoutService.scrollTo$
      .pipe(
        delay(0),
        takeUntil(this.destroyRef)
      )
      .subscribe((v) => {
        const targetElement = document.getElementById(v);

        if (targetElement) {
          this.scrollbar.scrollToElement(targetElement);
        }
    });
  }

  private onChangeRoute(): void {
    this.activeLink = this.router.url;

    this.setBg(this.activeLink);

    this.router.events
    .pipe(
      filter(v => v instanceof NavigationEnd),
      takeUntil(this.destroyRef)
    )
    .subscribe({
      next: (v: NavigationEnd) => {
        this.activeLink = v.urlAfterRedirects;
        this.scrollbar.scrollTo({top: 0});
        this.setBg(this.activeLink);

        if(this.mobile) this.openMenu();


      }
    })
  }

  private setBg(url: string): void {
    this.mirror = url.includes('booking') || url.includes('schedule');
  }

  private resize() {
    if (window.innerWidth <= 1025) {
      this.mobile = true;
      this.menuOpen = false;
    } else {
      this.mobile = false;
      this.menuOpen = true;
    }

    window.addEventListener('resize', () => {
        if (window.innerWidth <= 1025) {
          this.mobile = true;
          this.menuOpen = false;
        } else {
          this.mobile = false;
          this.menuOpen = true;
        }
    });
  }

  openMenu() {
    this.menuOpen = !this.menuOpen;
  }

  onNavigate(link: PersonalRoute): void {
    this.router.navigate([link], {relativeTo: this.route})
  }
}
