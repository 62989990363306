<ng-container *ngIf="user$ | async as profile">
  <div class="user">
    <div class="user-avatar" [ngStyle]="{backgroundImage: avatarImg}"></div>
    <div>
      <div class="user-name">
        {{profile.first_name}}<br/>
        {{profile.last_name}}
      </div>
      <div class="button" (click)="onPage(routesList.personal)">
        <span class="ar"></span>Перейти в профиль
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="pages$ | async as pages">
<div 
  class="section" 
  (click)="onPage(page.route)" 
  *ngFor="let page of pages"
>
  <span>{{page.name}}</span>
</div>
</ng-container>

<ng-container *ngIf="sectionPages$ | async as sectionPages">
<div 
  class="section" 
  (click)="onSection(page.route)"
  *ngFor="let page of sectionPages"
>
  <span>{{page.name}}</span>
  <div class="counter" *ngIf="showCounter(page.route) as counter">
    <span>NEW:</span> {{counter}}
  </div>
</div>
</ng-container>

<div class="logout" (click)="onLogout()">
<div class="logout-box">Выход</div>
</div>