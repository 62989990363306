import { GalleryPostReactionName } from 'core/eco-portal'
import { add } from 'core/api'
import { env } from 'env'

export function createGalleryUrls() {
  const gallery = add(env.rootApiUrl, 'gallery/')
  const gallery_post = add(gallery, 'post/')

  return {
    getPost: add(gallery_post, 'get/'),
    getPostList: add(gallery_post, 'list/'),
    createPost: add(gallery_post, 'create/'),
    toggleReaction: (name: GalleryPostReactionName) => add(gallery, `reaction/${name}/`)
  }
}
