import { Injectable } from '@angular/core'
import { CanActivate, CanActivateChild, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { AuthService } from 'services/api'

@Injectable({
  providedIn: 'root'
})
export class InternalUserGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(): Observable<boolean> {
    return this.authService.getUser().pipe(
      tap((user) => {
        // If the user only has access to the eco portal, redirect him to the fork page.
        if (user.eco) {
          this.router.navigateByUrl('/fork')
        }
      }),
      map((user) => !user.eco)
    )
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate()
  }
}
