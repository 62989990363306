<div class="custom_dialog game_moderator">

  <div class="close">
    <div class="close_icon" (click)="onConfirm()"></div>
  </div>

  <div class="container">
    <div class="title">Покупка оформлена!</div>

    <div class="text">
      Все молодцы всем спасибо, ждите новых релизов и оставайтесь на связи.
      <div>А теперь вы можете отправиться на обучающий портал и найти очень много информации.</div>
    </div>
    <div class="container_button" *ngIf="mobile">
      <div class="dialog_button purchase_finish" (click)="onConfirm()">ОК</div>
    </div>
  </div>

  <div class="container_button" *ngIf="!mobile">
    <div class="dialog_button purchase_finish" (click)="onConfirm()">ОК</div>
  </div>


</div>
