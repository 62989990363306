<app-header></app-header>
<div class="outer">
  <div class="workspace document" [@show_preview]="showPrev">

    <section class="content">

      <div class="back_container" (click)="goBack()">
        <div class="icon back"></div>
        <h2>ПОПЫТКИ</h2>
      </div>

      <mat-tab-group class="custom_attempts_tab" [(selectedIndex)]="activeTab" #mtg (selectedIndexChange)="selectedIndexChange($event)">

        <ng-container *ngFor="let tab of tabs;let k = index">
          <mat-tab label="Попытка {{k+1}}" *ngIf="testBody">
            <div class="greeting">
              Тест:<div class="question_text" translate="no">{{testBody.test}}</div>
            </div>
            <div class="greeting">
              Дата завершения теста:<div class="question_text" translate="no">{{testBody.finish | timestampToDate : 'shortDate'}} в {{testBody.finish | timestampToDate : 'shortTime'}}</div>
            </div>
            <div class="question_body" *ngFor="let question_body of testBody.questions; let i = index">
              <div class="greeting">
                Вопрос №{{i + 1}} <span [ngClass]="{
                              correct: question_body.is_right,
                              incorrect: !question_body.is_right
                              }">
                              {{ question_body.is_right ? 'Верно' : 'Неверно' }}</span>
              </div>
              <div class="question_text" translate="no" [innerHTML]="question_body.text | safeHtml"></div>

              <div class="question_info">
                Варианты ответа:
                <span *ngIf="question_body.type === 'checkbox'"> (выбери несколько вариантов ответа)</span>
                <span *ngIf="question_body.type === 'radio'"> (выбери один правильный ответ)</span>
                <span *ngIf="question_body.type === 'sort'"> (сортировка по приоритету)</span>
              </div>
              <div class="question_options">
                <!--Rating-->
                <ng-container *ngIf="question_body.type === 'rating'">
                  <mat-slider class="custom-slider"
                              min="{{question_body.options[0].text}}"
                              max="{{question_body.options[question_body.options.length - 1].text}}"
                              step="1"
                              thumbLabel="true"
                              tickInterval="1"
                              [disabled]="true"
                  ></mat-slider>
                </ng-container>

                <!--Radio-->
                <ng-container *ngIf="question_body.type === 'radio'">
                  <mat-radio-group class="options-group" [disabled]="true">
                    <mat-radio-button
                      value="{{option.id}}"
                      translate="no"
                      class="options-button"
                      [checked]="option.is_selected"
                      [ngClass]="{correct: option.is_right, incorrect: option.is_selected && !option.is_right}"
                      *ngFor="let option of question_body.options; let i = index"
                    >
                      <span [innerHTML]="option.text | safeHtml"></span>
                    </mat-radio-button>
                  </mat-radio-group>
                </ng-container>

                <!--Checkbox-->
                <ng-container *ngIf="question_body.type === 'checkbox'">
                  <div class="options-group">
                    <mat-checkbox
                      [disabled]="true"
                      value="{{option.id}}"
                      translate="no"
                      class="options-button"
                      [checked]="option.is_selected"
                      [ngClass]="{correct: option.is_selected && option.is_right, incorrect: option.is_selected && !option.is_right}"
                      *ngFor="let option of question_body.options; let i = index"
                    >
                      <span [innerHTML]="option.text | safeHtml"></span>
                    </mat-checkbox>
                  </div>
                </ng-container>

                <!--Sort-->
                <ng-container *ngIf="question_body.type === 'sort'">
                  <div cdkDropList class="options-group">
                    <div
                      translate="no"
                      cdkDrag
                      [cdkDragDisabled]="true"
                      class="options-button options-DropList"
                      [ngClass]="{correct: option.is_selected && option.is_right, incorrect: option.is_selected && !option.is_right}"
                      *ngFor="let option of question_body.options; let i = index"
                    >
                      <span class="number">{{i + 1}}</span>
                      <span class="text disabled" [innerHTML]="option.text | safeHtml"></span>
                    </div>
                  </div>
                </ng-container>

                <!--open-->
                <div class="options-textarea" *ngIf="question_body.type === 'text'">
                                    <textarea
                                      type="text"
                                      placeholder="Введите текст ответа"
                                      [value]="question_body.open"
                                      [ngStyle]="{cursor: 'default'}"
                                      [readOnly]="true"
                                    ></textarea>
                </div>
              </div>
            </div>
          </mat-tab>
        </ng-container>

      </mat-tab-group>

    </section>
  </div>
</div>
