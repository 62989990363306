import { Directive, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DestroyRef } from 'core/common';
import { filter, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appScrollToTop]',
  providers: [DestroyRef]
})
export class ScrollToTopDirective {

  constructor(private el: ElementRef, private router: Router, private destroyRef: DestroyRef) {
    this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.destroyRef)
    ).subscribe(() => {
      this.scrollToTop();
    });
  }


  private scrollToTop() {
    this.el.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
