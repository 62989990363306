import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToString',
})
export class ConvertToStringPipe implements PipeTransform {
  transform(arr: any): any {
    return arr.join(', ');
  }
}
