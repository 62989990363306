<app-header></app-header>

<div class="outer" [ngStyle]="{'backgroundImage': 'url(' + this.backgroundWord + ')'}">
  <div class="workspace">

    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>Назад</span>
        </div>
      </div>
    </div>

    <div class="text" [innerHTML]="text | safeHtml"></div>

    <section class="section_drop_container loreal" *ngIf="brand === 'OAP'">
      <div class="drop_container loreal"
           [id]="currentArr[0].id"
           cdkDropList
           [cdkDropListData]="currentArr[0].array"
           (cdkDropListDropped)="drop($event, boxes[0].order)"
           *ngIf="boxes"
           [ngStyle]="{'backgroundImage': 'url(' + boxes[0].file + ')', order: this.mobile ? 1 : 0}"
      >
        <div class="desc">{{boxes[0].text}}</div>
        <div class="item" *ngFor="let item of currentArr[0].array">
          <img class="image" [src]="item.file">
          <div class="text" *ngIf="item.text !== ''">{{item.text}}</div>
        </div>
      </div>
      <div
        class="item_container loreal"
        cdkDropList
        [cdkDropListData]="items"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListConnectedTo]="['currentDrop0', 'currentDrop1']"
        [ngStyle]="{order: this.mobile ? 3 : 0}"
      >
        <div class="item" *ngFor="let item of items" cdkDrag [cdkDragData]="item">
          <img class="image" [src]="item.file">
          <div class="text" *ngIf="item.text !== ''">{{item.text}}</div>
        </div>
      </div>
      <div class="drop_container loreal"
           cdkDropList
           [id]="currentArr[1].id"
           [cdkDropListData]="currentArr[1].array"
           (cdkDropListDropped)="drop($event, boxes[1].order)"
           *ngIf="boxes"
           [ngStyle]="{'backgroundImage': 'url(' + boxes[1].file + ')', order: this.mobile ? 2 : 0}"
      >
        <div class="desc">{{boxes[1].text}}</div>
        <div class="item" *ngFor="let item of currentArr[1].array">
          <img class="image" [src]="item.file">
          <div class="text" *ngIf="item.text !== ''">{{item.text}}</div>
        </div>
      </div>
    </section>

    <section class="section_drop_container garnier" *ngIf="brand === 'GRN'">
      <div
        class="item_container garnier"
        cdkDropList
        [cdkDropListData]="items"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListConnectedTo]="idArr"
      >
        <div class="item" *ngFor="let item of items" cdkDrag [cdkDragData]="item">
          <img class="image garnier" [src]="item.file">
          <div class="text" *ngIf="item.text !== ''">{{item.text}}</div>
        </div>
      </div>
      <div *ngIf="boxes" class="box_container garnier">
        <div class="drop_container garnier"
             cdkDropList
             *ngFor="let container of currentArr"
             [id]="container.id"
             [cdkDropListData]="container.array"
             (cdkDropListDropped)="drop($event, container.order)"
             [ngStyle]="{'backgroundImage': 'url(' + container.file + ')'}"
        >
          <div class="drop_items">
            <div class="item" *ngFor="let item of container.array">
              <img class="image small" [src]="item.file">
              <div class="text" *ngIf="item.text !== ''">{{item.text}}</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section_drop_container garnier" *ngIf="brand === 'GRN1'">
      <div
        class="item_container garnier"
        cdkDropList
        [cdkDropListData]="items"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListConnectedTo]="idArr"
      >
        <div class="item" *ngFor="let item of items" cdkDrag [cdkDragData]="item">
          <img class="image garnier" [src]="item.file">
        </div>
      </div>
      <div *ngIf="boxes" class="box_container garnier">
        <div class="drop_container garnier_new"
             cdkDropList
             *ngFor="let container of currentArr"
             [id]="container.id"
             [cdkDropListData]="container.array"
             (cdkDropListDropped)="drop($event, container.order)"
             [ngStyle]="{'backgroundImage': 'url(' + container.file + ')'}"
        >
          <div class="text_grn_new" *ngIf="container.text !== ''">{{container.text}}</div>
          <div class="drop_items grn_new">
            <div class="item" *ngFor="let item of container.array">
              <img class="image small" [src]="item.file">
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</div>
