import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { BaseService } from 'services/api'
import { animate, state, style, transition, trigger } from '@angular/animations'

@Component({
  selector: 'app-trends-list',
  templateUrl: './trends-list.component.html',
  styleUrls: ['./trends-list.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0
      })),
      state('show', style({
        opacity: 1
      })),
      transition('void => show', [
        style({
          opacity: 0
        }),
        animate(700, style({
          opacity: 1
        }))
      ])
    ])
  ]
})
export class TrendsListComponent implements OnInit {

  section = 'trends';

  breadcrumbs = [
    {
      id: null,
      title: 'Тренды',
      type: 'folder'
    }
  ];

  info = undefined;

  constructor(
    private router: Router,
    private baseService: BaseService
  ) {}

  goBack() {
    this.router.navigate(['learning/learning-home']);
  }

  onFolder(folder) {
    this.breadcrumbs.push({id: folder.id, title: folder.title, type: 'folder'});
    this.info = undefined;
    this.baseService.baseGetSectionContentArticles(this.section, folder.id).subscribe((response: any) => {
      console.log(response);

      this.info = response;

    }, () => {
      this.router.navigate( [ 'trends' ] );
    });
  }

  onPage(pageInfo) {
    this.router.navigate( [ 'trends/document/article/' + pageInfo.id ] );
  }

  onCrumb(index, info) {
    this.info = undefined;
    this.baseService.baseGetSectionContentArticles(this.section, info.id).subscribe((response: any) => {
      console.log(response);

      this.info = response;

    }, () => {
      this.router.navigate( [ 'trends' ] );
    });
    this.breadcrumbs = this.breadcrumbs.slice(0, index + 1);
  }

  likeOn(info, index) {
    if (info.is_liked) {
      this.info.documents[index].is_liked = false;
      this.info.documents[index].likes -= 1;
    } else {
      this.info.documents[index].is_liked = true;
      this.info.documents[index].likes += 1;
    }
    this.baseService.baseGetSectionDocumentLike(this.section, this.info.id);
  }

  ngOnInit() {
    this.baseService.baseGetSectionContentArticles(this.section, null).subscribe((response: any) => {
      console.log(response);

      this.info = response;

    }, () => {
      this.router.navigate( [ 'trends' ] );
    });
  }

}
