import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";

import {Location} from "@angular/common";
import {ExtraService} from "services";

@Component({
  selector: 'app-game-moderator-store',
  templateUrl: 'game-moderator-store.component.html',
  styleUrls: ['game-moderator-store.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class GameModeratorStoreComponent implements OnInit {

  constructor(
    private location: Location,
    public extraService: ExtraService
  ) {
  }

  goBack() {
    this.location.back()
  }

  ngOnInit() {
    this.extraService.backgroundStore = '/assets/images/game/bg_basket_mobile.png'
  }
}
