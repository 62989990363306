<div class="wrapper notranslate">
  <section class="logo_osn">
      <div class="workspace">
        <div class="back-button" (click)="goBack()"></div>
        <img class="logo" src="assets/images/logo-nyx-catalog.png">
      </div>
  </section>
  <section class="content">
    <div class="workspace">
      <ul class="catalog_menu">

        <li
          [class.catalog_menu_active]="cat.id === selected"
          *ngFor="let cat of catalog; let cat_index = index"
        >
          <span

            class="catalog_menu_open catalog_menu_notactive"
          >
            {{cat.title}}
          </span>
          <div *ngIf="cat.children != ''" class="catalog_osn">
            <div class="catalog_body">
              <div
                class="catalog_item"
                *ngFor="let child of cat.children; let child_index = index"
              >
                <div class="catalog_img" *ngIf="child.image">
                  <img
                   [routerLink]="['/catalogs/nyx/section']"
                   [queryParams]="{folder:cat.id,underfolder:child.id}"
                   [src]="'https://nyx-old-api.evpportal.ru/files/cpd/' + child.image + '?' + token" alt=""
                  />
                </div>
                <div
                  class="button"
                  [routerLink]="['/catalogs/nyx/section']"
                  [queryParams]="{folder:cat.id,underfolder:child.id}"
                >
                  <span>{{child.title}}</span>
                </div>
              </div>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </section>
</div>
