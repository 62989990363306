import { Component, OnInit, ViewChild } from '@angular/core'

import { animate, state, style, transition, trigger } from '@angular/animations'
import { ActivatedRoute, Router } from '@angular/router'

import { BaseService } from 'services/api'
import { MatTabGroup } from '@angular/material/tabs'

@Component({
  selector: 'app-marketing-list',
  templateUrl: 'marketing-list.component.html',
  styleUrls: ['marketing-list.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class MarketingListComponent implements OnInit {

  @ViewChild('mtg') tg: MatTabGroup;

  showPrev = 'show';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private baseService: BaseService,

  ) {

  }

  parentId = undefined;
  hasTabs = false;

  breadcrumbs = [];

  tabs = [];
  folders = [];
  foldersTab = [];
  documentsTab = [];
  documents = [];
  activeTab = 0;

  selectedIndexChange(evt: any) {
    this.baseService.baseGetSectionContentArticles('marketing_note', this.tabs[evt].id).subscribe((response: any) => {
      this.breadcrumbs = [];
      this.breadcrumbs.push({
        id: this.parentId,
        title: 'ВЕБИНАР ПО СЛОЖНЫМ ВОПРОСАМ',
        type: 'start'
      })
      this.foldersTab = response.folders;
      this.documentsTab = response.documents;
    })
  }

  goBack() {
    this.router.navigate(['learning/learning-home']);
  }

  goDocument(id) {
    this.router.navigate(['marketing/document/article/' + id]);
  }

  onFolder(folder) {
    this.breadcrumbs.push({id: folder.id, title: folder.title, type: 'folder'});
    this.baseService.baseGetSectionContentArticles('marketing_note', folder.id).subscribe((response: any) => {
      if (this.hasTabs) {
        this.foldersTab = response.folders;
        this.documentsTab = response.documents;
        return;
      }
      this.folders = response.folders.filter(item => item.type === 'folder');
      this.documents = response.documents
    }, () => {
      this.router.navigate( [ 'learning/learning-home' ] );
    });
  }

  onCrumb(index, info) {
    this.baseService.baseGetSectionContentArticles('marketing_note', info.id).subscribe((response: any) => {
      this.tabs = response.folders.filter(item => item.type === 'tab');
      if (this.tabs.length) {
        this.hasTabs = true;
        this.selectedIndexChange(this.activeTab);
      }
      this.folders = response.folders.filter(item => item.type === 'folder');
      this.documents = response.documents
    }, () => {
      this.goBack();
    });
    this.breadcrumbs = this.breadcrumbs.slice(0, index + 1);
  }

  like(event, info) {
    event.stopPropagation();
    if (!info.is_liked) {
      this.baseService.baseGetSectionDocumentLike('marketing_note', info.id).subscribe(() => {
        document.getElementById('count' + info.id).innerHTML = String(info.likes + 1);
      });
    } else {
      this.baseService.baseGetSectionDocumentLike('marketing_note', info.id).subscribe(() => {
        document.getElementById('count' + info.id).innerHTML = String(info.likes - 1);
      });
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.parentId = params.folderId;
      this.baseService.baseGetSectionContentArticles('marketing_note', params.folderId).subscribe((response: any) => {
        this.breadcrumbs = [];
        this.breadcrumbs.push({
          id: this.parentId,
          title: 'Маркетиг нота',
          type: 'start'
        })
        this.tabs = response.folders.filter(item => item.type === 'tab');
        this.hasTabs = false;
        if (this.tabs.length) {
          this.hasTabs = true;
          this.selectedIndexChange(this.activeTab);
        }
        this.folders = response.folders.filter(item => item.type === 'folder');
        this.documents = response.documents
      });
    });
  }

}
