import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService, StatisticsService } from "services/api";
import { DialogService } from "services";
import {AlertDialogComponent, FinalTestChangeDialogComponent} from "components/dialogs";

@Component({
  selector: 'app-team-dialog',
  templateUrl: './team-dialog.component.html',
  styleUrls: ['./team-dialog.component.scss'],
})

export class TeamDialogComponent implements OnInit {
  userId: any;

  profileInfo: any;

  courses: any;

  constructor(
    private authService: AuthService,
    private statisticsService: StatisticsService,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<TeamDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: { 'userId': any },
  ) {
    ({
      userId: this.userId
    } = this.dialogData);
  }

  close() {
    this.dialogRef.close();
  }

  addUserActivity() {
    let data;
    if (this.profileInfo.is_active) {
      this.authService.updateUser({id: this.userId, is_active: false}).subscribe(() => {
        data = {
          title: 'Пользователь успешно заблокирован',
          confirmButtonText: 'OK'
        }
        this.dialogService.openDialog(AlertDialogComponent, data);
        this.authService.getUser(this.userId).subscribe((response: any) => {
          this.profileInfo = response;
        });
      }, () => {
        data = {
          title: 'Произошла ошибка',
          confirmButtonText: 'OK'
        }
        this.dialogService.openDialog(AlertDialogComponent, data);
      });
      return;
    }
    this.authService.updateUser({id: this.userId, is_active: true}).subscribe(() => {
      data = {
        title: 'Пользователь успешно разблокирован',
        confirmButtonText: 'OK'
      }
      this.dialogService.openDialog(AlertDialogComponent, data);
      this.authService.getUser(this.userId).subscribe((response: any) => {
        this.profileInfo = response;
      });
    }, () => {
      data = {
        title: 'Произошла ошибка',
        confirmButtonText: 'OK'
      }
      this.dialogService.openDialog(AlertDialogComponent, data);
    });
    return;
  }

  changeDeadline(currentDate, profileId) {
    let data = {
      title: 'Продлить дедлайн',
      text: 'Установите дату',
      currentDate: currentDate,
      profileId: profileId,
      type: 'deadline'
    }
    this.dialogService.openDialog(FinalTestChangeDialogComponent, data).subscribe(() => {
      this.statisticsService.statisticsGetMaterials(this.userId).subscribe((response: any) => {
        this.courses = response;
      })
    });
  }

  changeAttempts(numAttempts, profileId) {
    let data = {
      title: 'Добавить попытки',
      text: 'Установите попытки',
      numAttempts: numAttempts,
      profileId: profileId,
      type: 'attempts'
    }
    this.dialogService.openDialog(FinalTestChangeDialogComponent, data).subscribe(() => {
      this.statisticsService.statisticsGetMaterials(this.userId).subscribe((response: any) => {
        this.courses = response;
      })
    });
  }

  ngOnInit() {
    this.authService.getUser(this.userId).subscribe((response: any) => {
      this.profileInfo = response;
    })
    this.statisticsService.statisticsGetMaterials(this.userId).subscribe((response: any) => {
      this.courses = response;
    })
  }

}
