<div class="custom_dialog game">
  <h2 mat-dialog-title class="dialog-title" [innerHTML]="text | safeHtml"></h2>

  <div mat-dialog-content class="dialog-content game">
    <div class="image" [ngStyle]="{'backgroundImage': 'url(' + image + ')'}">

    </div>
  </div>

  <div mat-dialog-actions>
    <div class="dialog_button" (click)="onConfirm()">{{confirmButtonText}}</div>
  </div>
</div>
