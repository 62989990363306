<div class="custom_dialog video-test">
  <form class="form" [formGroup]="form" (ngSubmit)="submit()" enctype="multipart/form-data" *ngIf="!showRightAnswer">

    <div class="question_body">

      <div class="question_info" translate="no" [innerHTML]="question.text | safeHtml">
      </div>

      <div class="greeting">
        Варианты ответа:
        <span *ngIf="question.type === 'checkbox'"> (выбери несколько вариантов ответа)</span>
        <span *ngIf="question.type === 'radio'"> (выбери один правильный ответ)</span>
        <span *ngIf="question.type === 'sort'"> (сортировка по приоритету)</span>
        <span *ngIf="question.type === 'rating'"> (поставьте оценку)</span>
        <span *ngIf="question.type === 'text'"> (открытый вопрос)</span>
      </div>

      <div class="question_options">

        <!--Rating-->
        <ng-container *ngIf="question.type === 'rating'">
          <mat-slider class="custom-slider"
                      min="{{question.options[0].text}}"
                      max="{{question.options[question.options.length - 1].text}}"
                      step="1"
                      thumbLabel="true"
                      tickInterval="1"
                      formControlName="{{question.id}}"
          ></mat-slider>
        </ng-container>

        <!--Radio-->
        <ng-container *ngIf="question.type === 'radio'">
          <mat-radio-group class="options-group" formControlName="{{question.id}}">
            <mat-radio-button
              value="{{option.id}}"
              translate="no"
              class="options-button"
              *ngFor="let option of question.options; let i = index"
            >
              <span [innerHTML]="option.text | safeHtml"></span>
            </mat-radio-button>
          </mat-radio-group>
        </ng-container>

        <!--Checkbox-->
        <ng-container *ngIf="question.type === 'checkbox'">
          <div class="options-group" formArrayName="{{question.id}}">
            <mat-checkbox
              formControlName="{{i}}"
              value="{{option.id}}"
              translate="no"
              class="options-button"
              *ngFor="let option of question.options; let i = index"
            >
              <span [innerHTML]="option.text | safeHtml"></span>
            </mat-checkbox>
          </div>
        </ng-container>

        <!--Sort-->
        <ng-container *ngIf="question.type === 'sort'">
          <div cdkDropList class="options-group" (cdkDropListDropped)="drop($event, question.id, question.options)">
            <div
              translate="no"
              cdkDrag
              class="options-button options-DropList"
              *ngFor="let option of question.options; let i = index"
            >
              <span class="number">{{i + 1}}</span>
              <span class="text" [innerHTML]="option.text | safeHtml"></span>
            </div>
          </div>
        </ng-container>

        <!--text-->
        <div class="options-textarea" *ngIf="question.type === 'text'">
                      <textarea
                        type="text"
                        placeholder="Введите текст ответа"
                        formControlName="{{question.id}}"
                      ></textarea>
        </div>

      </div>
    </div>

    <div class="test_submit_body">
      <button class="test_submit" type="submit">Отправить</button>
      <div class="test_submit" (click)="watchAgain('withQuestion')">Просмотреть ещё раз</div>
    </div>

  </form>

  <div class="form" *ngIf="showRightAnswer">
    <div class="question_body">

      <div class="question_info" translate="no" [innerHTML]="question.text | safeHtml" [ngClass]="{correct: right, incorrect: !right}">
      </div>

      <div class="greeting">
        Варианты ответа:
        <span *ngIf="rightAnswer.type === 'checkbox'"> (выбери несколько вариантов ответа)</span>
        <span *ngIf="rightAnswer.type === 'radio'"> (выбери один правильный ответ)</span>
        <span *ngIf="rightAnswer.type === 'sort'"> (сортировка по приоритету)</span>
        <span *ngIf="rightAnswer.type === 'rating'"> (поставьте оценку)</span>
        <span *ngIf="rightAnswer.type === 'text'"> (открытый вопрос)</span>
      </div>

      <div class="question_options">

        <!--Rating-->
        <ng-container *ngIf="rightAnswer.type === 'rating'">
          <mat-slider class="custom-slider"
                      min="{{rightAnswer.options[0].text}}"
                      max="{{rightAnswer.options[question.options.length - 1].text}}"
                      step="1"
                      thumbLabel="true"
                      tickInterval="1"
                      [disabled]="showRightAnswer"
          ></mat-slider>
        </ng-container>

        <!--Radio-->
        <ng-container *ngIf="rightAnswer.type === 'radio'">
          <mat-radio-group class="options-group" [disabled]="showRightAnswer">
            <mat-radio-button
              value="{{option.id}}"
              translate="no"
              class="options-button"
              [checked]="option.is_selected"
              [ngClass]="{correct: option.is_selected && option.is_right, incorrect: option.is_selected && !option.is_right}"
              *ngFor="let option of rightAnswer.options; let i = index"
            >
              <span [innerHTML]="option.text | safeHtml"></span>
            </mat-radio-button>
          </mat-radio-group>
        </ng-container>

        <!--Checkbox-->
        <ng-container *ngIf="rightAnswer.type === 'checkbox'">
          <div class="options-group">
            <mat-checkbox
              [disabled]="showRightAnswer"
              value="{{option.id}}"
              translate="no"
              class="options-button"
              [checked]="option.is_selected"
              [ngClass]="{correct: option.is_selected && option.is_right, incorrect: option.is_selected && !option.is_right}"
              *ngFor="let option of rightAnswer.options; let i = index"
            >
              <span [innerHTML]="option.text | safeHtml"></span>
            </mat-checkbox>
          </div>
        </ng-container>

        <!--Sort-->
        <ng-container *ngIf="rightAnswer.type === 'sort'">
          <div cdkDropList class="options-group" (cdkDropListDropped)="drop($event, question.id, question.options)">
            <div
              translate="no"
              cdkDrag
              [cdkDragDisabled]="true"
              class="options-button options-DropList"
              [ngClass]="{correct: option.is_selected && option.is_right, incorrect: option.is_selected && !option.is_right}"
              *ngFor="let option of rightAnswer.options; let i = index"
            >
              <span class="number">{{i + 1}}</span>
              <span class="text" [innerHTML]="option.text | safeHtml"></span>
            </div>
          </div>
        </ng-container>

        <!--text-->
        <div class="options-textarea" *ngIf="rightAnswer.type === 'text'">
                      <textarea
                        type="text"
                        placeholder="Введите текст ответа"
                        [value]="rightAnswer.text"
                        [ngStyle]="{cursor: 'default'}"
                        [readOnly]="showRightAnswer"
                      ></textarea>
        </div>

      </div>
    </div>

    <div class="test_submit_body">
      <div class="test_submit" (click)="continueWatch()">OK</div>
      <div class="test_submit" (click)="watchAgain('withoutQuestion')">Просмотреть ещё раз</div>
    </div>
  </div>


</div>
