<div *ngFor="let block of arrayContent;let i = index;" class="content" [id]="'trigger' + i">

  <div *ngIf="block.type === 'scorm'">
    <div class="scorm_container" [id]="'scorm_container' + block.id">
      <div class="overlay" [id]="'scorm_overlay' + block.id" [ngClass]="{'active': block.active, 'not_active': !block.active}">
        <div class="button" (click)="startScorm(block)">Начать просмотр</div>
      </div>
      <iframe [src]="block.link | safeURL" width="100%" height="100%" [id]="block.id" class="scorm"></iframe>
    </div>
  </div>

  <div *ngIf="block.type === 'html'">
    <div class="html_text title"
         *ngIf="block.title !== ''"
         [innerHTML]="block.title | safeHtml"
         [ngStyle]="{cursor: block.children && block.children.length ? 'pointer' : 'default'}"
         (click)="showChildren('children' + i)"
    ></div>
    <div class="html_text"
         *ngIf="block.text !== ''"
         [innerHTML]="block.text | safeHtml"
         [ngStyle]="{cursor: block.children && block.children.length ? 'pointer' : 'default'}"
         (click)="showChildren('children' + i)"
    ></div>
    <div *ngIf="block.children && block.children.length" [id]="'children' + i" class="no_show">
      <app-content-blocks-children [arrayContentChildren]="block.children"></app-content-blocks-children>
    </div>
  </div>

  <div class="image_block" *ngIf="block.type === 'image' && !!block.files">
    <h2 *ngIf="block.title !== ''">{{block.title}}</h2>

    <img [src]="(file.file)" *ngFor="let file of block.files" [ngStyle]="{cursor: block.children && block.children.length ? 'pointer' : 'default'}" (click)="showChildren('children' + i)">

    <div *ngIf="block.children && block.children.length" [id]="'children' + i" class="no_show">
      <app-content-blocks-children [arrayContentChildren]="block.children"></app-content-blocks-children>
    </div>
  </div>


  <div *ngIf="block.type === 'video_test'">
    <app-video-test [videoTestId]="block.video_test"></app-video-test>
  </div>


  <div class="video" *ngIf="block.type === 'video' && block.files !== null">
    <h2 *ngIf="block.title !== ''">{{block.title}}</h2>
    <vg-player>
      <vg-overlay-play></vg-overlay-play>
      <vg-buffering></vg-buffering>

      <vg-scrub-bar>
        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
        <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
      </vg-scrub-bar>

      <vg-controls [vgAutohide]="true">
        <vg-play-pause></vg-play-pause>
        <vg-playback-button [playbackValues]="[ '0.5', '1.0', '1.25', '1.5', '2.0' ]"></vg-playback-button>

        <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

        <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

        <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>


        <vg-mute></vg-mute>
        <vg-volume></vg-volume>

        <vg-fullscreen></vg-fullscreen>
      </vg-controls>
      <video [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" crossorigin poster="{{block.preview}}" *ngFor="let video of block.files">
        <source type="video/webm" [src]="video.file">
        <source type="video/mp4" [src]="video.file">
      </video>
    </vg-player>
  </div>


  <div *ngIf="block.type === 'gallery' && block.files.length > 0" class="gallery_block">
    <h2 *ngIf="block.title !== ''">{{block.title}}</h2>
    <div class="custom_swiper_for_document dd">
      <swiper
        #swiper
        *ngIf="thumbsSwiper"
        [zoom]="true"
        [thumbs]="{swiper: thumbsSwiper | findThumbs: i}"
        [grabCursor]="true"
    >
      <ng-template swiperSlide *ngFor="let file of block.files">
        <div class="swiper-zoom-container">
          <img class="image_big" src="{{file.file}}" />
        </div>
      </ng-template>
      </swiper>
      <!-- <button mat-mini-fab color="primary" (click)="swiperPrev()" class="dd-button prev">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button mat-mini-fab color="primary" (click)="swiperNext()" class="dd-button next">
        <mat-icon>chevron_right</mat-icon>
      </button> -->
    </div>
    <div class="dd">
      <swiper
      #swiperSmall
      (swiper)="getThumbs($event, i)"
      [spaceBetween]="10"
      [slidesPerView]="4"
      [freeMode]="true"
      [watchSlidesProgress]="true"
      class="custom_swiper_thumbs"
    >
      <ng-template swiperSlide *ngFor="let file of block.files">
        <img class="image_small" style="cursor: pointer;" src="{{file.file}}" />
      </ng-template>
    </swiper>
      <button mat-mini-fab color="primary" (click)="swiperPrev()" class="dd-button prev">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button mat-mini-fab color="primary" (click)="swiperNext()" class="dd-button next">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="block.type === 'link' && block.link !== ''" class="link">
    <h2 *ngIf="block.title !== ''">{{block.title}}</h2>
    <a href="{{block.link}}">{{block.title}}</a>
  </div>

  <div *ngIf="block.type === 'files' && block.files.length > 0" class="files" [ngClass]="{column: block.files.length === 1}">
    <h2 *ngIf="block.title !== ''">{{block.title}}</h2>
    <div class="file" *ngFor="let file of block.files" (click)="goFile(file, block.files.length, block.title)">
      <div class="file_icon" [ngClass]="{
           'doc': file.file.split('?')[0].split('.').pop() === 'doc',
           'docx': file.file.split('?')[0].split('.').pop() === 'docx',
           'pdf': file.file.split('?')[0].split('.').pop() === 'pdf',
           'xls': file.file.split('?')[0].split('.').pop() === 'xls',
           'xlsx': file.file.split('?')[0].split('.').pop() === 'xlsx',
           'ppt': file.file.split('?')[0].split('.').pop() === 'ppt',
           'pptx': file.file.split('?')[0].split('.').pop() === 'pptx'
           }"></div>
      <div class="file_title">{{file.title}}</div>
    </div>
  </div>

</div>
