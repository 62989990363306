import {Component, OnInit} from '@angular/core';

import {animate, state, style, transition, trigger} from "@angular/animations";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import {ExtraService, GameModeratorService} from "services";

@Component({
  selector: 'app-game-moderator',
  templateUrl: 'game-moderator.component.html',
  styleUrls: ['game-moderator.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class GameModeratorComponent implements OnInit {

  constructor(
    private location: Location,
    private router: Router,
    public gameModerService: GameModeratorService,
    public extraService: ExtraService
  ) {
  }

  mobile = false;
  startScreen: undefined | any

  goBack() {
    this.location.back()
  }

  switchLang(lang) {
    this.gameModerService.setLang(lang)
    this.gameModerService.rewrite({start_message: '', next_button: ''}).then((res: any) => {
      this.startScreen = res
    })
  }

  goRules() {
    this.router.navigate(['game-moderator/rules'])
  }

  resize() {
    if (window.innerWidth <= 1024) {
      this.mobile = true;
      this.extraService.backgroundStore = '/assets/images/game/bg_mobile_1.png'
    } else {
      this.mobile = false;
      this.extraService.backgroundStore = '/assets/images/game/bg_desktop_1.png'
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1024) {
        this.mobile = true;
        this.extraService.backgroundStore = '/assets/images/game/bg_mobile_1.png'
      } else {
        this.mobile = false;
        this.extraService.backgroundStore = '/assets/images/game/bg_desktop_1.png'
      }
    });
  }

  ngOnInit() {
    this.resize()
    this.gameModerService.getLang()
    this.gameModerService.rewrite({start_message: '', next_button: ''}).then((res: any) => {
      this.startScreen = res
    })
  }
}
