import { Directive, ElementRef, ViewChild } from '@angular/core'

@Directive()
export class InputDialogDirective {
  @ViewChild('input', {static: true}) protected set inputRef(ref: ElementRef<HTMLInputElement>) {
    this.input = ref.nativeElement
  }

  protected input!: HTMLInputElement

  /**
   * Opens the input dialog.
   */
  openDialog(): void {
    this.input.click()
  }
}
