import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService } from 'services/api';
import { StorageService } from 'services';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import SwiperCore, { Zoom, FreeMode, Thumbs, Mousewheel } from 'swiper';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { CompareType } from 'app/typings/catalog';

SwiperCore.use([Zoom, FreeMode, Thumbs, Mousewheel]);

@Component({
  selector: 'app-catalogs-card',
  templateUrl: './catalogs-card.component.html',
  styleUrls: ['./catalogs-card.component.scss'],
  animations: [
    trigger('show_preview', [
      state(
        'none',
        style({
          opacity: 0,
        })
      ),
      state(
        'show',
        style({
          opacity: 1,
        })
      ),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(
          500,
          style({
            opacity: 1,
          })
        ),
      ]),
    ]),
  ],
})
export class CatalogsCardComponent implements OnInit, OnDestroy {
  showPrev = 'show';
  sub$: Subscription;

  folderId = undefined;
  catalogId = undefined;

  productInfo = undefined;
  activeImg = null;

  sliderBt = false;
  sliderLength = 5;
  sliderOffset = this.sliderLength;
  slider = [];

  thumbsSwiper: any;

  compareSt = false;

  tab = 1;

  constructor(
    private location: Location,
    private api: BaseService,
    private route: ActivatedRoute,
    private storage: StorageService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.sub$?.unsubscribe();
  }

  goBack() {
    this.location.back();
  }

  onCompareByRef(ref: CompareType): void {
    this.router.navigate([ref], {
      relativeTo: this.route,
    });
  }

  imgAct(img) {
    this.activeImg = img.file;
    this.slider = this.slider.map((item) => {
      if (item.file === this.activeImg) {
        item.active = true;
      } else {
        item.active = false;
      }
      return item;
    });
  }

  btNp(type) {
    if (
      type === 'back' &&
      this.slider[0].file === this.productInfo.files[0].file
    ) {
      return;
    } else if (
      type === 'next' &&
      this.slider[this.slider.length - 1].file ===
        this.productInfo.files[this.productInfo.files.length - 1].file
    ) {
      return;
    } else {
      if (type === 'back') {
        this.sliderOffset--;
        this.slider.unshift(
          this.productInfo.files[this.sliderOffset - this.sliderLength]
        );
        this.slider.pop();
      } else {
        this.slider.push(this.productInfo.files[this.sliderOffset]);
        this.slider.shift();
        this.sliderOffset++;
      }
    }
  }

  tabOn(num) {
    this.tab = num;
  }

  compare() {
    if (!this.storage.getValue('compare')) {
      this.storage.setValue('compare', { products: [] });
    }
    const products = this.storage.getValue('compare')['products'];
    if (products.length > 0) {
      const products_id = this.storage
        .getValue('compare')
        ['products'].map((item) => item.id);
      const index = products_id.indexOf(this.productInfo.id);
      if (index !== -1) {
        products.splice(index, 1);
        this.compareSt = false;
      } else {
        products.push({
          id: this.productInfo.id,
          folder_id: this.folderId,
          catalog_id: this.catalogId,
        });
        this.compareSt = true;
      }
      this.storage.setValue('compare', { products: products });
    } else {
      products.push({
        id: this.productInfo.id,
        folder_id: this.folderId,
        catalog_id: this.catalogId,
      });
      this.storage.setValue('compare', { products: products });
      this.compareSt = true;
    }
  }

  ngOnInit() {
    this.sub$ = this.route.params.subscribe((params) => {
      this.tab = 1;

      this.folderId = params['folder_id'];
      this.catalogId = params['catalog_id'];

      this.sub$ = this.api
        .catalogProduct(params['product_id'])
        .subscribe((response: any) => {
          this.productInfo = response;

          this.slider = response.files;

          if (this.storage.getValue('compare')) {
            const products_id = this.storage
              .getValue('compare')
              ['products'].map((item) => item.id);
            const index = products_id.indexOf(this.productInfo.id);
            if (index !== -1) {
              this.compareSt = true;
            }
          }
        });
    });
  }
}
