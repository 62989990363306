import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { RootModule } from 'modules'
import { env } from 'env'

if (env.production) {
  enableProdMode()
}

platformBrowserDynamic().bootstrapModule(RootModule)
  .catch((err) => console.error(err))
