import { Pipe, PipeTransform } from '@angular/core';
import { WronTestQuestion } from 'app/api/profile/statistics';

@Pipe({
  name: 'checkTest'
})
export class CheckTestPipe implements PipeTransform {

  transform(value: WronTestQuestion[]): WronTestQuestion[] {
    return value.map(v => {
      return {...v, disabled: !v.section || !v.doc_id}
    });
  }

}
