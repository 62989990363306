<div class="workspace seasons">
  <div class="bread_and_title_container">
    <div class="bread_and_title">
      <div class="page_title" (click)="goBack()">
        <div class="icon back"></div>
        <span>НОВИНКИ</span>
      </div>
      <div class="breadcrumbs">
        <span class="crumb"><span [routerLink]="['/learning/learning-home']" class="crumb_title link">Учись</span></span>
        <span
          *ngFor="let bread of breadcrumbs;let i = index"
          class="crumb"
        >
        <span *ngIf="i === breadcrumbs.length - 1" class="crumb_title">{{bread.title}}</span>
        <span *ngIf="i !== breadcrumbs.length - 1" class="crumb_title link" (click)="onCrumb(i,bread)">{{bread.title}}</span>
      </span>
      </div>
    </div>
  </div>
  <div class="tabs_container" *ngIf="tabs.length">
    <mat-tab-group class="custom_tabs training" #mtg (selectedIndexChange)="selectedIndexChange($event)" [(selectedIndex)]="activeTab">
      <mat-tab *ngFor="let tab of tabs">
        <ng-template mat-tab-label>
          <div class="title">{{tab.title}}</div>
          <div class="counter" *ngIf="tab.news">+{{tab.news}}</div>
        </ng-template>
        <div class="content_container">
          <div class="folder_container" *ngIf="foldersTab.length">
            <div class="folder" (click)="onFolder(folder)" [ngStyle]="{backgroundImage:'url(' + (folder.preview) + ')'}" *ngFor="let folder of foldersTab">
              <div class="folder_title"></div>
              <div class="counter">+{{folder.news}}</div>
            </div>
          </div>
          <div class="document_container" *ngIf="documentsTab.length">
            <div class="document" *ngFor="let document of documentsTab" [ngStyle]="{'backgroundImage': 'url(' + document.preview + ')'}" (click)="goDocument(document.id)">
              <div *ngIf="document.is_new" class="new"></div>
              <div class="document_title">{{document.title}}</div>
              <div class="document_icon">
                <div class="views">
                  <div class="icon eye"></div>
                  <div class="count">{{document.views}}</div>
                </div>
                <div class="likes">
                  <div class="icon" [ngClass]="{liked: document.is_liked, like: !document.is_liked}" (click)="like($event, document)"></div>
                  <div class="count" [id]="'count' + document.id">{{document.likes}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="folder_container" *ngIf="folders.length">
    <div class="folder" (click)="onFolder(folder)" [ngStyle]="{backgroundImage:'url(' + (folder.preview) + ')'}"  *ngFor="let folder of folders">
      <div class="folder_title"></div>
      <div class="counter">+{{folder.news}}</div>
    </div>
  </div>
  <div class="document_container" *ngIf="documents.length">
    <div class="document" *ngFor="let document of documents" [ngStyle]="{'backgroundImage': 'url(' + document.preview + ')'}" (click)="goDocument(document.id)">
      <div *ngIf="document.is_new" class="new"></div>
      <div class="document_title">{{document.title}}</div>
      <div class="document_icon">
        <div class="views">
          <div class="icon eye"></div>
          <div class="count">{{document.views}}</div>
        </div>
        <div class="likes">
          <div class="icon" [ngClass]="{liked: document.is_liked, like: !document.is_liked}" (click)="like($event, document)"></div>
          <div class="count" [id]="'count' + document.id">{{document.likes}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
