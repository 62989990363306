import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'

import { ExtraService } from 'services'

@Component({
  selector: 'app-side-component-menu',
  templateUrl: 'side-component-menu.component.html',
  styleUrls: ['side-component-menu.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        }))
      ])
    ])
  ]
})
export class SideComponentMenuComponent implements OnInit, AfterViewInit {
  @Input() buttons = undefined;
  @Input() folders = undefined;
  @Input() typeSection = undefined;

  showPrev = 'show';

  @ViewChild('bg') button: any;

  mobile = false; // Включаем элементы свойственные мобильной версии
  menuOpen = true;

  constructor(private extraService: ExtraService) {}

  resize() {
    if (window.innerWidth <= 1025) {
      this.mobile = true;
      this.menuOpen = false;
    } else {
      this.mobile = false;
      this.menuOpen = true;
    }

    window.addEventListener('resize', () => {
        if (window.innerWidth <= 1025) {
          this.mobile = true;
          this.menuOpen = false;
        } else {
          this.mobile = false;
          this.menuOpen = true;
        }
    });
  }

  openMenu() {
    if (this.menuOpen) {
      this.menuOpen = false;
    } else {
      this.menuOpen = true;
    }
  }

  changeTitle(event, element) {
    if (this.mobile) {
      this.extraService.activeButton = {
        id: element.id,
        typeSection: this.typeSection
      };

      this.extraService.activeButtonTitle = event.target.innerText;
    }
  }

  ngOnInit() {
    this.resize();

    // if (this.folders) {
    //   if (window.innerWidth <= 1024) {
    //     this.mobile = true;
    //   }
    // }
  }

  ngAfterViewInit() {
    // if (this.mobile) {
    //   if (this.extraService.activeButton) {
    //     if (this.folders.find(item => item.id === +this.extraService.activeButton.id && item.typeSection === this.extraService.activeButton.typeSection)) {
    //       setTimeout(() => {
    //         document.getElementById(this.extraService.activeButton.id).click();
    //       })
    //       return;
    //     }
    //   }
    //   setTimeout (() => {
    //     this.button.nativeElement.click();
    //   })
    //   return;
    // }
    // return;
  }
}
