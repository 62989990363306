<div
  class="container"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  [scrollWindow]="true"
  [infiniteScrollDisabled]="endScroll"
  (scrolled)="paginationOn()"
>
  <div class="header_store">
    <div class="button_store basket" (click)="goBasket()">
      <div class="basket_icon"></div>
      <div class="text_button_store">корзина</div>
    </div>
    <div class="button_store">
      {{account}}
      <div class="coin_icon"></div>
    </div>
  </div>
  <div
    class="container_store"
  >
    <div class="product" *ngFor="let product of products">
      <div class="image_store">
        <img [src]="product.preview" class="image">
      </div>
      <div class="title">{{product.title}}</div>
      <div class="price_button_container">
        <div class="price">
          {{product.price}}
          <div class="price_coin"></div>
        </div>
        <div class="quantity">Кол-во: {{product.number}}</div>
        <div class="button_price" *ngIf="product.is_available" [ngClass]="{not_active: product.cart}" (click)="setCart(product)">
          {{!product.cart ? 'добавить' : 'товар'}} <br> {{!product.cart ? 'в корзину' : 'в корзине'}}
        </div>
      </div>
    </div>
  </div>
</div>
