import { HttpContext } from '@angular/common/http'

import { signed } from './http-context-tokens'

export class AppHttpContext extends HttpContext {
  /**
   * Adds a flag indicating that the request doesn't have an authorization header.
   */
  unsigned(): AppHttpContext {
    return this.set(signed, false) as this
  }
}
