<app-header></app-header>

<div *ngIf="comparisonInfo" class="outer card">
  <div class="workspace">

    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>Сравнение</span>
        </div>
      </div>
    </div>

    <div class="products">

      <div
        *ngIf="sliderBt"
        class="slider_bt back"
        (click)="btNp('back')"
        [ngClass]="{'uni': sliderProducts[0].id === comparisonInfo.products[0].id}"
      ></div>

      <div
        *ngIf="sliderBt"
        class="slider_bt next"
        (click)="btNp('next')"
        [ngClass]="{
          'uni': sliderProducts[sliderProducts.length - 1].id === comparisonInfo.products[comparisonInfo.products.length - 1].id
        }"
      ></div>

      <div
        [@show_preview]="showPrev"
        *ngFor="let pro of sliderProducts; let i = index"
        class="products_li"
        [ngClass]="{
          'one': sliderLength === 1,
          'two': sliderLength === 2,
          'three': sliderLength === 3,
          'four': sliderLength === 4
        }"
      >
        <div
          class="document"
        >
          <div class="del" (click)="delProduct(pro)"></div>
          <div (click)="onProduct(pro)" class="document_osn" [ngStyle]="{backgroundImage: 'url('+ (pro.file) + ')'}">
            <div class="document_text">
              <div class="document_text_title">{{pro.title}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="comparison">
      <div
        [@show_preview]="showPrev"
        *ngFor="let pro of sliderComparison; let i = index"
        class="comparison_li"
      >
        <div class="comparison_title">{{pro.title}}</div>
        <div class="comparison_tr">
          <div
            class="comparison_td"
            [ngClass]="{
              'one': sliderLength === 1,
              'two': sliderLength === 2,
              'three': sliderLength === 3,
              'four': sliderLength === 4
            }"
            *ngFor="let com of pro.values; let i = index"
            [innerHTML]="com | safeHtml"
          ></div>
        </div>
      </div>
    </div>

  </div>
</div>
