<div class="create" [ngClass]="{edit: isEdit}">
    <p class="title">{{title}}</p>

    <mat-tab-group 
      mat-stretch-tabs="false" 
      mat-align-tabs="start" 
      *ngIf="isEdit; else formRef"
      class="tab"
    >
      <mat-tab label="Главное">
        <ng-container [ngTemplateOutlet]="formRef"></ng-container>
      </mat-tab>
      <mat-tab label="Участники">
        <ng-container [ngTemplateOutlet]="tableRef"></ng-container>
      </mat-tab>
    </mat-tab-group>
    
    <div class="button_container" [ngClass]="{edit: isEdit}">
      <div class="button" (click)="submit()">ОК</div>
      <div class="button" (click)="onRemove(form.value.id)" *ngIf="isEdit && !disableAll">Удалить</div>
      <div class="button" (click)="onConfirm()" *ngIf="!isEdit">Отмена</div>
    </div>
  </div>

  <ng-template #formRef>
    <ng-scrollbar class="tab-container">
      <form [formGroup]="form" [ngClass]="{edit: isEdit}" class="form">
          <div class="form-box">
            <div class="form-label">
              <label class="required">Тема:</label>
            </div>
            <div class="form-action">
              <input placeholder="Введите тему" maxlength="100" formControlName="title" style="flex: 1;" [ngClass]="{disabled: disableAll}">
            </div>
          </div>
          <div class="form-box">
            <div class="form-label">
              <label class="required">Тренер:</label>
            </div>
            <div class="form-action trainer">
                <app-select [placeholder]="'Выберите тренера'" [blocked]="disableAll" formControlName="trainer">
                    <ng-container *ngFor="let trainer of trainers">
                      <app-select-option [value]="trainer.id" [label]="trainer.name">
                          <span class="bold">{{trainer.name}}</span>
                          <span>{{trainer.email}}</span>
                      </app-select-option>
                    </ng-container>
                </app-select>
            </div>
          </div>
          <ng-container *ngIf="!isEdit">
            <div class="form-box gap" *ngIf="!isEdit">
              <div class="form-label">
                <label>Участники:</label>
              </div>
              <div class="form-action">
                <div class="button_container file" *ngIf="!form.get('file').value">
                  <input id="file" type="file" accept=".xlsx" (change)="onChangeFile($event)" style="display: none;">
                  <label class="button" for="file">Загрузить</label>
                </div>
                <div *ngIf="form.get('file').value" class="file-input">
                  <input readonly type="text" [placeholder]="fileName" style="width: 100%;">
                  <button matRipple class="file-close" (click)="removeFile()">
                    <app-icon-loader [name]="'close'" [size]="16"></app-icon-loader>
                  </button>
                </div>
              </div>
            </div>
            <div class="form-box">
              <div class="form-label"></div>
              <div class="form-action end">
                <span class="error" *ngIf="!form.get('file').value">Xlsx файл с участниками</span>
              </div>
            </div>
          </ng-container>
          <div class="form-box">
            <div class="form-label">
              <label class="required">Категории:</label>
            </div>
            <div class="form-action">
              <mat-select
                multiple
                class="custom_select for_booking"
                placeholder="Выберите категорию"
                formControlName="category"
                [ngClass]="{disabled: disableAll}"
            >
                <mat-option *ngFor="let category of categoires" [value]="category.id">
                  {{category.title}}
                  <span class="select-option" [ngStyle]="{background: category?.color}"></span>
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="form-box">
            <div class="form-label">
              <label class="required">ЦА:</label>
            </div>
            <div class="form-action">
              <mat-select
                multiple
                class="custom_select for_booking"
                placeholder="Для кого"
                formControlName="target"
                [ngClass]="{disabled: disableAll}"
            >
                <mat-option *ngFor="let target of targetList" [value]="target.id">
                  {{target.title}}
                  <span class="select-option" [ngStyle]="{background: target?.color}"></span>
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="form-box">
            <div class="form-label">
              <label class="required">Формат:</label>
            </div>
            <div class="form-action">
              <mat-select
                  class="custom_select for_booking"
                  [ngClass]="{disabled: disableAll}"
                  placeholder="Выберите формат"
                  formControlName="online"
                  (valueChange)="onFormatChange()"
              >
                <mat-option [value]="null">--</mat-option>
                <mat-option [value]="false">Оффлайн</mat-option>
                <mat-option [value]="true">Онлайн</mat-option>
              </mat-select>
            </div>
          </div>
          <ng-container [ngSwitch]="form.get('online').value">
            <ng-container *ngSwitchCase="true" [ngTemplateOutlet]="dateFormRef"></ng-container>
            <ng-container *ngSwitchCase="false">
              <div class="form-box" *ngIf="!disableAll">
                <div class="form-label"></div>
                <div class="form-action">
                  <mat-checkbox [formControl]="checkboxForm">
                    <span style="font-size: 14px; line-height: 100%;">Без бронирования</span>
                  </mat-checkbox>
                </div>
              </div>
              <ng-container *ngIf="!checkboxForm.value; else dateFormRef">
                <div class="form-box">
                  <div class="form-label">
                    <label class="required">Аудитория:</label>
                  </div>
                  <div class="form-action">
                    <button matRipple class="create-button" *ngIf="!reservations.length" (click)="goToBooking()">Создайте бронирование</button>
                    <ng-container *ngIf="reservations.length">
                      <mat-select
                        class="custom_select for_booking"
                        placeholder="Выберите аудиторию"
                        formControlName="reservation"
                        [ngClass]="{disabled: disableAll}"
                        (valueChange)="findReservation($event)"
                      >
                        <mat-option [value]="null">--</mat-option>
                        <mat-option *ngFor="let room of reservations" [value]="room.id">
                          {{room.title}}
                          <span class="select-option" [ngStyle]="{background: room?.color}"></span>
                        </mat-option>
                      </mat-select>
                    </ng-container>
                  </div>
                </div>
                <ng-container *ngIf="selectedReservation">
                  <div class="form-box">
                    <div class="form-label">
                      <label>Адрес:</label>
                    </div>
                    <div class="form-action">
                      {{selectedReservation?.address}}
                    </div>
                  </div>
                  <div class="form-box">
                    <div class="form-label">
                      <label>Дата:</label>
                    </div>
                    <div class="form-action">
                      {{selectedReservation?.date | moment: 'D MMMM'}}
                    </div>
                  </div>
                  <div class="form-box">
                    <div class="form-label">
                      <label>Время:</label>
                    </div>
                    <div class="form-action">
                      {{selectedReservation?.start}} - {{selectedReservation?.finish}}
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <div class="form-box" *ngIf="event?.creator as creator">
            <div class="form-label">
              <label>Создал:</label>
            </div>
            <div class="form-action">
              {{creator.name}}
            </div>
          </div>
          <ng-template #dateFormRef>
            <div class="form-box" *ngIf="form.get('online').value">
              <div class="form-label"></div>
              <div class="form-action">
                <mat-checkbox formControlName="contest">
                  <span style="font-size: 14px; line-height: 100%;">Digital</span>
                </mat-checkbox>
              </div>
            </div>
            <div class="form-box">
              <div class="form-label">
                <label class="required">Дата:</label>
              </div>
              <div class="form-action noGap">
                <input  
                  matInput 
                  [matDatepicker]="picker" 
                  [ngClass]="{disabled:disableAll}"
                  placeholder="Выберите дату" 
                  formControlName="date"
                  (click)="picker.open()"
                >
                <mat-datepicker #picker></mat-datepicker>
              </div>
            </div>
            <div class="form-box">
              <div class="form-label">
                <label class="required">Время:</label>
              </div>
              <div class="form-action row">
                <mat-select
                  class="custom_select for_booking"
                  placeholder="Начало"
                  formControlName="start"
                  [ngClass]="{disabled: !form.controls.date.value || !intervals.length || disableAll}"
                >
                <mat-option [value]="null">--</mat-option>
                <mat-option *ngFor="let interval of intervals" [value]="interval.id">
                    {{interval.start | moment: 'HH:mm'}}
                </mat-option>
                </mat-select>
                <mat-select
                  class="custom_select for_booking"
                  [ngClass]="{disabled: !form.controls.start.value || disableAll}"
                  placeholder="Конец"
                  formControlName="finish"
                >
                <mat-option [value]="null">--</mat-option>
                <mat-option *ngFor="let interval of intervals | filterFinish:form.controls.start.value" [value]="interval.id">
                    {{interval.finish | moment: 'HH:mm'}}
                </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="form-box" *ngIf="form.get('contest').value">
              <div class="form-label">
                <label>Число участников:</label>
              </div>
              <div class="form-action end">
                <input 
                  [ngClass]="{disabled: disableAll}" 
                  type="number"
                  inputmode="numeric"
                  placeholder="Введите число" 
                  formControlName="number_participants" 
                  style="flex: 1;"
                >
              </div>
            </div>
            <div class="form-box">
              <div class="form-label">
                <label>
                  {{checkboxForm.value && !form.get('online').value ?'Место':'Ссылка'}}:
                </label>
              </div>
              <div class="form-action end">
                <input 
                  [ngClass]="{disabled: disableAll}" 
                  [placeholder]="checkboxForm.value && !form.get('online').value ? 'Введите место' : 'Введите ссылку'" 
                  formControlName="link" 
                  style="flex: 1;"
                >
              </div>
            </div>
          </ng-template>
      </form>
    </ng-scrollbar>
  </ng-template>

  <ng-template #tableRef>
    <ng-scrollbar class="tab-container">
      <div class="tableRef">
        <div *ngIf="disableMembers">
          <div class="form-box gap">
            <div class="form-label">
              <label>Участники:</label>
            </div>
            <div class="form-action">
              <div class="button_container file" *ngIf="!form.get('file').value">
                <input id="file" type="file" accept=".xlsx" (change)="onChangeFile($event)" style="display: none;">
                <label class="button" for="file">Загрузить</label>
              </div>
              <div *ngIf="form.get('file').value" class="file-input">
                <input readonly type="text" [placeholder]="fileName" style="width: 100%;">
                <button matRipple class="file-close" (click)="removeFile()">
                  <app-icon-loader [name]="'close'" [size]="16"></app-icon-loader>
                </button>
              </div>
            </div>
          </div>
          <div class="form-box gap">
            <div class="form-label"></div>
            <div class="form-action end">
              <span class="error" *ngIf="!form.get('file').value">Xlsx файл с участниками</span>
            </div>
          </div>
        </div>
        <table mat-table [dataSource]="participants" class="table" *ngIf="participants.length">
  
          <ng-container [matColumnDef]="'name'">
            <th mat-header-cell *matHeaderCellDef>Имя, почта</th>
            <td mat-cell *matCellDef="let element">
              <p>{{element.name}}</p>
              <p>{{element.email}}</p>
            </td>
          </ng-container>
      
          <ng-container [matColumnDef]="'country'">
            <th mat-header-cell *matHeaderCellDef>Страна</th>
            <td mat-cell *matCellDef="let element">
              {{element.country}}
            </td>
          </ng-container>
 
          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        </table>
        <p *ngIf="!participants.length" class="tableRef-empty">Список участников пусто</p>
      </div>
    </ng-scrollbar>
  </ng-template>



