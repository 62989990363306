import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class InfographicsService {
  dataPieChart = {
    condition: '',
    title: '',
    labels: [],
    values: [],
    colors: []
  }

  dataBarChart = {
    condition: '',
    labels: [],
    values: []
  }

  activeColumn = []

  setBarChart = new Set()

  chartTableRows: any

  public dataPieChart$ = new Subject();
  public dataBarChart$ = new Subject();

  public changeDataPieChart(data: any) {
    this.dataPieChart$.next(data);
  }

  public changeDataBarChart(data: any) {
    this.dataBarChart$.next(data);
  }

  checkColumnActive(columnItem) {
    this.activeColumn.push(columnItem)
    if (this.activeColumn.length === 2) {
      this.activeColumn[0].active = false
      this.activeColumn.shift()
    }
  }

  getDataPieChart(columnItem, condition, id) {
    if (!columnItem.title) {
      return;
    }
    this.checkColumnActive(columnItem);
    columnItem.active = columnItem.id === id;
    this.dataPieChart = {
      condition: condition,
      title: columnItem.title,
      labels: [],
      values: [],
      colors: []
    }
    this.chartTableRows.forEach((item) => {
      this.dataPieChart.labels.push(item.title)
      this.dataPieChart.values.push(item.value.find(x => x.title === columnItem.title).value)
      this.dataPieChart.colors.push(item.color)
    })
    this.changeDataPieChart(this.dataPieChart)
  }

  getDataBarChart(columns, condition) {
    this.setBarChart.clear()
    let currentDataBarChartValues = []
    this.dataBarChart = {
      condition: '',
      labels: [],
      values: []
    }
    this.dataBarChart.condition = condition
    columns.forEach((columnItem) => {
      this.dataBarChart.labels.push(columnItem.title)
      this.chartTableRows.forEach((rowItem) => {
        this.setBarChart.add(rowItem)
        this.dataBarChart.values.push({
          label: rowItem.title,
          data: rowItem.value.find(x => x.title === columnItem.title).value,
        })
      })
    })
    this.setBarChart.forEach((item: any) => {
      let dataFilter = this.dataBarChart.values.filter((itemArr) => itemArr.label === item.title)
      currentDataBarChartValues.push({
        label: item.title,
        data: dataFilter.map((item) => {
          return item.data
        }),
        backgroundColor: item.color,
      })
    })
    this.dataBarChart.values = currentDataBarChartValues
    this.changeDataBarChart(this.dataBarChart)
  }
}
