import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'

import { AuthService } from 'services/api'
import { navigationActions } from 'store/navigation'
import { DialogService, StorageService } from 'services'
import { Router } from '@angular/router'
import { AlertDialogComponent } from 'components/dialogs'

@Component({
  selector: 'app-fork-page',
  templateUrl: 'fork-page.component.html',
  styleUrls: ['fork-page.component.scss']
})
export class ForkPageComponent implements OnInit {
  readonly profile$ = this.authService.getUser()

  constructor(
    private readonly store: Store,
    private readonly authService: AuthService,
    private readonly dialogService: DialogService,
    private readonly storageService: StorageService,
    private readonly router: Router
  ) {}

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.store.dispatch(navigationActions.resetFromEcoFlag());
  }

  logout() {
    const data = {
      'title': 'Вы уверены, что хотите выйти?',
      'confirmButtonText': 'ДА',
      'notButtonText': 'НЕТ'
    }

    this.dialogService.openDialog(AlertDialogComponent, data).subscribe((res: any) => {
      if (res) {
        this.authService.signOut().subscribe(() => {
          this.storageService.setValue('token', '')
          this.router.navigate(['auth'])
        })
      }
    })
  }
}
