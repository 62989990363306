import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseService } from 'services/api';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {StorageService} from "services";

@Component({
  selector: 'app-catalogs-main',
  templateUrl: './catalogs-main.component.html',
  styleUrls: ['./catalogs-main.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0
      })),
      state('show', style({
        opacity: 1
      })),
      transition('void => show', [
        style({
          opacity: 0
        }),
        animate(500, style({
          opacity: 1
        }))
      ])
    ])
  ]
})
export class CatalogsMainComponent implements OnInit {

  showPrev = 'show';

  folderId = undefined;
  catalogInfo = undefined;

  mobile = false; // Включаем элементы свойственные мобильной версии
  menuOpen = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: BaseService,
    private storage: StorageService
  ) {}

  resize() {
    if (window.innerWidth <= 1025) {
      this.mobile = true;
      this.menuOpen = false;
    } else {
      this.mobile = false;
      this.menuOpen = true;
    }

    window.addEventListener('resize', () => {
        if (window.innerWidth <= 1025) {
          this.mobile = true;
          this.menuOpen = false;
        } else {
          this.mobile = false;
          this.menuOpen = true;
        }
    });
  }

  openMenu() {
    if (this.menuOpen) {
      this.menuOpen = false;
    } else {
      this.menuOpen = true;
    }
  }

  goBack() {
    this.router.navigate(['learning/learning-home']);
  }

  onFolder(info) {
    this.router.navigate( [ 'catalogs/' + info.id ] );
  }

  onSubFolder(info) {
    this.router.navigate( [ 'catalogs/subfolder/' + this.folderId + '/list/' + info.id ] );
  }

  onCatalog(info) {
    this.storage.setValue('catalog_storage_info', {
      title: info.title,
      tabId: null,
      index: null
    })
    this.router.navigate( [ 'catalogs/' + this.folderId + '/list/' + info.id ] );
  }

  onPdf(info) {
    location.assign(info.file);
  }

  ngOnInit() {
    this.route.params.subscribe( params => {
      this.resize();
      if (!this.storage.getValue('catalog_storage_info')) {
        this.storage.setValue('catalog_storage_info', '')
      }
      this.folderId = params['folder_id'];
      this.api.catalogFolder(params['folder_id']).subscribe((response: any) => {
        this.catalogInfo = response;
        this.catalogInfo.folders = this.catalogInfo.folders.map(
          item => {
            if (item.id === Number(params['folder_id'])) {
              item.active = true;
            }
            return item;
          }
        );
      });
    });
  }
}
