import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthService } from 'services/api';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  #lastLink = new BehaviorSubject<string>(null);

  get lastLink(): string {
    return this.#lastLink.getValue();
  }

  set lastLink(state: string) {
    this.#lastLink.next(state);
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialogService: MatDialog,
  ) {}

  canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<boolean> {
    this.#lastLink.next(state?.url);
    return new Observable((subscriber) => {
      this.authService.validateToken()
      .pipe(
        tap(() => this.lastLink = null)
      )
      .subscribe(
        () => subscriber.next(true),
        () => {
          this.router.navigate(['/auth']);

          // Закрываем все открытые окна
          this.dialogService.closeAll();
          subscriber.next(false);
        }
      );
    });
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate()
  }
}
