import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { animate, state, style, transition, trigger } from '@angular/animations'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'

import { BaseService, ExtraService } from 'services'
import { Observable } from 'rxjs'

SwiperCore.use([Autoplay, Navigation, Pagination])

@Component({
  selector: 'app-main',
  templateUrl: 'main.component.html',
  styleUrls: ['main.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0
      })),
      state('show', style({
        opacity: 1
      })),
      transition('void => show', [
        style({
          opacity: 0
        }),
        animate(500, style({
          opacity: 1
        }))
      ])
    ])
  ]
})
export class MainComponent implements OnInit {
  showPrev = 'show'
  banners: any
  bannersType: any
  clientWidth: any
  counter: any
  section$: Observable<any>;

  constructor(
    private readonly router: Router,
    private readonly baseService: BaseService,
    private extraService: ExtraService
  ) {}

  ngOnInit() {
    this.resize()

    this.baseService.baseGetBanners().subscribe((response: any) => this.banners = response)
    this.section$ = this.extraService.documentContent$;
  }

  goToLink(link) {
    if (link) {
      location.assign(link)
    }
  }

  goPage(page) {
    this.router.navigate([page])
  }

  goLearning() {
    this.router.navigate(['learning'])
  }

  goContests() {
    this.router.navigate(['contests'])
  }

  goImportant() {
    this.baseService.baseGetSectionContentArticles('materials').subscribe((response: any) => {
      this.router.navigate(['important/important-list/' + response.folders[0].id])
    })
  }

  goVideo() {
    this.baseService.baseGetSectionContentArticles('video').subscribe((response: any) => {
      this.router.navigate(['video/video-list/' + response.folders[0].id])
    })
  }

  goNews() {
    this.router.navigate(['news'])
  }

  onWelcomeBook(section) {
    this.router.navigate([section])
  }

  onSection(value) {
    const section = this.extraService.documentContent;
    this.router.navigate([value + '/folder/' + section.boxes.find(x => x.reference === value).id])
  }

  showCounter(value) {
    const section = this.extraService.documentContent;
    
    return section.boxes.find(x => x.reference === value).news
  }

  resize() {
    this.clientWidth = document.documentElement.clientWidth

    if (this.clientWidth >= 600) {
      this.bannersType = '1460х268px'
    } else {
      this.bannersType = '520х300px'
    }
  }
}
