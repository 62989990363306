import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { SelectOptionComponent } from './select-option/select-option.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRippleModule} from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    SelectComponent,
    SelectOptionComponent,
  ],
  imports: [
    CommonModule,
    OverlayModule,
    MatCheckboxModule,
    MatRippleModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule
  ],
  exports: [
    SelectComponent,
    SelectOptionComponent
  ]
})
export class SelectModule { }
