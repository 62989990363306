<app-header></app-header>

<div *ngIf="section" class="outer" [@show_preview]="showPrev" [ngStyle]="{'backgroundImage': 'url(/assets/images/learning_bg.png)'}">
  <div class="workspace learning_home">
    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>УЧИСЬ</span>
        </div>
      </div>
    </div>
    <div class="breadcrumbs">
      <span class="crumb"><span [routerLink]="['/main']" class="crumb_title link">Главная</span><span class="separator">&mdash;</span></span>
      <span class="crumb"><span class="crumb_title">Учись</span></span>
    </div>

    <div class="sections">
      <div class="sections_osn big">
        <div class="sections_left">
          <div class="sections_body two">
            <div class="section_button sk_1 loreal" (click)="onSection('loreal-paris')">
              <div *ngIf="showCounter('loreal-paris') > 0" class="counter"><span>NEW:</span> {{showCounter('loreal-paris')}}</div>
            </div>
            <div class="section_button garnier" (click)="onSection('garnier')">
              <div *ngIf="showCounter('garnier') > 0" class="counter"><span>NEW:</span> {{showCounter('garnier')}}</div>
            </div>
          </div>
          <div class="section_button" (click)="onSection('trainings')">
            <div *ngIf="showCounter('trainings') > 0" class="counter"><span>NEW:</span> {{showCounter('trainings')}}</div>
            Общие тренинги
          </div>
        </div>
        <div class="sections_right">
          <div class="section_button" [routerLink]="['/catalogs/', catalogItemId]">
            <!-- <div class="counter" ><span>NEW:</span> 67</div> -->
            Каталог
          </div>
          <div class="section_button" (click)="onSection('novelties')">
            <div *ngIf="showCounter('novelties') > 0" class="counter"><span>NEW:</span> {{showCounter('novelties')}}</div>
            Новинки
          </div>
        </div>
      </div>
      <div class="sections_osn smoll">
        <div class="section_rz">
          <div class="section_button vebinar" (click)="onSection('marketing_note')">
            <div *ngIf="showCounter('marketing_note') > 0" class="counter"><span>NEW:</span> {{showCounter('marketing_note')}}</div>
            Вебинары по сложным вопросам
          </div>
          <div class="section_button" (click)="onSection('trends')">
            <div *ngIf="showCounter('trends') > 0" class="counter"><span>NEW:</span> {{showCounter('trends')}}</div>
            ТРЕНДЫ
          </div>
        </div>
        <div class="section_rz">
<!--          <div class="section_button" (click)="onSection('sra')">-->
          <div class="section_button">
<!--            <div class="lock" *ngIf="showCounter('sra') > 0" ><span>NEW:</span> {{showCounter('sra')}}</div>-->
             <div class="lock"></div>
            Онлайн расписание
          </div>
          <div class="section_button sk_3" (click)="onSection('library-wordbook')">
            <div *ngIf="showCounter('library-wordbook') > 0" class="counter"><span>NEW:</span> {{showCounter('library-wordbook')}}</div>
            БИБЛИОТЕКА И СЛОВАРЬ
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="learn_button_set">
      <div class="learn_button">
        <div class="image_and_desc_container" (click)="goTrainings()">
          <div class="learn_button_image" [ngStyle]="{'backgroundImage': 'url(' + '/assets/learn-button-set/trainings.png' + ')'}">
            <div class="counter" *ngIf="counter && counter.courses">+{{counter.courses}}</div>
          </div>
          <span class="learn_button_description">ТРЕНИНГИ</span>
        </div>
      </div>
      <div class="learn_button">
        <div class="image_and_desc_container" (click)="goMarketing()">
          <div class="learn_button_image" [ngStyle]="{'backgroundImage': 'url(' + '/assets/learn-button-set/marketing.png' + ')'}">
            <div class="counter" *ngIf="counter && counter.marketing_note">+{{counter.marketing_note}}</div>
          </div>
          <span class="learn_button_description">МАРКЕТИНГ НОТА</span>
        </div>
      </div>
      <div class="learn_button">
        <div class="image_and_desc_container" (click)="goLibrary()">
          <div class="learn_button_image" [ngStyle]="{'backgroundImage': 'url(' + '/assets/learn-button-set/library-wordbook.png' + ')'}">
            <div class="counter" *ngIf="counter && counter.library-wordbook">+{{counter.library-wordbook}}</div>
          </div>
          <span class="learn_button_description">БИБЛИОТЕКА</span>
        </div>
      </div>
      <div class="learn_button">
        <div class="image_and_desc_container" [routerLink]="['/catalogs']">
          <div class="learn_button_image" [ngStyle]="{'backgroundImage': 'url(' + '/assets/learn-button-set/catalog.png' + ')'}"></div>
          <span class="learn_button_description">КАТАЛОГ</span>
        </div>
      </div>
      <div class="learn_button">
        <div class="image_and_desc_container" (click)="goNewest()">
          <div class="learn_button_image" [ngStyle]="{'backgroundImage': 'url(' + '/assets/learn-button-set/newest.png' + ')'}">
            <div class="counter" *ngIf="counter && counter.novelties">+{{counter.novelties}}</div>
          </div>
          <span class="learn_button_description">НОВИНКИ</span>
        </div>
      </div>
      <div class="learn_button">
        <div class="image_and_desc_container" [routerLink]="['/learning/trends']">
          <div class="learn_button_image" [ngStyle]="{'backgroundImage': 'url(' + '/assets/learn-button-set/trends.png' + ')'}">
            <div class="counter" *ngIf="counter && counter.trends">+{{counter.trends}}</div>
          </div>
          <span class="learn_button_description">ТРЕНДЫ</span>
        </div>
      </div>

      <div class="learn_button">
        <div class="image_and_desc_container block">
          <div class="learn_button_image block"></div>
          <span class="learn_button_description"></span>
        </div>
      </div>
      <div class="learn_button">
        <div class="image_and_desc_container block">
          <div class="learn_button_image block"></div>
          <span class="learn_button_description"></span>
        </div>
      </div>
      <div class="learn_button">
        <div class="image_and_desc_container block">
          <div class="learn_button_image block"></div>
          <span class="learn_button_description"></span>
        </div>
      </div>
    </div> -->

  </div>
</div>
