export enum TabType {
    Description = 'description',
    Ingredients = 'ingredients',
    Composition = 'composition',
    Application = 'application',
    Sales = 'sales',
    Questions = 'questions',
    Documents = 'documents',
    Reviews = 'reviews',
}
  
export interface ProductInfo {
  title: string;
  type: TabType;
  elements: any[];
}