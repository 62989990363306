import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { BaseService } from 'services';
import { Tag, TestQuestion } from 'services/api/catalogs/catalogs-query';

@Injectable()
export class CatalogsFaqService {
  readonly faqForm: FormGroup = this.fb.group({
    tag_ids: [],
    ean_code: null
  });

  #isLoading = new BehaviorSubject<boolean>(false);

  get isLoading$(): Observable<boolean> {
    return this.#isLoading.asObservable();
  }

  set isLoading(state: boolean) {
    this.#isLoading.next(state);
  }

  #faqs = new BehaviorSubject<TestQuestion[]>([]);

  get faqs$(): Observable<TestQuestion[]>  {
    return this.#faqs.asObservable();
  }

  #tags = new BehaviorSubject<Tag[]>([]);

  get tags$(): Observable<Tag[]> {
    return this.#tags.asObservable();
  }

  #activeTags = new BehaviorSubject<Set<number>>(new Set());

  get activeTags(): Set<number> {
    return this.#activeTags.getValue();
  }

  set activeTags(tags: Set<number>) {
    this.#activeTags.next(tags);
  }

  constructor(
    private baseService: BaseService,
    private fb: FormBuilder
  ) { }

  getTags$(): Observable<Tag[]> {
    return this.baseService.catalogTags$()
      .pipe(
        tap((response) => {
          this.#tags.next(response);
        })
      )
  }

  getCards$(): Observable<TestQuestion[]> {
    return this.baseService.catalogQuestions$(
      this.faqForm.value
    ).pipe(
      tap((response) => {
        this.#faqs.next(response);
      }),
      finalize(() => this.#isLoading.next(false))
    )
  }
}
