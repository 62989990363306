import { Component, Input, OnInit } from "@angular/core";

import { animate, state, style, transition, trigger } from '@angular/animations';
import { BaseService } from "services/api";

import { VgApiService } from '@videogular/ngx-videogular/core';
import { DialogService } from "services";

import { VideoTestDialogComponent } from "components/dialogs";

@Component({
  selector: 'app-video-test',
  templateUrl: 'video-test.component.html',
  styleUrls: ['video-test.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class VideoTestComponent implements OnInit {

  constructor(
    private baseService: BaseService,
    private dialogService: DialogService,
  ) {
  }

  @Input() videoTestId = undefined;
  videoTestBody = undefined;

  onPlayerReady(api: VgApiService) {
    const video = api.getMediaById('singleVideo');
    let stopTime = 0;
    video.subscriptions.timeUpdate.subscribe((event) => {
      let currentTime = +event.target.currentTime.toFixed(0);
      if (this.videoTestBody.questions.find(item => item.point === currentTime)) {
        video.pause();
        let question = this.videoTestBody.questions.shift();
        let data = {
          question: question
        }
        this.dialogService.openDialog(VideoTestDialogComponent, data).subscribe((res:  any) => {
          switch (res) {
            case 'continue':
              stopTime = currentTime;
              video.play();
              break;
            case 'withQuestion':
              api.getDefaultMedia().currentTime = stopTime;
              this.videoTestBody.questions.unshift(question);
              video.play();
              break;
            case 'withoutQuestion':
              api.getDefaultMedia().currentTime = stopTime;
              video.play();
              break;
          }
        })
      }
    })
  }

  ngOnInit() {
    this.baseService.baseGetVideoTest(this.videoTestId).subscribe((response: any) => {
      this.videoTestBody = response;
      console.log(response);
    })
  }

}
