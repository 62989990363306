import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Router} from "@angular/router";
import {BaseService} from "services/api";
import {ExtraService, GameModeratorService, StorageService} from "services";

@Component({
  selector: 'app-preview',
  templateUrl: 'preview.component.html',
  styleUrls: ['preview.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class PreviewComponent implements OnInit {

  constructor(
    private router: Router,
    private baseService: BaseService,
    private gameModerService: GameModeratorService,
    private storageService: StorageService,
    public extraService: ExtraService
  ) {
  }


  account: number
  mobile = false

  shopDescription: any

  lang: string

  goBasket() {
    this.router.navigate(['/game-moderator-store/basket']);
  }

  goStore() {
    this.router.navigate(['/game-moderator-store/store']);
  }

  resize() {
    if (window.innerWidth <= 1024) {
      this.mobile = true;
      this.extraService.backgroundStore = '../assets/images/game/bg_basket_mobile.png'
    } else {
      this.mobile = false;
      this.extraService.backgroundStore = '../assets/images/game/bg_basket_desktop.jpg'
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1024) {
        this.mobile = true;
        this.extraService.backgroundStore = '../assets/images/game/bg_basket_mobile.png'
      } else {
        this.mobile = false;
        this.extraService.backgroundStore = '../assets/images/game/bg_basket_desktop.jpg'
      }
    });
  }

  ngOnInit() {
    this.resize()
    this.gameModerService.getLang()
    this.lang = this.storageService.getValue('game_moder_lang')
    this.gameModerService.rewrite({shop: '', start_button: ''}).then((res: any) => {
      this.shopDescription = res
    })
    this.baseService.baseGetStoreAccount().subscribe((response: any) => {
      this.account = response.value
    })
  }

}
