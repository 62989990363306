import { Directive, Input, OnChanges } from '@angular/core'

@Directive()
export class ProgressBarDirective implements OnChanges {
  @Input() maxScores = 0
  @Input() currentScores = 0

  protected ratio = 0

  /**
   * ngOnChanges
   */
  ngOnChanges(): void {
    this.ratio = this.maxScores
      ? this.currentScores < this.maxScores
        ? this.currentScores / this.maxScores
        : 1
      : 0
  }
}
