import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { BaseService } from 'services/api'
import { DialogService } from 'services/dialog.service'
import { VideoDialogComponent } from 'components/dialogs'
import { animate, state, style, transition, trigger } from '@angular/animations'

declare var $: any;

@Component({
  selector: 'app-oap-mbl-catalog-card',
  templateUrl: './oap-mbl-catalog-card.component.html',
  styleUrls: ['./oap-mbl-catalog-card.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0
      })),
      state('show', style({
        opacity: 1
      })),
      transition('void => show', [
        style({
          opacity: 0
        }),
        animate(500, style({
          opacity: 1
        }))
      ]),
      transition('none => show', [
        style({
          opacity: 0
        }),
        animate(500, style({
          opacity: 1
        }))
      ]),
      transition('show => void', [
        style({
          opacity: 1
        }),
        animate(0, style({
          opacity: 0
        }))
      ]),
      transition('show => none', [
        style({
          opacity: 1
        }),
        animate(0, style({
          opacity: 0
        }))
      ])
    ])
  ]
})
export class OapMblCatalogCardComponent implements OnInit {
  product:any = undefined;
  images = []; //Все картинки
  number = 3; //Число картинок в слайдере
  start = 0;
  end = this.number;
  pictures = []; //Картинки на одной странице слайдера
  firstImage = undefined;
  tabs = ['Состав','Доп. информация'];
  activeTab = 'active_ingredients';
  showPrev = 'none';
  token = 'EpxjdSvfeScpMDABKR0LEjzB9cPBLG2RZDSsedZ9lGrHU9Z4zq';
  cpd_token: string = '';

  filesMny = 'https://mbl-old-api.evpportal.ru/files/';

  constructor(
    private route: ActivatedRoute,
    private api: BaseService,
    private dialogService: DialogService,
  ) {}

  onTab(index){
    this.activeTab = index;
  }

  otherItem(what){
    $(".window").removeClass("window_down");
    if(what === 'add_sales'){
      $("#additional").fadeIn(300);
    }
    if(what === 'nuances'){
      $("#nuances").fadeIn(300);
    }
  }

  close(){
    $(".window").addClass("window_down");
    $(".window_body").fadeOut(600);
  }

  openVideo(video){
    const data = {
      videoPath: this.filesMny + '' + video,
      token: this.token,
      confirmButtonText: 'Закрыть'
    };

    this.dialogService.openDialog(VideoDialogComponent, data).subscribe();
  }

  pictureChange(img){
    this.firstImage = img;
  }

  pictureThumb(wtf) {
    let length = this.images.length;
    let index = this.images.indexOf(this.firstImage);
    if(wtf === 'next'){
      if(index === (length - 1)) {
        this.firstImage = this.images[0];
      }else {
        this.firstImage = this.images[index + 1];
      }
    }
    if(wtf === 'back'){
      if(index === 0) {
        this.firstImage = this.images[length - 1];
      }else {
        this.firstImage = this.images[index - 1];
      }
    }
  }

  scrollingPictures(num:number = 0){
    if((this.start === 0 && num === -1) || (this.end === this.images.length && num === 1)){
      return;
    }
    //this.showPrev = 'none';
    this.pictures = [];
    this.start = (this.start + num);
    this.end = (this.end + num);
    for(let i = this.start; i < this.end; i++){
      this.pictures.push(this.images[i]);
    }
    this.showPrev = 'show';
  }

  ngOnInit() {
    $(".catalog_pod").fadeOut(0);
    $(".catalog_osn").slideUp(0);
    this.route.params.subscribe( params => {
      this.start = 0;
      this.end = this.number;
        this.api.productsMnyCatalog(params['product_id']).subscribe( ( response: any ) => {
          setTimeout(()=> {
            this.product = response;
          },0);

          this.images = response.images;
          if(this.images.length !== 0){
            this.firstImage = this.images[0];
            if(this.images.length > this.number){
              this.scrollingPictures();
            }else {
              this.pictures = response.images;
            }
          }
        });
    });
  }
}
