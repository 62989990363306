import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ExtraService, GameModeratorService, StorageService } from 'services'
import { Location } from '@angular/common'
import { AuthService } from 'services/api'

@Component({
  selector: 'app-game-moderator-rules',
  templateUrl: 'game-moderator-rules.component.html',
  styleUrls: ['game-moderator-rules.component.scss']
})
export class GameModeratorRulesComponent implements OnInit {
  constructor(
    private location: Location,
    private router: Router,
    private authService: AuthService,
    private gameModerService: GameModeratorService,
    private storageService: StorageService,
    public extraService: ExtraService
  ) {}

  rules: undefined | any

  inactiveButton = true;

  rulesCheck = false;

  mobile = false

  lang: string

  goBack() {
    this.location.back()
  }

  changeAgreement(event) {
    this.rulesCheck = false;
    if (event.checked) {
      this.inactiveButton = false;
      return;
    }
    this.inactiveButton = true;
    return;
  }

  onRules() {
    this.authService.authGetRules(this.lang).subscribe((response: any) => {
      let tab = window.open('about:blank', '_blank');
      tab.document.write(response.text);
      tab.document.close();
    })
  }

  goStages() {
    if (!this.inactiveButton) {
      this.router.navigate(['game-moderator/stages'])
    } else {
      this.rulesCheck = true;
    }
  }

  resize() {
    if (window.innerWidth <= 1024) {
      this.mobile = true;
      this.extraService.backgroundStore = '/assets/images/game/bg_mobile_2.png'
    } else {
      this.mobile = false;
      this.extraService.backgroundStore = '/assets/images/game/bg_desktop_2.jpg'
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1024) {
        this.mobile = true;
        this.extraService.backgroundStore = '/assets/images/game/bg_mobile_2.png'
      } else {
        this.mobile = false;
        this.extraService.backgroundStore = '/assets/images/game/bg_desktop_2.jpg'
      }
    });
  }

  ngOnInit() {
    this.resize()
    this.gameModerService.getLang()
    this.lang = this.storageService.getValue('game_moder_lang')
    this.gameModerService.rewrite({rules: '', start_button: ''}).then((res: any) => {
      this.rules = res
    })
  }

}
