import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'

@Pipe({
  name: 'timestampToDate'
})
export class TimestampToDatePipe implements PipeTransform {
  transform(timestamp: any, format: string): any {
    let result

    switch (format) {
      case 'shortDate':
        // dd.mm.yy
        result = moment(timestamp).format('D.M.YY')
        break
      case 'mediumDate':
        result = moment(timestamp).format('DD.MM.YYYY')
        break
      case 'shortTime':
        // hh:mm
        result = moment(timestamp).format('HH:MM')
        break
    }

    return result
  }
}
