import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SectionsDateService {
  sectionIo = {
    'loreal-paris': {
      'title': 'L\'OREAL PARIS',
      'homeLink': 'learning/learning-home/',
      'folderLink': 'loreal-paris/folder/',
      'documentLink': 'loreal-paris/document/article/',
      'homePage': {
        'status': true,
        'breadcrumbs': {title: 'Учись', type: 'home'}
      }
    },
    'garnier': {
      'title': 'Garnier',
      'homeLink': 'learning/learning-home/',
      'folderLink': 'garnier/folder/',
      'documentLink': 'garnier/document/article/',
      'homePage': {
        'status': true,
        'breadcrumbs': {title: 'Учись', type: 'home'}
      }
    },
    'faq': {
      'title': 'Академия',
      'homeLink': 'main/',
      'folderLink': 'faq/folder/',
      'documentLink': 'faq/document/article/',
      'homePage': {
        'status': false
      }
    },
    'assessment': {
      'title': 'Оценка',
      'homeLink': 'main/',
      'folderLink': 'assessment/folder/',
      'documentLink': 'assessment/document/article/',
      'homePage': {
        'status': false
      }
    },
    'materials': {
      'title': 'Важно',
      'homeLink': 'main/',
      'folderLink': 'materials/folder/',
      'documentLink': 'materials/document/article/',
      'homePage': {
        'status': false
      }
    },
    'video': {
      'title': 'Новичок',
      'homeLink': 'main/',
      'folderLink': 'video/folder/',
      'documentLink': 'video/document/article/',
      'homePage': {
        'status': false
      }
    },
    'news': {
      'title': 'Новости',
      'homeLink': 'main/',
      'folderLink': 'news/folder/',
      'documentLink': 'news/document/article/',
      'homePage': {
        'status': false
      }
    },
    'novelties': {
      'title': 'Новинки',
      'homeLink': 'learning/learning-home/',
      'folderLink': 'novelties/folder/',
      'documentLink': 'novelties/document/article/',
      'homePage': {
        'status': true,
        'breadcrumbs': {title: 'Учись', type: 'home'}
      }
    },
    'trends': {
      'title': 'Тренды',
      'homeLink': 'learning/learning-home/',
      'folderLink': 'trends/folder/',
      'documentLink': 'trends/document/article/',
      'homePage': {
        'status': true,
        'breadcrumbs': {title: 'Учись', type: 'home'}
      }
    },
    'marketing_note': {
      'title': 'ВЕБИНАР ПО СЛОЖНЫМ ВОПРОСАМ',
      'homeLink': 'learning/learning-home/',
      'folderLink': 'marketing_note/folder/',
      'documentLink': 'marketing_note/document/article/',
      'homePage': {
        'status': true,
        'breadcrumbs': {title: 'Учись', type: 'home'}
      }
    },
    'library-wordbook': {
      'title': 'Библиотека и словарь',
      'homeLink': 'learning/learning-home/',
      'folderLink': 'library-wordbook/folder/',
      'documentLink': 'library-wordbook/document/article/',
      'wordbookLink': 'library-wordbook/document/wordbook/',
      'homePage': {
        'status': true,
        'breadcrumbs': {title: 'Учись', type: 'home'}
      }
    },
    'trainings': {
      'title': 'Общие тренинги',
      'homeLink': 'learning/learning-home/',
      'folderLink': 'trainings/folder/',
      'documentLink': 'trainings/document/article/',
      'homePage': {
        'status': true,
        'breadcrumbs': {title: 'Учись', type: 'home'}
      }
    },
    'sra': {
      'title': 'SRA',
      'homeLink': 'learning/learning-home/',
      'folderLink': 'sra/folder/',
      'documentLink': 'sra/document/article/',
      'homePage': {
        'status': true,
        'breadcrumbs': {title: 'Учись', type: 'home'}
      }
    }
  }

  sectionsInfo() {
    return this.sectionIo;
  }
}
