<app-header></app-header>

<div class="outer" [ngStyle]="{'backgroundImage': 'url(' + this.backgroundWord + ')'}">
  <div class="workspace" *ngIf="gameInfo">

    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>{{gameInfo.title}}</span>
        </div>
      </div>
    </div>

    <div class="image_block" *ngIf="gameInfo.image">
      <img [src]="(gameInfo.image)">
    </div>

    <div class="html_text"
         *ngIf="gameInfo.text !== ''"
         [innerHTML]="gameInfo.text | safeHtml"
    ></div>

    <div class="video" *ngIf="gameInfo.file !== null">
      <vg-player>
        <vg-overlay-play></vg-overlay-play>
        <vg-buffering></vg-buffering>

        <vg-scrub-bar>
          <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
          <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>

        <vg-controls [vgAutohide]="true">
          <vg-play-pause></vg-play-pause>
          <vg-playback-button [playbackValues]="[ '0.5', '1.0', '1.25', '1.5', '2.0' ]"></vg-playback-button>

          <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

          <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

          <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>


          <vg-mute></vg-mute>
          <vg-volume></vg-volume>

          <vg-fullscreen></vg-fullscreen>
        </vg-controls>
        <video [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" crossorigin>
          <source type="video/mp4" [src]="gameInfo.file">
        </video>
      </vg-player>
    </div>

    <div class="button" (click)="goStages()">НАЧАТЬ</div>

  </div>
</div>
