import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { ApiService } from 'core/api'
import { NewsContent, NewsPreview } from 'core/eco-portal'
import { Pagination } from 'core/common'
import { createNewsUrls } from './news-urls'

@Injectable({
  providedIn: 'root'
})
export class NewsService extends ApiService {
  private readonly urls = createNewsUrls()

  /**
   * Gets news previews.
   *
   * @param pagination
   */
  getNewsPreviews(pagination: Pagination): Observable<NewsPreview[]> {
    return this.http.post<NewsPreview[]>(this.urls.getNewsPreviews, pagination)
  }

  /**
   * Gets the news content.
   *
   * @param id
   */
  getNewsContent(id: number): Observable<NewsContent> {
    return this.http.post<NewsContent>(this.urls.getNewsContent, {id})
  }

  /**
   * Marks the news as read.
   *
   * @param id
   */
  markNewsAsRead(id: number): Observable<Partial<NewsContent>> {
    return this.http.post<Partial<NewsContent>>(this.urls.markNewsAsRead, {id})
  }
}
