import { Injectable } from "@angular/core";
import { StorageService } from "services/storage.service";
import { ExtraService } from "services/extra.service";

@Injectable({
  providedIn: "root"
})

export class MetricsService {

  view = 0;
  duration = 0;
  getTimer: any;
  created = 0;

  constructor(
    private storage: StorageService,
    private extraService: ExtraService,
  ) {}

  timer() {
    this.duration = 0;

    this.getTimer = window.setInterval(() => {
      this.duration += 1;
    },1000);
  }

  takeTime(section, id, created) {

    if (!this.storage.getValue('metrics')) {
      this.storage.setValue('metrics', {'time_intervals': []});
    }

    const time_intervals = this.storage.getValue('metrics')['time_intervals'];

    switch (this.extraService.inDocument) {
      case 'document':
        time_intervals.push(
          {
            'date': Number(created),
            'action': 'view',
            'section_id': section,
            'model_name': 'Document',
            'entity_id': id,
            'duration': this.duration
          }
        );
        break;
    }

    this.storage.setValue(
      'metrics',
      {
        'time_intervals': time_intervals
      }
    );

  }

  clearTimer() {
    clearInterval(this.getTimer);
  }

}
