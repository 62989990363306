import { Directive, HostListener } from '@angular/core'

import { InputDialogDirective } from './input-dialog.directive'

@Directive()
export class InputDialogTriggerDirective {
  inputDialog!: InputDialogDirective
  disabled = false

  /**
   * Opens the input dialog.
   */
  @HostListener('click')
  protected openDialog(): void {
    if (this.disabled) {
      return
    }

    this.inputDialog.openDialog()
  }
}
