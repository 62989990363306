<div class="catalog-tabs">
  <div class="wrapper">
    <div class="tabs">
      <ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem class="slide" *ngFor="let tab of tabs; let i = index" (click)="tabOpen(tab.uid,i)" [ngClass]="{'active_tab': activeTab === i}">{{tab.title}}
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>
<div class="catalog_osn">
  <div class="catalog_content">
    <div class="back" (click)="goBack()">Вернуться в каталог</div>
    <div class="item" *ngFor="let item of items">
      <div class="item_title">{{item.title}}</div>
      <div class="products">
        <div
          class="product"
          *ngFor="let product of item.products"
          (mouseenter) ="showPreview(product,item)"
          (mouseleave) ="removePreview()"
          (click)="onProduct(product)"
        >
          {{product.title}}
          <div class="icon_container" [ngStyle]="{left: product.new && product.e_com ? '-30px' : '0'}">
            <div class="new" *ngIf="product.new"></div>
            <div class="e-com" *ngIf="product.e_com"></div>
          </div>
          <div *ngIf="preview && item.uid === uidHomePreview" class="item_img"><img [src]="(filesMny + '' + preview)"></div>
        </div>
      </div>
    </div>

  </div>
  <div class="close" (click)="tabClose()">Закрыть</div>
</div>
<div class="catalog_pod"></div>

<router-outlet></router-outlet>
