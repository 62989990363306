<app-header></app-header>

<div
  *ngIf="catalogInfo"
  class="outer"
  [@show_preview]="showPrev"
>
  <div class="workspace">

    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>КАТАЛОГ</span>
        </div>
      </div>
    </div>

    <section
      class="menu"
    >
      <div
        *ngIf="mobile"
        class="menu_open"
        (click)="openMenu()"
      >
        <span
          class="ar"
          [ngClass]="{
            'open': menuOpen
          }"
        ></span>Разделы
      </div>
      <div [@show_preview]="showPrev" *ngIf="menuOpen" class="menu_osn">
        <div
          class="menu_li"
          *ngFor="let info of catalogInfo.folders"
          (click)="onFolder(info)"
          [ngClass]="{
            'active': info.active
          }"
        >
          {{info.title}}
        </div>
      </div>
    </section>

    <section class="content">
      <ng-scrollbar
        *ngIf="catalogInfo.catalogs.length > 0"
        track="horizontal"
        style="height: 490px; width: 100%"
      >
        <div infiniteScroll
             appHorizontalScroll
             [horizontal]="true"
             [scrollWindow]="false"
             scrollViewport
             style="width: 100%; display: flex"
        >
          <div
            *ngIf="catalogInfo.catalogs.length > 0"
            class="documents"
          >
            <div
              [@show_preview]="showPrev"
              class="document"
              (click)="onCatalog(info)"
              *ngFor="let info of catalogInfo.catalogs;let i = index"
              [ngStyle]="{backgroundImage: 'url('+ (info.preview) + ')'}"
            >
              <!-- <div class="new">NEW</div> -->
              <div class="document_text">
                <div class="document_text_title">{{info.title}}</div>
                <div
                  class="button"
                >
                  <span class="ar"></span>Перейти
                </div>
              </div>
            </div>

          </div>
        </div>

      </ng-scrollbar>

      <ng-scrollbar
        *ngIf="catalogInfo.documents.length > 0"
        track="horizontal"
        style="height: 490px; width: 100%"
      >
        <div infiniteScroll
             appHorizontalScroll
             [horizontal]="true"
             [scrollWindow]="false"
             scrollViewport
             style="width: 100%; display: flex"
        >
          <div
            *ngIf="catalogInfo.documents.length > 0"
            class="documents"
          >
            <div
              [@show_preview]="showPrev"
              class="document"
              (click)="onPdf(info)"
              *ngFor="let info of catalogInfo.documents;let i = index"
              [ngStyle]="{backgroundImage: 'url('+ (info.preview) + ')'}"
            >
              <!-- <div class="new">NEW</div> -->
              <div class="document_text">
                <div class="document_text_title">{{info.title}}</div>
                <div
                  class="button"
                >
                  <span class="ar"></span>Смотреть
                </div>
              </div>
            </div>

          </div>
        </div>

      </ng-scrollbar>

      <ng-scrollbar
        *ngIf="catalogInfo.subfolders.length > 0"
        track="horizontal"
        style="height: 490px; width: 100%"
      >
        <div infiniteScroll
             appHorizontalScroll
             [horizontal]="true"
             [scrollWindow]="false"
             scrollViewport
             style="width: 100%; display: flex"
        >
          <div
            *ngIf="catalogInfo.subfolders.length > 0"
            class="documents"
          >
            <div
              [@show_preview]="showPrev"
              class="document"
              (click)="onSubFolder(info)"
              *ngFor="let info of catalogInfo.subfolders; let i = index"
              [ngStyle]="{backgroundImage: 'url('+ (info.preview) + ')'}"
            >
              <!-- <div class="new">NEW</div> -->
              <div class="document_text">
                <div class="document_text_title">{{info.title}}</div>
                <div
                  class="button"
                >
                  <span class="ar"></span>Перейти
                </div>
              </div>
            </div>

          </div>
        </div>

      </ng-scrollbar>

    </section>

  </div>
</div>
