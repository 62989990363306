<div class="custom_dialog booking" *ngIf="reservation">
  <div class="container_title">
    <div class="title">{{reservation.title}}</div>
    <div></div>
  </div>

  <div class="title_room">{{reservation.room.title}} с {{reservation.intervals[0].start}} по {{reservation.intervals[reservation.intervals.length - 1].finish}}</div>

  <div class="info_container">
    <div class="info">{{reservation.owner.name}}</div>
    <div class="info">{{reservation.owner.phone}}</div>
  </div>

  <div class="button_container">
    <div class="button" (click)="onConfirm()">ОК</div>
    <div class="button" (click)="deleteReservation()" *ngIf="!disableAll">УДАЛИТЬ</div>
  </div>
</div>
