export enum PersonalRoute {
    profile = 'profile',
    statistics = 'statistics',
    team = 'team',
    reports = 'reports',
    infographics = 'infographics',
    management = 'management',
    booking = 'booking',
    schedule = 'schedule'
}
