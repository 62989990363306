<app-header [logoWhite]="true"></app-header>

<div class="outer"  [ngStyle]="{'backgroundImage': 'url(' + extraService.backgroundStore + ')'}">
  <div class="workspace">

    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>BE IN TOUCH</span>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="block_text">
        <div class="title" [innerHTML]="testDescription?.second_stage.title | safeHtml"></div>
        <div class="text" [innerHTML]="testDescription?.second_stage.text | safeHtml"></div>
        <div class="button_container">
          <div class="button" [innerHTML]="testDescription?.start_button.text | safeHtml" (click)="goTest()"></div>
        </div>
      </div>
    </div>

  </div>
</div>
