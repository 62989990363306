import { Injectable } from '@angular/core';
import { StorageService } from "services/storage.service";
import { BaseService } from "services/api";

@Injectable({
  providedIn: 'root',
})
export class GameModeratorService {
  constructor(
    private storageService: StorageService,
    private baseService: BaseService
  ) {}

  lang: string

  startScreen = {
    start_message: null,
    next_button: null
  }

  rules = {
    rules: null,
    start_button: null
  }

  shopDescription = {
    shop: null,
    start_button: null
  }

  testDescription = {
    second_stage: null,
    start_button: null
  }

  getLang() {
    if (this.storageService.getValue('game_moder_lang')) {
      this.lang = this.storageService.getValue('game_moder_lang')
    } else {
      this.storageService.setValue('game_moder_lang', 'ru')
      this.lang = this.storageService.getValue('game_moder_lang')
    }
  }

  setLang(lang: string) {
    this.storageService.setValue('game_moder_lang', lang)
    this.lang = this.storageService.getValue('game_moder_lang')
  }

  async rewrite(obj: any) {
    Object.keys(obj).forEach((key) => {
      this.baseService.baseGameModerMessage(key, this.lang).subscribe((response: any) => {
        obj[key] = response
      })
    })
    return await obj
  }
}
