<app-header [logoWhite]="true"></app-header>

<div class="outer" [ngStyle]="{'backgroundImage': 'url(' + extraService.backgroundStore + ')'}">

  <div class="workspace">

    <div class="bread_and_title_container">
      <div class="bread_and_title game_moder">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>BE IN TOUCH</span>
        </div>
      </div>
    </div>

    <div class="store_container">
      <div class="store_button" (click)="goStore()">
        <div class="cart_icon"></div>
      </div>
      <div class="points_container">
        <div class="points">{{account}}</div>
        <div class="coin_icon"></div>
      </div>
    </div>

    <div class="stages_container">
      <div class="stage_container" *ngFor="let stage of stages; let i = index" [ngClass]="{not_active: !stage.is_available}" (click)="goStage(stage, i)">
        <div class="round">
          <div class="round_num">{{i + 1}}</div>
          <div class="icon" [ngClass]="{wordbook: i === 0, phone: i === 1, laptop: i === 2}"></div>
        </div>
        <div class="desc">
          <div class="title">{{stage.title}}</div>
          <div class="text">{{stage.description}}</div>
          <div class="time_and_coin_container">
            <div class="time">{{stage.time | msToTime}}</div>
            <div class="coin_container">
              +{{stage.scores}}
              <div class="coin"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
