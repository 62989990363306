import { Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss']
})
export class VideoDialogComponent implements OnInit {

  title: string;
  text: string;
  token: string;
  videoPath: string;
  confirmButtonText = 'OK';

  constructor(
    private dialogRef: MatDialogRef<VideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {'title': string, text: string, token: string, videoPath: string, confirmButtonText: string}
  ) {
    ({
      title: this.title,
      text: this.text,
      token: this.token,
      videoPath: this.videoPath,
      confirmButtonText: this.confirmButtonText
    } = this.dialogData);
  }

  ngOnInit() {

  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

}
