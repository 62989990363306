<app-header></app-header>

<div
  *ngIf="sectonInfo"
  class="outer"
  [@show_preview]="showPrev"
  [ngStyle]="{'backgroundImage': 'url(' + (sectonInfo.self.ground ? (sectonInfo.self.ground) : '/assets/images/learning_bg.png') + ')'}"
>
  <div class="workspace">
    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>L'OREAL PARIS</span>
        </div>
      </div>
    </div>

    <div *ngIf="breadcrumbs.length > 0" class="breadcrumbs">
      <span class="crumb"><span [routerLink]="['/main']" class="crumb_title link">Главная</span><span class="separator">&mdash;</span></span>
      <span class="crumb"><span [routerLink]="['/learning/learning-home']" class="crumb_title link">Учись</span><span class="separator">&mdash;</span></span>
      <span
        *ngFor="let bread of breadcrumbs;let i = index"
        class="crumb"
      >
        <span *ngIf="i === breadcrumbs.length - 1" class="crumb_title">{{bread.title}}</span>
        <span *ngIf="i !== breadcrumbs.length - 1" class="crumb_title link" (click)="onFolder(bread.id)">{{bread.title}}<span class="separator">&mdash;</span></span>
      </span>
    </div>

    <section class="menu">
      <div
        *ngIf="mobile"
        class="menu_open"
        [ngClass]="{
          'green': sectonInfo.self.color === 'green'
        }"
        (click)="openMenu()"
      >
        <span
          class="ar"
          [ngClass]="{
            'open': menuOpen
          }"
        ></span>Разделы
      </div>
      <div [@show_preview]="showPrev" *ngIf="menuOpen" class="menu_osn">
        <div
          class="menu_li"
          *ngFor="let info of folderMenu"
          (click)="onFolder(info.id)"
          [ngClass]="{
            'active': info.active,
            'green': sectonInfo.self.color === 'green'
          }"
        >{{info.title}}</div>
      </div>
    </section>

    <section class="content">
      <div *ngIf="sectonInfo.self.template === 'template_1'">
        <div class="center">
          <div
            *ngIf="sectonInfo.documents.length > 0"
            class="button"
            (click)="onDocument(sectonInfo.documents[0].id)"
          >{{sectonInfo.documents[0].title}}</div>
          <div
            *ngIf="folderContent.length > 0"
            class="button"
            (click)="onFolder(folderContent[0].id)"
          >{{folderContent[0].title}}</div>
        </div>
      </div>
      <div *ngIf="sectonInfo.self.template === 'template_2'">
        <div
          *ngIf="sectonInfo.documents.length > 0"
          class="documents"
        >
          <div
            (click)="onDocument(info.id)"
            *ngFor="let info of sectonInfo.documents;let i = index"
            class="document"
            [ngStyle]="{backgroundImage: 'url('+ (info.preview) + ')'}"
          >
            <div *ngIf="info.is_new" class="new">NEW</div>
            <div class="document_text">
              <div class="document_text_title">{{info.title}}</div>
              <div
                class="button"
                [ngClass]="{
                  'green': sectonInfo.self.color === 'green'
                }"
              >
                <span class="ar"></span>Перейти к изучению
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="folderContent.length > 0"
          class="documents"
        >
          <div
            *ngFor="let info of folderContent;let i = index"
            class="document"
            [ngStyle]="{backgroundImage: 'url('+ (info.preview) + ')'}"
            (click)="onFolder(info.id)"
          >
            <div class="document_text">
              <div class="document_text_title">{{info.title}}</div>
              <div
                class="button"
                [ngClass]="{
                  'green': sectonInfo.self.color === 'green'
                }"
              >
                <span class="ar"></span>Перейти к изучению
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  </div>
</div>
