<app-header></app-header>

<div *ngIf="productInfo" class="outer card">
  <div class="workspace">

    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>КАТАЛОГ</span>
        </div>
      </div>
    </div>

    <div class="card_top">

      <div class="card_image_body">

        <swiper
          (swiper)="thumbsSwiper = $event"
          [direction]="'vertical'"
          [spaceBetween]="10"
          [slidesPerView]="4"
          [freeMode]="true"
          [autoHeight]="true"
          class="custom_swiper_thumbs_for_catalogs_card"
        >
          <ng-template swiperSlide *ngFor="let slide of slider">
            <img src="{{slide.file}}" />
          </ng-template>
        </swiper>

        <swiper
          class="custom_swiper_for_catalogs_card"
          [zoom]="true"
          [thumbs]="{ swiper: thumbsSwiper }"
          [direction]="'vertical'"
          [mousewheel]="true"
        >
          <ng-template swiperSlide *ngFor="let slide of slider">
            <div class="swiper-zoom-container">
              <img src="{{slide.file}}" />
            </div>
          </ng-template>
        </swiper>
      </div>

      <div class="card_text">
        <div class="text_box">
          <h2>{{productInfo.box}}</h2>

          <div class="codes">
            <ng-container *ngFor="let code of productInfo.codes">
              <div class="codes-box">
                <img [src]="code.icon" [alt]="code.name" loading="lazy">
                <h3>{{code.name}}: {{code.value}}</h3>
              </div>
            </ng-container>
          </div>
  
          <h1>{{productInfo.title}}</h1>
  
        </div>
        <div *ngIf="productInfo.description" [innerHTML]="productInfo.description | safeHtml"></div>

        <div class="compares">
          <div [ngClass]="{'check': compareSt}" class="sr" (click)="compare()">Сравнить</div>
          <div class="sr" (click)="onCompareByRef('similar')">C sister брендом</div>
          <div class="sr" (click)="onCompareByRef('competitors')">C брендами конкурентами</div>
        </div>

      </div>
    </div>

    <app-catalogs-tab [productInfoList]="productInfo.info" [product_id]="productInfo.id"></app-catalogs-tab>
    
  </div>
</div>
