import { Pipe, PipeTransform } from '@angular/core';
import { CalendarEvent, CalendarInterval } from 'app/typings/calendar';
import * as moment from 'moment';

@Pipe({
  name: 'heightCount'
})
export class HeightCountPipe implements PipeTransform {

  transform(events: CalendarEvent[], firstDate?: CalendarInterval ,day?: moment.Moment, nonHeight?: boolean): CalendarEvent[] {

    if(day) {
      if(!nonHeight) {
        const filteredDate = events.filter(event => moment(event.date).isSame(day, 'day'))
        const transformed = filteredDate.map(v => this.calculateHeightAndTransform(v, firstDate.start)).filter(f => f !== null);
        
        return transformed;
      } else {
        const filteredDate = events.filter(event => moment(event.date).isSame(day, 'day'));

        return filteredDate;
      }
     
    } else {
      const tranformed =  events.map(v => this.calculateHeightAndTransform(v, firstDate.start)).filter(f => f !== null)
      
      return tranformed;
    }
  }

  private calculateHeightAndTransform(event: CalendarEvent, firstDate: moment.Moment): CalendarEvent {
    if (event.intervals && event.intervals.length > 0) {
      const transformedStart = moment(event.intervals[0].start, 'HH mm');
      const transformedFinish = moment(event.intervals[event.intervals.length - 1].finish, 'HH mm');
  
      const intervalHeight = 40;
      const intervalFromFirstDuration = transformedStart.diff(firstDate, 'minutes')
      const intervalDuration = transformedFinish.diff(transformedStart, 'minutes');
      
      const calculateTop = (intervalFromFirstDuration / 30) * intervalHeight;
      const calculatedHeight = (intervalDuration / 30) * intervalHeight - 8;
  
      event.top = calculateTop + 'px';
      event.height = calculatedHeight + 'px';
  
      return event;
    } 

    return null
  }
}


