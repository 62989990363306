import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapseComponent } from './collapse.component';
import { IconLoaderModule } from '../icon-loader/icon-loader.module';



@NgModule({
  declarations: [
    CollapseComponent
  ],
  imports: [
    CommonModule,
    IconLoaderModule
  ],
  exports: [
    CollapseComponent
  ]
})
export class CollapseModule { }
