import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { ApiService } from 'core/api'
import { SelectedQuestionOptions, Question } from 'core/eco-portal'
import { createQuestionsUrls } from './question-urls'

@Injectable({
  providedIn: 'root'
})
export class QuestionsService extends ApiService {
  private readonly urls = createQuestionsUrls()

  /**
   * Gets a list of questions.
   *
   * @param test_id
   */
  getQuestions(test_id: number): Observable<Question[]> {
    return this.http.post<Question[]>(this.urls.getQuestions, {test_id})
  }

  /**
   * Answers a question.
   *
   * @param question_id
   * @param options
   */
  answerQuestion(question_id: number, options: SelectedQuestionOptions): Observable<Partial<Question>> {
    return this.http.post(this.urls.answerQuestion, {question_id, options})
  }
}
