import {Component, OnInit, OnDestroy} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";

import {Location} from "@angular/common";
import {DialogService, ExtraService, GameModeratorService, StorageService} from "services";
import {
  AlertDialogComponent,
  GameModeratorAlertDialogComponent, GameModeratorAlertFinishDialogComponent,
  GameModeratorTestDialogComponent, GameModeratorTestFinishDialogComponent
} from "components/dialogs";
import {ActivatedRoute, Router} from "@angular/router";
import {BaseService} from "services/api";

@Component({
  selector: 'app-game-moderator-cells',
  templateUrl: 'game-moderator-cells.component.html',
  styleUrls: ['game-moderator-cells.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class GameModeratorCellsComponent implements OnInit, OnDestroy {

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private baseService: BaseService,
    private gameModerService: GameModeratorService,
    private storageService: StorageService,
    public extraService: ExtraService,
    private router: Router,
  ) {
  }

  description: any

  attemptId: any
  text: string
  questions = []
  answers = []

  // Таймер
  timeOut = false;
  time = 0;
  timeleft = this.time;
  timer = 0;
  startMoment = 0;
  endMoment = this.startMoment + this.timeleft * 1000;

  initiateTimer = false;

  alarmQuestions = []
  startAlarmQuestionsLength = 0
  alarmTimer = 0

  endTest = false

  lang: string

  goBack() {
    this.location.back()
  }

  openTest(question) {
    if (!question.open) {
      question.open = true

      // TODO: rewrite
      let item = document.getElementById('top');
      item.scrollIntoView();

      this.dialogService.openDialog(GameModeratorTestDialogComponent, {question: question}).subscribe((res: any) => {
          if (res) {
            res.attempt_id = this.attemptId
            this.baseService.baseGameModerAlertSave(res).subscribe((response: any) => {
              this.dialogService.openDialog(GameModeratorTestFinishDialogComponent, {question: question, right: response.is_right}).subscribe()
              if (!response.is_right) {
                document.getElementById(question.id).style.background = '#d1d1d1'
              }
            })
            this.answers.push(res)
          }
        })
    }
  }

  initCountdown() {
    this.initiateTimer = true;
    this.startMoment = Date.now();
    this.endMoment = this.startMoment + this.timeleft * 1000;
    this.timer = window.setInterval(() => {
      const now = Date.now();
      this.timeleft = Math.round((this.endMoment - now) / 1000);
      if (this.timeleft <= 0) {
        clearInterval(this.timer);
        this.timeleft = this.time;
        this.timeOut = true;
        this.submit();
      }
    }, 1000);
  }

  initAlertCountdown() {
    if (this.alarmQuestions.length > 0) {
      let time = 0
      if (this.alarmQuestions.length !== 0) {
        time = (30 / this.startAlarmQuestionsLength) + Math.floor(Math.random() * (5 - 1 + 1)) + 1
      } else {
        time = 0
      }
      this.alarmTimer = window.setInterval(() => {
        time -= 1
        if (time <= 0) {
          clearInterval(this.alarmTimer)
          this.questionAlert()
        }
      }, 1000)
    }
  }

  questionAlert() {
    if (this.alarmQuestions.length > 0) {
      let question = this.alarmQuestions.shift()
      this.dialogService.openDialog(GameModeratorAlertDialogComponent, {question: question}).subscribe((res: any) => {
        res.attempt_id = this.attemptId
        this.baseService.baseGameModerAlertSave(res).subscribe((response: any) => {
          this.dialogService.openDialog(GameModeratorAlertFinishDialogComponent, {question: question, right: response.is_right}).subscribe()
        })
        if (!this.endTest) {
          this.initAlertCountdown()
        } else {
          clearInterval(this.alarmTimer)
        }
        if (this.endTest && this.alarmQuestions.length !== 0) {
          this.questionAlert()
        } else if (this.endTest && this.alarmQuestions.length === 0) {
          this.submit()
        }
      })
    }
  }

  pause() {
    clearInterval(this.timer)
    clearInterval(this.alarmTimer)
    this.extraService.gamePaused = true
    if (this.lang === 'ru') {
      this.dialogService.openDialog(AlertDialogComponent, {title: 'Вы поставили игру на паузу', confirmButtonText: 'Продолжить'}).subscribe(() => {
        this.extraService.gamePaused = false
        this.initCountdown()
        this.initAlertCountdown()
      })
    } else {
      this.dialogService.openDialog(AlertDialogComponent, {title: 'You paused the game', confirmButtonText: 'Continue'}).subscribe(() => {
        this.extraService.gamePaused = false
        this.initCountdown()
        this.initAlertCountdown()
      })
    }
  }

  submit() {
    this.endTest = true
    let data = {
      attempt_id: this.attemptId,
    }
    if (this.alarmQuestions.length === 0) {
      this.baseService.baseGameModerAttemptFinish(data).subscribe((response: any) => {
        if (this.lang === 'ru') {
          this.dialogService.openDialog(AlertDialogComponent, {title: 'Вы заработали + ' + response.scores, confirmButtonText: 'ОК'}).subscribe(() => {
            this.router.navigate(['game-moderator/stages'])
          })
        } else {
          this.dialogService.openDialog(AlertDialogComponent, {title: 'You have earned + ' + response.scores, confirmButtonText: 'ОК'}).subscribe(() => {
            this.router.navigate(['game-moderator/stages'])
          })
        }
      })
    } else {
      this.timeleft = 0
      clearInterval(this.timer)
      this.questionAlert()
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.gameModerService.getLang()
      this.lang = this.storageService.getValue('game_moder_lang')
      this.baseService.baseGetGameModerStage(params.id).subscribe((response: any) => {
        this.description = response.description
      })
      this.baseService.baseGameModerAttemptStart(params.id).subscribe((response: any) => {
        this.questions = response.questions
        this.attemptId = response.attempt_id
        this.alarmQuestions = response.alarm
        this.startAlarmQuestionsLength = response.alarm.length
        this.questions.map((item) => {
          item.open = false
        })
        if (response.time) {
          this.time = +response.time;
          this.timeleft = this.time * 60;
          this.initCountdown();
          this.initAlertCountdown()
          this.extraService.gamePaused$.subscribe((response: any) => {
            if (response) {
              clearInterval(this.timer)
            } else {
              this.initCountdown()
            }
          })
        }
      })
    })
  }

  ngOnDestroy() {
    clearInterval(this.alarmTimer)
  }

}
