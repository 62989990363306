export enum ScheduleQuery {
    params = 'schedule/params/',
    eventCreate = 'schedule/event/create/',
    eventDelete = 'schedule/event/delete/',
    eventUpdate = 'schedule/event/update/',
    eventGet = 'schedule/event/get/',
    intervals = 'schedule/intervals/',
    month = 'schedule/month/',
    week = 'schedule/week/',
    participant = 'schedule/participant/list/',
    removeParticipant = 'schedule/participant/delete/'
}

export interface ScheduleInterval {
    id: number;
    start: string;
    finish: string;
}

export interface ScheduleWeek {
    category: number[];
    target_audience: number[];
    online: boolean;
    start: number;
    finish: number;
}

export interface ScheduleEvent {
    category: ScheduleParam[];
    date: number;
    id: number;
    target_audience: ScheduleParam[];
    creator: ScheduleCreator;
    edit?: boolean;
    contest: boolean;
    number_participants?: number;
    intervals: ScheduleInterval[];
    title: string;
    link?: string;
    online?: boolean,
    trainer?: ScheduleTrainer[];
    reservation?: number;
}

export interface ScheduleCreator extends ScheduleTrainer {
    id: number;
}

export interface ScheduleParams {
    category: ScheduleParam[];
    target_audience: ScheduleParam[];
    trainer: ScheduleTrainer[];
}

export interface ScheduleParam {
    id: number;
    title: string;
    color: string;
}

export interface ScheduleTrainer  {name: string; id: number; email: string};

export interface ScheduleCreate {
    online: boolean;
    title: string;
    date: number;
    link: string;
    category: number;
    trainer: ScheduleTrainer;
    reservation: number;
    intervals: ScheduleInterval[];
    file: File;
}

export interface ScheduleStatus {
    status: string
}

export interface ScheduleUsers {
    users: ScheduleParticipant[];
}

export interface ScheduleParticipant {
    id: number;
    country: string;
    email: string;
    name: string;
}