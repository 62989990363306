export enum WelcomeRoute {
    preview = 'preview',
    stages = 'stages',
    education = 'education',
    hr = 'hr',
    retail = 'retail',
    consultant = 'consultant',
    questions = 'questions'
}

export enum HrRoute {
    medical = 'medical',
    privilege = 'privilege',
    timeTracker = 'time-tracker',
    wage = 'wage',
    bonusScheme = 'bonus-scheme',
    bonusPayment = 'bonus-payment'
}

export enum RetailRoute {
    graph = 'graph',
    kpi = 'kpi',
    motivation = 'motivation',
    report = 'report',
    wheel = 'wheel'
}

export enum EducationRoute {
    categories = 'categories',
    development = 'development',
    direction = 'direction',
    integration = 'integration',
    learning = 'learning',
    mentor = 'mentor',
    promotion = 'promotion',
    shopper = 'shopper',
    training = 'training',
}

export enum ConsultantRoute {
    about = 'about',
    cloakroom = 'cloakroom',
    history = 'history'
}

export enum QuestionsRoute {
    dictionary = 'dictionary',
    direction = 'direction',
    holiday = 'holiday',
    recycling = 'recycling'
}