<app-header [makeReturnOnMain]="false" [showUser]="false" [showBack]="false"></app-header>

<div class="outer" [ngStyle]="{'backgroundImage': 'url(' + extraService.backgroundTest + ')'}">
  <div class="workspace document" *ngIf="question" [@show_preview]="showPrev">
    <section class="content">

      <div class="back_container" (click)="goBack()">
        <div class="icon back"></div>
        <h2>{{testInfo.title}}</h2>
      </div>

      <form class="form" [formGroup]="form" (ngSubmit)="saveAnswer()" enctype="multipart/form-data">

        <div class="timer" *ngIf="initiateTimer">{{timeleft | msToTime}}</div>

        <div class="question_body">

          <div class="question_info" [ngClass]="{error: errors[question.id]}">
            Вопрос №{{questionIndex + 1}}
          </div>

          <div class="question_info" translate="no" [innerHTML]="question.text | safeHtml">
          </div>

          <div class="greeting" [ngClass]="{error: errors[question.id]}">
            Варианты ответа:
            <span *ngIf="question.type === 'checkbox'"> (выбери несколько вариантов ответа)</span>
            <span *ngIf="question.type === 'radio'"> (выбери один правильный ответ)</span>
            <span *ngIf="question.type === 'sort'"> (сортировка по приоритету)</span>
            <span *ngIf="question.type === 'rating'"> (поставьте оценку)</span>
            <span *ngIf="question.type === 'text'"> (открытый вопрос)</span>
          </div>

          <div class="question_options">

            <!--Rating-->
            <ng-container *ngIf="question.type === 'rating'">
              <mat-slider class="custom-slider"
                          min="{{question.options[0].text}}"
                          max="{{question.options[question.options.length - 1].text}}"
                          step="1"
                          thumbLabel="true"
                          tickInterval="1"
                          formControlName="{{question.id}}"
              ></mat-slider>
            </ng-container>

            <!--Radio-->
            <ng-container *ngIf="question.type === 'radio'">
              <mat-radio-group class="options-group" [formControl]="$any(form.controls[question.id])">
                <mat-radio-button
                  value="{{option.id}}"
                  translate="no"
                  class="options-button"
                  *ngFor="let option of question.options; let i = index"
                >
                  <span [innerHTML]="option.text | safeHtml" [ngClass]="{right: testInfo.show_rights && option.is_right}"></span>
                  <div class="image-test" *ngIf="option.image"><img class="question-image" src='{{option.image}}'></div>
                </mat-radio-button>
              </mat-radio-group>
            </ng-container>

            <!--Checkbox-->
            <ng-container *ngIf="question.type === 'checkbox'">
              <div class="options-group" formArrayName="{{question.id}}">
                <mat-checkbox
                  formControlName="{{i}}"
                  value="{{option.id}}"
                  translate="no"
                  class="options-button"
                  *ngFor="let option of question.options; let i = index"
                >
                  <span [innerHTML]="option.text | safeHtml" [ngClass]="{right: testInfo.show_rights && option.is_right}"></span>
                  <div class="image-test" *ngIf="option.image"><img class="question-image" src='{{option.image}}'></div>
                </mat-checkbox>
              </div>
            </ng-container>

            <!--Sort-->
            <ng-container *ngIf="question.type === 'sort'">
              <div cdkDropList class="options-group" (cdkDropListDropped)="drop($event, question.id, question.options)">
                <div
                  translate="no"
                  cdkDrag
                  class="options-button options-DropList"
                  *ngFor="let option of question.options; let i = index"
                >
                  <span class="number">{{i + 1}}</span>
                  <span class="text" [innerHTML]="option.text | safeHtml"></span>
                </div>
              </div>
            </ng-container>

            <!--text-->
            <div class="options-textarea" *ngIf="question.type === 'text'">
                      <textarea
                        type="text"
                        placeholder="Введите текст ответа"
                        formControlName="{{question.id}}"
                      ></textarea>
            </div>

          </div>
        </div>

        <div class="test_submit_body">
          <button class="test_submit" type="submit">Ответить</button>
        </div>

      </form>
    </section>
  </div>
</div>
