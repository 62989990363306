import { Injectable } from '@angular/core';
import { RootRoute } from 'app/constants/root-routes';
import { LayoutPage } from 'app/typings/layout-pages';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainLayoutService {
  private pagesSubject = new BehaviorSubject<LayoutPage[]>(this.initPages());
  pages$: Observable<LayoutPage[]> = this.pagesSubject.asObservable();

  private routesSubject = new BehaviorSubject<{[key in string]:RootRoute}>(this.initRoutes());


  private initPages(): LayoutPage[] {
    return [
      {
        name: 'Главная',
        route: RootRoute.main,
        isCounter: false
      },
      {
        name: 'Учись',
        route: RootRoute.learning,
        isCounter: false
      },
      {
        name: 'Оценка',
        route: RootRoute.assessment,
        isCounter: true
      },
      {
        name: 'Важно',
        route: RootRoute.materials,
        isCounter: true
      },
      {
        name: 'НОВИЧОК',
        route: RootRoute.video,
        isCounter: true
      },
      {
        name: 'Новости',
        route: RootRoute.news,
        isCounter: true
      },
      {
        name: 'Академия',
        route: RootRoute.faq,
        isCounter: true
      }
    ]
  }

  private initRoutes(): { [key in string]: RootRoute } {
    return {
      personal: RootRoute.personal,
      main: RootRoute.main,
      learning: RootRoute.learning,
      assessment: RootRoute.assessment,
      materials: RootRoute.materials,
      video: RootRoute.video,
      news: RootRoute.news,
      faq: RootRoute.faq
    }
  }

  getRoutes():{[key in string]:RootRoute} {
    return this.routesSubject.getValue();
  }
}
