import { add } from 'core/api'
import { env } from 'env'

export function createAuthUrls() {
  const auth = add(env.rootApiUrl, 'auth/')
  const password = add(auth, 'password/')
  const user = add(auth, 'user/')
  const management_auth = add(env.rootApiUrl, 'management/auth/')

  return {
    signIn: add(auth, 'signin/'),
    logOut: add(auth, 'logout/'),
    message: add(auth, 'message/'),
    validateToken: add(auth, 'validate/'),
    resetPassword: add(password, 'reset/'),
    resetUserPassword: add(password, 'edit/'),
    getUser: add(user, 'get/'),
    updateUser: add(user, 'edit/'),
    getTeamUsers: add(user, 'list/'),
    getParams: add(auth, 'params/'),
    getRules: add(auth, 'message/rules/'),
    getForm: add(management_auth, 'types/'),
    registration: add(management_auth, 'create/')
  }
}
