import { Component } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'

@Component({
  selector: 'app-makeup',
  templateUrl: './makeup.component.html',
  styleUrls: ['./makeup.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0
      })),
      state('show', style({
        opacity: 1
      })),
      transition('void => show', [
        style({
          opacity: 0
        }),
        animate(500, style({
          opacity: 1
        }))
      ])
    ])
  ]
})
export class MakeupComponent {
  showPrev = 'show';
}
