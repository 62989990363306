<app-header></app-header>

<div *ngIf="catalogInfo" class="outer catalog">
  <div class="workspace">
    <div class="top_menu">
      <div class="top_menu_osn">
        <div class="back_li" (click)="goBack()">
          <div class="icon back"></div>
          <div class="back_title">{{catalogStorageInfo.title}}</div>
        </div>
      </div>
      <div class="top_menu_box">
          <app-catalogs-search (shareSearchResults)="onSearched($event)"></app-catalogs-search>
          <div class="feedback"
            (click)="onFaq()">
            <span>Вопрос-ответ</span>

            <div class="icon">
              <app-icon-loader 
                [name]="'feedback'"
                [size]="32">
              </app-icon-loader>
            </div>
          </div>
          <div class="comparison"
              (click)="comparisonOn()"
              (mouseenter)="changeBackgroundColor($event)"
              (mouseleave)="changeBackgroundColor($event)">
              <span class="comparison-text">Сравнение</span>
          </div>
      </div>
      <div *ngIf="comparisonCounter" class="comparison_count">{{comparisonCounter}}</div>
    </div>
    <ng-container *ngIf="searchResults.length === 0">
      <div class="container">

        <div class="tabs">
          <ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfig">
            <div
              ngxSlickItem
              class="slide"
              *ngFor="let tab of catalogInfo.macrocategories; let i = index"
              [ngClass]="{'active_tab': activeTab === i}"
              (click)="tabOpen(tab.id, i)"
            >
              {{tab.title}}
            </div>
          </ngx-slick-carousel>
        </div>
  
        <ng-scrollbar class="scroll_list">
          <div class="content" *ngFor="let dat of catalogInfo.lines; let i = index">
            <div class="line">
              <div (click)="list(dat.id, i)" class="line_bt">{{dat.title}}</div>
              <ng-scrollbar [ngClass]="{'scroll_list_catalog': dat.open && dat.products}">
                <div *ngIf="dat.open && dat.products" class="line_bd">
                  <a
                    *ngFor="let li of dat.products; let i = index"
                    class="line_bd_li"
                    [ngClass]="{'ecom': li.ecom && !li.is_new, 'new': li.is_new}"
                    (mouseenter)="showPreview(li, dat)"
                    (mouseleave)="removePreview()"
                    [href]="onProduct(li.id)"
                  >{{li.title}}</a>
  
                </div>
              </ng-scrollbar>
            </div>  
            <div
              *ngIf="pr && homePrId === dat.id"
              class="line_pr"
              [ngStyle]="{backgroundImage: 'url('+ (pr) + ')'}"
            ></div>
  
          </div>
        </ng-scrollbar>
      </div>
    </ng-container>
    <ng-container *ngIf="searchResults.length > 0">
        <div class="result">
          <h1 class="result-title">Результаты поиска: {{searchItem}}</h1>
          <ng-scrollbar track="horizontal" style="height: 490px; width: 100%">
            <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="300" appHorizontalScroll [horizontal]="true" [scrollWindow]="false" (scrolled)="onGetNew()"  scrollViewport>
              <div [@show_preview]="showPrev" class="documents">
                <div *ngFor="let info of searchResults; let i = index"  (click)="onSearchPage(info)">
                  <div class="document" [ngStyle]="{backgroundImage: 'url('+ (info.file) + ')'}">
                    <div class="document_text">
                      <div class="document_text_title">{{info.title}}</div>
                      <div class="button">
                        <span class="ar"></span>Перейти
                      </div>
                    </div>
                </div>
                </div>
              </div>
            </div>
          </ng-scrollbar>
        </div>
    </ng-container>
  </div>
</div>