import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FolderBox } from 'app/api/folder/folder-content';

@Component({
  selector: 'app-folder-card',
  templateUrl: './folder-card.component.html',
  styleUrls: ['./folder-card.component.scss']
})
export class FolderCardComponent {
  @Input() box: FolderBox;
  @Output() clicked = new EventEmitter<number>();

  onClick(id: number): void {
    this.clicked.emit(id);
  }
}
