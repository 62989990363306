export enum RootRoute {
    auth = 'auth',
    main = 'main',
    personal = 'personal',
    homework = 'homework',
    learning = 'learning',
    assessment = 'assessment',
    materials = 'materials',
    video = 'video',
    news = 'news',
    faq = 'faq',
    timetable = 'timetable',
    welcome_book = 'welcome-book'
}
