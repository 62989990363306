<app-header [makeReturnOnMain]="false" [showUser]="false" [showBack]="false"></app-header>
<div class="outer">
  <div class="workspace document" *ngIf="testBody" [@show_preview]="showPrev">

    <section class="content">

      <div class="back_container" (click)="goBack()">
        <div class="icon back"></div>
        <h2>{{testBody.title}}</h2>
      </div>


      <div *ngIf="!showRightAnswers">

        <form class="form" [formGroup]="form" (ngSubmit)="submit()" enctype="multipart/form-data">

          <div class="timer" *ngIf="initiateTimer">{{timeleft | msToTime}}</div>

          <div class="question_body" *ngFor="let question_body of testBody.questions; let i = index">

            <div class="question_info" [ngClass]="{error: errors[question_body.id]}">
              Вопрос №{{i + 1}}
            </div>

            <div class="question_info" translate="no" [innerHTML]="question_body.text | safeHtml">
            </div>

            <ng-container *ngIf="question_body?.image as image">
              <img width="200px" height="200px" [src]="image" [alt]="question_body.text" class="image">
            </ng-container>

            <div class="greeting" [ngClass]="{error: errors[question_body.id]}">
              Варианты ответа:
              <span *ngIf="question_body.type === 'checkbox'"> (выбери несколько вариантов ответа)</span>
              <span *ngIf="question_body.type === 'radio'"> (выбери один правильный ответ)</span>
              <span *ngIf="question_body.type === 'sort'"> (сортировка по приоритету)</span>
              <span *ngIf="question_body.type === 'rating'"> (поставьте оценку)</span>
              <span *ngIf="question_body.type === 'text'"> (открытый вопрос)</span>
            </div>

            <div class="question_options">

              <!--Rating-->
              <ng-container *ngIf="question_body.type === 'rating'">
                <mat-slider class="custom-slider"
                            min="{{question_body.options[0].text}}"
                            max="{{question_body.options[question_body.options.length - 1].text}}"
                            step="1"
                            thumbLabel="true"
                            tickInterval="1"
                            formControlName="{{question_body.id}}"
                ></mat-slider>
              </ng-container>

              <!--Radio-->
              <ng-container *ngIf="question_body.type === 'radio'">
                <mat-radio-group formControlName="{{question_body.id}}">
                  <div class="option" [ngClass]="{gapped: option?.image}" *ngFor="let option of question_body.options; let i = index">
                    <mat-radio-button
                      value="{{option.id}}"
                      translate="no"
                      class="options-button"
                    >
                      <span *ngIf="!option?.image" [innerHTML]="option.text | safeHtml"></span>
                    </mat-radio-button>
                    <label class="option-content" *ngIf="option?.image">
                      <span [innerHTML]="option.text | safeHtml"></span>
                      <img width="200px" height="200px" [src]="option.image" [alt]="option.text" class="image">
                    </label>
                  </div>
                </mat-radio-group>
              </ng-container>

              <!--Checkbox-->
              <ng-container *ngIf="question_body.type === 'checkbox'">
                <div class="option" [ngClass]="{gapped: option?.image}"  *ngFor="let option of question_body.options; let i = index">
                  <div class="options-group" formArrayName="{{question_body.id}}">
                    <mat-checkbox
                      formControlName="{{i}}"
                      value="{{option.id}}"
                      translate="no"
                      class="options-button"
                    >
                      <span *ngIf="!option?.image" [innerHTML]="option.text | safeHtml"></span>
                    </mat-checkbox>
                  </div>
                  <label class="option-content" *ngIf="option?.image">
                    <span [innerHTML]="option.text | safeHtml"></span>
                    <img width="200px" height="200px" [src]="option.image" [alt]="option.text" class="image">
                  </label>
                </div>
              </ng-container>

              <!--Sort-->
              <ng-container *ngIf="question_body.type === 'sort'">
                <div cdkDropList class="options-group" (cdkDropListDropped)="drop($event, question_body.id, question_body.options)">
                  <div
                    translate="no"
                    cdkDrag
                    class="options-button options-DropList"
                    *ngFor="let option of question_body.options; let i = index"
                  >
                    <span class="number">{{i + 1}}</span>
                    <span class="text" [innerHTML]="option.text | safeHtml"></span>
                  </div>
                </div>
              </ng-container>

              <!--text-->
              <div class="options-textarea" *ngIf="question_body.type === 'text'">
                      <textarea
                        type="text"
                        placeholder="Введите текст ответа"
                        formControlName="{{question_body.id}}"
                      ></textarea>
              </div>

            </div>
          </div>

          <div class="test_submit_body">
            <button class="test_submit" type="submit">Отправить</button>
          </div>

        </form>

      </div>

      <div *ngIf="showRightAnswers">

        <div class="question_body" *ngFor="let question_body of rightAnswers; let i = index">

          <div class="question_info" [ngClass]="{correct: question_body.is_right, incorrect: !question_body.is_right}">
            Вопрос №{{i + 1}}
          </div>

          <div class="question_info" translate="no" [innerHTML]="question_body.text | safeHtml"></div>

          <ng-container *ngIf="question_body?.image as image">
            <img width="200px" height="200px" [src]="image" [alt]="question_body.text" class="image">
          </ng-container>

          <div class="greeting">
            Варианты ответа:
            <span *ngIf="question_body.type === 'checkbox'"> (выбери несколько вариантов ответа)</span>
            <span *ngIf="question_body.type === 'radio'"> (выбери один правильный ответ)</span>
            <span *ngIf="question_body.type === 'sort'"> (сортировка по приоритету)</span>
            <span *ngIf="question_body.type === 'rating'"> (поставьте оценку)</span>
          </div>

          <div class="question_options">

            <!--Rating-->
            <ng-container *ngIf="question_body.type === 'rating'">
              <mat-slider class="custom-slider"
                          min="{{question_body.options[0].text}}"
                          max="{{question_body.options[question_body.options.length - 1].text}}"
                          step="1"
                          thumbLabel="true"
                          tickInterval="1"
                          [disabled]="showRightAnswers"
              ></mat-slider>
            </ng-container>

             <!--Radio-->
            <ng-container *ngIf="question_body.type === 'radio'">
              <mat-radio-group class="options-group" [disabled]="showRightAnswers">
                <div class="list">
                  <div class="option"
                  [ngClass]="{correct: option.is_selected && option.is_right, incorrect: option.is_selected && !option.is_right, gapped: option?.image}"
                  *ngFor="let option of question_body.options; let i = index">
                   <mat-radio-button
                     value="{{option.id}}"
                     translate="no"
                     [checked]="option.is_selected"
                     class="options-button none"
                   >
                     <span *ngIf="!option?.image" [innerHTML]="option.text | safeHtml"></span>
                   </mat-radio-button>
                   <label class="option-content" *ngIf="option?.image">
                     <span [innerHTML]="option.text | safeHtml"></span>
                     <img width="200px" height="200px" [src]="option.image" [alt]="option.text" class="image">
                   </label>
                 </div>
                </div>
              </mat-radio-group>
            </ng-container>

            <!--Checkbox-->
            <ng-container *ngIf="question_body.type === 'checkbox'">
              <div class="list options-group">
                <div 
                  class="option" 
                  [ngClass]="{correct: option.is_selected && option.is_right, incorrect: option.is_selected && !option.is_right, gapped: option?.image}"
                  *ngFor="let option of question_body.options; let i = index"
                >

                <mat-checkbox
                  [disabled]="showRightAnswers"
                  value="{{option.id}}"
                  translate="no"
                  class="options-button none"
                  [checked]="option.is_selected"
                  >
                  <span *ngIf="!option?.image" [innerHTML]="option.text | safeHtml"></span>
                </mat-checkbox>
                <label class="option-content" *ngIf="option?.image">
                  <span [innerHTML]="option.text | safeHtml"></span>
                  <img width="200px" height="200px" [src]="option.image" [alt]="option.text" class="image">
                </label>
              </div>
            </div>
            </ng-container>

            <!--Sort-->
            <ng-container *ngIf="question_body.type === 'sort'">
              <div cdkDropList class="options-group">
                <div
                  translate="no"
                  cdkDrag
                  [cdkDragDisabled]="true"
                  class="options-button options-DropList"
                  [ngClass]="{correct: option.is_selected && option.is_right, incorrect: option.is_selected && !option.is_right}"
                  *ngFor="let option of question_body.options; let i = index"
                >
                  <span class="number">{{i + 1}}</span>
                  <span class="text disabled" [innerHTML]="option.text | safeHtml"></span>
                </div>
              </div>
            </ng-container>

            <div class="options-textarea" *ngIf="question_body.type === 'text'">
                <textarea
                  type="text"
                  placeholder="Введите текст ответа"
                  [value]="question_body.open"
                  [ngStyle]="{cursor: 'default'}"
                  [readOnly]="showRightAnswers"
                ></textarea>
            </div>

          </div>


        </div>

      </div>

    </section>
  </div>
</div>
