<div class="custom_dialog game_moderator">

  <div class="title">История покупок</div>

  <div class="container">
    <form class="form" [formGroup]="form">
      <div class="purchase_container">
        <div class="purchase_title">Офис:</div>
        <input class="purchase_input" type="text" placeholder="Номер места" formControlName="office">
      </div>
      <div class="title">или</div>
      <div class="purchase_container">
        <div class="purchase_title">Адрес:</div>
        <input class="purchase_input" type="text" placeholder="Адрес доставки" formControlName="address">
      </div>
      <div class="purchase_container">
        <div class="purchase_title">Телефон:</div>
        <input class="purchase_input" type="text" placeholder="+7(xxx)-xxx-xx-xx" formControlName="phone">
      </div>
    </form>
    <div class="dialog_button" (click)="submit()">ОК</div>
  </div>

<!--  <div class="dialog_button" (click)="submit()">ОК</div>-->

</div>
