<div 
  [ngClass]="{active: checkedItems.length, disabled: blocked}"
  class="select" 
  (click)="toggleVisibility($event)" 
  cdkOverlayOrigin 
  #trigger="cdkOverlayOrigin"
>
  <span>{{ checkedItems.length ? checkedItems.join(', ') : placeholder}}</span>
  <mat-icon>arrow_drop_down</mat-icon>
</div>

<ng-template 
  cdkConnectedOverlay
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'select-backdrop'"
  (backdropClick)="isOpen = false"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
>
  <div class="menu" [@fade]="'show'">
    <div class="list">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>