<div class="custom_dialog" [ngClass]="{test: isTest}">
  <h2 mat-dialog-title class="dialog-title" [innerHTML]="title | safeHtml" *ngIf="!isTest"></h2>

  <div mat-dialog-content class="dialog-content" [ngClass]="{test: isTest}" [innerHTML]="text | safeHtml"></div>

  <div mat-dialog-actions [ngClass]="{'dialog-actions': notButtonText}">
    <div class="dialog_button" (click)="onConfirm()" [ngClass]="{test: isTest}">{{confirmButtonText}}</div>
    <div *ngIf="notButtonText" class="dialog_button" [ngClass]="{test: isTest}" (click)="onNot()">{{notButtonText}}</div>
  </div>
</div>
