import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WelcomeLayoutComponent } from './welcome-layout.component';
import { RouterModule } from '@angular/router';
import { IconLoaderModule } from 'app/shared/components/icon-loader/icon-loader.module';
import { MatRippleModule } from '@angular/material/core';



@NgModule({
  declarations: [
    WelcomeLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    IconLoaderModule,
    MatRippleModule
  ]
})
export class WelcomeLayoutModule { }
