<app-header></app-header>

<div
  *ngIf="docBody"
  class="outer"
  [@show_preview]="showPrev"
  [ngStyle]="{'backgroundImage': 'url(' + (docBody.ground ? (docBody.ground) : '/assets/images/learning_bg.png') + ')'}"
>
  <div class="workspace">

    <div class="document_body">

      <div class="bread_and_title_container">
        <div class="bread_and_title">
          <a class="page_title" (click)="goBack()">
            <div class="icon back"></div>
            <span>{{docBody.title}}</span>
          </a>
        </div>
      </div>

      <div *ngIf="breadcrumbs.length > 0" class="breadcrumbs">
        <span class="crumb"><span [routerLink]="['/main']" class="crumb_title link">Главная</span><span class="separator">&mdash;</span></span>
        <span
          *ngFor="let bread of breadcrumbs;let i = index"
          class="crumb"
        >
          <span *ngIf="i === breadcrumbs.length - 1" class="crumb_title">{{bread.title}}</span>
          <span *ngIf="i !== breadcrumbs.length - 1" class="crumb_title link" (click)="onBread(bread)">{{bread.title}}<span class="separator">&mdash;</span></span>
        </span>
      </div>

      <section class="content">
        <app-content-blocks [arrayContent]="docBody.content"></app-content-blocks>
        <div class="button-container" >
          <ng-container *ngIf="docBody.homework">
            <button class="button" [disabled]="!docBody.homework.is_available" (click)="goHomework(docBody.homework.id)">ДОМАШНЕЕ ЗАДАНИЕ</button>
          </ng-container>
          <div class="button" *ngIf="docBody.final_test" (click)="goFinalTest(docBody.final_test)">ФИНАЛЬНЫЙ ТЕСТ</div>
        </div>
      </section>

    </div>

  </div>
</div>
