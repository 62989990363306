<img class="image" [src]="message.file || '/assets/images/bee.png'" alt="">

<div class="container-dialog-text">
  <div class="text-container scroll">
    <div *ngIf="message.title" class="title">{{message.title}}</div>
    <div *ngIf="message.text" class="text" [innerHTML]="message.text | safeHtml"></div>
  </div>

  <div class="button" (click)="dialogRef.close()">ОК</div>
</div>
