import { Component, OnInit} from '@angular/core';
import { Location } from "@angular/common";
import { animate, state, style, transition, trigger } from '@angular/animations';
import {BaseService} from "services/api";
import {ExtraService} from "services";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-library-wordbook',
  templateUrl: 'library-wordbook-hub.component.html',
  styleUrls: ['library-wordbook-hub.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class LibraryWordbookHubComponent implements OnInit {

  showPrev = 'show';

  open = false;
  showMenu = true;

  sectionInfo = undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private baseService: BaseService,
    public extraService: ExtraService
  ) {
  }

  goBack() {
    this.location.back()
  }

  goSection(section) {
    switch (section.reference) {
      case 'library':
        this.router.navigate([section.reference + '/folder/' + section.id]);
        break
      case 'wordbook':
        this.router.navigate([section.reference + '/' + section.id])
        break
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.baseService.documentsContent({id: params.id, start_index: 0, limit: 5}).subscribe((response: any) => {
        this.sectionInfo = response
      })
    })
  }

}
