import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageDirective } from './image.directive';
import { HideTextDirective } from './hide-text.directive';
import { HorizontalScrollDirective } from './horizontal-scroll.directive';
import { EllipsisDirective } from './ellipsis.directive';
import { ScrollToTopDirective } from './scroll-to-top.directive';
import { CustomImageDirective } from './custom-image.directive';
import { ToggleDirective } from './toggle.directive';



@NgModule({
  declarations: [
    ImageDirective,
    HideTextDirective,
    HorizontalScrollDirective,
    EllipsisDirective,
    ScrollToTopDirective,
    CustomImageDirective,
    ToggleDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ImageDirective,
    HideTextDirective,
    HorizontalScrollDirective,
    EllipsisDirective,
    ScrollToTopDirective,
    CustomImageDirective,
    ToggleDirective
  ]
})
export class DirectivesModule { }
