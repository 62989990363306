import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replacementWord',
})
export class ReplacementWordPipe implements PipeTransform {
  transform(word: string, mode: string): any {
    switch (mode) {
      case 'game':
        if (word === '') {
          word = '&nbsp;_________________&nbsp;'
        }
        break
      case 'calendar':
        let wordArr = []
        for (let i = 0; i < word.length; i++) {
          wordArr.push(' ')
        }
        word = wordArr.join('')
    }

    return word
  }
}
