<app-header [color]="'custom'" [fixHeader]="false"></app-header>

<div class="outer game" *ngIf="stageInfo && gameInfo">
  <div class="game_card_container">
    <img [src]="this.stageInfo.image" alt="" [ngStyle]="{objectFit: this.stageInfo.startIndex === 5 ? 'full' : 'cover'}">
    <div class="back_and_text_container">
      <div class="back_container" (click)="goBack($event)">
        <div class="icon back"></div>
        <span>{{this.gameInfo.title}}</span>
      </div>
      <div class="accordion">
        <div class="accordion-text" [ngClass]="{open: isOpen}" [innerHTML]="this.gameInfo.text_stages | safeHtml"></div>
        <div class="accordion-button" (click)="toggleText()">
          <span>{{isOpen ? 'Свернуть' : "Развернуть"}}</span>
          <div class="accordion-icon" [ngClass]="{rotate: isOpen}">
            <app-icon-loader [name]="'down'" [color]="'white'" [size]="16"></app-icon-loader>
          </div>
        </div>
      </div>
    </div>
    <div class="game_card" *ngFor="let stage of stages; let i = index" (click)="goStage(stage, i)"></div>
  </div>
</div>
