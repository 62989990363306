<div class="tabs">
    <ng-container *ngFor="let tab of productInfoList; let i = index;">
        <div [ngClass]="{'active': activeTab === i}" class="tabs__tab" (click)="onChangeTab(i)">{{tab.title}}</div>
    </ng-container>
  </div>

<div [ngSwitch]="productInfoList[activeTab].type">
    <div *ngSwitchCase="tabModel.description" class="tab__info">
        <div class="info__row" *ngFor="let dat of productInfoList[activeTab].elements; let i = index">
            <div class="info__column bg">{{dat.descposition}}:</div>
            <div class="info__column" [innerHTML]="dat.text | safeHtml"></div>
        </div>
    </div>
    <div *ngSwitchCase="tabModel.ingredients" class="tab__info">
      <div class="txt__img" *ngFor="let dat of productInfoList[activeTab].elements; let i = index">
        <div
          class="txt__bg"
          [ngStyle]="{'backgroundImage': 'url(' + (dat.file) + ')'}"
        >
        </div>
        <div class="txt__text">
          <div [innerHTML]="dat.text | safeHtml">{{dat.text}}</div>
          <div *ngIf="dat.desposition" [innerHTML]="dat.descposition | safeHtml"></div>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="tabModel.composition" class="tab__info">
      <div class="info__txt" *ngFor="let dat of productInfoList[activeTab].elements; let i = index">
        <div [innerHTML]="dat.text | safeHtml"></div>
        <div *ngIf="dat.desposition" [innerHTML]="dat.descposition | safeHtml"></div>
      </div>
    </div>
    <div *ngSwitchCase="tabModel.application" class="tab__info">
      <div class="info__txt" *ngFor="let dat of productInfoList[activeTab].elements; let i = index">
        <div [innerHTML]="dat.text | safeHtml"></div>
        <div *ngIf="dat.desposition" [innerHTML]="dat.descposition | safeHtml"></div>
        <div class="txt__video" *ngIf="dat.file !== null">
          <h2 *ngIf="dat.title !== ''">{{dat.title}}</h2>
          <vg-player>
            <vg-overlay-play></vg-overlay-play>
            <vg-buffering></vg-buffering>

            <vg-scrub-bar>
              <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
              <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
            </vg-scrub-bar>

            <vg-controls [vgAutohide]="true">
              <vg-play-pause></vg-play-pause>
              <vg-playback-button [playbackValues]="[ '0.5', '1.0', '1.25', '1.5', '2.0' ]"></vg-playback-button>

              <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

              <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

              <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>


              <vg-mute></vg-mute>
              <vg-volume></vg-volume>

              <vg-fullscreen></vg-fullscreen>
            </vg-controls>
            <video [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" crossorigin poster="{{dat.preview}}">
              <source type="video/webm" [src]="dat.file">
              <source type="video/mp4" [src]="dat.file">
            </video>
          </vg-player>
        </div>
        <div class="txt__container">
          <img  [src]="(img.file)" *ngFor="let img of dat.files; let i = index">
        </div>
      </div>
    </div>
    <div *ngSwitchCase="tabModel.sales" class="tab__info">
      <div class="info__sale" *ngFor="let dat of productInfoList[activeTab].elements; let i = index">
        <div class="sale__hg"><span>Шаг {{i + 1}}.</span> {{dat.text}}</div>
        <div
          class="sale__document"
          (click)="onSale(dat.add_product)"
          [ngStyle]="{backgroundImage: 'url('+ (dat.add_product.file) + ')'}"
        >
          <div class="document__text">
            <div class="document__title">{{dat.add_product.title}}</div>
            <div
              class="button document__button "
            >
              <span class="document__ar"></span>Перейти
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="tabModel.questions" class="tab__info">
        <div class="info__questions" *ngFor="let item of productInfoList[activeTab].elements;">
            <ul class="questions__list">
                <li class="questions__title">Вопрос:</li>
                <li class="questions__info">{{item.title}}</li>
            </ul>
            <ul class="questions__list">
              <li class="questions__title">Ответ:</li>
              <li class="questions__info" [innerHTML]="item.text | safeHtml"></li>
          </ul>
        </div>
    </div>
    <div *ngSwitchCase="tabModel.documents" class="tab__info">
      <ng-container *ngFor="let item of productInfoList[activeTab].elements">
          <a [href]="item.file" target="_blank" class="info__docs">
            <div class="docs__img">
              <img [src]="item.preview" alt="">
            </div>
            <span>{{item.title}}</span>
          </a>
      </ng-container>
    </div>
    <div *ngSwitchCase="tabModel.reviews" class="tab__info">
        <div class="info__reviews">
          <div class="reviews__checkbox">
            <mat-checkbox
              [labelPosition]="'before'"
              [checked]="online$ | async"
              [disabled]="disabled$ | async"
              (change)="catalogsTabService.onlineChanges($event,product_id)"
              >
              <span class="checkbox__label">Online</span>
            </mat-checkbox>
            <mat-checkbox
              [labelPosition]="'before'"
              [checked]="offline$ | async"
              [disabled]="disabled$ | async"
              (change)="catalogsTabService.offlineChanges($event, product_id)"
              >
              <span class="checkbox__label">Offline</span>
            </mat-checkbox>
          </div>
          <div class="reviews__card" *ngFor="let item of (filteredData$ | async).length > 0 ? (filteredData$ | async) : productInfoList[activeTab].elements">
              <div class="card__info">
                <div class="card__column">
                  <div class="card__top">
                     <h2 class="card__title">{{item.author}}</h2>
                     <span class="card__status">{{item.online ? 'Online' : 'Offline'}}</span>
                  </div>
                  <div class="card__rate">
                    <div class="card__star" *ngFor="let _ of [1,2,3,4,5]; let i = index">
                        <img src="../../../../../assets/images/star.png" alt="star"  *ngIf="i < item.rating">
                        <img src="../../../../../assets/images/star_empty.png" alt="star" *ngIf="i >= item.rating">
                    </div>
                  </div>
                </div>
                <div class="card__column">
                    <h2 class="card__title">{{item.title}}</h2>
                    <div class="card__comment" [innerHTML]="item.text | safeHtml"></div>
                </div>
              </div>
              <ng-container *ngIf="item.link">
                <a target="_blank">
                  <span class="card__link">{{item.link}}</span>
                </a>
              </ng-container>
          </div>
        </div>
    </div>
</div>




