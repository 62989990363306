import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompareType } from 'app/typings/catalog';
import { env } from 'env';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ComparisonRes, Product } from 'services/api/catalogs/catalogs-query';

@Injectable()
export class CatalogsComparingService {
  readonly selectForm: FormGroup = this.fb.group({
    ids: [],
  });

  #products = new BehaviorSubject<Product[]>([]);

  get products$(): Observable<Product[]> {
    return this.#products.asObservable();
  }

  set products(value: Product[]) {
    this.#products.next(value);
  }

  #comparisonList = new BehaviorSubject<ComparisonRes>(null);

  get comparisonList$(): Observable<ComparisonRes> {
    return this.#comparisonList.asObservable().pipe(
      filter(value => !!value)
    );
  }
  
  set comparisonList(value: ComparisonRes) {
    this.#comparisonList.next(value);
  }

  constructor(private http: HttpClient, private fb: FormBuilder) {}

  getProducts(id: number, ref: CompareType): Observable<Product[]> {
    const link = env.rootApiUrl + `catalog/product/${ref}/`;

    return this.http.post<Product[]>(link, { id });
  }

  getCompare(ids: number[]): Observable<ComparisonRes> {
    const link = env.rootApiUrl + `catalog/compare/`;

    return this.http.post<ComparisonRes>(link, { ids });
  }
}
