import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-video-player',
  templateUrl: 'video-player.component.html',
  styleUrls: ['video-player.component.scss']
})
export class VideoPlayerComponent {
  @Input() videos!: {file: string, title?: string}[]
}
