import { Injectable } from '@angular/core'
import { HttpHeaders } from '@angular/common/http'

import { ApiService, AppHttpContext } from 'core/api'
import { env } from 'env'
import { Observable } from 'rxjs'
import { GameModerTest, TestStage } from 'app/api/game-moderator/test'
import { Tag, TestQuestion } from './catalogs/catalogs-query'

@Injectable({
  providedIn: 'root'
})
export class BaseService extends ApiService {
  private readonly root = env.rootApiUrl

  private readonly baseGetSectionsUrl = this.root + 'articles/sections/'

  private readonly baseGetBannersUrl = this.root + 'main/banners/'
  private readonly baseGetCountersUrl = this.root + 'main/counters/'

  private readonly baseGetVideoTestUrl = this.root + 'video-tests/get/'
  private readonly baseAnswerSaveVideoTestUrl = this.root + 'video-tests/answer/save/'

  private readonly baseFinalTestGetUrl = this.root + 'final-tests/get/'
  private readonly baseFinalTestAttemptStartUrl = this.root + 'final-tests/attempt/start/'
  private readonly baseFinalTestAttemptFinishUrl = this.root + 'final-tests/attempt/finish/'
  private readonly baseFinalTestGetAttemptUrl = this.root + 'final-tests/attempt/get/'
  private readonly baseFinalTestProfileEditUrl = this.root + 'final-tests/profile/edit/'

  private readonly baseViewScormUrl = this.root + 'documents/scorm/view/'

  private readonly baseViewDocumentUrl = this.root + 'documents/view/'

  private readonly baseGetGameUrl = this.root + 'games/game/get/'
  private readonly baseGetGameStageListUrl = this.root + 'games/stage/list/'
  private readonly baseGetGameStageUrl = this.root + 'games/stage/get/'
  private readonly baseGameResultCreateUrl = this.root + 'games/result/create/'

  private readonly baseTestAttemptStartUrl = this.root + 'tests/attempt/start/'
  private readonly baseTestAttemptFinishUrl = this.root + 'tests/attempt/finish/'

  private readonly baseBookingWeekUrl = this.root + 'booking/week/'
  private readonly baseBookingGetOwnersUrl = this.root + 'booking/owners/'
  private readonly baseBookingReservationGetUrl = this.root + 'booking/reservation/get/'
  private readonly baseBookingReservationDeleteUrl = this.root + 'booking/reservation/delete/'
  private readonly baseBookingReservationCreateUrl = this.root + 'booking/reservation/create/'
  private readonly baseBookingMonthUrl = this.root + 'booking/month/'
  private readonly baseBookingRoomsUrl = this.root + 'booking/rooms/'
  private readonly baseBookingIntervalsUrl = this.root + 'booking/intervals/'

  private readonly baseGetGameModerStagesListUrl = this.root + 'moder-games/stage/list/'
  private readonly baseGetGameModerStageUrl = this.root + 'moder-games/stage/get/'
  private readonly baseGameModerAttemptStartUrl = this.root + 'moder-games/attempt/start/'
  private readonly baseGameModerAttemptFinishUrl = this.root + 'moder-games/attempt/finish/'
  private readonly baseGameModerAlertSaveUrl = this.root + 'moder-games/answer/save/'

  private readonly baseGetStoreAccountUrl = this.root + 'store/account/get/'
  private readonly baseGetStoreProductListUrl = this.root + 'store/product/list/'
  private readonly basePurchaseCreateUrl = this.root + 'store/purchase/create/'
  private readonly basePurchaseListUrl = this.root + 'store/purchase/list/'

  baseGetSections() {
    return this.http.post(this.baseGetSectionsUrl, null)
  }

  baseGetBanners() {
    return this.http.post(this.baseGetBannersUrl, null)
  }

  baseGetCounters() {
    return this.http.post(this.baseGetCountersUrl, null)
  }

  // TODO: Optimize.
  baseGetSectionContentArticles(section, id?) {
    if (id) {
      return this.http.post(this.root + 'articles/' + section + '/content/', {id})
    }

    return this.http.post(this.root + 'articles/' + section + '/content/', null)
  }

  baseGetSectionDocumentArticles(section, id) {
    return this.http.post(this.root + 'articles/' + section + '/document/', {id})
  }

  baseGetSectionDocumentLike(section, id) {
    return this.http.post(this.root + 'articles/' + section + '/like/', {id})
  }

  baseGetContentCourses(section, id?) {
    if (id) {
      return this.http.post(this.root + 'courses/' + section + '/content/', {id})
    }

    return this.http.post(this.root + 'courses/' + section + '/content/', null)
  }

  baseGetDocumentCourses(section, id) {
    return this.http.post(this.root + 'courses/' + section + '/document/', {id})
  }

  baseGetVideoTest(id) {
    return this.http.post(this.baseGetVideoTestUrl, {id})
  }

  baseAnswerSaveVideoTest(data) {
    return this.http.post(this.baseAnswerSaveVideoTestUrl, data)
  }

  baseFinalTestGet(id) {
    return this.http.post(this.baseFinalTestGetUrl, {id})
  }

  baseFinalTestAttemptStart(id) {
    return this.http.post(this.baseFinalTestAttemptStartUrl, {id})
  }

  baseFinalTestAttemptFinish(data) {
    return this.http.post(this.baseFinalTestAttemptFinishUrl, data)
  }

  baseFinalTestGetAttempt(id) {
    return this.http.post(this.baseFinalTestGetAttemptUrl, {id})
  }

  baseFinalTestProfileEdit(type, id, deadline?, num_attempts?) {
    if (type === 'deadline') {
      return this.http.post(this.baseFinalTestProfileEditUrl, {id, deadline})
    }

    return this.http.post(this.baseFinalTestProfileEditUrl, {id, num_attempts})
  }

  baseGetCourseDocumentLike(section, id) {
    return this.http.post(this.root + 'courses/' + section + '/like/', {id})
  }

  catalogContent(id: any = null) {
    return this.http.post(this.root + 'catalog/content/', {id})
  }

  // TODO: What is there a hardcoded token?
  mnyCatalog(catalogUid = null, catalogUids = []) {
    const headers = new HttpHeaders({
      'Authorization': 'Token EpxjdSvfeScpMDABKR0LEjzB9cPBLG2RZDSsedZ9lGrHU9Z4zq'
    })

    return this.http.post(
      'https://mbl-old-api.evpportal.ru/api/catalog/cpd/folder/all/',
      {uid: catalogUid, list_uids: catalogUids},
      {
        headers,
        context: new AppHttpContext().unsigned()
      }
    )
  }

  // TODO: What is there a hardcoded token?
  productsMnyCatalog(tabId: string) {
    const headers = new HttpHeaders({
      'Authorization': 'Token EpxjdSvfeScpMDABKR0LEjzB9cPBLG2RZDSsedZ9lGrHU9Z4zq'
    })

    return this.http.post(
      'https://mbl-old-api.evpportal.ru/api/catalog/cpd/product/get/',
      {uid: tabId},
      {
        headers,
        context: new AppHttpContext().unsigned()
      }
    )
  }

  // TODO: What is there a hardcoded token?
  nyxCatalog() {
    const headers = new HttpHeaders({
      'Authorization': 'Token 4e3cb8b0-6168-11ec-9afa-d8f3bc3ad36e'
    })

    return this.http.post(
      'https://nyx-old-api.evpportal.ru/api/catalogs/cpd/folder/all/',
      {all_lang: 'false', lang: 'rus'},
      {
        headers,
        context: new AppHttpContext().unsigned()
      }
    )
  }

  // TODO: What is there a hardcoded token?
  productsNyxCatalog(idfolder: string) {
    const headers = new HttpHeaders({
      'Authorization': 'Token 4e3cb8b0-6168-11ec-9afa-d8f3bc3ad36e'
    })

    return this.http.post(
      'https://nyx-old-api.evpportal.ru/api/catalogs/cpd/product/all/',
      {
        folder_id: idfolder,
        all_lang: 'false',
        lang: 'rus'
      },
      {
        headers,
        context: new AppHttpContext().unsigned()
      }
    )
  }

  // TODO: What is there a hardcoded token?
  productNyxCatalog(id) {
    const headers = new HttpHeaders({
      'Authorization': 'Token 4e3cb8b0-6168-11ec-9afa-d8f3bc3ad36e'
    })

    return this.http.post(
      'https://nyx-old-api.evpportal.ru/api/catalogs/cpd/product/get/',
      {
        product_id: id,
        all_lang: 'false',
        lang: 'rus'
      },
      {
        headers,
        context: new AppHttpContext().unsigned()
      }
    )
  }

  documentsContent(data: any = null): any {
    return this.http.post(this.root + 'documents/content/', data)
  }

  wordbookContent(data: any = null) {
    return this.http.post(this.root + 'documents/wordbook/content/', {
      id: data.id,
      start_index: data.start_index,
      limit: data.limit
    })
  }

  wordbookSearch(data: any = null) {
    return this.http.post(this.root + 'documents/wordbook/search/', {
      value: data.searchValue,
      start_index: data.start_index,
      limit: data.limit
    })
  }

  wordbookDocument(id: any): Observable<any> {
    return this.http.post(this.root + 'documents/wordbook/document/', {id})
  }

  documentsDocument(id: any): Observable<any> {
    return this.http.post(this.root + 'documents/document/', {id})
  }

  sectionCatalogContent(data: any = null) {
    return this.http.post(this.root + 'documents/catalog/content/', {
      id: data.id,
      start_index: data.start_index,
      limit: data.limit
    })
  }

  documentsBoxChain(id: any) {
    return this.http.post(this.root + 'documents/box-chain/', {id})
  }

  catalogFolder(id = null) {
    return this.http.post(this.root + 'catalog/folder/', {id})
  }

  catalogCatalog(data) {
    return this.http.post(this.root + 'catalog/catalog/', data)
  }

  catalogProduct(id) {
    return this.http.post(this.root + 'catalog/product/', {id})
  }

  catalogTags$(): Observable<Tag[]> {
    return this.http.post<Tag[]>(this.root + 'catalog/tags/', null)
  }

  catalogQuestions$(value: {ean_code: string; tag_ids: number[]}): Observable<TestQuestion[]> {
    return this.http.post<any[]>(this.root + 'catalog/question_answers/', value)
  }

  catalogCompare(ids: any) {
    return this.http.post(this.root + 'catalog/compare/', {ids})
  }

  baseViewScorm(id: number, action: string) {
    return this.http.post(this.baseViewScormUrl, {id, action})
  }

  baseViewDocument(id: number) {
    return this.http.post(this.baseViewDocumentUrl, {id})
  }

  baseGetGame(id: number) {
    return this.http.post(this.baseGetGameUrl, {id})
  }

  baseGetGameStageList(game_id: number) {
    return this.http.post(this.baseGetGameStageListUrl, {game_id})
  }

  baseGetGameStage(id: number) {
    return this.http.post(this.baseGetGameStageUrl, {id})
  }

  baseGameResultCreate(data) {
    return this.http.post(this.baseGameResultCreateUrl, data)
  }

  baseGameGetMessage(reference: string, id: string) {
    return this.http.post(this.root + `games/message/${id}/${reference}/`, null)
  }

  baseTestAttemptStart(testId) {
    return this.http.post(this.baseTestAttemptStartUrl, {id: testId})
  }

  baseTestAttemptFinish(attemptInfo) {
    return this.http.post(this.baseTestAttemptFinishUrl, {...attemptInfo})
  }

  baseBookingWeek(room, start, finish): Observable<any> {
    return this.http.post(this.baseBookingWeekUrl, {room, start: +start, finish: +finish})
  }

  baseBookingMonth(room, year, month) {
    return this.http.post(this.baseBookingMonthUrl, {room, year, month})
  }

  baseBookingRooms() {
    return this.http.post(this.baseBookingRoomsUrl, {})
  }

  baseBookingIntervals() {
    return this.http.post(this.baseBookingIntervalsUrl, {})
  }

  baseBookingReservationGet(id: number) {
    return this.http.post(this.baseBookingReservationGetUrl, {id})
  }

  baseBookingGetOwners() {
    return this.http.post(this.baseBookingGetOwnersUrl, null)
  }

  baseBookingReservationDelete(id: number) {
    return this.http.post(this.baseBookingReservationDeleteUrl, {id})
  }

  baseBookingReservationCreate(value) {
    return this.http.post(this.baseBookingReservationCreateUrl, value)
  }

  baseGameModerMessage(reference, language_id) {
    return this.http.post(this.root + `moder-games/message/${reference}/`, {language_id})
  }

  baseGetGameModerStagesList(language_id) {
    return this.http.post(this.baseGetGameModerStagesListUrl, {language_id})
  }

  baseGetGameModerStage(id): Observable<TestStage> {
    return this.http.post<TestStage>(this.baseGetGameModerStageUrl, {id})
  }

  baseGameModerAttemptStart(stage_id): Observable<GameModerTest> {
    return this.http.post<GameModerTest>(this.baseGameModerAttemptStartUrl, {stage_id})
  }

  baseGameModerAttemptFinish(data) {
    return this.http.post(this.baseGameModerAttemptFinishUrl, data)
  }

  baseGameModerAlertSave(data) {
    return this.http.post(this.baseGameModerAlertSaveUrl, data)
  }

  baseGetStoreAccount() {
    return this.http.post(this.baseGetStoreAccountUrl, null)
  }

  baseGetStoreProductList(limit: number, offset: number) {
    return this.http.post(this.baseGetStoreProductListUrl, {limit, offset})
  }

  basePurchaseCreate(products, formData) {
    return this.http.post(this.basePurchaseCreateUrl, {products, params: formData})
  }

  basePurchaseList(limit: number, offset: number) {
    return this.http.post(this.basePurchaseListUrl, {limit, offset})
  }
}
