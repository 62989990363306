import { Item } from 'core/model'
import { ReqState } from './req-state'
import { NormalizedList } from './normalized-list'

export const isPending = (req: ReqState) => req === ReqState.pending

/**
 * Empty function to ignore errors in component store effects.
 */
export function ignoreError() {}

/**
 * Normalizes a list of items.
 *
 * @param items
 */
export function normalizeItemList<T extends Item>(items: T[]): NormalizedList<T> {
  return items.reduce((list, item) => {
    list.byId[item.id] = item
    list.allIds.push(item.id)

    return list
  }, {byId: {}, allIds: []})
}
