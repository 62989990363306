import { Component, OnInit } from '@angular/core'
import { BaseService } from 'services/api'
import { Router } from '@angular/router'

@Component({
  selector: 'app-nyx-catalog-home',
  templateUrl: './nyx-catalog-home.component.html',
  styleUrls: ['./nyx-catalog-home.component.scss']
})
export class NyxCatalogHomeComponent implements OnInit {
  currentLang = {name: 'RU', lang: 'rus'};
  currentLangCatalog = 'rus';

  selected: any;
  token = '4e3cb8b0-6168-11ec-9afa-d8f3bc3ad36e';
  catalog: any = [];

  constructor(
    private api: BaseService,
    private router: Router
  ) {}

  ngOnInit() {
    this.api.nyxCatalog()
    .subscribe((response: any) => {
      console.log(response);
      this.catalog = response;
    });
  }

  goBack() {
    this.router.navigate(['/catalogs_old']);
  }
}
