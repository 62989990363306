import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseService } from 'services/api';
import { DialogService, StorageService } from 'services';
import { AlertDialogComponent } from 'components/dialogs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { timer } from 'rxjs';
import { CatalogsSearchService } from 'services/api/catalogs/catalogs-search.service';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-catalogs-list',
  templateUrl: './catalogs-list.component.html',
  styleUrls: ['./catalogs-list.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0
      })),
      state('show', style({
        opacity: 1
      })),
      transition('void => show', [
        style({
          opacity: 0
        }),
        animate(500, style({
          opacity: 1
        }))
      ])
    ])
  ]
})
export class CatalogsListComponent implements OnInit {
  showAutocomplete = false;
  options: string[] = ['Опция 1', 'Опция 2', 'Опция 3', 'Опция 4'];
  searchResults: any[] = [];
  searchItem: string;

  showPrev = 'show';
  activeTab = 0;

  slideConfig = {
    'slidesToShow': 4,
    'slidesToScroll': 1,
    'responsive': [{
      'breakpoint': 1060,
      'settings': {
        slidesToShow: 2
      }
    },
    {
      'breakpoint': 600,
      'settings': {
        slidesToShow: 1
      }
    },
    {
      'breakpoint': 415,
      'settings': {
        slidesToShow: 1
      }
    }]
  };

  folderId = undefined;
  catalogInfo = undefined;
  catalogId = undefined;
  macrocategoryId = undefined;
  pr = null;
  homePrId = null;
  catalogStorageInfo = {
    title: '',
    tabId: null,
    index: null
  }
  comparisonCounter = 0;
  mobile = false;

  constructor(
    private router: Router,
    private api: BaseService,
    private route: ActivatedRoute,
    private storage: StorageService,
    private dialogService: DialogService,
    private catalogsSearchService: CatalogsSearchService
  ) {}

  onFaq(): void {
    this.router.navigate(
      ['faq'],
      {relativeTo: this.route}
    );
  }

  //to get more data
  onGetNew(): void {
    this.catalogsSearchService.setOffset('continue');
    this.catalogsSearchService.getProducts()
    .pipe(
      filter(v => v.length > 0)
    )
    .subscribe({
      next: v => {
        this.searchResults = [...this.searchResults, ...v];
      }
    })
  }

  onSearched(data: {searchResults: any[], searchItem: string}): void {
    this.showPrev = 'none';
    timer(200).subscribe({next: _ => this.showPrev = 'show'})
    this.searchResults =  data.searchResults;
    this.searchItem = data.searchItem;
  }

  onSearchPage(data: any): void {
    this.router.navigate(['catalogs/' + this.folderId + '/list/' + this.catalogId + '/card/' +  data.id]);
  }

  goBack() {
    this.storage.setValue('catalog_storage_info', '')
    this.router.navigate( [ 'catalogs/' + this.folderId ] );
  }

  tabOpen(id, index) {
    this.activeTab = index;
    this.macrocategoryId = undefined;
    this.api.catalogCatalog({id: this.catalogId, macrocategory_id: id}).subscribe((response: any) => {
      this.macrocategoryId = id;
      this.catalogInfo.lines = response.lines;
    });
  }

  list(id, index) {
    if (this.catalogInfo.lines[index].open) {
      this.catalogInfo.lines[index].open = false;
    } else {
      if (this.catalogInfo.lines[index].products) {
        this.catalogInfo.lines[index].open = true;
      } else {
        this.api.catalogCatalog({id: this.catalogId, macrocategory_id: this.macrocategoryId, line_id: id}).subscribe((response: any) => {
          this.catalogInfo.lines[index].open = true;
          this.catalogInfo.lines[index].products = response.products
        });
      }
    }
  }

  showPreview(info, home) {
    if (info.files[0]) {
      this.pr = info.files[0].file;
    }
    this.homePrId = home.id;
    this.showPrev = 'show';
  }

  removePreview() {
    this.pr = null;
    this.homePrId = null;
    this.showPrev = 'none';
  }

  onProduct(id) {
    this.storage.setValue('catalog_storage_info', {
      title: this.catalogStorageInfo.title,
      tabId: this.macrocategoryId,
      index: this.activeTab
    })
    return 'catalogs/' + this.folderId + '/list/' + this.catalogId + '/card/' +  id
  }

  comparisonOn() {
    if (!this.storage.getValue('compare') || this.storage.getValue('compare')['products'].length === 0) {
      let data = {
        'text': 'Нет товаров для сравнения.',
        'confirmButtonText': 'ОК'
      };
      this.dialogService.openDialog(AlertDialogComponent, data);
    } else {
      this.router.navigate( [ 'catalogs/comparison/' + this.folderId + '/list/' + this.catalogId ] );
    }
  }

  changeBackgroundColor(event) {
    let counter: any = document.getElementsByClassName('comparison_count')[0]
    if (!counter) {
      return
    }
    if (event.type === 'mouseenter') {
      counter.style.background = '#ffffff'
    } else {
      counter.style.background = '#BFB0A5'
    }
  }

  ngOnInit() {

    this.route.params.subscribe( params => {
      if (window.innerWidth <= 1025) {
        this.mobile = true;
      }
      this.folderId = params['folder_id'];
      this.catalogId = params['catalog_id'];
      this.api.catalogCatalog({id: params['catalog_id']}).subscribe((response: any) => {
        this.catalogInfo = response;
        if (this.storage.getValue('compare')) {
          if (this.storage.getValue('compare')['products'].length !== 0) {
            this.comparisonCounter = this.storage.getValue('compare')['products'].length
          }
        }
        if (this.storage.getValue('catalog_storage_info')) {
          this.catalogStorageInfo = this.storage.getValue('catalog_storage_info')
          if (this.catalogStorageInfo.tabId && this.catalogStorageInfo.index) {
            this.tabOpen(this.catalogStorageInfo.tabId, this.catalogStorageInfo.index)
          }
        }
      })
    });
  }
}
