import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private readonly storageKey = 'nyx-v-01'
  private readonly authKey = 'auth-v-01'
  private readonly formDataKey = 'form-v-01'

  constructor() {
    if (!localStorage.getItem(this.storageKey)) {
      localStorage.setItem(this.storageKey, '{}')
    }

    if (!localStorage.getItem(this.authKey)) {
      localStorage.setItem(this.authKey, '{}')
    }

    if (!localStorage.getItem(this.formDataKey)) {
      localStorage.setItem(this.formDataKey, '{}')
    }
  }

  setValue(key: string, value: any): void {
    let data = JSON.parse(<string>localStorage.getItem(this.storageKey))

    if (!data) {
      data = {}
      data[key] = {}
    } else if (!data[key]) {
      data[key] = {}
    }

    if (typeof (value) === 'object') {
      data[key] = Object.assign(data[key], value)
    } else {
      data[key] = value
    }

    localStorage.setItem(this.storageKey, JSON.stringify(data))
  }

  setWithKey(value: any, key: string = 'auth'): void {
    let storeKey = this.authKey

    if (key === 'form') {
      storeKey = this.formDataKey
    }

    let data = JSON.parse(<string>localStorage.getItem(storeKey))

    if (!data) {
      data = {}
    }

    if (typeof (value) === 'object') {
      data = Object.assign(data, value)
    } else {
      data = value
    }

    localStorage.setItem(storeKey, JSON.stringify(data))
  }

  getWithKey(key: string = 'auth'): any {
    let storeKey = this.authKey

    if (key === 'auth') {
      storeKey = this.authKey
    } else if (key === 'form') {
      storeKey = this.formDataKey
    }

    return JSON.parse(<string>localStorage.getItem(storeKey))
  }

  setAll(value: any): void {
    localStorage.setItem(this.storageKey, JSON.stringify(value))
  }

  getValue(key: string): any {
    const data = JSON.parse(<string>localStorage.getItem(this.storageKey))

    if (!data) {
      return null
    }

    return data[key]
  }

  getAll(): any {
    return JSON.parse(<string>localStorage.getItem(this.storageKey))
  }
}
