import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { env } from 'env'
import { finalize } from 'rxjs/operators';
import { CatalogQuery } from './catalogs-query';

@Injectable({
  providedIn: 'root'
})
export class CatalogsTabService {
  private rootApi: string = env.rootApiUrl;

  private onlineSubject = new BehaviorSubject<boolean>(true);
  online$ = this.onlineSubject.asObservable();

  private offlineSubject = new BehaviorSubject<boolean>(true);
  offline$ = this.offlineSubject.asObservable();

  private filteredDataSubject = new BehaviorSubject<any[]>([]);
  filteredData$ = this.filteredDataSubject.asObservable();

  private disabledSubject = new BehaviorSubject<boolean>(false);
  disabled$ = this.disabledSubject.asObservable();

  private sub$: Subscription;

  constructor(private http: HttpClient) { }

  //on toggle online checkbox
  onlineChanges(value: MatCheckboxChange, product_id: number): void {
    this.onlineSubject.next(value.checked);
  
    if (!value.checked && this.offlineSubject.value) {
      this.disabledSubject.next(true);
      this.getFilteredReview(product_id, false);
    } 

    if(value.checked && this.offlineSubject.value) {
      this.filteredDataSubject.next([]);
    }

    if(!value.checked && !this.offlineSubject.value) {
      this.disabledSubject.next(true);
      this.offlineSubject.next(true);

      this.getFilteredReview(product_id, false);
    }
  }

    //on toggle offline checkbox
  offlineChanges(value: MatCheckboxChange, product_id: number): void {
    this.offlineSubject.next(value.checked);
  
    if (!value.checked && this.onlineSubject.value) {
      this.disabledSubject.next(true);
      
      this.getFilteredReview(product_id, true);
    } 
    if(value.checked && this.onlineSubject.value) {
      this.filteredDataSubject.next([]);
    }
    if(!value.checked && !this.onlineSubject.value) {
      this.disabledSubject.next(true);
      this.onlineSubject.next(true);

      this.getFilteredReview(product_id, true);
    }
  }

    //to get filtered data 
  getFilteredReview(product_id: number, value: boolean): void {
    this.sub$ = this.getFiltredResponse(product_id, value)
      .pipe(
        finalize(() => {this.disabledSubject.next(false); this.sub$?.unsubscribe()})
      )
      .subscribe({
        next: v => {
          this.filteredDataSubject.next(v);
        }
      });
  }

    //filtered data response
  getFiltredResponse(product_id: number, online: boolean ): Observable<any> {
    return this.http.post<any>(this.rootApi + CatalogQuery.filter, {product_id: product_id, infoposition_id: 9, online: online});
  }
}
