import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, DialogService, ExtraService, StorageService } from 'services';
import { AlertDialogComponent } from 'components/dialogs';
import { Brand } from 'app/constants/brands';
import { RootRoute } from 'app/constants/root-routes';
import { DestroyRef } from 'core/common';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FolderData } from 'core/model/folder';
import { MainLayoutService } from 'app/layout/main-layout/services/main-layout.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DestroyRef]
})
export class HeaderComponent implements OnInit {
  @Input() showUser: boolean = false;

  brands: Brand[] = [Brand.logo];
  routesList: {[key in string]:RootRoute};
  section$:Observable<FolderData>;
  fixed: boolean;

  isVisible:boolean;
  avatarImg: string = 'url(/assets/images/icons/avatar.png)';
  logoutImg: string = 'url(/assets/images/icons/exit.png)';
  
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly dialogService: DialogService,
    private readonly storageService: StorageService,
    private mainLayoutService: MainLayoutService,
    private destroyRef: DestroyRef,
    private extraService: ExtraService
  ) {}

  ngOnInit() {
    this.routesList = this.mainLayoutService.getRoutes();
    this.section$ = this.extraService.documentContent$;
  }

  private signOut(): void {
    this.authService.signOut()
    .pipe(
      takeUntil(this.destroyRef)
    )
    .subscribe( _ => {
      this.storageService.setValue('token', '')
      this.goPage(RootRoute.auth);
    })
  }

  goPage(page: string): void {
    this.router.navigate([page]);
  }

  logout(): void {
    const data = {
      'title': 'Вы уверены, что хотите выйти?',
      'confirmButtonText': 'ДА',
      'notButtonText': 'НЕТ'
    }

    this.dialogService.openDialog(AlertDialogComponent, data)
    .pipe(
      takeUntil(this.destroyRef)
    )
    .subscribe((res: boolean) => {
      if (res) {
       this.signOut();
      }
    })
  }

  openMenu(): void {
    this.isVisible = !this.isVisible;
  }

  headerFixed(event: Event) {
    const scrolled = (event.target as HTMLElement).scrollTop;
  
    if (scrolled === 0) {
      this.fixed = false;
    } else {
      this.fixed = true;
    }

  }
}
