import { NgModule } from '@angular/core'

import { FileDialogComponent } from './components'
import { FileDialogTriggerDirective } from './directives'

@NgModule({
  declarations: [
    FileDialogComponent,
    FileDialogTriggerDirective
  ],
  exports: [
    FileDialogComponent,
    FileDialogTriggerDirective
  ]
})
export class FileDialogModule {}
