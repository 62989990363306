import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appHideText]'
})
export class HideTextDirective implements OnChanges {
  @Input() text: string = '123';

  empty: string = 'hidden';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.text) {
      this.checkAndSetText();
    }

  }

  private checkAndSetText() {
    const textContent = this.text ? this.text.trim() : '';

    if (!textContent) {
      this.renderer.setProperty(this.el.nativeElement, 'textContent', this.empty);
      this.renderer.setStyle(this.el.nativeElement, 'opacity', '0');
    } else {
      this.renderer.setProperty(this.el.nativeElement, 'textContent', this.text);
      this.renderer.setStyle(this.el.nativeElement, 'opacity', '1');
    }
  }

}
