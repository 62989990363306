import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorDayPipe } from './color-day.pipe';
import { ConvertToStringPipe } from './convert-to-string.pipe';
import { FindThumbsPipe } from './find-thumbs.pipe';
import { MsToTimePipe } from './ms-to-time.pipe';
import { ReplacementWordPipe } from './replacement-word.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { TimestampToDatePipe } from './timestamp-to-date.pipe';
import { MomentPipe } from './moment.pipe';
import { FilterFinishPipe } from './filter-finish.pipe';
import { HeightCountPipe } from './height-count.pipe';
import { CheckTestPipe } from './check-test.pipe';

@NgModule({
  declarations: [
    ColorDayPipe,
    ConvertToStringPipe,
    FindThumbsPipe,
    MsToTimePipe,
    ReplacementWordPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    TimestampToDatePipe,
    MomentPipe,
    FilterFinishPipe,
    HeightCountPipe,
    CheckTestPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ColorDayPipe,
    ConvertToStringPipe,
    FindThumbsPipe,
    MsToTimePipe,
    ReplacementWordPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    TimestampToDatePipe,
    MomentPipe,
    FilterFinishPipe,
    HeightCountPipe,
    CheckTestPipe
  ]
})
export class PipesModule { }
