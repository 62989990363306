import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './main-layout.component';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { BurgerComponent } from './components/burger/burger.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { IconLoaderModule } from 'app/shared/components/icon-loader/icon-loader.module';



@NgModule({
  declarations: [
    MainLayoutComponent,
    HeaderComponent,
    BurgerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgScrollbarModule,
    IconLoaderModule
  ]
})
export class MainLayoutModule { }
