import { Component, OnInit } from '@angular/core'

import { animate, state, style, transition, trigger } from '@angular/animations'
import { BaseService } from 'services/api'
import { ActivatedRoute, Router } from '@angular/router'
import { DialogService } from 'services'
import { AlertDialogComponent, GameFinishDialogComponent } from 'components/dialogs'

@Component({
  selector: 'app-game-stages',
  templateUrl: 'game-stages.component.html',
  styleUrls: ['game-stages.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})
export class GameStagesComponent implements OnInit {
  isOpen: boolean = true;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private baseService: BaseService,
    private dialogService: DialogService
  ) {
  }

  gameId = undefined
  gameBrand = undefined
  gameInfo = undefined
  stages = undefined

  stageInfo = {
    image: undefined,
    startIndex: undefined
  }

  warning = {
    title: 'Произошла ошибка',
    confirmButtonText: 'OK'
  }

  toggleText(): void {
    this.isOpen = !this.isOpen;
  }

  goBack(event) {
    event.stopPropagation()
    this.router.navigate([`game/${this.gameId}`])
  }

  resize(image) {
    if (window.innerWidth <= 1025) {
      this.stageInfo.image = image.horizontal
    } else {
      this.stageInfo.image = image.vertical
    }
  }

  goStage(stage, index) {
    if (index !== this.stageInfo.startIndex) {
      return
    }
    switch (stage.type) {
      case 'test':
        this.router.navigate([`game/${this.gameId}/${this.gameBrand}/stage/${stage.id}`], {queryParams: {testId: stage.test}}).catch(() => {
          this.dialogService.openDialog(AlertDialogComponent, this.warning)
        })
        break
      case 'drop-image':
        this.router.navigate([`game/${this.gameId}/${this.gameBrand}/stage/${stage.id}/drop-image`]).catch(() => {
          this.dialogService.openDialog(AlertDialogComponent, this.warning)
        })
        break
      case 'photo':
        this.router.navigate([`game/${this.gameId}/${this.gameBrand}/stage/${stage.id}/photo`]).catch(() => {
          this.dialogService.openDialog(AlertDialogComponent, this.warning)
        })
        break
      case 'drop-word':
        this.router.navigate([`game/${this.gameId}/${this.gameBrand}/stage/${stage.id}/drop-word`]).catch(() => {
          this.dialogService.openDialog(AlertDialogComponent, this.warning)
        })
        break
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.gameId = params.id
      this.gameBrand = params.brand
      this.baseService.baseGetGameStageList(this.gameId).subscribe((response: any) => {
        this.gameInfo = response.game
        this.stages = response.stages
        if (this.stages.findIndex(item => item.is_passed !== true) !== -1) {
          this.stageInfo.image = this.stages[this.stages.findIndex(item => item.is_passed !== true)].images
          this.stageInfo.startIndex = this.stages.findIndex(item => item.is_passed !== true)
        } else {
          this.stageInfo.image = this.stages[4].images
          this.stageInfo.startIndex = 5
          this.baseService.baseGameGetMessage('finish', this.gameId).subscribe((response: any) => {
            const data = {
              text: response.text,
              image: response.file,
              confirmButtonText: 'OK'
            }
            this.dialogService.openDialog(GameFinishDialogComponent, data)
          })
        }
        this.resize(this.stageInfo.image)
      })
    })
  }
}
