<div class="header">
  <div class="header-box">
    <app-icon-loader [name]="'logo'" class="header-logo"></app-icon-loader>
  </div>
</div>
<main >
  <div class="container-breadcrumbs" *ngIf="isBreadcrumb$ | async">
    <div 
      class="container-button-back" 
      *ngIf="navigationTitle$ | async as item"
      [routerLink]="item.backRoute"
    >
      <app-icon-loader 
        [name]="'down'" 
        [size]="25" 
        class="back-button-transform">
      </app-icon-loader>
      <span>{{item.title}}</span>
    </div>
    <div matRipple class="button" (click)="onNavigate()">Вернуться на портал</div>
  </div>
  <router-outlet></router-outlet>
</main>
