import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {GameModeratorService, StorageService} from "services";

@Component({
  selector: 'app-game-moderator-alert-dialog',
  templateUrl: './game-moderator-alert-dialog.component.html',
  styleUrls: ['./game-moderator-alert-dialog.component.scss'],
})

export class GameModeratorAlertDialogComponent implements OnInit {

  question: any;

  form: FormGroup = this.formBuilder.group({});

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<GameModeratorAlertDialogComponent>,
    private storageService: StorageService,
    private gameModerService: GameModeratorService,
    private matDialogService: MatDialog,
    @Inject(MAT_DIALOG_DATA) private dialogData: { question: any },
  ) {
    ({
      question: this.question,
    } = this.dialogData);
  }

  timeOut = false;
  time = 0;
  timeleft = this.time;
  timer = 0;
  startMoment = 0;
  endMoment = this.startMoment + this.timeleft * 1000;

  initiateTimer = false;

  answer: any

  lang: string

  scrollTo() {
    document.getElementById('scroll').scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

  initCountdown() {
    this.initiateTimer = true;
    this.startMoment = Date.now();
    this.endMoment = this.startMoment + this.timeleft * 1000;
    this.timer = window.setInterval(() => {
      const now = Date.now();
      this.timeleft = Math.round((this.endMoment - now) / 1000);
      if (this.timeleft <= 0) {
        clearInterval(this.timer);
        this.timeleft = this.time;
        this.timeOut = true;
        this.answerQuestion();
      }
    }, 1000);
  }

  makeFormGroup(form: any) {
    const group = {};
    switch (form.type) {
      case 'radio':
        group[form.id] = [null, Validators.required];
        break
      case 'checkbox':
        group[form.id] = this.formBuilder.array(
          form.options.map(() => this.formBuilder.control(false)),
        );
        break
      case 'sort':
        group[form.id] = [form.options.map((arr: any) => arr.id), Validators.required];
        break
      case 'text':
        group[form.id] = ['', Validators.required];
        break
      case 'rating':
        group[form.id] = ['', Validators.required];
        break
    }
    this.form = this.formBuilder.group(group);
  }

  drop(event: CdkDragDrop<string[]>, controlName: any, arr: any) {
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
    this.form.get(String(controlName)).setValue(arr.map((inf: any) => inf.id));
  }

  answerQuestion() {
    if(!this.form.pristine) {
      switch (this.question.type) {
        case 'radio':
          this.answer = {
            question_id: +Object.keys(this.form.value)[0],
            options: [+this.form.get(String(this.question.id)).value],
          };
          break
        case 'checkbox':
          const checkboxAnswers = [];
          Object.keys(this.form.get(String(this.question.id)).value).forEach((i) => {
            if (this.form.get(String(this.question.id)).value[i]) {
              checkboxAnswers.push(this.question.options[i].id);
            }
          });
          this.answer = {
            question_id: +Object.keys(this.form.value)[0],
            options: checkboxAnswers
          }
          break
        case 'sort':
          this.answer = {
            question_id: +Object.keys(this.form.value)[0],
            options: this.form.get(String(this.question.id)).value
          }
          break
        default:
          this.answer = {
            question_id: +Object.keys(this.form.value)[0],
            options: Object.values(this.form.value)
          }
          break
      }
      this.dialogRef.close(this.answer)
    } else {
      this.matDialogService.closeAll();
    }
   
  }

  ngOnInit() {
    this.gameModerService.getLang()
    this.lang = this.storageService.getValue('game_moder_lang')
    this.dialogRef.disableClose = true
    this.makeFormGroup(this.question)
    if (this.question.time) {
      this.time = +this.question.time;
      this.timeleft = this.time;
      this.initCountdown();
    }
  }

}
