import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-game-finish-dialog',
  templateUrl: './game-finish-dialog.component.html',
  styleUrls: ['./game-finish-dialog.component.scss'],
})

export class GameFinishDialogComponent {
  text: string;
  image: string;
  confirmButtonText = 'OK';

  constructor(
    private dialogRef: MatDialogRef<GameFinishDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {'text': string, image: string, confirmButtonText: string},
  ) {
    ({
      text: this.text,
      image: this.image,
      confirmButtonText: this.confirmButtonText,
    } = this.dialogData);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

}
