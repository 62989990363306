import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { ApiService } from 'core/api'
import { Comment } from 'core/eco-portal'
import { Pagination } from 'core/common'
import { createCommentsUrls } from './comments-urls'

@Injectable({
  providedIn: 'root'
})
export class CommentsService extends ApiService {
  private readonly urls = createCommentsUrls()

  /**
   * Gets a list of comments.
   *
   * @param section
   * @param entity_id
   * @param pagination
   */
  getComments(section: string, entity_id: number, pagination: Pagination): Observable<Comment[]> {
    return this.http.post<Comment[]>(this.urls.getComments(section), {entity_id, ...pagination})
  }

  /**
   * Sends a comment.
   *
   * @param section
   * @param entity_id
   * @param text
   */
  sendComment(section: string, entity_id: number, text: string): Observable<Comment> {
    return this.http.post<Comment>(this.urls.createComment(section), {entity_id, text})
  }
}
