import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appImage]'
})
export class ImageDirective {
  @Input() fallback: string = '../../assets/images/icons/avatar.png'

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('error') onError() {
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.fallback);
    this.centerImage();
  }

  private centerImage() {
    this.renderer.setStyle(this.el.nativeElement, 'display', 'flex');
    this.renderer.setStyle(this.el.nativeElement, 'justify-content', 'center');
    this.renderer.setStyle(this.el.nativeElement, 'align-items', 'center');
    this.renderer.setStyle(this.el.nativeElement, 'object-fit', 'contain');
    this.renderer.setStyle(this.el.nativeElement, 'background-color', '#B0A091');
  }

}
