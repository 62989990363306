<div class="wrapper">
    <app-header></app-header>

    <div class="workspace">
        <div class="bread_and_title_container">
            <div class="bread_and_title">
                <a 
                    [routerLink]="'../'" 
                    class="page_title"
                >
                    <div class="icon back"></div>
                    <span>Сравнение</span>
                </a>
            </div>
        </div>

        <div class="catalogs">
            <ng-container *ngIf="comparisonList$ | async">
                <div class="products">
                    <div class="container">
                        <div class="box">
                            <ng-container *ngFor="let product of comparisonProducts$ | async; trackBy: trackById">
                                <div
                                    class="product self"
                                    [ngClass]="{hidden: isLoading}"
                                    #productRef
                                >
                                    <img 
                                        [src]="product?.file"
                                        custom-image
                                        [fallback]="true"
                                    >
                                    <p>{{product?.title}}</p>
                                </div>
                            </ng-container>
                        </div>
                        <swiper
                            #swiper
                            [config]="config$ | async"
                            class="swiper"
                        >
                            <ng-template 
                                swiperSlide 
                                *ngFor="let product of comparisonProducts$ | async; trackBy: trackById"
                            >
                                <div
                                    class="product"
                                    [ngClass]="{hidden: isLoading}"
                                    #productRef
                                >
                                    <img 
                                        [src]="product?.file"
                                        custom-image
                                        [fallback]="true"
                                    >
                                    <p>{{product?.title}}</p>
                                </div>
                            </ng-template>
                        </swiper>
                    </div>
        
                    <div class="loading" *ngIf="isLoading">
                        <mat-progress-spinner 
                            [mode]="'indeterminate'" 
                            [diameter]="80">
                        </mat-progress-spinner>
                    </div>
        
                    <ng-container *ngIf="selectedCounter$ | async as counter">
                        <aside class="aside">
                            <ng-container *ngIf="allowedEl">
                                <p class="counter">{{counter}} / {{allowedEl}}</p>
                            </ng-container>
                            <div 
                                class="list" 
                                [ngClass]="{
                                    single: listType === 'single',
                                    double: listType === 'double',
                                    thriple: listType === 'thriple'
                                }"
                            > 
                                <ng-container *ngFor="let product of products$ | async">
                                    <button 
                                        mat-button
                                        class="small-product"
                                        [ngClass]="{active: selectedProducts.has(product?.id)}"
                                    >
                                        <img 
                                            [src]="product?.file"
                                            custom-image
                                            [fallback]="true"
                                            (click)="onSelectProduct(product.id)"
                                        />
                                    </button>
                                </ng-container>
                            </div>
                       </aside>
                    </ng-container>
                </div>
                
                <ul class="statistic">
                    <ng-container *ngFor="let comparison of comparison$ | async; trackBy: trackByTitle">
                        <li class="first">
                            {{comparison.title}}
                        </li>
                        <li class="second">
                            <ng-container *ngFor="let item of comparison.values">
                                <p [ngStyle]="{width: columnSize}" [innerHTML]="item | safeHtml"></p>
                            </ng-container>
                        </li>
                    </ng-container>
                </ul>
            </ng-container>

            <div class="loading fixed" *ngIf="!(comparisonList$ | async)">
                <mat-progress-spinner 
                    [mode]="'indeterminate'" 
                    [diameter]="80">
                </mat-progress-spinner>
            </div>
        </div>
    </div>
</div>
