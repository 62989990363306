<app-header [logoWhite]="true"></app-header>

<div class="outer" [ngStyle]="{'backgroundImage': 'url(' + extraService.backgroundStore + ')'}">
  <div class="workspace game_moder_column">

    <div class="bread_and_title_container">
      <div class="bread_and_title game_moder">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>BE IN TOUCH</span>
        </div>
      </div>
    </div>

    <div class="container_text">
      <div class="text_block">
        <div class="text" *ngIf="startScreen" [innerHTML]="startScreen.start_message.text | safeHtml"></div>
      </div>
    </div>

    <div class="buttons_container" *ngIf="startScreen">
      <div class="translate_container">
        <div class="translate_button" [ngClass]="{active: gameModerService.lang === 'ru'}" (click)="switchLang('ru')">РУС</div>
<!--        <div class="translate_button" [ngClass]="{active: gameModerService.lang === 'en'}" (click)="switchLang('en')">ENG</div>-->
      </div>
      <div  class="button" (click)="goRules()" [innerHTML]="startScreen.next_button.text | safeHtml"></div>
    </div>

  </div>
</div>
