<div class="header" [ngClass]="{fixed: fixed}">
    <div 
      class="header-container">
      <div 
        *ngIf="showUser"
        class="user" 
        [ngStyle]="{backgroundImage: avatarImg}" 
        (click)="goPage(routesList.personal)">
      </div>
    
      <div class="logout" [ngStyle]="{backgroundImage: logoutImg}" (click)="logout()"></div>
    
      <div class="burger" (click)="openMenu()">
        <div class="burger-box" [ngClass]="{'close': isVisible}"></div>
      </div>
    </div>
    <div class="header-box">
      <app-icon-loader [name]="'logo'" class="header-logo" (click)="goPage(routesList.main)"></app-icon-loader>
    </div>
</div>
  
<div class="menu-wrapper" *ngIf="isVisible">
  <div class="menu" (scroll)="headerFixed($event)">
    <div class="menu-section">
      <app-burger 
        [routesList]="routesList" 
        [avatarImg]="avatarImg" 
        [section]="section$ | async"
        (pageClick)="goPage($event)"
        (logoutClick)="logout()">
      </app-burger>
    </div>
  </div>
</div>
  