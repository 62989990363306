import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { ApiService } from 'core/api'
import { KpiContent, KpiPreview, KpiMessage, PlanetPageData } from 'core/eco-portal'
import { createKpiUrls } from './kpi-urls'

@Injectable({
  providedIn: 'root'
})
export class KpiService extends ApiService {
  private readonly urls = createKpiUrls()

  /**
   * Gets KPI previews.
   */
  getKpiPreviews(): Observable<KpiPreview[]> {
    return this.http.post<KpiPreview[]>(this.urls.getKpiPreviews, null)
  }

  /**
   * Gets the content of the KPI.
   *
   * @param id
   */
  getKpiContent(id: number): Observable<KpiContent> {
    return this.http.post<KpiContent>(this.urls.getKpiContent, {id})
  }

  /**
   * Uploads photos to the KPI action.
   *
   * @param actionId
   * @param photos
   */
  uploadPhotosToKpiAction(actionId: number, photos: File[]): Observable<void> {
    const formData = new FormData()

    formData.append('action_id', String(actionId))
    photos.forEach((photo) => formData.append('files', photo))

    return this.http.post<void>(this.urls.uploadPhotosToKpiAction, formData)
  }

  /**
   * Gets a KPI message.
   *
   * @param ref
   */
  getKpiMessage(ref: string): Observable<KpiMessage> {
    return this.http.post<KpiMessage>(this.urls.getKpiMessage(ref), null)
  }

  /**
   * Gets the planet page data.
   */
  getPlanetPageData(): Observable<PlanetPageData> {
    return this.http.post<PlanetPageData>(this.urls.getPlanetPageData, null)
  }
}
