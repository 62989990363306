import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { VgCoreModule } from '@videogular/ngx-videogular/core'
import { VgControlsModule } from '@videogular/ngx-videogular/controls'
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play'
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering'

import { VideoPlayerComponent } from './components'
import { HorizontalScrollDirective } from './directives'
import { ColorDayPipe, ConvertToStringPipe, FindThumbsPipe, MsToTimePipe, ReplacementWordPipe, SafeHtmlPipe, SafeUrlPipe, TimestampToDatePipe } from './pipes'

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
  ],
  declarations: [
    ColorDayPipe,
    ConvertToStringPipe,
    FindThumbsPipe,
    HorizontalScrollDirective,
    MsToTimePipe,
    ReplacementWordPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    TimestampToDatePipe,
    VideoPlayerComponent,
  ],
  exports: [
    ColorDayPipe,
    ConvertToStringPipe,
    FindThumbsPipe,
    HorizontalScrollDirective,
    MsToTimePipe,
    ReplacementWordPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    TimestampToDatePipe,
    VideoPlayerComponent,
  ]
})
export class SharedModule {}
