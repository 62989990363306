import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from 'services';

@Injectable({
  providedIn: 'root'
})
export class DocumentResolver implements Resolve<boolean> {

  constructor(private baseService: BaseService, private router: Router) {}
  
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const id = route.params.id;
    const section = route.params.section;
    const docType = route.params.docType;

    if(docType === 'wordbook' || section === 'wordbook') {
      return this.baseService.wordbookDocument(id)
      .pipe(
        catchError(() => {
          this.router.navigate(['error'])
          return EMPTY
        })
      )
    }
    
    return this.baseService.documentsDocument(id)
    .pipe(
      catchError(() => {
        this.router.navigate(['error'], {skipLocationChange: true})
        return EMPTY
      })
    )
  }
}
