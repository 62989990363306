import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { KpiMessage } from 'core/eco-portal'

@Component({
  selector: 'app-bee-overlay',
  templateUrl: 'bee-overlay.component.html',
  styleUrls: ['bee-overlay.component.scss']
})
export class BeeOverlayComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) readonly message: KpiMessage,
    readonly dialogRef: MatDialogRef<any>
  ) {}
}
