import {Component, HostListener, OnInit, OnDestroy} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common'

import { animate, state, style, transition, trigger } from '@angular/animations'
import {BaseService, StatisticsService} from 'services/api'
import * as AOS from 'aos'
import * as moment from "moment";
import {DialogService, MetricsService, SectionsDateService, StorageService} from 'services'
import { AlertDialogComponent } from 'components/dialogs'
import { HomeworkRoute } from 'app/constants/homework-routes'
import { RootRoute } from 'app/constants/root-routes'

@Component({
  selector: 'app-document',
  templateUrl: 'document.component.html',
  styleUrls: ['document.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})
export class DocumentComponent implements OnInit, OnDestroy {
  showPrev = 'show';

  docSection = undefined;
  docType = undefined;
  docId = undefined;
  docBody = undefined;

  created = moment().format('x');

  breadcrumbs = [];

  sectionIo = this.sectionsDate.sectionsInfo();

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private storage: StorageService,
    private sectionsDate: SectionsDateService,
    private baseService: BaseService,
    private statisticsService: StatisticsService,
    private metrics: MetricsService
  ) {
  }

  goBack() {
    const lastBread = this.breadcrumbs[this.breadcrumbs.length - 2];

   if(lastBread) {
    this.router.navigate( [
      this.sectionIo[this.docSection]['folderLink'] + lastBread.id
    ])
   } else {
    this.location.back();
   }
  }

  goHomework(id:number): void {
    this.router.navigate([RootRoute.homework,HomeworkRoute.perform,id,this.docBody.box]);
  }

  goVideoTest(id) {
    this.router.navigate(['video-test/' + id]);
  }

  goFinalTest(finalTest) {
    if (finalTest.is_available) {
      this.router.navigate(['final-test/' + finalTest.id]);
      return;
    }
    this.dialogService.openDialog(AlertDialogComponent, {title: 'Тест недоступен', confirmButtonText: 'ОК'})
  }

  scrollToElement($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  onBread(info) {
    if (info.type === 'home') {
      this.router.navigate( [ this.sectionIo[this.docSection]['homeLink'] ] );
    } else {
      this.router.navigate( [ this.sectionIo[this.docSection]['folderLink'] + info.id ] );
    }
  }

  @HostListener('document:visibilitychange')
  unloadHandler() {
    if (document.hidden) {
      this.setMetrics()
    } else {
      this.metrics.timer();
    }
  }

  setMetrics() {
    this.metrics.takeTime(this.docSection, this.docId, this.created);
    if (this.storage.getValue('metrics') && this.storage.getValue('metrics')['time_intervals'].length > 0) {
      this.metrics.clearTimer();
      const logs = this.storage.getValue('metrics')['time_intervals'];
      this.storage.setValue('metrics', {'time_intervals': []});
      this.statisticsService.addStatisticsLog(logs).subscribe()
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.docSection = params.section;
      this.docType = params.docType;
      this.docId = params.id;
      this.metrics.timer();
      switch (this.docType) {
        case 'article':
          this.baseService.documentsDocument(this.docId).subscribe((response: any) => {
            response.content.map((item) => {
              if (item.type === 'scorm') {
                item.active = false
              }
            })
            this.docBody = response;

            this.baseService.documentsBoxChain(response['box']).subscribe((breadcrumbs: any) => {
              this.breadcrumbs = breadcrumbs;
              this.breadcrumbs.push({title: this.docBody.title});

              if (this.sectionIo[this.docSection]['homePage']['status']) {
                this.breadcrumbs.unshift(this.sectionIo[this.docSection]['homePage']['breadcrumbs']);
              }
            });

          });
          break;
        case 'course':
          if (this.docSection === 'makeup') {
            this.docSection = 'learning'
          }
          this.baseService.baseGetDocumentCourses(this.docSection, this.docId).subscribe((response: any) => {
            this.docBody = response;
            AOS.init({
              duration: 1200,
              easing: 'ease-in-out-back'
            });
          });
          break;
        case 'wordbook': {
          this.baseService.wordbookDocument(this.docId).subscribe((response: any) => {
            this.docBody = response;

            this.baseService.documentsBoxChain(response['box']).subscribe((breadcrumbs: any) => {
              breadcrumbs.pop()
              this.breadcrumbs = breadcrumbs;
              this.breadcrumbs.push({title: this.docBody.title});

              if (this.sectionIo[this.docSection]['homePage']['status']) {
                this.breadcrumbs.unshift(this.sectionIo[this.docSection]['homePage']['breadcrumbs']);
              }
            });

          });
          break;
        }
      }
    })
  }

  ngOnDestroy() {
    this.setMetrics();
    this.metrics.clearTimer();
  }

}
