import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogService } from "services";
import { AlertDialogComponent } from "../alert/alert-dialog.component";
import { DestroyRef } from 'core/common';
import { filter, takeUntil } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import  * as _moment from 'moment';
import { env } from 'env';
import { default as _rollupMoment } from 'moment';
import { CalendarInterval } from 'app/typings/calendar';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ScheduleParam, ScheduleEvent, ScheduleParticipant, ScheduleQuery, ScheduleStatus, ScheduleTrainer } from 'app/api/profile/schedule';
import { BookingRoom } from 'app/api/profile/booking';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonalRoute } from 'app/constants/personal-routes';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'D MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-schedule-create',
  templateUrl: './schedule-create.component.html',
  styleUrls: ['./schedule-create.component.scss'],
  providers: [
    DestroyRef,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE,MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ScheduleCreateComponent implements OnInit {
  form: FormGroup;
  intervals: CalendarInterval[] = [];
  categoires: ScheduleParam[] = [];
  reservations: BookingRoom[] = [];
  targetList: ScheduleParam[] = [];
  trainers: ScheduleTrainer[] = [];
  fileName: string;
  event: ScheduleEvent;
  isEdit: boolean;
  disableAll: boolean;
  disableMembers: boolean;
  selectedReservation: BookingRoom;
  participants: ScheduleParticipant[];
  columns: string[] = ['name', 'country'];
  title: string = 'Создать мероприятие';
  checkboxForm = new FormControl(false);

  constructor(
    private dialogRef: MatDialogRef<ScheduleCreateComponent>,
    private dialogService: DialogService,
    private matDialogService: MatDialog,
    private destroyRef: DestroyRef,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DIALOG_DATA) public dialogData: {
      categories: ScheduleParam[],
      intervals: CalendarInterval[], 
      reservations: BookingRoom[], 
      event: ScheduleEvent,
      participants: ScheduleParticipant[],
      targetList: ScheduleParam[],
      trainers: ScheduleTrainer[],
      callback: Function,
      resCall: Function 
    },
  ) {
    this.initForm();

    this.categoires = this.dialogData.categories;
    this.intervals = this.dialogData.intervals;
    this.reservations = this.dialogData.reservations;
    this.participants = this.dialogData.participants;
    this.targetList = this.dialogData.targetList;
    this.trainers = this.dialogData.trainers;

    if(this.dialogData.event) {
      this.isEdit = true;
      this.title = 'Изменить мероприятие';
      this.patchEvent(this.dialogData.event);
    }
  }

  goToBooking(): void {
    this.router.navigate([PersonalRoute.booking], {relativeTo: this.route.firstChild});
    
    this.matDialogService.closeAll();
  }

  ngOnInit(): void {
    this._adapter.setLocale('ru');
  }

  private patchEvent(event: ScheduleEvent) {
    this.event = event;

    this.form.patchValue({
      id: event.id,
      title: event.title,
      trainer: event.trainer.map(t => t.id),
      category: event.category.map(e => e.id),
      online: event.online,
      contest: event.contest,
      number_participants: event.number_participants ? event.number_participants : null,
      target: event.target_audience,
      date: event.date ? moment(event.date) : null,
      start: event.intervals ? event.intervals[0].id : null,
      finish: event.intervals ? event.intervals[event.intervals.length - 1].id : null,
      link: event.link ? event.link : '',
      reservation: event.reservation ? event.reservation : null
    })

    this.checkboxForm.setValue(event.reservation ? false : true);
    
    if(!event.online && !this.checkboxForm.value) {
      this.form.patchValue({
        date: null,
        start: null,
        finish: null,
        link: null
      })
    }

    this.form.markAsPristine();

    if(!event.edit) {
      this.title = 'Просмотр мероприятия';

      this.disableAll = true;
    }

    if(event.edit) {
      this.disableMembers = true;
    }

    this.findReservation(event.reservation);
  } 

  private createEventRes(form: FormData): Observable<ScheduleStatus> {
    return this.http.post<ScheduleStatus>(env.rootApiUrl + ScheduleQuery.eventCreate, form);
  }

  private updateEventRes(form: FormData): Observable<ScheduleStatus> {
    return this.http.post<ScheduleStatus>(env.rootApiUrl + ScheduleQuery.eventUpdate, form);
  }

  private removeRes(id: number): Observable<ScheduleStatus> {
    return this.http.post<ScheduleStatus>(env.rootApiUrl + ScheduleQuery.eventDelete, {id});
  }

  private buildFinalData(): FormData {
    const form = this.form.value;
    const formData = new FormData();
 
    formData.append('online', form.online);
    formData.append('title', form.title);
    formData.append('category', JSON.stringify(form.category));
    formData.append('target_audience',JSON.stringify(form.target));
    formData.append('contest',form.contest);
    formData.append('link', form.link);
    formData.append('trainer', JSON.stringify(form.trainer));

    if(form.id) {
      formData.append('id', form.id);
    }

    if(form.contest && form.online) {
      formData.append('number_participants', form.number_participants);
    } else {
      formData.append('number_participants', null);
    }

    if(form.date || this.checkboxForm.value) {
      formData.append('date', moment(form.date).utc(true).valueOf().toString());
    }

    if (form.reservation && !this.checkboxForm.value) {
      formData.append('reservation', form.reservation);
    }
  
    if (form.online || this.checkboxForm.value) {
      const intervalsData = JSON.stringify([form.start, form.finish]);
      formData.append('intervals', intervalsData);
    }
  
    if (form.file instanceof File) {
      formData.append('file', form.file, form.file.name);
    }
  
    return formData;
  }

  private initForm(): void {
    this.form = new FormGroup({
      id: new FormControl(null),
      title: new FormControl('', [Validators.required]),
      trainer: new FormControl(null, [Validators.required]),
      category: new FormControl(null, [Validators.required]),
      target: new FormControl(null,[Validators.required]),
      file: new FormControl(null),
      online: new FormControl(null, [Validators.required]),
      date: new FormControl(null),
      start: new FormControl(null),
      finish: new FormControl(null),
      link: new FormControl(''),
      isBooking: new FormControl(false),
      reservation: new FormControl(null),
      contest: new FormControl(null),
      number_participants: new FormControl(null)
    })
  }

  private handleCallback(): void {
    this.dialogData.resCall();
  }

  private successDialog(text?: string): void {
    const title = text ? text : 'Мероприятие успешно создано';
    const confirmButtonText = 'ОК';

    this.dialogService.openDialog(AlertDialogComponent, { title, confirmButtonText,  confirmCall: this.handleCallback.bind(this)})
      .pipe(
        takeUntil(this.destroyRef)
      )
      .subscribe(() => {
        this.dialogData.callback();

        this.matDialogService.closeAll()
      })
  }

  private errorDialog(text?: string): void {
    const title = 'Произошла ошибка';
    const confirmButtonText = 'ОК';

    this.dialogService.openDialog(AlertDialogComponent, { title, text, confirmButtonText })
  }

  private checkFormat(): boolean {
    const onlineFields = ['date', 'start', 'finish'];
    const offlineFields = ['reservation'];
    const format = this.form.get('online').value;
    const isBooking = this.checkboxForm.value;
    
    if(format || isBooking ) {
      let checker = onlineFields.map(v => this.form.get(v).value).filter(f => f === null);
      
      return checker.length === 0
    } else {
      const checker = offlineFields.map(v => this.form.get(v).value).filter(f => f === null);

      return checker.length === 0
    }

  }

  findReservation(id: number): void {
    this.selectedReservation = this.reservations.find(v => v.id === id);
  }

  onChangeFile($event: any): void {
    const inputElement = $event.target as HTMLInputElement;

    if (inputElement.files && inputElement.files.length > 0) {
        this.fileName = inputElement.files[0].name;
       
        this.form.patchValue({file: inputElement.files[0]});
        this.form.markAsDirty();
    }
}

  removeFile(): void {
    this.form.patchValue({file: null})
  }

  startValueChange(): void {
    this.form.patchValue({ finish: null });
  }

  onFormatChange(): void {
    this.form.patchValue({date: null, start: null, finish: null, link: '', reservation: null, contest: false});
    this.checkboxForm.setValue(false);

    this.selectedReservation = null;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  submit() {
    if(this.isEdit) {
      this.onUpdate();
    } else {
      this.onCreate();
    }
  }

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
  
    // Удаляем все символы, кроме цифр
    const numericValue = inputValue.replace(/\D/g, '');
  
    // Добавляем символ "+" в начало
    const newValue = '+' + numericValue;
  
    // Обновляем значение в поле ввода
    inputElement.value = newValue;
  
    // Обновляем модель
    this.form.patchValue({phone: newValue});
  }

  onBlurInput(): void {
    if(this.form.get('phone').value.length < 2) {
      this.form.patchValue({phone: ''})
    }
  }

  onCreate(): void {
    if (this.form.valid && this.checkFormat()) {
      this.createEventRes(this.buildFinalData())
        .pipe(
          takeUntil(this.destroyRef)
        )
        .subscribe({
          next: _ =>  this.successDialog(),
          error: e => {
            const error = e.error?.error[0];
            
            this.errorDialog(error);
          }
        })
    } else {
      const emailControl = this.form.get('email');
      const emailError = emailControl && emailControl.hasError('email');
      const requiredFields = ['title', 'trainer', 'category', 'online'];
      const missingFields = requiredFields.filter(field => this.form.get(field).hasError('required'));
  
      if (emailError && !missingFields.length) {
        this.errorDialog('Неверная почта');
      }

      if (missingFields.length > 0 && emailError) {
        this.errorDialog('Неверная почта. Заполните все поля.');
      }  

      if(!emailError) {
        this.errorDialog('Заполните все поля.');
      }
    }
  }

  onUpdate(): void {
    if(!this.form.pristine || !this.checkboxForm.pristine) {
      if(this.form.valid && this.checkFormat()) {
        this.updateEventRes(this.buildFinalData())
        .pipe(
          takeUntil(this.destroyRef)
        )
        .subscribe({
          next: _ =>  this.successDialog('Мероприятие успешно изменено'),
          error: e => {
            const error = e.error?.error[0];

            this.errorDialog(error)
          }
        })
      } else {
        const emailControl = this.form.get('email');
          const emailError = emailControl && emailControl.hasError('email');
          const requiredFields = ['title', 'trainer', 'category', 'online'];
          const missingFields = requiredFields.filter(field => this.form.get(field).hasError('required'));
      
          if (emailError && !missingFields.length) {
            this.errorDialog('Неверная почта');
          }
  
          if (missingFields.length > 0 && emailError) {
            this.errorDialog('Неверная почта. Заполните все поля.');
         }  
  
          if(!emailError) {
            this.errorDialog('Заполните все поля.');
          }
      }
    } else {
      this.matDialogService.closeAll();
    }
  }

  onRemove(id: number): void {
    const title = 'Вы действительно хотите удалить?';
    this.dialogService.openDialog(AlertDialogComponent, {title, confirmButtonText: 'Да', notButtonText: 'Нет', confirmCall: this.handleCallback.bind(this)})
    .pipe(
      filter(v => v),
      takeUntil(this.destroyRef)
    )
    .subscribe({
      next: _ => {
        this.removeRes(id)
        .subscribe({
          next: _ => {
            this.dialogData.callback();

            this.matDialogService.closeAll();
          }
        })
      }
    })
  }
}
