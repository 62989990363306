import {Component, Input, OnInit, HostListener, OnDestroy, ViewChild} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DialogService } from 'services';
import { AlertDialogComponent } from 'components/dialogs';
import SwiperCore, { Zoom, FreeMode, Thumbs, Swiper } from "swiper";
import {BaseService} from "services/api";
import { AppHttpContext } from 'core/api'
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Zoom, FreeMode, Thumbs]);

@Component({
  selector: 'app-content-blocks',
  templateUrl: './content-blocks.component.html',
  styleUrls: ['./content-blocks.component.scss'],
})

export class ContentBlocksComponent implements OnInit, OnDestroy {
  @ViewChild('swiper') swiper: SwiperComponent;
  @ViewChild('#swiperSmall') swiperSmall: SwiperComponent;

  thumbsSwiper: any;

  inactive = false;

  open = false;

  @Input() arrayContent = [];

  constructor(
    private http: HttpClient,
    private baseService: BaseService,
    private dialogService: DialogService,
  ) {}

  getThumbs(event, i) {
    setTimeout(() => {
      return this.thumbsSwiper = [{
        event: event,
        index: i
      }]
    })
  }

  goFile(file, length, titleBlock) {
    if (file.file.split('?')[0].split('.').pop() === 'pdf') {
      window.open(file.file, '_blank');
    } else {
      // location.assign(file.file)
      if (!this.inactive) {
        this.inactive = true;
        this.http.get(
          file.file,
          {
            responseType: 'blob',
            observe: 'response',
            context: new AppHttpContext().unsigned()
          }).subscribe(response => {

            // file-type
            let fileName: string;

            if (length > 1) {
              fileName = file.title + '.' + file.file.split('?')[0].split('.').pop();
            } else {
              fileName = file.title + '.' + file.file.split('?')[0].split('.').pop();
            }

            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(response.body);
            link.download = fileName;
            link.click();
            link.remove();
            this.inactive = false;
          }, () => {
            const data = {
              'title': 'Произошла ошибка, попробуйте ещё раз.',
              'confirmButtonText': 'OK'
            }
            this.inactive = false;
            this.dialogService.openDialog(AlertDialogComponent, data);
          });
      }
      return;
    }
  }

  showChildren($element) {
    if (document.getElementById($element)) {
      if (document.getElementById($element).classList.contains('no_show')) {
        document.getElementById($element).classList.remove('no_show');
      } else {
        document.getElementById($element).classList.add('no_show');
      }
    }
    return;
  }

  startScorm(block: any) {
    this.arrayContent.map((item) => {
      if (item.id === block.id) {
        item.active = true
        item.wasActive = true
        this.baseService.baseViewScorm(block.id, 'start').subscribe()
      } else {
        item.active = false
      }
      if (item.id !== block.id && item.wasActive && !item.active) {
        delete item.wasActive
        this.baseService.baseViewScorm(item.id, 'finish').subscribe()
      }
    })
  }

  @HostListener('document:visibilitychange')
  unloadHandler() {
    if (document.hidden) {
      this.arrayContent.forEach((item) => {
        if (item.type === 'scorm' && item.active) {
          this.baseService.baseViewScorm(item.id, 'finish').subscribe()
        }
      })
    } else {
      this.arrayContent.forEach((item) => {
        if (item.type === 'scorm' && item.active) {
          this.baseService.baseViewScorm(item.id, 'start').subscribe()
        }
      })
    }
  }

  swiperNext(): void {
    this.swiper?.swiperRef.slideNext(400);
  }

  swiperPrev(): void {
    this.swiper?.swiperRef.slidePrev(400);
  }

  swiperSmallNext(): void {
    this.swiperSmall?.swiperRef.slideNext(400);
  }

  swiperSmallPrev(): void {
    this.swiperSmall?.swiperRef.slidePrev(400);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.arrayContent.forEach((item) => {
      if (item.type === 'scorm' && item.active) {
        this.baseService.baseViewScorm(item.id, 'finish').subscribe()
      }
    })
  }
}
