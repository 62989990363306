<div class="custom_dialog game_moderator" *ngIf="!extraService.gamePaused">
  <div class="container">
    <form class="question_form" [formGroup]="form" enctype="multipart/form-data">
      <div class="question_container">
        <div class="question_text">{{question.text}}</div>

        <div class="question">
          <ng-container *ngIf="question.type === 'radio'">
            <mat-radio-group class="options-group" formControlName="{{question.id}}">
              <mat-radio-button
                value="{{option.id}}"
                translate="no"
                class="options-button"
                *ngFor="let option of question.options; let i = index"
              >
                <span [innerHTML]="option.text | safeHtml"></span>
              </mat-radio-button>
            </mat-radio-group>
          </ng-container>

          <ng-container *ngIf="question.type === 'checkbox'">
            <div class="options-group" formArrayName="{{question.id}}">
              <mat-checkbox
                formControlName="{{i}}"
                value="{{option.id}}"
                translate="no"
                class="options-button"
                *ngFor="let option of question.options; let i = index"
              >
                <span [innerHTML]="option.text | safeHtml"></span>
              </mat-checkbox>
            </div>
          </ng-container>

          <ng-container *ngIf="question.type === 'sort'">
            <div cdkDropList class="options-group" (cdkDropListDropped)="drop($event, question.id, question.options)">
              <div
                translate="no"
                cdkDrag
                class="options-button options-DropList"
                *ngFor="let option of question.options; let i = index"
              >
                <span class="number">{{i + 1}}</span>
                <span class="text" [innerHTML]="option.text | safeHtml"></span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="question.type === 'drop'">
            <div class="image_drop_container" cdkDropListGroup>
              <div class="item_container" cdkDropList>
                <div *ngFor="let option of options" class="item" cdkDrag>
                  <img class="image" [src]="option.image" alt="">
                </div>
              </div>

              <div class="box_container">
                <div
                  *ngFor="let box of boxes; index as boxIndex"
                  class="box"
                  cdkDropList
                  (cdkDropListDropped)="dropImage($event, boxIndex)"
                >
                  <img class="image" [src]="box.image" alt="">
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="container_button_test">
<!--          <div class="button_test" (click)="pause()">сделать паузу</div>-->
          <div class="button_test" (click)="notAnswer()">не знаю</div>
          <div class="button_test" [ngClass]="{hidden: !isValid}" (click)="sendAnswer()">ответить</div>
        </div>
      </div>
    </form>
  </div>
</div>
