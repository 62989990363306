<app-header></app-header>

<div *ngIf="banners && (section$ | async)" class="outer" [@show_preview]="showPrev">
  <div class="workspace main">
    <swiper
      class="custom_swiper"
      style="--swiper-navigation-color: #000;--swiper-pagination-color: #000"
      [autoplay]="true"
      [navigation]="false"
      [pagination]="{clickable: true}"
    >
      <ng-template swiperSlide *ngFor="let banner of banners">
        <div
          class="swiper_image"
          [ngStyle]="{cursor: banner.link !== null ? 'pointer' : 'default'}"
          (click)="goToLink(banner.link)"
        >
          <img [src]="banner.images[bannersType]" alt="">
        </div>
      </ng-template>
    </swiper>


    <div class="section_container">

      <div class="section_container_side left">
        <div class="md two">
          <div class="section_button sk_1" (click)="onSection('assessment')">
            <div *ngIf="showCounter('assessment') > 0" class="counter"><span>NEW:</span> {{showCounter('assessment')}}</div>
            Оценка
          </div>
          <div class="section_button" (click)="onSection('materials')">
            <div *ngIf="showCounter('materials') > 0" class="counter"><span>NEW:</span> {{showCounter('materials')}}</div>
            Важно
          </div>
        </div>
        <div class="md one">
          <div class="section_button" (click)="goPage('learning')">
            <!-- <div class="counter" ><span>NEW:</span> 67</div> -->
            Учись
          </div>
        </div>
        <div class="md two">
          <div class="section_button" (click)="onSection('video')">
            <div *ngIf="showCounter('video') > 0" class="counter"><span>NEW:</span> {{showCounter('video')}}</div>
            Новичок
          </div>
          <div class="section_button sk_3" (click)="onSection('news')">
            <div *ngIf="showCounter('news') > 0" class="counter"><span>NEW:</span> {{showCounter('news')}}</div>
            Новости
          </div>
        </div>
      </div>

      <div class="section_container_side right">
        <div class="section_button" (click)="onWelcomeBook('welcome-book/preview')">
          welcome book
        </div>
        <div class="section_button" (click)="onSection('faq')">
          <div *ngIf="showCounter('faq') > 0" class="counter"><span>NEW:</span> {{showCounter('faq')}}</div>
          Академия
        </div>
      </div>
  </div>
</div>
