import { Component, Input, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { ProductInfo, TabType } from 'core/model/catalog-tab';
import { Observable } from 'rxjs';
import { CatalogsTabService } from 'services/api/catalogs/catalogs-tab.service';

@Component({
  selector: 'app-catalogs-tab',
  templateUrl: './catalogs-tab.component.html',
  styleUrls: ['./catalogs-tab.component.scss']
})
export class CatalogsTabComponent implements OnInit {
  @Input() productInfoList: ProductInfo[] = [];
  @Input() product_id: number;

  online$: Observable<boolean>;
  offline$: Observable<boolean>;
  filteredData$: Observable<any[]>
  disabled$: Observable<boolean>;

  activeTab: number = 0;
  
  tabModel: { [key in TabType]: TabType } = {
    [TabType.Description]: TabType.Description,
    [TabType.Ingredients]: TabType.Ingredients,
    [TabType.Composition]: TabType.Composition,
    [TabType.Application]: TabType.Application,
    [TabType.Sales]: TabType.Sales,
    [TabType.Questions]: TabType.Questions,
    [TabType.Documents]: TabType.Documents,
    [TabType.Reviews]: TabType.Reviews,
  };

  constructor(private router: Router, public catalogsTabService: CatalogsTabService) { }

  ngOnInit(): void {
    this.online$ = this.catalogsTabService.online$;
    this.offline$ = this.catalogsTabService.offline$;
    this.filteredData$ = this.catalogsTabService.filteredData$;
    this.disabled$ = this.catalogsTabService.disabled$;
  }

  onChangeTab(index: number): void {
    this.activeTab = index;
  }

  onSale(info:any) {
    this.router.navigate( [ 'catalogs/' + info.folder + '/list/' + info.catalog + '/card/' +  info.id] );
  }

}
