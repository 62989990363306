<app-header></app-header>

<div class="outer" [ngStyle]="{'backgroundImage': 'url(' + extraService.backgroundTest + ')'}">
  <div class="workspace">

    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>Назад</span>
        </div>
      </div>
    </div>

    <div class="text" [innerHTML]="text | safeHtml"></div>

    <div class="button" (click)="goTest()">Начать</div>

  </div>
</div>
