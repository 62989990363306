<div class="content_catalogs">
  <div class="bread_and_title_container">
    <div class="bread_and_title">
      <div class="page_title" (click)="goBack()">
        <div class="icon back"></div>
        <span>Каталог</span>
      </div>
    </div>
  </div>

  <div class="tabs">
    <div class="tab" [ngClass]="{active: tab === 1}" (click)="tabOn(1)">L`Oreal Paris</div>
    <div class="tab" [ngClass]="{active: tab === 2}" (click)="tabOn(2)">Maybelline NY</div>
    <div class="tab" [ngClass]="{active: tab === 3}" (click)="tabOn(3)">NYX Professional Makeup</div>
    <div class="tab" [ngClass]="{active: tab === 4}" (click)="tabOn(4)">Garnier</div>
    <div class="tab" [ngClass]="{active: tab === 5}" (click)="tabOn(5)">Каталоги для скачивания</div>
  </div>

  <div *ngIf="catalogs">

    <ng-container *ngFor="let card of catalogs">
      <div class="folder" (click)="openCatalog(card.uid)">
        <div class="image" [ngStyle]="{backgroundImage: 'url(' + filesMny + '' + card.image + ')'}"></div>
        <div class="title">{{card.title}}</div>
        <div class="description" *ngIf="!!card.description" [innerHTML]="card.description | safeHtml"></div>
      </div>
    </ng-container>

  </div>

  <div class="pdf_body" *ngIf="pdf">

    <div (click)="backFolderPdf()" class="back" *ngIf="pdf['folders'].length === 0 ">Назад</div>

    <ng-container *ngFor="let data of pdf['folders']">
      <div class="folder" (click)="openFolderPdf(data.id)">
        <div class="image" [ngStyle]="{backgroundImage: 'url(' + data.preview + ')'}"></div>
        <div class="title">{{data.title}}</div>
      </div>
    </ng-container>

    <ng-container *ngFor="let data of pdf['documents']">
      <div class="pdf">
        <div class="image" [ngStyle]="{backgroundImage: 'url(' + (data.preview ? (data.preview) : '/assets/images/icons/type-doc-icons/pdf_icon.png') + ')'}"></div>
        <a target="_blank" href="{{data.file}}" class="title">{{data.title}}</a>
      </div>
    </ng-container>

    <div class="center" *ngIf="pdf['folders'].length === 0 && pdf['documents'].length === 0 ">Кталогов нет</div>

  </div>
  <!-- <div *ngIf="tab === 'nyx'">
    <ng-container>
      <div class="folder" (click)="openCatalog(null, tab)">
        <div class="image" [ngStyle]="{backgroundImage: 'url(assets/images/nyx_catalog.png)'}"></div>
        <div class="title">NYX Professional Makeup</div>
      </div>
    </ng-container>
  </div> -->
</div>
