export enum RootSectionPath {
  ecoPortal = 'eco-portal'
}

export enum EcoPortalSectionPath {
  home = 'home',
  planet = 'planet',
  kpi = 'kpi',
  gallery = 'gallery',
  news = 'news',
  profile = 'profile',
  tests = 'tests',
  // test = 'test'
}
