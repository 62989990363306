import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
  @Input() value: boolean;
  @Input() label: string;
  @Input() isGreen: boolean = true;
  @Output() onChangeValue = new EventEmitter<boolean>();

  onChange(value: boolean): void {
    this.onChangeValue.emit(value);
  }
}
