<app-header [logoWhite]="true"></app-header>

<div class="outer" [ngStyle]="{'backgroundImage': 'url(assets/images/game/bg_game_2_mobile.png)'}">
  <div class="workspace">

    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>BE IN TOUCH</span>
        </div>
      </div>
    </div>

    <div class="text" >{{description}}</div>

    <div class="container_question">

      <div class="timer">{{timeleft | msToTime}}</div>

      <div class="container_review">
        <div class="avatar" [ngStyle]="{'backgroundImage': 'url(/assets/images/icons/avatar.png)'}"></div>
        <div class="container_review box">
          <div class="review_header">
            <div class="review_text bold">Анонимный отзыв</div>
            <div class="container_circle">
              <div class="circle_block">
                <div
                  class="circle"
                  [ngClass]="{purple: (counter$ | async) === i }"
                  *ngFor="let circle of circleArr; let i = index">
                </div>
              </div>
              <div class="circle_text">{{(counter$ | async) + 1 + '/' + circleArr.length}}</div>
            </div>
          </div>
          <div class="review_text">
            {{question?.text}}
          </div>
        </div>
      </div>

      <div class="container_review question">
        <div class="question_box" *ngFor="let option of question?.options">
          <div class="question_body" (click)="addAnswers(option.id)">
            <div class="question_header">
              <div class="question_circle" [ngClass]="{active: options.has(option.id)}"></div>
              <div class="review_text question">{{option?.text}}</div>
            </div>
            <div class="question_image">
              <img [src]="option.image" loading="lazy">
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="container_button_test">
      <div class="button_test" (click)="submit()">{{lang === 'ru' ? 'завершить' : 'end'}}</div>
      <div class="button_test" (click)="pause()">{{lang === 'ru' ? 'сделать паузу' : 'pause'}}</div>
      <ng-container *ngIf="!isNext">
        <div class="button_test" (click)="onNext()">{{lang === 'ru' ? 'дальше' : 'next'}}</div>
      </ng-container>
    </div>

  </div>
</div>
