import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'colorDay',
})
export class ColorDayPipe implements PipeTransform {
  transform(day: number, date: any): any {
    return moment(date.format('M') + '/' + `${day}/` + date.format('YYYY')).locale('ru').isoWeek() === date.isoWeek()
  }
}
