import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { ApiService, AppHttpContext } from 'core/api'
import { User } from 'core/auth'
import { StorageService } from '../../storage.service'
import { createAuthUrls } from './auth-urls'

const tokenKey = 'token'

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ApiService {
  private readonly urls = createAuthUrls()

  constructor(http: HttpClient, private readonly storage: StorageService) {
    super(http)
  }

  get token(): string {
    return this.storage.getValue(tokenKey)
  }

  saveNewTokens(response: any) {
    let resultToken = false

    if (response.token !== undefined) {
      this.storage.setValue(tokenKey, response.token)
      this.storage.setValue('token-management', '')
      resultToken = true
    } else {
      console.error('Токен авторизации не пришёл.')
    }

    return resultToken
  }

  validateToken() {
    return this.http.post(this.urls.validateToken, null)
  }

  signIn(email: string, password: string) {
    return this.http.post(this.urls.signIn, {email, password}, {context: new AppHttpContext().unsigned()})
  }

  signOut() {
    return this.http.post(this.urls.logOut, null)
  }

  signUp(data) {
    return this.http.post(this.urls.registration, data)
  }

  resetPassword(email: string) {
    return this.http.post(this.urls.resetPassword, {email}, {context: new AppHttpContext().unsigned()})
  }

  authGetRules(language_id: string) {
    return this.http.post(this.urls.getRules, {language_id})
  }

  /**
   * Gets user data.
   *
   * @param id
   */
  getUser(id: number | null = null): Observable<User> {
    return this.http.post<User>(this.urls.getUser, {id})
  }

  /**
   * Updates user data.
   *
   * @param userData
   */
  updateUser(userData: Partial<User> | FormData): Observable<void> {
    return this.http.post<void>(this.urls.updateUser, userData)
  }

  authResetUserPassword(old_password: string, new_password: string) {
    return this.http.post(this.urls.resetUserPassword, {old_password, new_password})
  }

  authGetParams() {
    return this.http.post(this.urls.getParams, null)
  }

  authGetTeamUsers(limit: number, offset: number, filters?) {
    // TODO: Reduce to one return statement.
    if (filters) {
      return this.http.post(this.urls.getTeamUsers, {limit, offset, filters})
    }

    return this.http.post(this.urls.getTeamUsers, {limit, offset})
  }

  getForm() {
    return this.http.post(this.urls.getForm, null)
  }

  getMessageRef(): Observable<any> {
    return this.http.post(this.urls.message + 'congratulation/', {language_id: 'ru'})
  }
}
