import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComplimentVideoComponent } from './compliment-video.component';
import { IconLoaderModule } from 'app/shared/components/icon-loader/icon-loader.module';
import { MatButtonModule } from '@angular/material/button';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';



@NgModule({
  declarations: [
    ComplimentVideoComponent
  ],
  imports: [
    CommonModule,
    IconLoaderModule,
    MatButtonModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
  ]
})
export class ComplimentVideoModule { }
