import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

import { BaseService, GameModeratorService, StorageService } from 'services'

@Component({
  selector: 'app-game-moderator-purchase-list-dialog',
  templateUrl: 'game-moderator-purchase-list-dialog.component.html',
  styleUrls: ['game-moderator-purchase-list-dialog.component.scss']
})
export class GameModeratorPurchaseListDialogComponent implements OnInit {
  lang = ''
  offset = 0
  limit = 5
  purchaseList: any[] | null = null
  endScroll = false

  constructor(
    private dialogRef: MatDialogRef<any>,
    private storageService: StorageService,
    private gameModerService: GameModeratorService,
    private baseService: BaseService
  ) {}

  ngOnInit(): void {
    this.gameModerService.getLang()
    this.lang = this.storageService.getValue('game_moder_lang')

    this.baseService.basePurchaseList(this.limit, this.offset).subscribe((response: any) => this.purchaseList = response)
  }

  paginationOn(): void {
    this.offset += this.limit

    this.baseService.basePurchaseList(this.limit, this.offset).subscribe((response: any) => {
      Array.prototype.push.apply(this.purchaseList, response)

      if (response.length < this.limit) {
        this.endScroll = true
      }
    })
  }

  onConfirm(): void {
    this.dialogRef.close(true)
  }
}
