import { Component, OnInit, OnDestroy } from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import { BaseService } from "services/api";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {DialogService, ExtraService} from "services";
import {AlertDialogComponent} from "components/dialogs";

interface ITestInfo {
  attempt_id: number | undefined,
  questions: any,
  time: number | undefined,
  title: string | undefined,
  show_rights: boolean | undefined
}

@Component({
  selector: 'app-game-test',
  templateUrl: 'game-test.component.html',
  styleUrls: ['game-test.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class GameTestComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private location: Location,
    private baseService: BaseService,
    private dialogService: DialogService,
    public extraService: ExtraService
  ) {
  }

  showPrev = 'show';

  brand: string;
  gameId: number;

  testInfo: ITestInfo | undefined
  questionIndex = 0;
  totalQuestions = 0;
  question: any;
  answers = [];

  form: FormGroup = this.formBuilder.group({});

  blockSend = false; // Блокировка отправки ответов
  errors = {}; // Неотвеченные вопросы

  // Таймер
  timeOut = false;
  time = 60;
  timeleft = this.time;
  timer = 0;
  startMoment = 0;
  endMoment = this.startMoment + this.timeleft * 1000;

  initiateTimer = false;
  anywaySubmit = false;

  goBack() {
    this.location.back()
  }

  initCountdown() {
    this.initiateTimer = true;
    this.startMoment = Date.now();
    this.endMoment = this.startMoment + this.timeleft * 1000;
    this.timer = window.setInterval(() => {
      const now = Date.now();
      this.timeleft = Math.round((this.endMoment - now) / 1000);
      if (this.timeleft <= 0) {
        clearInterval(this.timer);
        this.timeleft = this.time;
        this.timeOut = true;
        this.submit(this.answers);
      }
    }, 1000);
  }

  makeFormGroup(form: any) {
    const group = {};
    switch (form.type) {
      case 'radio':
        group[form.id] = [null, Validators.required];
        break
      case 'checkbox':
        group[form.id] = this.formBuilder.array(
          form.options.map(() => this.formBuilder.control(false)),
        );
        break
      case 'sort':
        group[form.id] = [form.options.map((arr: any) => arr.id), Validators.required];
        break
      case 'open':
        group[form.id] = ['', Validators.required];
        break
      case 'input':
        group[form.id] = ['', Validators.required];
        break
      case 'rating':
        group[form.id] = ['', Validators.required];
        break
    }
    this.form = this.formBuilder.group(group);
  }

  drop(event: CdkDragDrop<string[]>, controlName: any, arr: any) {
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
    this.form.get(String(controlName)).setValue(arr.map((inf: any) => inf.id));
  }

  saveAnswer() {
    if (!this.form.valid) {
      this.errors[this.question.id] = true;
      return;
    }
    this.questionIndex += 1;
    if (this.questionIndex > this.totalQuestions - 1) {
      this.answers.push({
        question_id: this.question.id,
        options: this.form.value[this.question.id],
        type: this.question.type,
        index: this.questionIndex
      })
      this.submit(this.answers)
      return
    }
    this.answers.push({
      question_id: this.question.id,
      options: this.form.value[this.question.id],
      type: this.question.type,
      index: this.questionIndex
    })
    this.question = this.testInfo.questions[this.questionIndex]
    this.makeFormGroup(this.question)
  }

  submit(answers) {
    clearInterval(this.timer)
    let attemptInfo
    let dialogData = {
      title: '',
      confirmButtonText: 'OK'
    }
    if (!answers.length) {
      dialogData.title = 'Произошла ошибка'
      this.dialogService.openDialog(AlertDialogComponent, dialogData).subscribe(() => {
        this.goBack()
      })
      return
    }
    if (this.timeOut) {
      dialogData.title = 'Время вышло!'
      this.dialogService.openDialog(AlertDialogComponent, dialogData).subscribe(() => {
        this.goBack()
      })
      return
    }
    answers.map((item) => {
      switch (item.type) {
        case 'radio':
          item.options = [+item.options]
          break
        case 'checkbox':
          let check = []
          item.options.map((i, index) => {
            if (i) {
              check.push(this.testInfo.questions[item.index - 1].options[index].id)
              item.options = check
            }
          })
          break
      }
      delete item.index
      delete item.type
    })
    const answersGame = this.answers.filter((answer) => answer.options.length && answer.options[0])
    attemptInfo = {
      attempt_id: this.testInfo.attempt_id,
      answers: answersGame
    }

    this.baseService.baseTestAttemptFinish(attemptInfo).subscribe((response: any) => {
      if (response.success) {
        dialogData.title = 'Миссия пройдена!'
      } else {
        dialogData.title = 'Миссия не пройдена!'
      }
      this.dialogService.openDialog(AlertDialogComponent, dialogData).subscribe(() => {
        this.router.navigate([`/game/${this.gameId}/${this.brand}/stages`])
      })
    })
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.gameId = params.id
      this.brand = params.brand
      this.baseService.baseTestAttemptStart(params.testId).subscribe((response: any) => {
        this.testInfo = response
        this.totalQuestions = this.testInfo.questions.length
        this.question = this.testInfo.questions[this.questionIndex]
        if (this.testInfo.time) {
          this.time = +this.testInfo.time;
          this.timeleft = this.time * 60;
          this.initCountdown();
        }
        this.makeFormGroup(this.question)
      }, () => {
        this.goBack()
      })
    })
  }

  ngOnDestroy() {
    clearInterval(this.timer)
  }

}
