<div class="header_osn"
  [ngClass]="{
    'show_user': showUser,
    'auth': template === 'auth',
    'white': color === 'white',
    'fixed': fixed,
    'custom': color === 'custom'
  }">
  <div class="logo_container">
    <app-icon-loader [name]="'logo'" class="svg_logo" [ngClass]="{white: logoWhite}" (click)="goMain()"></app-icon-loader>
  </div>

  <div class="personal" *ngIf="(user$ | async) && showUser">
    <div class="user_container" (click)="goPersonal()">
      <div class="avatar" [ngStyle]="{'backgroundImage': 'url(/assets/images/icons/avatar.png)'}"></div>
    </div>
  </div>

  <div class="logout" (click)="logout()" *ngIf="showBack"></div>

  <div class="burger_container" (click)="openMenu()" *ngIf="showBack">
    <div class="burger" [ngClass]="{'close': showBurgerMenu}"></div>
  </div>
</div>

<div class="menu-wrapper"  *ngIf="showBurgerMenu">
  <div class="menu_mobile">
    <div class="section_container">
      <div *ngIf="user$ | async as user">
        <div class="user_container">
          <div class="avatar" [ngStyle]="{'backgroundImage': 'url(/assets/images/icons/avatar.png)'}"></div>
          <div>
            <div class="name">
              {{user.first_name}}<br />
              {{user.last_name}}
            </div>
            <div class="button" (click)="goPersonal()">
              <span class="ar"></span>Перейти в профиль
            </div>
          </div>
        </div>
      </div>
  
      <div class="section_button" (click)="goMain()">
        <span>ГЛАВНАЯ</span>
      </div>
  
      <div class="section_button" (click)="goLearning()">
        <span>УЧИСЬ</span>
      </div>
  
      <div class="section_button" (click)="onSection('assessment')">
        <span>ОЦЕНКА</span>
        <div *ngIf="showCounter('assessment') > 0" class="counter"><span>NEW:</span> {{showCounter('assessment')}}</div>
      </div>
  
      <div class="section_button" (click)="onSection('materials')">
        <span>ВАЖНО</span>
        <div *ngIf="showCounter('materials') > 0" class="counter"><span>NEW:</span> {{showCounter('materials')}}</div>
      </div>
  
      <div class="section_button" (click)="onSection('video')">
        <span>НОВИЧОК</span>
        <div *ngIf="showCounter('video') > 0" class="counter"><span>NEW:</span> {{showCounter('video')}}</div>
      </div>
  
      <div class="section_button" (click)="onSection('news')">
        <span>НОВОСТИ</span>
        <div *ngIf="showCounter('news') > 0" class="counter"><span>NEW:</span> {{showCounter('news')}}</div>
      </div>
  
      <div class="section_button" (click)="onSection('faq')">
        <span>АКАДЕМИЯ</span>
        <div *ngIf="showCounter('faq') > 0" class="counter"><span>NEW:</span> {{showCounter('faq')}}</div>
      </div>
  
      <div class="logout_container">
        <div class="logout" (click)="logout()" *ngIf="showBack">Выход</div>
      </div>
    </div>
  </div>
</div>

