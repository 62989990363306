import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import {
  AuthComponent,
  MainComponent,
  ContestsComponent,
  ImportantComponent,
  LearningComponent,
  NewsComponent,
  VideoComponent,
  FolderComponent,
  DocumentComponent,
  FinalTestComponent,
  AttemptsComponent,
  CatalogsComponent,
  CatalogsOldComponent,
  GameComponent,
  GameModeratorComponent
} from 'components/pages'

import {
  ImportantListComponent
} from 'components/pages/important'

import {
  VideoListComponent
} from 'components/pages/video'

import {
  ArchiveContestsComponent
} from 'components/pages/contests'

import {
  LearningHomeComponent,
  NewestComponent,
  TrainingsComponent,
  TrendsComponent,
  MarketingComponent,
  MakeupComponent
} from 'components/pages/learning'

import {
  TrainingsListComponent
} from 'components/pages/learning/trainings'

import {
  NewestListComponent
} from 'components/pages/learning/newest'

import {
  MarketingListComponent
} from 'components/pages/learning/marketing'

import {
  CatalogsMainComponent,
  CatalogsListComponent,
  CatalogsCardComponent,
  CatalogsComparisonComponent,
  SubfolderDocumentListComponent
} from 'components/pages/catalogs'

import {
  CatalogsOldListComponent,
  OapMblCatalogComponent,
  NyxCatalogComponent
} from 'components/pages/catalogs_old'

import {
  OapMblCatalogCardComponent
} from 'components/pages/catalogs_old/oap-mbl-catalog'

import {
  NyxCatalogSectionComponent,
  NyxCatalogHomeComponent,
  NyxCatalogCardComponent
} from 'components/pages/catalogs_old/nyx-catalog'

import {
  GameStagesComponent,
  GameStageComponent,
  GameTestComponent,
  GameDropImageComponent,
  GamePhotoComponent,
  GameDropWordComponent
} from 'components/pages/game'

import {
  GameModeratorRulesComponent,
  GameModeratorStagesComponent,
  GameModeratorCellsComponent,
  GameModeratorTestComponent,
  GameModeratorStoreComponent, GameModeratorLiteracyTestComponent
} from 'components/pages/game-moderator'

import { BasketComponent, PreviewComponent, StoreComponent } from 'components/pages/game-moderator/game-moderator-store'
import { PreviewTestComponent } from 'components/pages/game-moderator/game-moderator-test'
import { CellsPreviewComponent } from 'components/pages/game-moderator/game-moderator-cells'
import { LiteracyTestPreviewComponent } from 'components/pages/game-moderator/game-moderator-literacy-test/literacy-test-preview/literacy-test-preview.component'

import { ForkPageComponent } from 'components/pages/fork/fork-page.component'
import { RootSectionPath } from 'core/navigation'

import { AuthGuard, InternalUserGuard } from './guards'
import { RootRoute } from 'app/constants/root-routes'
import { BaseLayoutComponent } from 'app/layout/base-layout/base-layout.component'
import { PersonalRoute } from 'app/constants/personal-routes'
import { WelcomeLayoutComponent } from 'app/layout/welcome-layout/welcome-layout.component'
import { FolderResolver } from 'core/resolvers/folder.resolver'
import { DocumentResolver } from 'core/resolvers/document.resolver'
import { CatalogsFaqComponent } from 'components/pages/catalogs/catalogs-faq/catalogs-faq.component'
import { CatalogsComparingComponent } from 'components/pages/catalogs/catalogs-comparing/catalogs-comparing.component'


const routes: Routes = [
  {path: '', redirectTo: '/fork', pathMatch: 'full'},
  {
    path: 'error',
    loadChildren: () => import('../error-page/error-page.module').then(m => m.ErrorPageModule)
  },
  {path: 'auth', component: AuthComponent},
  {path: 'fork', component: ForkPageComponent, canActivate: [AuthGuard]},
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard, InternalUserGuard]
  },
  {
    path: 'contests',
    component: ContestsComponent,
    canActivate: [AuthGuard, InternalUserGuard]
  },
  {
    path: 'contests/archive/:id',
    component: ArchiveContestsComponent,
    canActivate: [AuthGuard, InternalUserGuard]
  },
  {
    path: 'important',
    component: ImportantComponent,
    canActivate: [AuthGuard, InternalUserGuard],
    canActivateChild: [AuthGuard, InternalUserGuard],
    children: [
      {path: 'important-list/:folderId', component: ImportantListComponent},
      {path: '**', redirectTo: 'main'}
    ]
  },
  {
    path: 'learning',
    component: LearningComponent,
    canActivate: [AuthGuard, InternalUserGuard],
    canActivateChild: [AuthGuard, InternalUserGuard],
    children: [
      {
        path: 'learning-home',
        component: LearningHomeComponent,
        canActivate: [AuthGuard, InternalUserGuard]},
      {
        path: 'newest',
        component: NewestComponent,
        canActivate: [AuthGuard, InternalUserGuard],
        canActivateChild: [AuthGuard, InternalUserGuard],
        children: [
          {path: 'newest-list/:folderId', component: NewestListComponent},
          {path: '**', redirectTo: 'learning-home'}
        ]
      },
      {
        path: 'trainings',
        component: TrainingsComponent,
        canActivate: [AuthGuard, InternalUserGuard],
        canActivateChild: [AuthGuard, InternalUserGuard],
        children: [
          {path: 'trainings-list/:folderId', component: TrainingsListComponent},
          {path: '**', redirectTo: 'learning-home'}
        ]
      },
      {
        path: 'trends',
        component: TrendsComponent,
        canActivate: [AuthGuard, InternalUserGuard]
      },
      {
        path: 'marketing',
        component: MarketingComponent,
        canActivate: [AuthGuard, InternalUserGuard],
        canActivateChild: [AuthGuard, InternalUserGuard],
        children: [
          {path: 'marketing-list/:folderId', component: MarketingListComponent},
          {path: '**', redirectTo: 'learning-home'}
        ]
      },
      {
        path: 'makeup',
        component: MakeupComponent,
        canActivate: [AuthGuard, InternalUserGuard]
      },
      // { path: 'loreal/:folderId', component: LorealComponent, canActivate: [ AuthGuard ] },
      {path: '**', redirectTo: 'learning-home'}
    ]
  },
  {
    path: 'news',
    canActivate: [AuthGuard, InternalUserGuard],
    component: NewsComponent
  },
  {
    path: 'video',
    component: VideoComponent,
    canActivate: [AuthGuard, InternalUserGuard],
    canActivateChild: [AuthGuard, InternalUserGuard],
    children: [
      {path: 'video-list/:folderId', component: VideoListComponent},
      {path: '**', redirectTo: 'main'}
    ]
  },
  {
    path: ':section/folder/:folderId', 
    component: FolderComponent, 
    canActivate: [AuthGuard, InternalUserGuard],
    resolve: {
        folderData: FolderResolver
    }
  },
  {
    path: ':section/document/:docType/:id', 
    component: DocumentComponent, 
    canActivate: [AuthGuard, InternalUserGuard],
    resolve: {
      documentData: DocumentResolver
    }
  },
  {path: 'final-test/:id', component: FinalTestComponent, canActivate: [AuthGuard, InternalUserGuard]},
  {path: 'attempts', component: AttemptsComponent, canActivate: [AuthGuard, InternalUserGuard]},
  {
    path: 'catalogs',
    component: CatalogsComponent,
    canActivate: [AuthGuard, InternalUserGuard],
    canActivateChild: [AuthGuard, InternalUserGuard],
    children: [
      {path: ':folder_id', component: CatalogsMainComponent},
      {path: ':folder_id/list/:catalog_id', component: CatalogsListComponent},
      {path: ':folder_id/list/:catalog_id/faq', component: CatalogsFaqComponent},
      {path: ':folder_id/list/:catalog_id/card/:product_id', component: CatalogsCardComponent},
      {path: 'comparison/:folder_id/list/:catalog_id', component: CatalogsComparisonComponent},
      {path: ':folder_id/list/:catalog_id/card/:product_id/:ref', component: CatalogsComparingComponent},
      {path: 'subfolder/:folder_id/list/:subfolder_id', component: SubfolderDocumentListComponent},
      {path: '**', redirectTo: '/main'}
    ]
  },
  {
    path: 'catalogs_old',
    component: CatalogsOldComponent,
    children: [
      {path: '', component: CatalogsOldListComponent},
      {
        path: ':id/mny', component: OapMblCatalogComponent, children: [
          {path: ':product_id', component: OapMblCatalogCardComponent},
          {path: '**', redirectTo: ''}
        ]
      },
      {
        path: 'nyx', component: NyxCatalogComponent,
        children: [
          {path: '', component: NyxCatalogHomeComponent},
          {path: 'section', component: NyxCatalogSectionComponent},
          {path: 'section/card', component: NyxCatalogCardComponent},
          {path: '**', redirectTo: ''}
        ]
      }
    ]
  },
  {path: 'game/:id', component: GameComponent, canActivate: [AuthGuard]},
  {path: 'game/:id/:brand/stages', component: GameStagesComponent, canActivate: [AuthGuard]},
  {path: 'game/:id/:brand/stage/:stageId', component: GameStageComponent, canActivate: [AuthGuard]},
  {path: 'game/:id/:brand/stage/:stageId/test/:testId', component: GameTestComponent, canActivate: [AuthGuard]},
  {path: 'game/:id/:brand/stage/:stageId/drop-image', component: GameDropImageComponent, canActivate: [AuthGuard]},
  {path: 'game/:id/:brand/stage/:stageId/photo', component: GamePhotoComponent, canActivate: [AuthGuard]},
  {path: 'game/:id/:brand/stage/:stageId/drop-word', component: GameDropWordComponent, canActivate: [AuthGuard]},

  {path: 'game-moderator', component: GameModeratorComponent, canActivate: [AuthGuard]},
  {path: 'game-moderator/rules', component: GameModeratorRulesComponent, canActivate: [AuthGuard]},
  {path: 'game-moderator/stages', component: GameModeratorStagesComponent, canActivate: [AuthGuard]},
  {path: 'game-moderator/stages/cells/:id', component: GameModeratorCellsComponent, canActivate: [AuthGuard]},
  {path: 'game-moderator/stages/literacy-test/:id', component: GameModeratorLiteracyTestComponent, canActivate: [AuthGuard]},
  {path: 'game-moderator/stages/test/:id', component: GameModeratorTestComponent, canActivate: [AuthGuard]},
  {path: 'game-moderator/preview-test', component: PreviewTestComponent, canActivate: [AuthGuard]},
  {path: 'game-moderator/cells-preview', component: CellsPreviewComponent, canActivate: [AuthGuard]},
  {path: 'game-moderator/literacy-test-preview', component: LiteracyTestPreviewComponent, canActivate: [AuthGuard]},
  {
    path: 'game-moderator-store',
    component: GameModeratorStoreComponent,
    canActivate: [AuthGuard],
    children: [
      {path: 'store', component: StoreComponent, canActivate: [AuthGuard]},
      {path: 'basket', component: BasketComponent, canActivate: [AuthGuard]},
      {path: 'preview', component: PreviewComponent, canActivate: [AuthGuard]},
      {path: '**', redirectTo: 'preview'}
    ]
  },
  {
    path: RootSectionPath.ecoPortal,
    loadChildren: () => import('modules/pages/eco-portal/eco-portal.module').then((module) => module.EcoPortalModule),
    canActivate: [AuthGuard]
  },
  {
    path: RootRoute.homework,
    canActivate: [AuthGuard],
    loadChildren: () => import('modules/homework/homework.module').then((m) => m.HomeworkModule)
  },
  {
    path: RootRoute.personal,
    canActivate: [AuthGuard, InternalUserGuard],
    canActivateChild: [AuthGuard, InternalUserGuard],
    component: BaseLayoutComponent,
    children: [
      {
        path: PersonalRoute.profile,
        loadChildren: () => import('modules/personal/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: PersonalRoute.statistics,
        loadChildren: () => import('modules/personal/statistics/statistics.module').then(m => m.StatisticsModule)
      },
      {
        path: PersonalRoute.team,
        loadChildren: () => import('modules/personal/team/team.module').then(m => m.TeamModule)
      },
      {
        path: PersonalRoute.reports,
        loadChildren: () => import('modules/personal/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: PersonalRoute.infographics,
        loadChildren: () => import('modules/personal/infographics/infographics.module').then(m => m.InfographicsModule)
      },
      {
        path: PersonalRoute.booking,
        loadChildren: () => import('modules/personal/booking/booking.module').then(m => m.BookingModule)
      },
      {
        path: PersonalRoute.schedule,
        loadChildren: () => import('modules/personal/schedule/schedule.module').then(m => m.ScheduleModule)
      },
      {
        path: PersonalRoute.management,
        loadChildren: () => import('modules/personal/management/management.module').then(m => m.ManagementModule)
      },
      {
        path: '**',
        redirectTo: PersonalRoute.profile
      }
    ]
  },
  {
    path: RootRoute.welcome_book,
    component: WelcomeLayoutComponent,
    loadChildren: () => import('modules/welcome-book/welcome-book.module').then((m) => m.WelcomeBookModule)
  },
  {path: '**', redirectTo: '/fork', pathMatch: 'full'}
]

@NgModule({
  imports: [RouterModule.forRoot(routes,{ scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class RootRoutingModule {}
