import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { first } from 'rxjs/operators'

import { AuthService, DialogService, ExtraService, StorageService } from 'services'
import { AlertDialogComponent } from 'components/dialogs'
import { navigationSelectors } from 'store/navigation'
import { EcoPortalSectionPath, RootSectionPath } from 'core/navigation'
import { Observable } from 'rxjs'
import { User } from 'core/model/user'

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() template = 'default'
  @Input() color = 'default'
  @Input() showUser = true
  @Input() makeReturnOnMain = true
  @Input() showBack = true
  @Input() fixHeader = true
  @Input() logoWhite: boolean = false;

  showBurgerMenu = false
  fixed = false

  user$: Observable<User>;

  private fromEco = false

  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly authService: AuthService,
    private readonly dialogService: DialogService,
    private readonly storageService: StorageService,
    public readonly extraService: ExtraService
  ) {}

  ngOnInit() {
    if (this.fixHeader) {
      this.headerFixed()
    }

    this.user$ = this.extraService.user$;  
    this.store.select(navigationSelectors.selectFromEco).pipe(first()).subscribe((fromEco) => this.fromEco = fromEco)
  }

  goMain() {
    if (this.makeReturnOnMain) {
      if (this.fromEco) {
        this.router.navigate(['/', RootSectionPath.ecoPortal, EcoPortalSectionPath.home])
      } else {
        this.router.navigate(['main'])
      }
    }
  }

  goPersonal() {
    this.router.navigate(['personal'])
  }

  logout() {
    const data = {
      'title': 'Вы уверены, что хотите выйти?',
      'confirmButtonText': 'ДА',
      'notButtonText': 'НЕТ'
    }

    this.dialogService.openDialog(AlertDialogComponent, data).subscribe((res: any) => {
      if (res) {
        this.authService.signOut().subscribe(() => {
          this.storageService.setValue('token', '')
          this.router.navigate(['auth'])
        })
      }
    })
  }

  openMenu() {
    this.showBurgerMenu = !this.showBurgerMenu ? true : false
  }

  goLearning() {
    this.router.navigate(['learning'])
  }

  onSection(value) {
    const section = this.extraService.documentContent;

    this.router.navigate([value + '/folder/' + section.boxes.find(x => x.reference === value).id])
  }

  showCounter(value) {
    const section = this.extraService.documentContent;
    return section.boxes.find(x => x.reference === value).news
  }

  headerFixed() {
    const that = this
    window.onscroll = function () {
      const scrolled = window.pageYOffset || document.documentElement.scrollTop

      if (scrolled === 0) {
        that.fixed = false
      } else {
        that.fixed = true
      }
    }
  }
}
