import {Component, OnInit} from '@angular/core';
import { Router } from "@angular/router";
import {animate, state, style, transition, trigger} from "@angular/animations";

import {Location} from "@angular/common";
import {BaseService} from "services/api";
import {ExtraService, GameModeratorService} from "services";


@Component({
  selector: 'app-game-moderator-stages',
  templateUrl: 'game-moderator-stages.component.html',
  styleUrls: ['game-moderator-stages.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class GameModeratorStagesComponent implements OnInit {

  constructor(
    private location: Location,
    private router: Router,
    private baseService: BaseService,
    private gameModerService: GameModeratorService,
    public extraService: ExtraService
  ) {
  }

  stages: any
  account: number
  mobile = false

  goBack() {
    this.location.back()
  }

  goStore() {
    this.router.navigate(['game-moderator-store'])
  }

  goStage(stage, index) {
    if (stage.is_available) {
      switch (index) {
        case 0:
          this.router.navigate(['game-moderator/preview-test'])
          break
        case 1:
          this.router.navigate(['game-moderator/literacy-test-preview'])
          break
        case 2:
          this.router.navigate(['game-moderator/cells-preview'])
          break
      }
    }
  }

  resize() {
    if (window.innerWidth <= 1024) {
      this.mobile = true;
      this.extraService.backgroundStore = '/assets/images/game/bg_mobile_3.png'
    } else {
      this.mobile = false;
      this.extraService.backgroundStore = '/assets/images/game/bg_desktop_3.png'
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1024) {
        this.mobile = true;
        this.extraService.backgroundStore = '/assets/images/game/bg_mobile_3.png'
      } else {
        this.mobile = false;
        this.extraService.backgroundStore = '/assets/images/game/bg_desktop_3.png'
      }
    });
  }

  ngOnInit() {
    this.resize()
    this.gameModerService.getLang()
    this.baseService.baseGetGameModerStagesList(this.gameModerService.lang).subscribe((response: any) => {
      this.stages = response
    })
    this.baseService.baseGetStoreAccount().subscribe((response: any) => {
      this.account = response.value;
    })
  }
}
