import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService } from 'services/api';

declare var $: any;

@Component({
  selector: 'app-nyx-catalog-card',
  templateUrl: './nyx-catalog-card.component.html',
  styleUrls: ['./nyx-catalog-card.component.scss']
})
export class NyxCatalogCardComponent implements OnInit {

  pageID = 'catalog-card';

  currentLang = {name: 'RU', lang: 'rus'};
  currentLangCatalog = 'rus';

  idFolder: any;
  idUnderFolder: number;
  idFolderHome: number;

  arrItem: any = undefined;

  imgUrl: any;
  imgPrise: any;
  objUrls: string[] = [];
  nuances: any = [];
  add_sales: any = [];
  labels: any = [];
  newObjNuances: any = '';
  newObjSales: any = '';
  newObjLabels: any = '';
  token = '4e3cb8b0-6168-11ec-9afa-d8f3bc3ad36e';

  routeLink: any = ['/catalog/section'];
  query: any = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: BaseService,
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe( params => {
      this.idFolder = params[ 'folder' ];
      this.idUnderFolder = params[ 'underfolder' ];
      this.idFolderHome = params[ 'folderHome' ];

      if (!this.idFolder && !this.idUnderFolder && !this.idFolderHome) {
        this.router.navigate( [ 'catalogs_old/nyx' ] );
      }

    });

    this.api.productNyxCatalog(this.idUnderFolder).subscribe( ( response_item: any ) => {
      this.query = {
        folder: this.idFolderHome,
        underfolder: this.idFolder
      };

      this.arrItem = response_item;

      for (let image in this.arrItem.nuances) {
        if (this.arrItem.nuances[image].file !== '' && this.arrItem.nuances[image].file !== null) {

          this.newObjNuances = {'title': this.arrItem.nuances[image].title, 'url': this.arrItem.nuances[image].file};
          this.nuances.push(this.newObjNuances);
        }
      }

      for (let image in this.arrItem.add_sales) {
       if (this.arrItem.add_sales[image].image !== '' && this.arrItem.add_sales[image].image !== null) {

         this.newObjSales = {
           'url': this.arrItem.add_sales[image].image,
           'id': this.arrItem.add_sales[image].id,
           'folder_id': this.arrItem.add_sales[image].folder_id,
           'folder__parent_id': this.arrItem.add_sales[image].folder__parent_id
         };
         this.add_sales.push(this.newObjSales);
       }
      }

      for (let image in this.arrItem.labels) {
        if (this.arrItem.labels[image].file.length !== 0) {
          this.newObjLabels = {
           'url': this.arrItem.labels[image].file
          };
          this.labels.push(this.newObjLabels);
        }
      }

    });
  }
  goBack() {
    this.router.navigate(['/catalogs_old/nyx/section'], { queryParams: this.query});
  }

}
