<ng-container *ngIf="profile$ | async as profile">
  <div class="header_osn">
    <div class="container-logo-fork">
      <app-icon-loader [name]="'logo'" class="svg_logo"></app-icon-loader>
    </div>
    <div class="logo_container">
      <div class="logout" (click)="logout()"></div>
    </div>
  </div>

  <section>
    <div class="blocks">
      <div class="block1 block" *ngIf="!profile.eco" routerLink="/main">
        <div class="substrate">
          <div class="title">Обучающий портал</div>

          <div class="button">
            <span>Посетить портал</span>
            <span class="ar"></span>
          </div>
        </div>
      </div>

      <div class="block2 block" routerLink="/game-moderator">
        <div class="substrate">
          <div class="title">BE IN TOUCH</div>

          <div class="button">
            <span>Перейти к игре</span>
            <span class="ar"></span>
          </div>
        </div>
      </div>

<!--      <div class="block3 block" routerLink="/eco-portal">-->
<!--        <div class="substrate">-->
<!--          <div class="title">L’OREAL FOR THE FUTURE</div>-->

<!--          <div class="button">-->
<!--            <span>Посетить портал</span>-->
<!--            <span class="ar"></span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </section>
</ng-container>
