import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss']
})
export class CollapseComponent  {
  @Input() title: string;
  @Input() titleTemplate: TemplateRef<any>;
  @Input() open: boolean = false;
  @Input() hideIcon: boolean = false;
  @Input() autoToggle: boolean = true;
  @Input() cursor: 'default' | 'pointer' = 'pointer';
  
  @Output() clicked = new EventEmitter<boolean>();

  toggle(): void {
    if(this.autoToggle) {
      this.open = !this.open;

      this.clicked.emit(this.open);
    }
  }
}
