import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MainLayoutService } from '../../services/main-layout.service';
import { RootRoute } from 'app/constants/root-routes';
import { Observable, from } from 'rxjs';
import { LayoutPage } from 'app/typings/layout-pages';
import { concatMap, skip, take, toArray } from 'rxjs/operators';
import { User } from 'core/model/user';
import { FolderBox, FolderData } from 'core/model/folder';
import { ExtraService } from 'services';

@Component({
  selector: 'app-burger',
  templateUrl: './burger.component.html',
  styleUrls: ['./burger.component.scss']
})
export class BurgerComponent implements OnInit {
  @Input() avatarImg: string;
  @Input() section: FolderData;
  @Input() routesList: {[key in string]: RootRoute};

  @Output() pageClick = new EventEmitter<RootRoute>();
  @Output() logoutClick = new EventEmitter<void>();
  
  pages$: Observable<LayoutPage[]>;
  sectionPages$: Observable<LayoutPage[]>;
  user$: Observable<User>;

  constructor(
    private router: Router,
    private mainLayoutService: MainLayoutService,
    private extraService: ExtraService
    ) { }

  ngOnInit(): void {
    this.pages$ = this.mainLayoutService.pages$.pipe(concatMap(v => from(v).pipe(take(2),toArray())));
    this.sectionPages$ = this.mainLayoutService.pages$.pipe(concatMap(v => from(v).pipe(skip(2),toArray())));
    this.user$ = this.extraService.user$;
  }
  
  onPage(route: RootRoute): void {
    this.pageClick.emit(route)
  }

  onLogout(): void {
    this.logoutClick.emit();
  }

  onSection(section: RootRoute) {
    this.router.navigate([section + '/folder/' + this.section?.boxes.find((x:FolderBox) => x.reference === section).id])
  }

  showCounter(section: RootRoute) {
    return this.section?.boxes.find((x:FolderBox) => x.reference === section).news
  }
}
