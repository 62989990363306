import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {AlertDialogComponent} from "components/dialogs";
import {DialogService} from "services";
import {BaseService} from "services/api";

@Component({
  selector: 'app-video-test-dialog',
  templateUrl: './video-test-dialog.component.html',
  styleUrls: ['./video-test-dialog.component.scss'],
})
export class VideoTestDialogComponent implements OnInit {

  question: any;

  blockSend = false; // Блокировка отправки ответов
  errors = {}; // Неотвеченные вопросы
  showRightAnswer = false;
  rightAnswer = undefined;
  right = undefined;

  form: FormGroup = this.formBuilder.group({});

  constructor(
    private dialogRef: MatDialogRef<VideoTestDialogComponent>,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private baseService: BaseService,
    @Inject(MAT_DIALOG_DATA) private dialogData: {'question': string },
  ) {
    ({
      question: this.question,
    } = this.dialogData);
  }

  drop(event: CdkDragDrop<string[]>, controlName: any, arr: any) {
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
    this.form.get(String(controlName)).setValue(arr.map((inf: any) => inf.id));
  }

  makeFormGroup(form: any) {
    const group = {};

    if (form.type === 'radio') {
      group[form.id] = [null, Validators.required];
    } else if (form.type === 'checkbox') {
      group[form.id] = this.formBuilder.array(
        form.options.map(() => this.formBuilder.control(false)),
      );
    } else if (form.type === 'sort') {
      group[form.id] = [form.options.map((arr: any) => arr.id), Validators.required];
    } else if (form.type === 'text') {
      group[form.id] = ['', Validators.required];
    } else if (form.type === 'rating') {
      group[form.id] = ['', Validators.required];
    }

    this.form = this.formBuilder.group(group);

  }

  submit() {
    const answers = [];
    this.blockSend = false;
    this.errors = {};

    if (this.question.type === 'radio') {
      if (this.form.get(String(this.question.id)).value) {
        this.errors[this.question.id] = false;
        answers.push({
          options: [+this.form.get(String(this.question.id)).value],
        });
      } else {
        this.errors[this.question.id] = true;
        this.blockSend = true;
        answers.push({
          question_id: this.question.id,
          options: []});
      }
    } else if (this.question.type === 'checkbox') {
      const checkboxAnswers = [];
      Object.keys(this.form.get(String(this.question.id)).value).forEach((i) => {
        if (this.form.get(String(this.question.id)).value[i]) {
          checkboxAnswers.push(this.question.options[i].id);
        }
      });
      if (checkboxAnswers.length > 0) {
        this.errors[this.question.id] = false;
      } else {
        this.errors[this.question.id] = true;
        this.blockSend = true;
      }
      answers.push({
        question_id: this.question.id,
        options: checkboxAnswers,
      });
    } else if (this.question.type === 'sort') {
      this.errors[this.question.id] = false;
      answers.push({
        question_id: this.question.id,
        options: this.form.get(String(this.question.id)).value,
      });
    } else if (this.question.type === 'text') {
      if (this.form.get(String(this.question.id)).value !== '') {
        this.errors[this.question.id] = false;
      } else {
        this.errors[this.question.id] = true;
        this.blockSend = true;
      }
      answers.push({
        question_id: this.question.id,
        text: this.form.get(String(this.question.id)).value,
      });
    } else if (this.question.type === 'rating') {
      const ratingValue = String(this.form.get(String(this.question.id)).value);
      const ratingOptions = this.question.options;
      const ratingAnswer = ratingOptions.find( item => item.text === ratingValue);
      if (this.form.get(String(this.question.id)).value !== '') {
        this.errors[this.question.id] = false;
      } else {
        this.errors[this.question.id] = true;
        this.blockSend = true;
      }
      answers.push({
        question_id: this.question.id,
        options: [ratingAnswer.id],
      });
    }

    if (this.blockSend) {
      const data = {
        'title': 'Не на все вопросы даны ответы.',
        confirmButtonText: 'ОK',
      };
      this.dialogService.openDialog(AlertDialogComponent, data);
    } else {
      const answersOn = {
        question_id: this.question.id,
        options: answers[0].options,
      };
      this.baseService.baseAnswerSaveVideoTest(answersOn).subscribe((response) => {
        this.showRightAnswer = true;
        this.rightAnswer = response['right_options'];
        this.right = response['is_right']
      });
    }
  }

  continueWatch() {
    this.dialogRef.close('continue');
  }

  watchAgain(how) {
    switch (how) {
      case 'withQuestion':
        this.dialogRef.close('withQuestion');
        break
      case 'withoutQuestion':
        this.dialogRef.close('withoutQuestion')
        break
    }
  }

  ngOnInit() {
    this.dialogRef.disableClose = true;
    this.makeFormGroup(this.question);
  }

}
