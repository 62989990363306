<div class="custom_dialog">
  <h2 mat-dialog-title class="dialog-title" [innerHTML]="title | safeHtml"></h2>

  <video controls>
    <source type="video/webm" [src]="(videoPath)">
    <source type="video/mp4" [src]="(videoPath)">
  </video>

  <div mat-dialog-actions>
    <div class="dialog_button" (click)="onConfirm()">{{confirmButtonText}}</div>
  </div>
</div>
