import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { CatalogsSearchService } from '../../../../services/api/catalogs/catalogs-search.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, tap,delay, take } from 'rxjs/operators';
import { DialogService } from 'services';
import { AlertDialogComponent } from 'components/dialogs';


@Component({
  selector: 'app-catalogs-search',
  templateUrl: './catalogs-search.component.html',
  styleUrls: ['./catalogs-search.component.scss']
})
export class CatalogsSearchComponent implements OnInit, OnDestroy {
  @Output() shareSearchResults = new EventEmitter<{searchResults: any[], searchItem: string}>();

  isVisible$: Observable<boolean>;
  options$: Observable<string[]>;
  selectedItem: string;

  get searchForm(): FormControl {
    return this.catalogsSearchService.searchForm;
  }

  private searchSubject: Subject<void> = new Subject<void>();

  constructor(private catalogsSearchService: CatalogsSearchService, private dialogService: DialogService,) { }

  ngOnInit(): void {
    this.onTypingSearch();
    this.isVisible$ = this.catalogsSearchService.isVisible$;
  }

  //unsubscribe from subscription
  ngOnDestroy(): void {
    this.searchSubject.unsubscribe();
  }

  //listen to user typing with delay 500
  private onTypingSearch(): void {
    this.searchForm.valueChanges
    .pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(v => {
        if(v.length === 0 ) {
          this.options$ = of([]);
          this.shareSearchResults.emit({searchResults: [], searchItem: ''});
        }
      }),
      filter(v => v.length > 0),
      map(v => v.toLowerCase()),
      takeUntil(this.searchSubject)
    )
    .subscribe({
      next: v => {
        this.options$ = this.catalogsSearchService.getOptions(v);
      }
    });
  }

  //dropdown hide and show
  toggleDropdown(state: boolean): void {
    this.catalogsSearchService.onToggleDropdown(state);
  }

  //submit to show products
  submit(): void {
    this.toggleDropdown(false);
    this.catalogsSearchService.setOffset('new');

    this.catalogsSearchService.getProducts()
    .pipe(
      filter(() => this.searchForm.value),
      map(v => this.searchForm.value.length === 0 ? [] : v),
      takeUntil(this.searchSubject)
    )
    .subscribe({
      next: v => {
        if(v.length === 0) {
          this.dialogService.openDialog(AlertDialogComponent, { title: 'Товар с таким артикулом не найден', confirmButtonText: 'OK'});
        } else {
          this.shareSearchResults.emit({searchResults: v, searchItem: this.searchForm.value});
        }
      }
    })
  }

  //to make auto complete
  onSelectOption(value: string): void {
    of(1)
      .pipe(
        delay(100),
        take(1)
      )
      .subscribe({
        next: _ => {
          this.selectedItem = value;
          this.searchForm.setValue(value);
          this.toggleDropdown(false);
        }
      })
  }

}
