import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { DialogService, ExtraService } from 'services'
import { AlertDialogComponent } from 'components/dialogs'
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-game-moderator-test-dialog',
  templateUrl: './game-moderator-test-dialog.component.html',
  styleUrls: ['./game-moderator-test-dialog.component.scss'],
})

export class GameModeratorTestDialogComponent implements OnInit {

  question: any;

  form: FormGroup = this.formBuilder.group({});

  answer: any;

  boxes: any;
  options: any = [];
  dropAnswer = new Map()
  isValid: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    public extraService: ExtraService,
    private dialogRef: MatDialogRef<GameModeratorTestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: { question: any },
  ) {
    ({
      question: this.question
    } = this.dialogData);
  }

  makeFormGroup(form: any) {

    const group = {};

    switch (form.type) {
      case 'radio':
        group[form.id] = [null, Validators.required];
        break

      case 'checkbox':
        group[form.id] = this.formBuilder.array(
          form.options.map(() => this.formBuilder.control(false)),
        );
        break

      case 'sort':
        group[form.id] = [form.options.map((arr: any) => arr.id), Validators.required];
        break

      case 'text':
        group[form.id] = ['', Validators.required];
        break

      case 'rating':
        group[form.id] = ['', Validators.required];
        break
    }

    this.form = this.formBuilder.group(group);

    this.listenForm();
  }

  drop(event: CdkDragDrop<string[]>, controlName: any, arr: any) {
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
    this.form.get(String(controlName)).setValue(arr.map((inf: any) => inf.id));
  }

  dropImage(event: CdkDragDrop<any>, targetBoxIndex: number) {
    const droppedOption = this.options[event.previousIndex]
    const targetBox = this.boxes[targetBoxIndex]

    targetBox.image = droppedOption.image
    this.dropAnswer.set(targetBox.id, [droppedOption.id]);
    this.isValid = true;

    this.options.splice(event.previousIndex, 1)
  }

  pause() {
    this.extraService.gamePaused = true
    this.extraService.changeGamePaused(this.extraService.gamePaused)
    this.dialogService.openDialog(AlertDialogComponent, {title: 'Вы поставили игру на паузу', confirmButtonText: 'Продолжить'})
      .subscribe(() => {
        this.extraService.gamePaused = false
        this.extraService.changeGamePaused(this.extraService.gamePaused)
      })
  }

  setAnswer() {
    switch (this.question.type) {
      case 'radio':
        this.answer = {
          question_id: +Object.keys(this.form.value)[0],
          options: [+this.form.get(String(this.question.id)).value],
        };
        break
      case 'checkbox':
        const checkboxAnswers = [];
        Object.keys(this.form.get(String(this.question.id)).value).forEach((i) => {
          if (this.form.get(String(this.question.id)).value[i]) {
            checkboxAnswers.push(this.question.options[i].id);
          }
        });
        this.answer = {
          question_id: +Object.keys(this.form.value)[0],
          options: checkboxAnswers
        }
        break
      case 'sort':
        this.answer = {
          question_id: +Object.keys(this.form.value)[0],
          options: this.form.get(String(this.question.id)).value
        }
        break
      case 'drop':
        this.answer = {
          question_id: this.question.id,
          options: Object.fromEntries(this.dropAnswer.entries())
        }
        break
      default:
        this.answer = {
          question_id: +Object.keys(this.form.value)[0],
          options: Object.values(this.form.value)
        }
        break
    }
  }

  notAnswer() {
    this.dialogRef.close(false)
    this.question.open = null
  }

  sendAnswer() {
    this.setAnswer()
    this.dialogRef.close(this.answer)
  }

  listenForm(): void {
    this.form.valueChanges
      .pipe(
        map((v) => {
          if (this.question.type === 'checkbox') {
            const formValues = Object.values(v)[0] as any;
            const filteredValues = formValues.filter((value:any) => value === true);

            return filteredValues.length > 0;
          }

          return this.form.valid

        })
      )
      .subscribe({
        next: v => {
          this.isValid = v;
        }
      })
  }

  ngOnInit() {
    this.dialogRef.disableClose = true;

    if (this.question.type === 'drop') {
      this.boxes = this.question.boxes
      this.options = this.question.options

      this.options.map((item, index) => {
        item.index = index
      })
    } else {
      this.makeFormGroup(this.question)
    }
  }
}
