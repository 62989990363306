import { Directive, ElementRef, HostListener } from '@angular/core';
import { CatalogsSearchService } from 'services/api/catalogs/catalogs-search.service';

@Directive({
  selector: '[appDropdown]'
})
export class DropdownDirective {

  constructor(private catalogsSearchService: CatalogsSearchService, private elementRef: ElementRef ) { }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.catalogsSearchService.onToggleDropdown(false);
    }
  }

}
