import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DialogService } from 'services';
import { AlertDialogComponent } from 'components/dialogs';
import SwiperCore, { Zoom, FreeMode, Thumbs } from "swiper";
import { AppHttpContext } from 'core/api'

SwiperCore.use([Zoom, FreeMode, Thumbs]);

@Component({
  selector: 'app-content-blocks-children',
  templateUrl: './content-blocks-children.component.html',
  styleUrls: ['./content-blocks-children.component.scss'],
})

export class ContentBlocksChildrenComponent implements OnInit {

  thumbsSwiper: any;

  inactive = false;

  @Input() arrayContentChildren = [];

  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
  ) {}

  goFile(file, length, titleBlock) {
    if (file.file.split('?')[0].split('.').pop() === 'pdf') {
      window.open(file.file, '_blank');
    } else {
      if (!this.inactive) {
        this.inactive = true;

        this.http.get(
          file.file,
          {
            responseType: 'blob',
            observe: 'response',
            context: new AppHttpContext().unsigned()
          }).subscribe(response => {

            // file-type
            let fileName: string;

            if (length > 1) {
              fileName = file.title + '.' + file.file.split('?')[0].split('.').pop();
            } else {
              fileName = file.title + '.' + file.file.split('?')[0].split('.').pop();
            }

            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(response.body);
            link.download = fileName;
            link.click();
            link.remove();
            this.inactive = false;
          }, () => {
            const data = {
              'title': 'Произошла ошибка, попробуйте ещё раз.',
              'confirmButtonText': 'OK'
            }
            this.inactive = false;
            this.dialogService.openDialog(AlertDialogComponent, data);
          });
      }
      return;
    }
  }

  ngOnInit() {
  }
}
