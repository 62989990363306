import { Component, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router'
import { BaseService } from 'services'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-subfolder-document-list',
  templateUrl: './subfolder-document-list.component.html',
  styleUrls: ['./subfolder-document-list.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0
      })),
      state('show', style({
        opacity: 1
      })),
      transition('void => show', [
        style({
          opacity: 0
        }),
        animate(500, style({
          opacity: 1
        }))
      ])
    ])
  ]
})

export class SubfolderDocumentListComponent implements OnInit, OnDestroy {

  showPrev = 'show';

  folderId = undefined;
  subFolderId = undefined;
  catalogInfo = undefined;

  menuOpen = true;
  sub$: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: BaseService,
  ) {}

  ngOnInit() {
    this.sub$ = this.route.params.subscribe( params => {
      this.resize();
      if (params['subfolder_id']) {
        this.folderId = params['folder_id'];
        this.subFolderId = params['subfolder_id'];
        this.api.catalogFolder(params['subfolder_id']).subscribe((response: any) => {
          this.catalogInfo = response;
          this.catalogInfo.folders = this.catalogInfo.folders.map(
            item => {
              if (item.id === Number(params['subfolder_id'])) {
                item.active = true;
              }
              return item;
            }
          );
        });
      } else {
        this.sub$ = this.api.catalogFolder().subscribe((response: any) => {
          this.router.navigate( [ 'catalogs/' + response.folders[0].id ] );
        });
      }

    });
  }

  ngOnDestroy(): void {
    this.sub$?.unsubscribe();
  }

  resize() {
    if (window.innerWidth <= 1025) {
      this.menuOpen = false;
    } else {
      this.menuOpen = true;
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1025) {
        this.menuOpen = false;
      } else {
        this.menuOpen = true;
      }
    });
  }

  goBack() {
    this.router.navigate( [ 'catalogs/' + this.folderId ] );
  }

  onPdf(info) {
    location.assign(info.file);
  }

}
