import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { BaseService } from 'services/api'

@Component({
  selector: 'app-archive-contests',
  templateUrl: 'archive-contests.component.html',
  styleUrls: ['archive-contests.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})
export class ArchiveContestsComponent implements OnInit {
  showPrev = 'show';

  section = 'contests';

  breadcrumbs = [
    {
      id: null,
      title: 'Архив',
      type: 'folder'
    }
  ];

  info = undefined;

  constructor(
    private baseService: BaseService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  goBack() {
    this.router.navigate(['contests']);
  }

  onFolder(folder) {
    this.breadcrumbs.push({id: folder.id, title: folder.title, type: 'folder'});
    this.info = undefined;
    this.baseService.baseGetSectionContentArticles(this.section, folder.id).subscribe((response: any) => {
      console.log(response);

      this.info = response;

    }, () => {
      this.router.navigate( [ 'contests' ] );
    });
  }

  onPage(pageInfo) {
    this.router.navigate( [ 'contests/document/article/' + pageInfo.id ] );
  }

  onCrumb(index, info) {
    this.info = undefined;
    this.baseService.baseGetSectionContentArticles(this.section, info.id).subscribe((response: any) => {
      console.log(response);

      this.info = response;

    }, () => {
      this.router.navigate( [ 'contests' ] );
    });
    this.breadcrumbs = this.breadcrumbs.slice(0, index + 1);
  }

  likeOn(info, index) {
    if (info.is_liked) {
      this.info.documents[index].is_liked = false;
      this.info.documents[index].likes -= 1;
    } else {
      this.info.documents[index].is_liked = true;
      this.info.documents[index].likes += 1;
    }
    this.baseService.baseGetSectionDocumentLike(this.section, this.info.id);
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.baseService.baseGetSectionContentArticles(this.section, +params.id).subscribe((response: any) => {
        this.info = response;

      }, () => {
        this.router.navigate( [ 'contests' ] );
      });
    })
  }

}
