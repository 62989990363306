import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseService } from 'services/api';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-loreal',
  templateUrl: './loreal.component.html',
  styleUrls: ['./loreal.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0
      })),
      state('show', style({
        opacity: 1
      })),
      transition('void => show', [
        style({
          opacity: 0
        }),
        animate(500, style({
          opacity: 1
        }))
      ])
    ])
  ]
})
export class LorealComponent implements OnInit {

  showPrev = 'show';

  mobile = false; // Включаем элементы свойственные мобильной версии
  menuOpen = true;

  breadcrumbs = [];
  sectonInfo = undefined;
  folderMenu = [];
  folderContent = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private baseService: BaseService
  ) {}

  reset() {
    this.breadcrumbs = [];
    this.sectonInfo = undefined;
    this.folderMenu = [];
    this.folderContent = [];
  }

  resize() {
    if (window.innerWidth <= 1025) {
      this.mobile = true;
      this.menuOpen = false;
    } else {
      this.mobile = false;
      this.menuOpen = true;
    }
    window.addEventListener('resize', () => {
        if (window.innerWidth <= 1025) {
          this.mobile = true;
          this.menuOpen = false;
        } else {
          this.mobile = false;
          this.menuOpen = true;
        }
    });
  }

  openMenu() {
    if (this.menuOpen) {
      this.menuOpen = false;
    } else {
      this.menuOpen = true;
    }
  }

  goBack() {
    this.router.navigate(['learning/learning-home']);
  }

  onFolder(id) {
    this.router.navigate( [ 'learning/loreal/' + id ] );
  }

  onDocument(id) {
    this.router.navigate( [ 'loreal/document/article/' + id ] );
  }

  ngOnInit() {
    this.route.params.subscribe( params => {

      this.reset();
      this.resize();

      if (params['folderId']) {
        this.baseService.documentsBoxChain(params['folderId']).subscribe((response: any) => {
          console.log(response);
          this.breadcrumbs = response;
        });
        this.baseService.documentsContent(params['folderId']).subscribe((response: any) => {
          console.log(response);
          for (const box of response.boxes) {
            if (box.center && box.is_available) {
              this.folderContent.push(box);
            } else if (!box.center && box.is_available) {
              this.folderMenu.push(box);
            }
          }

          if (this.folderMenu.length === 0) {
            this.baseService.documentsContent(response.self.parent).subscribe((parent: any) => {
              console.log(parent);
              for (const box of parent.boxes) {
                if (!box.center && box.is_available) {
                  this.folderMenu.push(box);
                }
              }
              this.folderMenu = this.folderMenu.map(
                item => {
                  if (item.id === Number(params['folderId'])) {
                    item.active = true;
                  }
                  return item;
                }
              );
            });
          }

          if (this.folderContent.length === 0 && response.documents.length === 0 && this.folderMenu.length !== 0) {
            this.router.navigate( [ 'learning/loreal/' + this.folderMenu[0].id ] );
          } else {
            this.sectonInfo = response;
          }

          console.log(this.folderContent);
          console.log(this.folderMenu);

        });
      }

    });
  }
}
