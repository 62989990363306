import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'

import { InputDialogDirective } from 'core/directives'

@Component({
  selector: 'app-file-dialog',
  template: '<input #input type="file" [accept]="accept" [multiple]="_multiple" (change)="emitFiles()">',
  styles: [':host {display: none;}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileDialogComponent extends InputDialogDirective {
  @Input() accept = ''
  @Input() set multiple(flag: BooleanInput) {
    this._multiple = coerceBooleanProperty(flag)
  }
  @Output() readonly file = new EventEmitter<File>()
  @Output() readonly files = new EventEmitter<File[]>()

  _multiple = false

  /**
   * Emits the selected files and resets the file input.
   */
  emitFiles(): void {
    const files = this.input.files

    if (this.multiple) {
      this.files.emit(Array.from(files))
    } else {
      this.file.emit(files[0])
    }

    this.input.value = ''
  }
}
