<div class="header_store">
  <div class="button_store basket" (click)="goBasket()">
    <div class="basket_icon"></div>
    <div class="text_button_store">{{lang === 'ru' ? 'корзина' : 'cart'}}</div>
  </div>
  <div class="button_store">
    {{account}}
    <div class="coin_icon"></div>
  </div>
</div>

<div class="container">
  <div class="block_text">
    <div class="title" [innerHTML]="shopDescription.shop.title | safeHtml"></div>
    <div class="text" [innerHTML]="shopDescription.shop.text | safeHtml"></div>
    <div class="button_container">
      <div class="button" [innerHTML]="shopDescription.start_button.text | safeHtml" (click)="goStore()"></div>
    </div>
  </div>
</div>
