<div class="custom_dialog booking create" *ngIf="owners">

  <div class="title">Бронирование</div>

  <form [formGroup]="form" enctype="multipart/form-data" class="form">
    <div class="form-box">
      <div class="form-label">
        <label>ФИО:</label>
      </div>
      <div class="form-action">
        <input readonly placeholder="ФИО" [value]="owner?.name" style="flex: 1;">
      </div>
    </div>
    <div class="form-box">
      <div class="form-label">
        <label class="required">Тема:</label>
      </div>
      <div class="form-action">
        <input placeholder="Введите тему" maxlength="100" formControlName="title" style="flex: 1;">
      </div>
    </div>
    <div class="form-box">
      <div class="form-label">
        <label class="required">Аудитория:</label>
      </div>
      <div class="form-action">
        <mat-select
          class="custom_select for_booking"
          placeholder="Выберите аудиторию"
          formControlName="room"
          (valueChange)="roomValueChange()"
        >
          <mat-option [value]="null">--</mat-option>
          <mat-option
            *ngFor="let room of rooms"
            [value]="room.id"
          >
            {{room.title}}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="form-box">
      <div class="form-label">
        <label class="required">Дата:</label>
      </div>
      <div class="form-action noGap">
        <input  
          matInput 
          [ngClass]="{disabled: form.controls.room.invalid}"
          [matDatepicker]="picker" 
          placeholder="Выберите дату" 
          [matDatepickerFilter]="dateFilter" 
          formControlName="date"
          (dateChange)="onGetIntervals($event.value)"
          (click)="picker.open()"
        >
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
    <div class="form-box">
      <div class="form-label">
        <label class="required">Время:</label>
      </div>
      <div class="form-action row">
        <mat-select
          [ngClass]="{disabled: form.controls.date.invalid || !intervals.length}"
          class="custom_select for_booking"
          placeholder="Начало"
          formControlName="start"
          (valueChange)="startValueChange()"
        >
          <mat-option [value]="null">--</mat-option>
          <mat-option *ngFor="let interval of intervals" [value]="interval">
            {{interval.start | moment: 'HH:mm'}}
          </mat-option>
        </mat-select>
        <mat-select
          [ngClass]="{disabled: form.controls.start.invalid}"
          class="custom_select for_booking"
          placeholder="Конец"
          formControlName="finish"
        >
        <mat-option [value]="null">--</mat-option>
        <mat-option *ngFor="let interval of intervals | filterFinish:form.controls.start.value?.id" [value]="interval.id">
          {{interval.finish | moment: 'HH:mm'}}
        </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="form-box">
      <div class="form-label">
        <label>Телефон:</label>
      </div>
      <div class="form-action">
        <span class="info">{{owner?.phone}}</span>
      </div>
    </div>
    <div class="form-box wrap">
      <div class="form-label">
        <label>E-mail:</label>
      </div>
      <div class="form-action">
        <p class="">{{owner?.email}}</p>
      </div>
    </div>
  </form>
  <div class="button_container">
    <div class="button" (click)="submit()">ОК</div>
    <div class="button" (click)="onConfirm()">Отмена</div>
  </div>
</div>
