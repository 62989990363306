<div class="wrapper notranslate">
  <div *ngIf="nuances.length !== 0" id="nuances" class="window_body">
    <div class="window">
      <div class="cross"></div>
      <div class="window_osn nano">
        <div class="nano-content" *ngIf="nuances">
          <div class="nuances_item"
            *ngFor="let nuan of nuances"
          >
            <div class="nuances_item_img"><img [src]="'https://nyx-old-api.evpportal.ru/files/cpd/' + nuan.url + '?' + token" /></div>
            {{nuan.title}}
          </div>
        </div>
      </div>
    </div>
  	<div class="window_fon"></div>
  </div>

  <div *ngIf="add_sales.length !== 0" id="additional" class="window_body">
    <div class="window">
      <div class="cross"></div>
      <div class="window_osn nano">
        <div class="nano-content">
          <div
            class="additional_item"
            *ngFor="let sales of add_sales"
          >
            <a
              class="additional_item_img"
              href="/catalogs/nyx/section/card?folderHome={{sales.folder__parent_id}}&folder={{sales.folder_id}}&underfolder={{sales.id}}"
            >
              <img [src]="'https://nyx-old-api.evpportal.ru/files/cpd/' + sales.url + '?' + token" />
            </a>
          </div>
        </div>
      </div>
    </div>
  	<div class="window_fon"></div>
  </div>
  <section class="logo_osn">
      <div class="workspace">
        <div class="back-button" (click)="goBack()"></div>
        <img class="logo" src="assets/images/logo-nyx-catalog.png">
      </div>
  </section>
  <section class="content card_body">
      <div class="workspace">
        <div class="card-nyx" *ngIf="arrItem">
          <div class="card_big_left">
            <div class="card_big_photo" *ngIf="arrItem.image">
              <img [src]="'https://nyx-old-api.evpportal.ru/files/cpd/' + arrItem.image + '?' + token" alt="" />
            </div>
            <div class="card_big_left_info">
              <div class="card_big_title">{{arrItem.title['' + currentLangCatalog + '']}}</div>
              <div *ngIf="currentLangCatalog == 'rus'" class="card_big_title pink">
                {{arrItem.title['rus']}}
              </div>
              <div  *ngIf="arrItem.price !== 0" class="price">{{arrItem.price}}<img *ngIf="arrItem.price_image" [src]="'https://nyx-old-api.evpportal.ru/files/cpd/' + arrItem.price_image + '?' + token"></div>
            </div>
            <div class="card_big_buttons">
              <div *ngIf="nuances.length !== 0" id="button_nun" class="card_big_button">
                  Оттенки<span></span>
              </div>
              <div *ngIf="add_sales.length !== 0" id="button_add" class="card_big_button">
                  Доп. продажи<span></span>
              </div>
            </div>
            <div *ngIf="labels.length !== 0" class="card_big_signs">
              <img *ngFor="let label of labels" [src]="'https://nyx-old-api.evpportal.ru/files/cpd/' + label.url + '?' + token">
            </div>
          </div>
          <div class="card_big_right">
            <div class="card_big_top">
              <div *ngIf="arrItem.price !== 0" class="price">{{arrItem.price}}<img *ngIf="arrItem.price_image" [src]="'https://nyx-old-api.evpportal.ru/files/cpd/' + arrItem.price_image + '?' + token"></div>
              <div class="card_big_title">{{arrItem.title['' + currentLangCatalog + '']}}</div>
              <div *ngIf="currentLangCatalog == 'rus'" class="card_big_title pink">
                {{arrItem.title['rus']}}
              </div>
            </div>
            <div class="card_big_tabs">
              <div class="card_big_tab tab_active" data-hash="1">
                  Описание продукта
              </div>
              <div *ngIf="arrItem.composition[0].length !== 0" class="card_big_tab tab_not_active" data-hash="2">
                  Состав
              </div>
              <div *ngIf="arrItem.info[0].length !== 0" class="card_big_tab tab_not_active" data-hash="3">
                  Информация
              </div>
            </div>

            <div id="tab_1" class="card_big_text nano">
              <div class="nano-content">
                <div class="card_big_table">
                  <div
                    class="card_big_table_row"
                    *ngFor="let desc of arrItem.description"
                  >
                    <div class="card_big_table_cell">{{desc[0]}}</div>
                    <div class="card_big_table_cell">{{desc[1]}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="arrItem.composition[0].length !== 0" id="tab_2" class="card_big_text nano">
              <div class="nano-content">
                <div class="card_big_table">
                  <div
                    class="card_big_table_row"
                    *ngFor="let compo of arrItem.composition"
                  >
                    <div class="card_big_table_cell">{{compo[0]}}</div>
                    <div class="card_big_table_cell">{{compo[1]}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="arrItem.info[0].length !== 0" id="tab_3" class="card_big_text nano">
              <div class="nano-content">
                <div class="card_big_table">
                  <div
                    class="card_big_table_row"
                    *ngFor="let info of arrItem.info"
                  >
                    <div class="card_big_table_cell">{{info[0]}}</div>
                    <div class="card_big_table_cell">{{info[1]}}</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
  </section>
</div>
