import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router'
import { DialogService } from 'services'
import { BaseService } from 'services/api'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { AlertDialogComponent } from 'components/dialogs'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { RootRoute } from 'app/constants/root-routes'
import { PersonalRoute } from 'app/constants/personal-routes'

@Component({
  selector: 'app-final-test',
  templateUrl: 'final-test.component.html',
  styleUrls: ['final-test.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})
export class FinalTestComponent implements OnInit, OnDestroy {
  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private baseService: BaseService,
    private router: Router
  ) {}

  showPrev = 'show';

  form: FormGroup = this.formBuilder.group({});

  showRightAnswers: boolean;
  rightAnswers: any;
  more_detail = false;

  testBody: any;

  blockSend = false; // Блокировка отправки ответов
  errors = {}; // Неотвеченные вопросы

  noSave = false;

  // Таймер
  timeOut = false;
  time = 60;
  timeleft = this.time;
  timer = 0;
  startMoment = 0;
  endMoment = this.startMoment + this.timeleft * 1000;

  initiateTimer = false;
  anywaySubmit = false;

  goBack() {
    if (this.initiateTimer) {
      const data = {title: 'Вы действительно хотите покинуть тест?', text: 'Внимание, если вы покините тест, то попытка будет засчитана и ответы будут отправлены.', confirmButtonText: 'ДА', notButtonText: 'НЕТ'}
      this.dialogService.openDialog(AlertDialogComponent, data).subscribe((res: any) => {
        if (res) {
          this.anywaySubmit = true;
          this.submit();
        } else {
          return;
        }
      })
    } else {
      this.location.back();
    }
  }

  initCountdown() {
    this.initiateTimer = true;
    this.startMoment = Date.now();
    this.endMoment = this.startMoment + this.timeleft * 1000;
    this.timer = window.setInterval(() => {
      const now = Date.now();
      this.timeleft = Math.round((this.endMoment - now) / 1000);
      if (this.timeleft <= 0) {
        clearInterval(this.timer);
        this.timeleft = this.time;
        this.timeOut = true;
        this.submit();
      }
    }, 1000);
  }

  drop(event: CdkDragDrop<string[]>, controlName: any, arr: any) {
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
    this.form.get(String(controlName)).setValue(arr.map((inf: any) => inf.id));
  }

  makeFormGroup(form: any) {
    const group = {};

    Object.keys(form.questions).forEach((key) => {
      if (form.questions[key].type === 'radio') {
        group[form.questions[key].id] = [null, Validators.required];
      } else if (form.questions[key].type === 'checkbox') {
        group[form.questions[key].id] = this.formBuilder.array(
          form.questions[key].options.map(() => this.formBuilder.control(false)),
        );
      } else if (form.questions[key].type === 'sort') {
        group[form.questions[key].id] = [form.questions[key].options.map((arr: any) => arr.id), Validators.required];
      } else if (form.questions[key].type === 'text') {
        group[form.questions[key].id] = ['', Validators.required];
      } else if (form.questions[key].type === 'rating') {
        group[form.questions[key].id] = ['', Validators.required];
      }
    });

    this.form = this.formBuilder.group(group);

  }

  submit() {
    const answers = [];
    this.blockSend = false;
    this.errors = {};
    Object.keys(this.testBody.questions).forEach((key) => {
      if (this.testBody.questions[key].type === 'radio') {
        if (this.form.get(String(this.testBody.questions[key].id)).value) {
          this.errors[this.testBody.questions[key].id] = false;
          answers.push({
            question_id: this.testBody.questions[key].id,
            options: [+this.form.get(String(this.testBody.questions[key].id)).value],
            text: '',
          });
        } else {
          this.errors[this.testBody.questions[key].id] = true;
          this.blockSend = true;
          answers.push({
            question_id: this.testBody.questions[key].id,
            options: [],
            text: '',});
        }
      } else if (this.testBody.questions[key].type === 'checkbox') {
        const checkboxAnswers = [];
        Object.keys(this.form.get(String(this.testBody.questions[key].id)).value).forEach((i) => {
          if (this.form.get(String(this.testBody.questions[key].id)).value[i]) {
            checkboxAnswers.push(this.testBody.questions[key].options[i].id);
          }
        });
        if (checkboxAnswers.length > 0) {
          this.errors[this.testBody.questions[key].id] = false;
        } else {
          this.errors[this.testBody.questions[key].id] = true;
          this.blockSend = true;
        }
        answers.push({
          question_id: this.testBody.questions[key].id,
          options: checkboxAnswers,
          text: '',
        });
      } else if (this.testBody.questions[key].type === 'sort') {
        this.errors[this.testBody.questions[key].id] = false;
        answers.push({
          question_id: this.testBody.questions[key].id,
          options: this.form.get(String(this.testBody.questions[key].id)).value,
          text: '',
        });
      } else if (this.testBody.questions[key].type === 'text') {
        if (this.form.get(String(this.testBody.questions[key].id)).value !== '') {
          this.errors[this.testBody.questions[key].id] = false;
        } else {
          this.errors[this.testBody.questions[key].id] = true;
          this.blockSend = true;
        }
        answers.push({
          question_id: this.testBody.questions[key].id,
          options: [],
          text: this.form.get(String(this.testBody.questions[key].id)).value,
        });
      } else if (this.testBody.questions[key].type === 'rating') {
        const ratingValue = String(this.form.get(String(this.testBody.questions[key].id)).value);
        const ratingOptions = this.testBody.questions[key].options;
        const ratingAnswer = ratingOptions.find( item => item.text === ratingValue);
        if (this.form.get(String(this.testBody.questions[key].id)).value !== '') {
          this.errors[this.testBody.questions[key].id] = false;
        } else {
          this.errors[this.testBody.questions[key].id] = true;
          this.blockSend = true;
        }
        answers.push({
          question_id: this.testBody.questions[key].id,
          options: [ratingAnswer.id],
          text: '',
        });
      }
    });
    if (this.blockSend && !this.timeOut) {
      if (!this.anywaySubmit) {
        const data = {
          'title': 'Не на все вопросы даны ответы.',
          confirmButtonText: 'ОK',
        };
        this.dialogService.openDialog(AlertDialogComponent, data);
      }
    } else {
      this.noSave = true;
      const answersOn = {
        attempt_id: this.testBody.attempt_id,
        answers: answers,
      };
      this.baseService.baseFinalTestAttemptFinish(answersOn).subscribe((response: any) => {
        let data = {};
        if (this.timeOut) {
          data = {title: 'Время вышло.', confirmButtonText: 'Перейти к изучению материалов', notButtonText: 'МОИ ОТВЕТЫ', isTest: true}
        } else {
          const success = 'Тест пройден успешно, но вы можете ознакомиться с материалами по темам из теста';
          const failure = `Вы набрали <strong>${response.result} баллов из 100</strong>, советуем изучить материалы по тем вопросам, в которых вы допустили ошибки`;
          data = {  
              text: response.success ? success : failure, 
              confirmButtonText: 'Перейти к изучению материалов',
              notButtonText: 'МОИ ОТВЕТЫ',
              isTest: true
          }
        }
        this.dialogService.openDialog(AlertDialogComponent, data, '', true).subscribe((res: any) => {
          if (res) {
              this.router.navigate([RootRoute.personal, PersonalRoute.statistics], {queryParams: {doc_id: response.doc_id}})
          } else {
            this.initiateTimer = false;
            this.more_detail = response.more_detail;
            clearInterval(this.timer);
            this.baseService.baseFinalTestGetAttempt(this.testBody.attempt_id).subscribe((responseAnswers: any) => {
              this.showRightAnswers = true;
              this.rightAnswers = responseAnswers.questions;

              console.log(this.rightAnswers)
            });
          }
        });
      }, () => {
        this.dialogService.openDialog(AlertDialogComponent, {title: 'Возникла ошибка отправки ответов.', text: 'Пожалуйста, сделайте скриншот и отправьте на почту поддержки: support@learn.nyxcosmetic.ru', confirmButtonText: 'ОК'})
        clearInterval(this.timer);
      });
    }
    if (this.anywaySubmit) {
      this.errors = {};
      const answersOn = {
        attempt_id: this.testBody.attempt_id,
        answers: answers,
      };
      this.baseService.baseFinalTestAttemptFinish(answersOn).subscribe(() => {
        this.location.back();
      }, () => {
        this.dialogService.openDialog(AlertDialogComponent, {
          title: 'Возникла ошибка отправки ответов.',
          text: 'Пожалуйста, сделайте скриншот и отправьте на почту поддержки: support@learn.nyxcosmetic.ru',
          confirmButtonText: 'ОК'})
        clearInterval(this.timer);
      });
    }
  }

  @HostListener('window:beforeunload')
  doUnload() {
    return false
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.showRightAnswers = false;
      this.baseService.baseFinalTestAttemptStart(params.id).subscribe((response: any) => {
        this.testBody = response;
        this.makeFormGroup(response);
        if (response.time) {
          this.time = +response.time;
          this.timeleft = this.time * 60;
          this.initCountdown();
        }
        console.log(response);
      }, () => {
        this.location.back();
      });
    });
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }
}
