<div class="custom_dialog game_moderator">
  <div class="container_popup_test">
    <img class="img_background" [src]="question.image ? question.image : 'assets/images/popup_test.png'">
    <div class="container_test">
      <div class="container_button_cells popup">
        <div class="text" *ngIf="right">
          <p>{{lang === 'ru' ? 'Это правильный ответ' : 'This is right answer'}}</p>
          <div class="coin_container">
            <p>{{lang === 'ru' ? 'Вы заработали' : 'You are got'}} +{{question.scores}}</p>
            <div class="coin_popup"></div>
          </div>
        </div>
        <div class="text" *ngIf="!right">
          <p>{{lang === 'ru' ? 'Ответ неверный.' : 'The answer is not correct.'}}</p>
          <p>{{lang === 'ru' ? 'Рекомендуем пройти обучение.' : 'We recommend getting trained.'}}</p>
          <p>{{lang === 'ru' ? 'Желаем удачи на следующем звонке.' : 'We wish you good luck on your next call.'}}</p>
        </div>
        <div class="container_coin">
          <div class="button_cells" (click)="close()">ОК</div>
        </div>
      </div>
    </div>
  </div>

</div>
