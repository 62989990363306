<div class="compliment">
    <button mat-button [ngClass]="{active: isPlay}" (click)="onClose()">
        <app-icon-loader [name]="'close'" [size]="26"></app-icon-loader>
    </button>
    <div class="video">
        <img *ngIf="!isPlay" src="../../../../assets/newYear.jpg" alt="newYear">
        <vg-player>
            <vg-overlay-play></vg-overlay-play>
            <vg-buffering></vg-buffering>
        
            <video [vgMedia]="$any(media)" (play)="onPlay()" [poster]="''" #media id="singleVideo" preload="auto" crossorigin>
                <source type="video/mp4" [src]="link">
            </video>
        </vg-player>
    </div>
</div>  