import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss']
})
export class SelectOptionComponent {
  @Input() value: number;
  @Input() label: string;
  @Output() add = new EventEmitter<{value: number, label: string}>();
  @Output() remove = new EventEmitter<{value: number, label: string}>();
  
  checked: boolean;

  onChange($event: MatCheckboxChange): void {
    if($event.checked) {
      this.add.emit({value: this.value, label: this.label})
    } else {
      this.remove.emit({value: this.value, label: this.label})
    }
  }

}
