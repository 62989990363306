<app-header [logoWhite]="true"></app-header>

<div class="outer" id="top" [ngStyle]="{'backgroundImage': 'url(assets/images/game/bg_game_3.png)'}">
  <div class="workspace" *ngIf="!extraService.gamePaused">

    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>BE IN TOUCH</span>
        </div>
      </div>
    </div>

    <div class="text" >{{description}}</div>

    <div class="container_button_cells">
      <div class="button_cells time">
        <div class="icon_time"></div>
        <div class="text_time">{{timeleft | msToTime}}</div>
      </div>
      <div class="button_cells" (click)="pause()">{{lang === 'ru' ? 'сделать паузу' : 'pause'}}</div>
      <div class="button_cells" (click)="submit()">{{lang === 'ru' ? 'завершить' : 'end'}}</div>
    </div>

    <div class="container_coin">
      <div *ngFor="let question of questions" (click)="openTest(question)">
        <div class="coin" [id]='question.id'>
          <div class="coin_icon" *ngIf="!question.open"></div>
          <div class="coin_open" *ngIf="question.open">
            <div class="coin_title">{{question.topic}}</div>
            <div class="coin_result">
              {{question.scores}}
              <div [id]='question.id + "icon"' class="coin_icon_result"></div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>
