import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { ApiService } from 'core/api'
import { OverallUserScores, UserScores } from 'core/eco-portal'
import { Pagination } from 'core/common'
import { createEcoStatisticsUrls } from './eco-statistics-urls'

@Injectable({
  providedIn: 'root'
})
export class EcoStatisticsService extends ApiService {
  private readonly urls = createEcoStatisticsUrls()

  /**
   * Gets the overall user scores.
   */
  getOverallScores(): Observable<OverallUserScores> {
    return this.http.post<OverallUserScores>(this.urls.getOverallScores, null)
  }

  /**
   * Gets a list of user scores.
   *
   * @param pagination
   */
  getScoreList(pagination: Pagination): Observable<UserScores[]> {
    return this.http.post<UserScores[]>(this.urls.getScoreList, pagination)
  }
}
