<app-header [showUser]="false"></app-header>

<div class="outer" [@show_preview]="showPrev">
  <div class="workspace newest">
    <router-outlet></router-outlet>
    <app-side-component-menu [folders]="folders" *ngIf="folders && showMenu"></app-side-component-menu>
    <div class="mobile_menu_container" *ngIf="!showMenu && folders">
      <div class="button_menu" (click)="openMenuMobile()">
        <div>{{extraService.activeButtonTitle}}</div>
      </div>
      <div class="arrow_icon" [ngClass]="{ arrow_down: !open, arrow_up: open }"></div>
      <app-side-component-menu (click)="openSideMenuMobile()" [folders]="folders" [typeSection]="'articles'" [ngClass]="{no_show: !open, show: open}"></app-side-component-menu>
    </div>
  </div>
</div>
