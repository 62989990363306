<div  class="container">
  <div class="header_store">
    <div class="button_basket basket">
      <div class="text_button_store" (click)="openHistory()">{{lang === 'ru' ? 'история покупок' : 'cart history'}}</div>
    </div>
    <div class="button_basket">
      {{account}}
      <div class="coin_icon"></div>
    </div>
  </div>

  <div class="container_store">
    <div class="product" *ngFor="let product of cart">
      <div class="image_store">
        <img [src]="product.preview" class="image">
        <mat-checkbox class="checkbox_basket" (change)="changeChecked($event, product)">
        </mat-checkbox>
      </div>
      <div class="title">{{product.title}}</div>
      <div class="price">
        {{product.price}}
        <div class="price_coin"></div>
      </div>
      <div class="container_sum" *ngIf="shoppingList.length && product.checked">
<!--        <div class="plus" (click)="changeQuantity(product, '+')"></div>-->
        <div class="sum">{{changeQuantity(product)}} шт.</div>
<!--        <div class="minus" (click)="changeQuantity(product, '-')"></div>-->
      </div>
    </div>
  </div>

  <div class="container_summa">
    <div class="text_container">
      <div class="product_sum">{{lang === 'ru' ? 'выбрано товаров:' : 'selected products'}}<span>{{shoppingList.length}}</span></div>
      <div class="product_sum">
        {{lang === 'ru' ? 'на сумму:' : 'sum'}}
        {{sum}}
        <div class="coin_sum"></div>
      </div>
      <div class="error_text" *ngIf="sum > account">{{lang === 'ru' ? 'Недостаточно баллов для оплаты товара' : 'Not enough points to pay for the product'}}</div>
    </div>
    <div class="button_container">
      <div class="button_sum" (click)="clearCart()">{{lang === 'ru' ? 'удалить' : 'clear'}}</div>
      <div class="button_sum" (click)="buy()">{{lang === 'ru' ? 'купить' : 'buy'}}</div>
    </div>
  </div>

</div>
