import { Injectable } from '@angular/core'

import { ApiService } from 'core/api'
import { createStatisticsUrls } from './statistics-urls'
import { Observable } from 'rxjs'
import { env } from 'env';
import { WronTestQuestion } from 'app/api/profile/statistics';
import * as moment from 'moment'

@Injectable({
  providedIn: 'root'
})
export class StatisticsService extends ApiService {
  private readonly urls = createStatisticsUrls()


  statisticsGetParams(ref: string): Observable<any>{
    return this.http.post<any>(this.urls.getParams + ref, null)
  }

  statisticsGetParamsResult(ref: any): Observable<any>{
    return this.http.post<any>(this.urls.getParamsResult, ref)
  }

  statisticsGetMaterials(id = null) {
    return this.http.post(this.urls.getMaterials, {id})
  }

  statisticsGetScores(limit, offset) {
    return this.http.post(this.urls.getScores, {limit, offset})
  }

  get statisticsGetRulesUrl() {
    return this.urls.getRules
  }

  statisticsGetCourses() {
    return this.http.post(this.urls.getCourses, null)
  }

  statisticsGetList() {
    return this.http.post(this.urls.getList, null)
  }

  statisticsGetCoursesTotalReport() {
    return this.http.post(this.urls.getCoursesTotalReport, null)
  }

  statisticsGetCoursesDetailReport(id) {
    return this.http.post(this.urls.getCoursesDetailReport, {id})
  }

  statisticsGetGameReport(game_id) {
    return this.http.post(this.urls.getGameReport, {game_id})
  }

  statisticsGetReportParams(reference: string, doc_id = null) {
    return this.http.post(this.urls.getReportParams(reference), doc_id ? {doc_id} : null)
  }

  statisticsGetScormReport(element_id) {
    return this.http.post(this.urls.getScormReport, {element_id})
  }

  statisticsBeInTouch(): Observable<any> {
    return this.http.post(this.urls.getBeInTouch, null)
  }

  statisticsGetGroups(filters = null) {
    return this.http.post(this.urls.getGroups, {filters})
  }

  statisticsGetInfographicsAssessment(year, month, filters, group) {
    return this.http.post(this.urls.getInfographicsAssessment, {year, month, filters, group})
  }

  statisticsGetInfographicsPerformance(date, filters, group) {
    return this.http.post(
      this.urls.getInfographicsPerformance,
      {
        start: +date.start.locale('ru').format('x'),
        finish: +date.finish.locale('ru').format('x'),
        filters,
        group
      }
    )
  }

  statisticsGetInfographicsTopCourses(number: number) {
    return this.http.post(this.urls.getInfographicsTopCourses(number), null)
  }

  statisticsGetInfographicsStandards() {
    return this.http.post(this.urls.getInfographicsStandards, null)
  }

  finalTestWrongQuestion(doc_id: number): Observable<WronTestQuestion[]> {
    return this.http.post<WronTestQuestion[]>(env.rootApiUrl + 'final-tests/wrong-questions/', {doc_id})
  }

  addStatisticsLog(logs) {
    return this.http.post(this.urls.getMetricsAdd, {logs: logs})
  }

  getReferenceList() {
    return this.http.post(this.urls.getReferenceList, null)
  }

  getReportsList(reference: string) {
    return this.http.post(this.urls.getReportsList(reference), null)
  }

  getContentCourseReport(formValue) {
    let currentFinish = +moment(formValue.finish).add(3, 'h').add(1, 'm').format('x')
    let currentStart = +moment(formValue.start).add(3, 'h').add(1, 'm').format('x')
    return this.http.post(this.urls.getCountentCourseReport(formValue.reference), {
      start: currentStart,
      finish: currentFinish,
      doc_id: formValue.doc_id
    })
  }

}
