import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormControl, FormGroup } from '@angular/forms'

import { DialogService } from 'services'
import { BaseService } from 'services/api'

import { AlertDialogComponent } from '..'

import * as moment from 'moment'
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'

@Component({
  selector: 'app-final-test-change-dialog',
  templateUrl: './final-test-change-dialog.component.html',
  styleUrls: ['./final-test-change-dialog.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})

export class FinalTestChangeDialogComponent implements OnInit {

  title: string;
  text: string;
  currentDate: any;
  profileId: any;

  numAttempts: any;

  type: string;

  form: FormGroup;

  constructor(
    private dialogService: DialogService,
    private baseService: BaseService,
    private dialogRef: MatDialogRef<FinalTestChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {'title': string, 'text': string, 'currentDate': any, 'numAttempts': any, 'profileId': any, 'type': string },
  ) {
    ({
      title: this.title,
      text: this.text,
      currentDate: this.currentDate,
      numAttempts: this.numAttempts,
      profileId: this.profileId,
      type: this.type
    } = this.dialogData);
  }

  dateChange(event, control) {
    control.setValue(moment(event.value.valueOf()));
    control.markAsDirty();
  }

  onConfirmDeadline() {
    let data;
    this.baseService.baseFinalTestProfileEdit('deadline', this.form.get('id').value, this.form.get('deadline').value._i).subscribe(() => {
      data = {
        title: 'Дедлайн продлен успешно',
        confirmButtonText: 'OK'
      }
      this.dialogService.openDialog(AlertDialogComponent, data).subscribe(() => {
        this.dialogRef.close()
      })
    }, () => {
      data = {
        title: 'Произошла ошибка',
        confirmButtonText: 'OK'
      }
      this.dialogService.openDialog(AlertDialogComponent, data).subscribe(() => {
        this.dialogRef.close()
      })
    })
  }

  onConfirmAttempts() {
    let data;
    this.baseService.baseFinalTestProfileEdit('attempts', this.form.get('id').value, null, this.form.get('numAttempts').value).subscribe(() => {
      data = {
        title: 'Попытки добавлены успешно',
        confirmButtonText: 'OK'
      }
      this.dialogService.openDialog(AlertDialogComponent, data).subscribe(() => {
        this.dialogRef.close()
      })
    }, () => {
      data = {
        title: 'Произошла ошибка',
        confirmButtonText: 'OK'
      }
      this.dialogService.openDialog(AlertDialogComponent, data).subscribe(() => {
        this.dialogRef.close()
      })
    })
  }

  ngOnInit() {
    if (this.type === 'deadline') {
      this.form = new FormGroup({
        'id': new FormControl(this.profileId),
        'deadline': new FormControl(null)
      })
    }
    if (this.type === 'attempts') {
      this.form = new FormGroup({
        'id': new FormControl(this.profileId),
        'numAttempts': new FormControl(null)
      })
    }
  }

}
