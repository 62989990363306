<div class="custom_dialog user_change">
  <form [formGroup]="form" (ngSubmit)="onConfirm()" enctype="multipart/form-data">
    <div mat-dialog-title class="dialog-text" [innerHTML]="title | safeHtml"></div>

    <div mat-dialog-content class="dialog-content">
      <div class="input-container">
        <p>Старый пароль</p>
        <input
          type="password"
          formControlName="old_password"
        >
        <div class="error" *ngIf="form.get('old_password').invalid && form.get('old_password').touched">
          <div *ngIf="form.get('new_password')['errors']['required']">Заполните поле</div>
        </div>
      </div>
      <div class="input-container">
        <p>Новый пароль</p>
        <input
          type="password"
          formControlName="new_password"
        >
        <div class="error" *ngIf="form.get('new_password').invalid && form.get('new_password').touched">
          <div *ngIf="form.get('new_password')['errors']['minlength']">Поле должно содержать не менее 8 символов</div>
          <div *ngIf="form.get('new_password')['errors']['required']">Заполните поле</div>
        </div>
      </div>
    </div>

    <button type="submit" [disabled]="form.invalid || form.pristine" class="dialog_button user_change_button">{{confirmButtonText}}</button>
  </form>
</div>
