import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { RootRoute } from 'app/constants/root-routes';
import { filter, map } from 'rxjs/operators';
import { AuthService, BaseService, ExtraService } from 'services';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class RootComponent  implements OnInit{

  constructor(
    private router: Router,
    private readonly authService: AuthService,
    public readonly extraService: ExtraService,
    private baseService: BaseService
  ){}

  ngOnInit(): void {
    this.listenToRoute();
  }

  private listenToRoute(): void {
    this.router.events
    .pipe(
      filter(v => v instanceof NavigationStart),
      map((v: NavigationStart) => v.url)
    )
    .subscribe({
      next: (v:string) => {
        const user = this.extraService.user;

        if(v !== `/${RootRoute.auth}` && !user) {
          this.authService.getUser().subscribe((response: any) => {
            this.extraService.profileInfoVal = response;
            this.extraService.user = response;
          });
      
          this.baseService.documentsContent().subscribe((response: any) => {
            this.extraService.documentContent = response;
          })
        }

        if(v === `/${RootRoute.homework}`) {
          this.router.navigate([RootRoute.main])
        }
      }
    })
  }
}
