<app-header></app-header>

<div class="outer" [ngStyle]="{'backgroundImage': 'url(' + this.backgroundWord + ')'}">
  <div class="workspace">

    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>Назад</span>
        </div>
      </div>
    </div>

    <div class="text" [innerHTML]="text | safeHtml"></div>

    <section class="section_drop_container">
      <div
        class="item_container"
        cdkDropList
        [cdkDropListData]="items"
        [cdkDropListConnectedTo]="['words']"
        (cdkDropListDropped)="drop($event)"
      >
        <div class="item" *ngFor="let item of items" cdkDrag [cdkDragData]="item">
          <div class="text" *ngIf="item.text !== ''">{{item.text}}</div>
        </div>
      </div>
      <div
        class="drop_container"
        *ngIf="boxes"
        cdkDropList
        [cdkDropListData]="words"
        (cdkDropListDropped)="drop($event)"
        id="words"
      >
      <ng-container *ngFor="let word of words; let i = index">
        <div class="item" [ngClass]="{paragraph: word.paragraph}" *ngIf="word.paragraph" style="height: 1px;"></div>
        <div class="item" [innerHTML]="word.text | replacementWord: 'game'"></div>
      </ng-container>
      </div>
    </section>
  </div>
</div>
