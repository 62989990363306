export enum CatalogQuery {
    search = 'catalog/search/',
    autocomplete = 'catalog/code/',
    filter = 'catalog/elements/'
}

export interface ProductFile {
    file: string;
}

export interface Product {
    id: number;
    title: string;
    description?: string;
    box?: string;
    codes?: any[];
    files: ProductFile[];
    file?: string;
    comparison?: ComparisonItem;
}

export interface EAN {
    product: Product;
    code: string;
}

export interface Tag {
    id: number;
    name: string;
}

export interface TestQuestion {
    id: number;
    title: string;
    description: string;
    ean: EAN;
    tag: Tag;
}

export interface ComparisonRes {
    comparison: ComparisonItem[];
    products: Product[];
}

export interface ComparisonItem {
    title: string;
    values: string[];
}