<app-header [logoWhite]="true"></app-header>

<div class="outer" [ngStyle]="{'backgroundImage': 'url(' + extraService.backgroundStore + ')'}">

  <div class="workspace">

    <div class="bread_and_title_container">
      <div class="bread_and_title game_moder">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>BE IN TOUCH</span>
        </div>
      </div>
    </div>

    <div class="rules_container" *ngIf="rules">
      <div class="title" [innerHTML]="rules.rules.title | safeHtml"></div>
      <div class="text" [innerHTML]="rules.rules.text | safeHtml"></div>
      <div class="agreement_container">
        <div class="agreement">
          <mat-checkbox (change)="changeAgreement($event)"></mat-checkbox>
          <span *ngIf="lang === 'ru'">Согласие на <span class="underline" (click)="onRules()">обработку данных</span></span>
          <span *ngIf="lang === 'en'">Consent to <span class="underline" (click)="onRules()">data processing</span></span>
        </div>
        <div class="agreement_check" *ngIf="this.rulesCheck">
          <span *ngIf="lang === 'ru'">Подтвердите согласие на обработку данных</span>
          <span *ngIf="lang === 'en'">Confirm consent to data processing</span>
        </div>
      </div>
      <div class="button" [innerHTML]="rules.start_button.text | safeHtml" (click)="goStages()"></div>
    </div>

  </div>

</div>
