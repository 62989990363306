<div appDropdown class="search">
    <div class="container">
      <input type="text" placeholder="Арт." [formControl]="searchForm" (focus)="toggleDropdown(true)" (keydown.enter)="submit()" class="input">
      <button class="submit" (click)="submit()" [disabled]="!searchForm.value.length">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10a7 7 0 1 0 14 0a7 7 0 1 0-14 0m18 11l-6-6"/>
        </svg>
      </button>
    </div>
    <ng-container *ngIf="(options$ | async) as options">
      <div class="dropdown" *ngIf="(isVisible$ | async) && (options?.length > 0) && (options[0] !== searchForm.value)">
          <ng-container [ngTemplateOutlet]="dropdownContent"></ng-container>
      </div>
  </ng-container>
</div>


<ng-template #dropdownContent>
  <div class="box">
    <ng-scrollbar>
      <ul>
        <li *ngFor="let option of options$ | async" class="item" [ngClass]="{'active':searchForm.value === selectedItem}" (click)="onSelectOption(option)">{{option}}</li>
      </ul>
    </ng-scrollbar>
  </div>
</ng-template>
