import { AUTO_STYLE, animate, state, style, transition, trigger } from '@angular/animations';

export const fadeAnimation =  trigger('fade', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(200, style({
          opacity: 1,
        })),
      ]),
]);

export const cardFlip =  trigger("cardFlip", [
  state(
    "default",
    style({
      transform: "none"
    })
  ),
  state(
    "flipped",
    style({
      transform: "rotateY(180deg)"
    })
  ),
  state(
    "matched",
    style({
      visibility: "false",
      transform: "scale(0.05)",
      opacity: 0
    })
  ),
  transition("default => flipped", [animate("400ms")]),
  transition("flipped => default", [animate("400ms")]),
  transition("* => matched", [animate("400ms")])
]);



export const COLLAPSE = trigger('collapse', [
    state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
    state('true', style({ height: '0', visibility: 'hidden' })),
    transition('false => true', animate(200 + 'ms ease-in')),
    transition('true => false', animate(200 + 'ms ease-out'))
])