import { Component, OnInit } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { ActivatedRoute } from '@angular/router'
import { Location } from '@angular/common'
import { BaseService } from 'services/api'
import { DialogService } from 'services'
import { AlertDialogComponent } from 'components/dialogs'

interface IDropEntity {
  file: string | undefined,
  id: number,
  order: number,
  text: string | undefined,
  paragraph?: boolean
}

interface IWord {
  text: string,
  paragraph: boolean
}

@Component({
  selector: 'app-game-drop-word',
  templateUrl: 'game-drop-word.component.html',
  styleUrls: ['game-drop-word.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})
export class GameDropWordComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private baseService: BaseService,
    private dialogService: DialogService
  ) {
  }

  stageId: number
  brand: string
  text: string
  backgroundWord: string
  boxes: IDropEntity[]
  items: IDropEntity[] | any
  words: IWord[] = []
  startOrder = 1

  goBack() {
    this.location.back()
  }

  drop(event) {
    if (event.previousContainer === event.container) {
      return
    } else {
      if (event.item.data.order === this.startOrder) {
        let index = this.words.findIndex(item => item.text === '')
        if (~index) {
          if (index === this.words.length - 1) {
            this.words[index].text =  '&nbsp;' + event.item.data.text;
          } else {
            this.words[index].text =  '&nbsp;' + event.item.data.text + '&nbsp;';
          }
        }
        const ArrItemsIndex = this.items.indexOf(event.item.data)
        this.items.splice(ArrItemsIndex, 1)
        this.startOrder += 1
        if (!this.items.length) {
          const data = {
            title: 'Миссия пройдена!',
            confirmButtonText: 'OK'
          }
          this.dialogService.openDialog(AlertDialogComponent, data).subscribe(() => {
            const formData = new FormData()
            formData.append('stage_id', String(this.stageId));
            this.baseService.baseGameResultCreate(formData).subscribe(() => {
              this.location.back()
            })
          })
        }
      }
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.brand = params.brand
      this.stageId = params.stageId
      this.baseService.baseGetGameStage(this.stageId).subscribe((response: any) => {
        this.text = response.text
        this.backgroundWord = response.background
        this.boxes = response.elements.boxes
        this.items = response.elements.items
        this.boxes.forEach((item) => {
          this.words.push({
            text: item.text,
            paragraph: item.paragraph
          })
        })
      })
    })
  }

}
