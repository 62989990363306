import { Component } from '@angular/core';
import { DestroyRef } from 'core/common';


@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  providers: [DestroyRef]
})
export class MainLayoutComponent {}
