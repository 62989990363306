import { Injectable } from '@angular/core';
import { TestQuestion } from 'app/api/game-moderator/test';
import { TestAnswer } from 'app/typings/game-moderator/game-moderator';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class GameModerLiteracyTestService {
 readonly attemptSubject = new BehaviorSubject<number>(null);

 private currentQuestionSubject = new BehaviorSubject<number>(0);
 currentQuestion$: Observable<number> = this.currentQuestionSubject.asObservable();

 private questionSubject = new BehaviorSubject<TestQuestion[]>([]);
 question$: Observable<TestQuestion[]> = this.questionSubject.asObservable();

 private counterSubject = new BehaviorSubject<number>(0);
 counter$: Observable<number> = this.counterSubject.asObservable();

 private answersSubject = new BehaviorSubject<TestAnswer[]>(null);
 answers$: Observable<TestAnswer[]> = this.answersSubject.asObservable();

 get answers () {
  return this.answersSubject.getValue();
 }
 
 set answers (data: TestAnswer[]) {
  this.answersSubject.next(data);
 }
 
 get currentQ () {
  return this.currentQuestionSubject.getValue();
 }

 set currentQ (index: number) {
  this.currentQuestionSubject.next(index);
 }

 get question() {
  return this.questionSubject.getValue();
 }

 set question(data: TestQuestion[]) {
  this.questionSubject.next(data);
 }

 get counter() {
  return this.counterSubject.getValue();
 }

 set counter(index: number) {
  this.counterSubject.next(index);
 }
}
