import { Component, OnInit, ViewChild } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { ActivatedRoute } from '@angular/router'
import { Location } from '@angular/common'
import { BaseService } from 'services/api'
import { DialogService } from 'services'
import { AlertDialogComponent } from 'components/dialogs'

@Component({
  selector: 'app-game-photo',
  templateUrl: 'game-photo.component.html',
  styleUrls: ['game-photo.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})
export class GamePhotoComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private baseService: BaseService,
    private dialogService: DialogService
  ) {
  }

  @ViewChild( 'photoInput' ) photoInput;

  stageId: number
  brand: string
  text: string
  backgroundWord: string

  file: any;
  loadPhoto = '';

  goBack() {
    this.location.back()
  }

  getPicture() {
    if (!this.loadPhoto) {
      this.photoInput.nativeElement.click();
    } else {
      const data = {
        title: 'Миссия пройдена!',
        confirmButtonText: 'OK'
      }
      console.log(this.file);
      this.dialogService.openDialog(AlertDialogComponent, data).subscribe(() => {
        const formData = new FormData()
        formData.append('stage_id', String(this.stageId));
        formData.append( 'file', this.file );
        this.baseService.baseGameResultCreate(formData).subscribe(() => {
          this.location.back()
        })
      })
    }
  }

  processWebImage(event) {
    if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png') {
      this.file = event.target.files[0];

      const reader: FileReader = new FileReader();
      const self = this;
      reader.onload = (readerEvent: any) => {
        self.loadPhoto = readerEvent.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.brand = params.brand
      this.stageId = params.stageId
      this.baseService.baseGetGameStage(this.stageId).subscribe((response: any) => {
        this.text = response.text
        this.backgroundWord = response.background
      })
    })
  }
}
