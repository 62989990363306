import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from 'services/api';

@Component({
  selector: 'app-catalogs_old-list',
  templateUrl: './catalogs_old-list.component.html',
  styleUrls: ['./catalogs_old-list.component.scss']
})
export class CatalogsOldListComponent implements OnInit {

  tab = 1;

  catalogs = undefined;
  pdf = undefined;
  filesMny = 'https://mbl-old-api.evpportal.ru/files/';

  tabs = {
    1: ['4a9a9a92-082d-11e9-9d57-8f161d225a29', '5aee4dbc-082d-11e9-92a4-bf0346f84061', '83382af8-ca79-11ea-959a-618c9200fad8', '1d76bcbe-ca85-11ea-a5a2-2dd6ebaea304', '3832ed28-b357-11eb-ad37-0242de57301f'],
    2: ['f6fd6cec-09a4-11e9-9139-857bb6080b3a'],
    3: ['b968251a-662c-11ec-b9f4-0242ffeaadbd'],
    4: ['c9061a68-aa35-11ea-8271-43e56ae7c403', '0eef4390-b708-11ea-a5a2-2dd6ebaea304', 'a81d1854-bf81-11ea-959a-618c9200fad8', '8e29496c-c64e-11ea-a5a2-2dd6ebaea304', '5fc2115c-ca55-11ea-8271-43e56ae7c403'],
  }

  constructor(
    private router: Router,
    private api: BaseService
  ) {}

  tabOn(type) {
    this.tab = type;
    this.catalogs = undefined;
    this.pdf = undefined;
    if (type !== 5) {
      this.api.mnyCatalog(null, this.tabs[this.tab])
      .subscribe((response: any) => {
        console.log(response);
        this.catalogs = response;
      });
    } else {
      this.api.catalogContent()
      .subscribe((response: any) => {
        console.log(response);
        this.pdf = response;
      });
    }
  }

  backFolderPdf() {
    this.api.catalogContent()
    .subscribe((response: any) => {
      console.log(response);
      this.pdf = response;
    });
  }

  openFolderPdf(id) {
    this.api.catalogContent(id)
    .subscribe((response: any) => {
      console.log(response);
      this.pdf = response;
    });
  }

  openCatalog(catalogUid: string){
    this.router.navigate(['catalogs_old/' + catalogUid + '/mny']);

    // if (path === 'mny'){
    //   this.router.navigate(['catalogs/' + catalogUid + '/mny']);
    // } else if (path === 'nyx') {
    //   this.router.navigate(['catalogs', 'nyx']);
    // }
  }

  goBack() {
    this.router.navigate(['learning/learning-home']);
  }

  ngOnInit() {
    this.api.mnyCatalog(null, this.tabs[this.tab])
    .subscribe((response: any) => {
      console.log(response);
      this.catalogs = response;
    });
  }
}
