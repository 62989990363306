import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConsultantRoute, EducationRoute, HrRoute, QuestionsRoute, RetailRoute, WelcomeRoute } from 'app/constants/welcome-book';
import { WelcomeNavigationItem, WelcomePhoneItem } from 'app/typings/welcome-book';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class WelcomeLayoutService {
  #navigationTitle = new BehaviorSubject<WelcomeNavigationItem>(null);
  navigationTitle$: Observable<WelcomeNavigationItem> = this.#navigationTitle.asObservable();

  #list = new BehaviorSubject<WelcomePhoneItem[]>([]);
  list$: Observable<WelcomePhoneItem[]> = this.#list.asObservable();

  #breadcrumb = new BehaviorSubject<boolean>(true);
  breadcrumb$: Observable<boolean> = this.#breadcrumb.asObservable();

  set breadcrumb(state: boolean) {
    this.#breadcrumb.next(state)
  }

  constructor(private router: Router) {
    this.onRouteChanges();
  }

  private onRouteChanges(): void {
    this.router.events
    .pipe(
      filter(v => v instanceof NavigationEnd)
    )
    .subscribe((v: NavigationEnd) => {
        this.checkSection(v.urlAfterRedirects);
    })
  }

  private checkSection(url: string): void {
    const parts = url.split('/').filter(v => v);

    switch (parts[1]) {
      case WelcomeRoute.hr: {
        const list = [
          {
            title: 'СУРВ',
            route: HrRoute.timeTracker
          },
          {
            title: 'Заработную плату',
            route: HrRoute.wage
          },
          {
            title: 'Бонусную схему',
            route: HrRoute.bonusScheme
          },
          {
            title: 'Политику выплаты премии',
            route: HrRoute.bonusPayment
          },
          {
            title: 'Медицинские осмотры',
            route: HrRoute.medical
          },
          {
            title: 'Привилегии сотрудников',
            route: HrRoute.privilege
          }
        ];

        this.#list.next(list);

        if(!this.checkHr(parts[2])) {
          const item: WelcomeNavigationItem = {
            title: 'Hr',
            backRoute: WelcomeRoute.stages
          }

          this.#navigationTitle.next(item);
        }

        break
      }

      case WelcomeRoute.education: {
        const list = [
          {
            title: 'Направления обучения',
            route: EducationRoute.direction
          },
          {
            title: 'Интеграцию на ближайшие 3 месяца',
            route: EducationRoute.integration
          },
          {
            title: 'Обучающий портал',
            route: EducationRoute.learning
          },
          {
            title: 'Развитие в роли консультанта красоты',
            route: EducationRoute.development
          },
          {
            title: 'Тайный покупатель',
            route: EducationRoute.shopper
          },
          {
            title: 'Категории',
            route: EducationRoute.categories
          },
          {
            title: 'Как повысить свою категорию вниутри команды ВА',
            route: EducationRoute.promotion
          },
          {
            title: 'Тренинги обязательные',
            route: EducationRoute.training
          },
          {
            title: 'Проект наставник',
            route: EducationRoute.mentor
          },
        ];

        this.#list.next(list);

        if(!this.checkEducation(parts[2])) {
          const item: WelcomeNavigationItem = {
            title: 'Обучение',
            backRoute: WelcomeRoute.stages
          }

          this.#navigationTitle.next(item);
        }
        break
      }

      case WelcomeRoute.retail: {
        const list = [
          {
            title: 'KPI',
            route: RetailRoute.kpi
          },
          {
            title: 'Колесо компетенций',
            route: RetailRoute.wheel
          },
          {
            title: 'Мотивации',
            route: RetailRoute.motivation
          },
          {
            title: 'График работы',
            route: RetailRoute.graph
          },
          {
            title: 'Отчет L`Report',
            route: RetailRoute.report
          }
        ];

        this.#list.next(list);

        if(!this.checkRetail(parts[2])) {
          const item: WelcomeNavigationItem = {
            title: 'Retail',
            backRoute: WelcomeRoute.stages
          }

          this.#navigationTitle.next(item);
        }

        break
      }

      case WelcomeRoute.consultant: {
        const list = [
          {
            title: 'Кто такой консультант красоты?',
            route: ConsultantRoute.about
          },
          {
            title: 'История брендов',
            route: ConsultantRoute.history
          },
          {
            title: 'Стандарты внешнего вида',
            route: ConsultantRoute.cloakroom
          }
        ];

        this.#list.next(list);

        if(!this.checkConsultant(parts[2])) {
          const item: WelcomeNavigationItem = {
            title: 'Консультант красоты',
            backRoute: WelcomeRoute.stages
          }

          this.#navigationTitle.next(item);
        }
        break
      }

      case WelcomeRoute.questions: {
        const list = [
          {
            title: 'Куда направлять вопросы?',
            route: QuestionsRoute.direction
          },
          {
            title: 'Словарь',
            route: QuestionsRoute.dictionary
          },
          {
            title: 'Вопрос-ответ: переработки',
            route: QuestionsRoute.recycling
          },
          {
            title: 'Вопрос-ответ: больничный,отпуск',
            route: QuestionsRoute.holiday
          }
        ];

        this.#list.next(list);

        if(!this.checkQuestions(parts[2])) {
          const item: WelcomeNavigationItem = {
            title: 'Вопрос-ответ',
            backRoute: WelcomeRoute.stages
          }

          this.#navigationTitle.next(item);
        }
        break
      }

      default: {
        this.#navigationTitle.next(null);
      }
    }
  }

  private checkHr(url: string): boolean {

    switch (url) {
      case HrRoute.medical: {
        const item: WelcomeNavigationItem = {
          title: 'Медицинские осмотры',
          backRoute: WelcomeRoute.hr
        }

        this.#navigationTitle.next(item);

        return true
      }

      case HrRoute.privilege: {
        const item: WelcomeNavigationItem = {
          title: 'Привилегии сотрудников',
          backRoute: WelcomeRoute.hr
        }

        this.#navigationTitle.next(item);

        return true
      }

      case HrRoute.timeTracker: {
        const item: WelcomeNavigationItem = {
          title: 'СУРВ',
          backRoute: WelcomeRoute.hr
        }

        this.#navigationTitle.next(item);


        return true
      }

      case HrRoute.wage: {
        const item: WelcomeNavigationItem = {
          title: 'Заработную плату',
          backRoute: WelcomeRoute.hr
        }

        this.#navigationTitle.next(item);

        return true
      }

      case HrRoute.bonusPayment: {
        const item: WelcomeNavigationItem = {
          title: 'Политику выплаты премии',
          backRoute: WelcomeRoute.hr
        }

        this.#navigationTitle.next(item);

        return true
      }

      case HrRoute.bonusScheme: {
        const item: WelcomeNavigationItem = {
          title: 'Бонусную схему',
          backRoute: WelcomeRoute.hr
        }

        this.#navigationTitle.next(item);

        return true
      }

      default: return false;
    }
  }

  private checkEducation(url: string): boolean {

    switch (url) {
      case EducationRoute.categories: {
        const item: WelcomeNavigationItem = {
          title: 'Категории',
          backRoute: WelcomeRoute.education
        }

        this.#navigationTitle.next(item);

        return true
      }

      case EducationRoute.development: {
        const item: WelcomeNavigationItem = {
          title: 'Развитие в роли консультанта красоты',
          backRoute: WelcomeRoute.education
        }

        this.#navigationTitle.next(item);

        return true
      }

      case EducationRoute.direction: {
        const item: WelcomeNavigationItem = {
          title: 'Направления обучения',
          backRoute: WelcomeRoute.education
        }

        this.#navigationTitle.next(item);


        return true
      }

      case EducationRoute.integration: {
        const item: WelcomeNavigationItem = {
          title: 'Интеграцию на ближайшие 3 месяца',
          backRoute: WelcomeRoute.education
        }

        this.#navigationTitle.next(item);

        return true
      }

      case EducationRoute.learning: {
        const item: WelcomeNavigationItem = {
          title: 'Обучающий портал',
          backRoute: WelcomeRoute.education
        }

        this.#navigationTitle.next(item);

        return true
      }

      case EducationRoute.mentor: {
        const item: WelcomeNavigationItem = {
          title: 'Проект наставник',
          backRoute: WelcomeRoute.education
        }

        this.#navigationTitle.next(item);

        return true
      }

      case EducationRoute.promotion: {
        const item: WelcomeNavigationItem = {
          title: 'Как повысить свою категорию вниутри команды ВА',
          backRoute: WelcomeRoute.education
        }

        this.#navigationTitle.next(item);

        return true
      }

      case EducationRoute.training: {
        const item: WelcomeNavigationItem = {
          title: 'Тренинги обязательные',
          backRoute: WelcomeRoute.education
        }

        this.#navigationTitle.next(item);

        return true
      }

      case EducationRoute.shopper: {
        const item: WelcomeNavigationItem = {
          title: 'Тайный покупатель',
          backRoute: WelcomeRoute.education
        }

        this.#navigationTitle.next(item);

        return true
      }

      default: return false;
    }
  }

  private checkRetail(url: string): boolean {
    switch (url) {
      case RetailRoute.graph: {
        const item: WelcomeNavigationItem = {
          title: 'График работы',
          backRoute: WelcomeRoute.retail
        }

        this.#navigationTitle.next(item);

        return true
      }

      case RetailRoute.kpi: {
        const item: WelcomeNavigationItem = {
          title: 'KPI',
          backRoute: WelcomeRoute.retail
        }

        this.#navigationTitle.next(item);

        return true
      }

      case RetailRoute.motivation: {
        const item: WelcomeNavigationItem = {
          title: 'Мотивации',
          backRoute: WelcomeRoute.retail
        }

        this.#navigationTitle.next(item);


        return true
      }

      case RetailRoute.report: {
        const item: WelcomeNavigationItem = {
          title: 'Отчет L`Report',
          backRoute: WelcomeRoute.retail
        }

        this.#navigationTitle.next(item);

        return true
      }

      case RetailRoute.wheel: {
        const item: WelcomeNavigationItem = {
          title: 'Колесо компетенций',
          backRoute: WelcomeRoute.retail
        }

        this.#navigationTitle.next(item);

        return true
      }

      default: return false;
    }
  }

  private checkConsultant(url: string): boolean {
    switch (url) {
      case ConsultantRoute.about: {
        const item: WelcomeNavigationItem = {
          title: 'Кто такой консультант красоты?',
          backRoute: WelcomeRoute.consultant
        }

        this.#navigationTitle.next(item);

        return true
      }

      case ConsultantRoute.history: {
        const item: WelcomeNavigationItem = {
          title: 'История брендов',
          backRoute: WelcomeRoute.consultant
        }

        this.#navigationTitle.next(item);

        return true
      }

      case ConsultantRoute.cloakroom: {
        const item: WelcomeNavigationItem = {
          title: 'Стандарты внешнего вида',
          backRoute: WelcomeRoute.consultant
        }

        this.#navigationTitle.next(item);

        return true
      }

      default: return false;
    }
  }

  private checkQuestions(url: string): boolean {
    switch (url) {
      case QuestionsRoute.direction: {
        const item: WelcomeNavigationItem = {
          title: 'Куда направлять вопросы?',
          backRoute: WelcomeRoute.questions
        }

        this.#navigationTitle.next(item);

        return true
      }

      case QuestionsRoute.dictionary: {
        const item: WelcomeNavigationItem = {
          title: 'Словарь',
          backRoute: WelcomeRoute.questions
        }

        this.#navigationTitle.next(item);

        return true
      }

      case QuestionsRoute.recycling: {
        const item: WelcomeNavigationItem = {
          title: 'Вопрос-ответ: переработка',
          backRoute: WelcomeRoute.questions
        }

        this.#navigationTitle.next(item);

        return true
      }

      case QuestionsRoute.holiday: {
        const item: WelcomeNavigationItem = {
          title: 'Вопрос-ответ: больничный,отпуск',
          backRoute: WelcomeRoute.questions
        }

        this.#navigationTitle.next(item);

        return true
      }

      default: return false;
    }
  }
}
