import {Component, OnDestroy, OnInit } from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {BaseService} from "services/api";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogService, ExtraService, GameModeratorService, StorageService} from "services";
import {
  AlertDialogComponent,
  GameModeratorAlertDialogComponent,
  GameModeratorAlertFinishDialogComponent
} from "components/dialogs";
import { Observable, forkJoin } from 'rxjs'
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { GameModerTest, TestQuestion } from 'app/api/game-moderator/test';
import { DestroyRef } from 'core/common';
import { GameModeratorTestService } from './game-moderator-test.service';

@Component({
  selector: 'app-game-moderator-test',
  templateUrl: 'game-moderator-test.component.html',
  styleUrls: ['game-moderator-test.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
  providers: [DestroyRef, GameModeratorTestService]
})

export class GameModeratorTestComponent implements OnInit, OnDestroy {
  question: TestQuestion;
  options: Set<number>;
  isNext: boolean;
  counter$: Observable<number>;
  circleArr: number[] = [];
  description: string;

  answers = new Map()

  endTest = false;

  lang: string;

  // Таймер
  timeOut = false;
  time = 0;
  timeleft = this.time;
  timer = 0;
  startMoment = 0;
  endMoment = this.startMoment + this.timeleft * 1000;

  initiateTimer = false;

  alarmQuestions = []
  startAlarmQuestionsLength = 0
  alarmTimer = 0


  constructor(
    private route: ActivatedRoute,
    private baseService: BaseService,
    private dialogService: DialogService,
    private gameModerService: GameModeratorService,
    private storageService: StorageService,
    public extraService: ExtraService,
    private router: Router,
    private destroyRef: DestroyRef,
    private gameModeratorTestService: GameModeratorTestService
  ) {
  }


  ngOnInit() {
    this.counter$ = this.gameModeratorTestService.counter$;

    this.listenToQuestion();
    this.listenToAnswers();
    this.listenParams();
  }

  ngOnDestroy() {
    clearInterval(this.alarmTimer)
  }

  private listenToAnswers(): void {
    this.gameModeratorTestService.answers$
    .pipe(
      filter(v => v !== null),
      takeUntil(this.destroyRef)
    )
    .subscribe({
      next: v => {
        const currentAnswer = v.find(a => a.question_id === this.question.id);

        this.options = currentAnswer.options;
      }
    })
  }

  // show current question
  private listenToQuestion(): void {
    this.gameModeratorTestService.currentQuestion$
    .pipe(
      switchMap(v => (
        this.gameModeratorTestService.question$.pipe(map(q => q[v]))
      )),
      takeUntil(this.destroyRef)
    )
    .subscribe({
      next: v => {
       this.question = v;
      }
    })
  }

  //toggle isNext
  private listenToCounter(): void {
    this.gameModeratorTestService.counter$
    .pipe(
      map(v => {
        const questionLength = this.gameModeratorTestService.question.length - 1;

        return questionLength === v
      } ),
      takeUntil(this.destroyRef)
    )
    .subscribe({
      next: v => {
        this.isNext = v;
      }
    })
  }

  private listenParams(): void {
    this.route.params
    .pipe(
      map(v => +v.id),
      takeUntil(this.destroyRef)
    )
    .subscribe((id: number) => {
      this.gameModerService.getLang();
      this.lang = this.storageService.getValue('game_moder_lang');
      
      this.getInitialData(id);
    })
  }

  private getInitialData(id: number) : void {
    const stage$ = this.baseService.baseGetGameModerStage(id);
    const test$ = this.baseService.baseGameModerAttemptStart(id);
    forkJoin([stage$, test$])
    .subscribe({
      next: ([stage, test]) => {
        this.setTest(test);

        this.description = stage.description;
      }
    })
  }

  private setTest(val: GameModerTest): void {
    this.gameModeratorTestService.question = val.questions;
    this.gameModeratorTestService.attemptSubject.next(val.attempt_id);
    this.circleArr = Array(val.questions.length).fill(1);

    const answers = [];

    val.questions.forEach(q => {
      answers.push({question_id: q.id, options: new Set<number>()})
    })

    this.gameModeratorTestService.answers = answers;

    this.alarmQuestions = val.alarm
    this.startAlarmQuestionsLength = val.alarm.length;

    this.initAlarm(val.time);
    this.listenToCounter();
  }
  //Alarm methods
  private initAlarm(time: number): void {
    if (time) {
      this.time = +time;
      this.timeleft = this.time * 60;
      this.initCountdown();
      this.initAlertCountdown()
    }
  }

  private initCountdown() {
    this.initiateTimer = true;
    this.startMoment = Date.now();
    this.endMoment = this.startMoment + this.timeleft * 1000;
    this.timer = window.setInterval(() => {
      const now = Date.now();
      this.timeleft = Math.round((this.endMoment - now) / 1000);
      if (this.timeleft <= 0) {
        clearInterval(this.timer);
        this.timeleft = this.time;
        this.timeOut = true;
        this.submit();
      }
    }, 1000);
  }

  private initAlertCountdown() {
    if (this.alarmQuestions.length > 0) {
      let time = 0
      if (this.alarmQuestions.length !== 0) {
        time = (30 / this.startAlarmQuestionsLength) + Math.floor(Math.random() * (5 - 1 + 1)) + 1
      } else {
        time = 0
      }
      this.alarmTimer = window.setInterval(() => {
        time -= 1
        if (time <= 0) {
          clearInterval(this.alarmTimer)
          this.questionAlert()
        }
      }, 1000)
    }
  }

  private questionAlert() {
    if (this.alarmQuestions.length > 0) {
      const attempId = this.gameModeratorTestService.attemptSubject.getValue();
      let question = this.alarmQuestions.shift()
      this.dialogService.openDialog(GameModeratorAlertDialogComponent, {question: question})
      .pipe(
        filter(v => v)
      )
      .subscribe((res: any) => {
        res.attempt_id = attempId;
        this.baseService.baseGameModerAlertSave(res).subscribe((response: any) => {
          this.dialogService.openDialog(GameModeratorAlertFinishDialogComponent, {question: question, right: response.is_right}).subscribe()
        })
        if (!this.endTest) {
          this.initAlertCountdown()
        } else {
          clearInterval(this.alarmTimer)
        }
        if (this.endTest && this.alarmQuestions.length !== 0) {
          this.questionAlert()
        } else if (this.endTest && this.alarmQuestions.length === 0) {
          this.submit()
        }
      })
    }
  }

  goBack() {
    this.router.navigate(['game-moderator','stages']);
  }

  addAnswers(id: number): void {
    this.radioMode(id);
  }

  private radioMode(id: number): void {
    const answers = this.gameModeratorTestService.answers;
    const matchedQ = answers.findIndex(q => q.question_id === this.question.id);

    answers[matchedQ].options.clear();
    answers[matchedQ].options.add(id);

    this.gameModeratorTestService.answers = answers;
  }

  //submit methods
  submit() {
    this.endTest = true;
    const answers = this.gameModeratorTestService.answers;
    const attemptId = this.gameModeratorTestService.attemptSubject.getValue();
    const finalData = answers.map(v => ({question_id: v.question_id, options: [...v.options]}));
    const data = { attempt_id: attemptId, answers: finalData.filter(v => v.options.length)};

    if (this.alarmQuestions.length === 0) {
      this.baseService.baseGameModerAttemptFinish(data).subscribe((response: any) => {
        if (this.lang === 'ru') {
          if (response.result === 100) {
            this.dialogService.openDialog(AlertDialogComponent, {title: 'Задание выполнено + ' + response.scores, confirmButtonText: 'ОК'}).subscribe(() => {
              this.router.navigate(['game-moderator/stages'])
            })
          } else {
            this.dialogService.openDialog(AlertDialogComponent, {title: 'Задание не выполнено. Рекомендуем пройти обучение. Желаем удачи в следующий раз.', confirmButtonText: 'ОК'}).subscribe(() => {
              this.router.navigate(['game-moderator/stages'])
            })
          }
        } else {
          if (response.result === 100) {
            this.dialogService.openDialog(AlertDialogComponent, {title: 'Task completed + ' + response.scores, confirmButtonText: 'ОК'}).subscribe(() => {
              this.router.navigate(['game-moderator/stages'])
            })
          } else {
            this.dialogService.openDialog(AlertDialogComponent, {title: 'Task not completed. We recommend getting trained. We wish you good luck next time.', confirmButtonText: 'ОК'}).subscribe(() => {
              this.router.navigate(['game-moderator/stages'])
            })
          }

        }
      })
    } else {
      this.timeleft = 0
      clearInterval(this.timer)
      this.questionAlert()
    }
  }

  pause() {
    clearInterval(this.timer)
    clearInterval(this.alarmTimer)
    this.extraService.gamePaused = true
    if (this.lang === 'ru') {
      this.dialogService.openDialog(AlertDialogComponent, {title: 'Вы поставили игру на паузу', confirmButtonText: 'Продолжить'}).subscribe(() => {
        this.extraService.gamePaused = false
        this.initCountdown()
        this.initAlertCountdown()
      })
    } else {
      this.dialogService.openDialog(AlertDialogComponent, {title: 'You paused the game', confirmButtonText: 'Continue'}).subscribe(() => {
        this.extraService.gamePaused = false
        this.initCountdown()
        this.initAlertCountdown()
      })
    }

  }
  //

  onNext(): void {
    const lastQ = this.gameModeratorTestService.currentQ;
    const lastCount = this.gameModeratorTestService.counter;

    this.gameModeratorTestService.currentQ = lastQ + 1;
    this.gameModeratorTestService.counter = lastCount + 1;
  }

}
