<div class="custom_dialog game_moderator">
  <div class="title">{{lang === 'ru' ? 'История покупок' : 'Cart history'}}</div>

  <ng-scrollbar *ngIf="purchaseList" style="height: 150px; width: 100%">
    <div infiniteScroll scrollViewport [scrollWindow]="false" (scrolled)="paginationOn()" [infiniteScrollDisabled]="endScroll">
      <div class="container">
        <div class="text" *ngIf="purchaseList.length === 0">{{
          lang === 'ru'
            ? 'Информация появится после первой оформленной покупки'
            : 'Information will appear after the first completed purchase'
        }}</div>

        <div class="purchase_container" *ngFor="let purchase of purchaseList">
          <div class="title">{{purchase.product}}</div>

          <div class="price_container">
            <div class="price">{{purchase.cost}}</div>
            <div class="coin"></div>
          </div>

          <div class="date">{{purchase.date | timestampToDate:'shortDate'}}</div>
        </div>
      </div>
    </div>
  </ng-scrollbar>

  <div class="dialog_button" (click)="onConfirm()">ОК</div>
</div>
