import { add } from 'core/api'
import { env } from 'env'

export function createStatisticsUrls() {
  const statistics = add(env.rootApiUrl, 'statistics/')
  const statistics_reports = add(statistics, 'reports/')
  const statistics_info = add(statistics, 'info/')
  const statistics_logs = add(statistics, 'logs/')

  return {
    getMaterials: add(statistics, 'materials/'),
    getScores: add(statistics, 'scores/'),
    getRules: add(statistics, 'rules/'),
    getGroups: add(statistics, 'groups/'),
    getParams: add(statistics, 'reports/params/'),
    getParamsResult: add(statistics, 'reports/users/export/'),
    getBeInTouch: add(statistics, 'reports/store/export/'),

    getCourses: add(statistics_reports, 'courses/'),
    getList: add(statistics_reports, 'list/'),
    getCoursesTotalReport: add(statistics_reports, 'courses-total/export/'),
    getCoursesDetailReport: add(statistics_reports, 'courses-detail/export/'),
    getGameReport: add(statistics_reports, 'game-photos/export/'),
    getScormReport: add(statistics_reports, 'scorm/export/'),
    getReportParams: (ref: string) => add(statistics_reports, `params/${ref}/`),

    getInfographicsAssessment: add(statistics_info, 'assessment/'),
    getInfographicsPerformance: add(statistics_info, 'performance/'),
    getInfographicsStandards: add(statistics_info, 'standards/'),
    getInfographicsTopCourses: (number: number) => add(statistics_info, `courses/top/${number}/`),

    getMetricsAdd: add(statistics_logs, 'add/'),
    getReferenceList: add(statistics_reports, 'reference-list/'),
    getReportsList: (reference: string) => add(statistics_reports, `${reference}/list/`),
    getCountentCourseReport: (reference: string) => add(statistics_reports,`${reference}/export/`)
  }
}
