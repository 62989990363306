import { AfterViewInit, Directive, ElementRef } from '@angular/core'

@Directive({
  selector: '[appHorizontalScroll]'
})
export class HorizontalScrollDirective implements AfterViewInit {
  constructor(private readonly elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.elementRef.nativeElement.addEventListener('wheel', (event) => {
      event.preventDefault()
      this.elementRef.nativeElement.scrollLeft += event.deltaY
    })
  }
}
