import { add } from 'core/api'
import { env } from 'env'

export function createKpiUrls() {
  const kpi = add(env.rootApiUrl, 'kpi/')
  const kpi_stage = add(kpi, 'stage/')

  return {
    uploadPhotosToKpiAction: add(kpi, 'kpi/load-photo/'),
    getKpiMessage: (ref: string) => add(kpi, `message/${ref}/`),
    getPlanetPageData: add(kpi, 'planet/get/'),
    getKpiContent: add(kpi_stage, 'get/'),
    getKpiPreviews: add(kpi_stage, 'list/'),
  }
}
