import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CatalogsFaqService } from './catalogs-faq.service';
import { DestroyRef } from 'core/common';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Product, Tag, TestQuestion } from 'services/api/catalogs/catalogs-query';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-catalogs-faq',
  templateUrl: './catalogs-faq.component.html',
  styleUrls: ['./catalogs-faq.component.scss'],
  providers: [CatalogsFaqService, DestroyRef],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogsFaqComponent implements OnInit {
  eanCode: string;
  tags$: Observable<Tag[]>;
  faqs$: Observable<TestQuestion[]>;
  product$: Observable<Product>;
  isLoading$: Observable<boolean>;
  
  get faqForm(): FormGroup {
    return this.catalogFaqService.faqForm;
  }

  constructor(
    private destroy$: DestroyRef,
    private catalogFaqService: CatalogsFaqService
  ) { }

  ngOnInit(): void {
    this.tags$ = this.catalogFaqService.tags$;
    this.faqs$ = this.catalogFaqService.faqs$;
    this.isLoading$ = this.catalogFaqService.isLoading$;

    this.product$ = this.faqs$.pipe(
      map(faqs => {
        if(!!this.faqForm.value.ean_code?.length) {
          return faqs[0]?.ean?.product
        } 

        return null
      })
    );

    this.initComponentInitials();
  }

  onSelectTag(id: number): void {
    const currentTags = this.catalogFaqService.activeTags;

    if(this.isHasId(id)) {
      currentTags.delete(id);
    } else {
      currentTags.add(id);
    }

    this.catalogFaqService.activeTags = currentTags;

    this.faqForm.patchValue({
      tag_ids: [...currentTags]
    });
  }

  onSearch(): void {
    this.faqForm.patchValue({
      ean_code: this.eanCode
    })
  }

  isHasId(id: number): boolean {
    return this.catalogFaqService.activeTags.has(id);
  }

  private initComponentInitials(): void {
    combineLatest([
      this.catalogFaqService.getTags$(),
      this.faqForm.valueChanges.pipe(
        startWith({})
      )
    ]).pipe(
      tap(() => this.catalogFaqService.isLoading = true),
      debounceTime(500),
      switchMap(() => (
        this.catalogFaqService.getCards$()
      )),
      takeUntil(this.destroy$)
    ).subscribe();
  }
}

//при клике на продукт перенаправить на страницу товара