<div class="video" *ngIf="videoTestBody">

  <h2>{{videoTestBody.title}}</h2>

  <vg-player (onPlayerReady)="onPlayerReady($event)">
    <vg-overlay-play></vg-overlay-play>
    <vg-buffering></vg-buffering>

    <vg-scrub-bar>
      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>

    <vg-controls [vgAutohide]="true">
      <vg-play-pause></vg-play-pause>
      <vg-playback-button [playbackValues]="[ '0.5', '1.0', '1.25', '1.5', '2.0' ]"></vg-playback-button>

      <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

      <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

      <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>


      <vg-mute></vg-mute>
      <vg-volume></vg-volume>

      <vg-fullscreen></vg-fullscreen>
    </vg-controls>
    <video [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" crossorigin poster="{{videoTestBody.preview}}" *ngFor="let video of videoTestBody.video">
      <source type="video/webm" [src]="video.file">
      <source type="video/mp4" [src]="video.file">
    </video>
  </vg-player>
</div>
