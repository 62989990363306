<div class="wrapper">
    <app-header></app-header>

    <div class="workspace">
        <div class="bread_and_title_container">
            <div class="bread_and_title">
                <a 
                    [routerLink]="'../'" 
                    class="page_title"
                >
                    <div class="icon back"></div>
                    <span>ВОПРОС-ОТВЕТ</span>
                </a>
            </div>
        </div>

        <div class="search">
            <div class="container">
                <input 
                    [(ngModel)]="eanCode"
                    type="text" 
                    placeholder="Введите код" 
                    class="input"
                    (keydown.enter)="onSearch()"
                >
                <button 
                    mat-button
                    class="submit"
                    (click)="onSearch()"
                >
                    <app-icon-loader 
                        [name]="'search'">
                    </app-icon-loader>
                </button>
            </div>
        </div>

        <div class="catalogs-faq">
            <div class="tags">
                <p>Выберите теги:</p>

                <div class="items">
                    <ng-container *ngFor="let tag of tags$ | async">
                        <button 
                            mat-button 
                            class="tag-item"
                            [ngClass]="{active: isHasId(tag.id)}"
                            (click)="onSelectTag(tag.id)"
                        >
                            {{tag.name}}
                        </button>
                    </ng-container>
                </div>
            </div>

            <ng-container *ngIf="!(isLoading$ | async); else loadingRef">
                <a 
                    [routerLink]="'../' + '/card' + '/' + product.id"
                    class="product" 
                    *ngIf="product$ | async as product"
                >
                    <img [src]="product.files[0].file">
                    <p>{{product.title}}</p>
                </a>

                <div class="faqs">
                    <ng-container *ngFor="let faq of faqs$ | async">
                        <app-collapse 
                            [titleTemplate]="titleRef"
                        >
                            <span 
                                [innerHTML]="faq.description | safeHtml">
                            </span>
                        </app-collapse>

                        <ng-template #titleRef>
                            <div class="title-ref">
                                <img 
                                    class="ean-photo"
                                    [src]="faq.ean.product.files[0].file"
                                >
                                
                                <div class="content">
                                    <p>{{faq.title}}</p>

                                    <button 
                                        mat-button 
                                        class="tag-item active"
                                    >
                                        {{faq.tag.name}}
                                    </button>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>

</div>


<ng-template #loadingRef>
    <div class="loading">
        <mat-progress-spinner
        [diameter]="100"
        [mode]="'indeterminate'">
    </mat-progress-spinner>
    </div>
</ng-template>