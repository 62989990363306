import { createReducer, on } from '@ngrx/store'

import { NavigationState } from './navigation-state'
import * as actions from './navigation.actions'

const initialState: NavigationState = {
  fromEco: false
}

export const navigationReducer = createReducer(initialState,
  on(actions.setFromEcoFlag, (): NavigationState => ({
    fromEco: true
  })),

  on(actions.resetFromEcoFlag, (): NavigationState => ({
    fromEco: false
  }))
)
