import { Component, OnInit } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { DialogService, ExtraService, GameModeratorService, StorageService } from 'services'
import { BaseService } from 'services/api'
import { AlertDialogComponent, GameModeratorPurchaseListDialogComponent, GameModeratorPurchaseProcessDialogComponent } from 'components/dialogs'

@Component({
  selector: 'app-basket',
  templateUrl: 'basket.component.html',
  styleUrls: ['basket.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})
export class BasketComponent implements OnInit {
  constructor(
    private storageService: StorageService,
    private baseService: BaseService,
    private dialogService: DialogService,
    private gameModerService: GameModeratorService,
    public extraService: ExtraService
  ) {
  }

  account: number
  cart = []
  shoppingList = []
  sum = 0

  lang: string

  changeChecked(event, product) {
    if (event.checked) {
      product.checked = true
      this.shoppingList.push(JSON.parse(JSON.stringify(product)))
      this.shoppingList.find(item => item.id === product.id).number = 1
      this.sum = 0
      this.changeSum()
    } else {
      product.checked = false
      this.shoppingList.splice(this.shoppingList.findIndex(item => item.id === product.id), 1)
      this.sum = 0
      this.changeSum()
    }
  }

  changeQuantity(product: any, sign?: string) {
    if (product.checked) {
      switch (sign) {
        case '+':
          if (this.shoppingList.find(item => item.id === product.id).number < product.number) {
            this.shoppingList.find(item => item.id === product.id).number += 1
            this.sum = 0
            this.changeSum()
          }
          break
        case '-':
          if (this.shoppingList.find(item => item.id === product.id).number !== 0) {
            this.shoppingList.find(item => item.id === product.id).number -= 1
            this.sum = 0
            this.changeSum()
          }
          break
      }
    }
    return this.shoppingList.find(item => item.id === product.id).number
  }

  changeSum() {
    let sumArr = []
    this.shoppingList.forEach((item) => {
      sumArr.push(item.number * item.price)
    })
    sumArr.forEach((item) => {
      this.sum += item
    })
  }

  clearCart() {
    if (this.shoppingList.length) {
      this.dialogService.openDialog(AlertDialogComponent, {title: 'Вы действительно хотите очистить корзину?', confirmButtonText: 'ДА', notButtonText: 'НЕТ'}).subscribe((res) => {
        if (res) {
          this.shoppingList.forEach(shoppingItem => {
            this.cart.splice(this.cart.findIndex(item => item.id === shoppingItem.id), 1)
            this.sum = 0
          })
          this.shoppingList = []
          this.changeSum()
          this.storageService.setValue('cart', '')
          this.cart.map((item, index) => {
            let obj = {[index]: item}
            this.storageService.setValue('cart', obj)
          })
        }
      })
    }
  }

  buy() {
    console.log(this.shoppingList)
    if (this.sum <= this.account && this.sum !== 0) {
      this.dialogService.openDialog(GameModeratorPurchaseProcessDialogComponent, {shoppingList: this.shoppingList}).subscribe((res) => {

        // if (res) {
        //   console.log(res)
        //   const shoppingList$ = from(this.shoppingList)
        //   shoppingList$.pipe(
        //     concatMap((product: any) => this.baseService.basePurchaseCreate(this.shoppingList))
        //   ).subscribe(() => {
        //     this.dialogService.openDialog(GameModeratorPurchaseFinishDialogComponent, {}).subscribe(() => {
        //       this.storageService.setValue('cart', '')
        //       this.location.back()
        //     })
        //   }, () => {
        //     this.dialogService.openDialog(AlertDialogComponent, {title: 'Произошла ошибка', confirmButtonText: 'ОК'})
        //   })
        // }
      }, () => {
        this.dialogService.openDialog(AlertDialogComponent, {title: 'Произошла ошибка', confirmButtonText: 'ОК'})
      })
    }
  }

  openHistory() {
    this.dialogService.openDialog(GameModeratorPurchaseListDialogComponent)
  }

  ngOnInit() {
    this.gameModerService.getLang()
    this.lang = this.storageService.getValue('game_moder_lang')
    this.extraService.backgroundStore = '/assets/images/game/bg_basket_mobile.png'
    Object.keys(this.storageService.getValue('cart')).map((key) => {
      this.cart.push(this.storageService.getValue('cart')[key])
    })
    this.cart.map((item) => {
      item.checked = false
    })
    this.baseService.baseGetStoreAccount().subscribe((response: any) => {
      this.account = response.value
    })
  }
}
