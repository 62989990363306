import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {ExtraService, SectionsDateService} from 'services'
import { BaseService } from 'services/api'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { map, takeUntil, tap } from 'rxjs/operators'
import { DestroyRef } from 'core/common'

interface SectionFolder {id: number, title: string; documents: any[]; start_index: number; limit: number, next: boolean};

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss'],
  providers: [DestroyRef],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0
      })),
      state('show', style({
        opacity: 1
      })),
      transition('void => show', [
        style({
          opacity: 0
        }),
        animate(500, style({
          opacity: 1
        }))
      ])
    ])
  ]
})
export class FolderComponent implements OnInit {
  showPrev = 'show';

  mobile = false; // Включаем элементы свойственные мобильной версии
  menuOpen = true;

  homeworkSections:SectionFolder[] = [];

  next = false;
  endScroll = false;
  wordbookSearched = false;
  breadcrumbs = [];
  sectonInfo = undefined;
  folderMenu = [];
  folderContent = [];
  sections = [];
  catalogs: any;
  symbols = {
    ru: [],
    en: [],
    num: []
  };
  terms = {
    documents: [],
    symbols: []
  };
  term = {
    id: null,
    title: ''
  };
  searchTermsValue = '';

  productPreview = null;

  folderData = { id: null, 'start_index': 0, 'limit': 40 };

  folderSection = undefined;

  sectionIo = this.sectionsDate.sectionsInfo();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sectionsDate: SectionsDateService,
    private baseService: BaseService,
    private extraService: ExtraService,
    private destroyRef: DestroyRef
  ) {}

  onGetSection(event: boolean,id:number, index: number): void {
    const isLoaded = this.homeworkSections[index].documents.length > 0;
    const pagination = {id: id, start_index: 0, limit: 40};

    if(event && !isLoaded) {
      this.baseService.documentsContent(pagination)
      .pipe(
        takeUntil(this.destroyRef)
      )
      .subscribe((response: any) => {
        this.homeworkSections[index].documents = response.documents;
        this.homeworkSections[index].next = true;
      });
    }
  }

  onScroll(i: number): void {
    this.homeworkSections[i].start_index += 6;

    const id = this.homeworkSections[i].id;
    const limit = this.homeworkSections[i].limit;
    const start = this.homeworkSections[i].start_index;
    const currentDoc = this.homeworkSections[i].documents;

    this.baseService.documentsContent({id, start_index: start, limit})
    .pipe(
      tap((v:any) => {
        this.homeworkSections[i].next = v.next;
      }),
      takeUntil(this.destroyRef)
    )
    .subscribe({
      next: (v: any) => {
        this.homeworkSections[i].documents = [...currentDoc, ...v.documents];
      }
    })
  }

  reset() {
    this.breadcrumbs = [];
    this.sectonInfo = undefined;
    this.folderMenu = [];
    this.folderContent = [];
    this.sections = [];
    this.catalogs = [];
    this.symbols = {
      ru: [],
      en: [],
      num: []
    };
    this.terms = {
      documents: [],
      symbols: []
    };
    this.term = {
      id: null,
      title: ''
    }
    this.searchTermsValue = '';
    this.folderData = { id: null, 'start_index': 0, 'limit': 40 };
    this.endScroll = false;
    this.wordbookSearched = false;
    this.productPreview = null;
  }

  resize() {
    if (window.innerWidth <= 1025) {
      this.mobile = true;
      this.menuOpen = false;
    } else {
      this.mobile = false;
      this.menuOpen = true;
    }

    window.addEventListener('resize', () => {
        if (window.innerWidth <= 1025) {
          this.mobile = true;
          this.menuOpen = false;
        } else {
          this.mobile = false;
          this.menuOpen = true;
        }
    });
  }

  openMenu() {
    if (this.menuOpen) {
      this.menuOpen = false;
    } else {
      this.menuOpen = true;
    }
  }

  goBack() {
    this.router.navigate([this.sectionIo[this.folderSection]['homeLink']]);
  }

  onFolder(info) {
    switch (info.type) {
      case 'home':
        this.router.navigate( [ this.sectionIo[this.folderSection]['homeLink']  ] );
        break
      case 'catalog':
        this.router.navigate( [ this.sectionIo[this.folderSection]['folderLink'] + info.id ] );
        break
      default:
        this.router.navigate( [ this.sectionIo[this.folderSection]['folderLink'] + info.id ] );
    }
  }

  onDocument(info) {
    if (info.file) {
      this.baseService.baseViewDocument(info.id).subscribe(() => {
        window.open(info.file, '_blank')
      })
    } else {
      this.router.navigate( [ this.sectionIo[this.folderSection]['documentLink'] + info.id ] );
    }
  }

  paginationOn(type) {
    this.folderData['start_index'] = this.folderData['start_index'] + this.folderData['limit'];
    switch (type) {
      case 'documents':
        this.baseService.documentsContent(this.folderData).subscribe((response: any) => {
          Array.prototype.push.apply(this.sectonInfo.documents, response.documents);
          if (!response.next) {
            this.endScroll = true
          }
        });
        break
      case 'section_catalog':
        this.baseService.documentsContent(this.folderData).subscribe((response: any) => {
          Array.prototype.push.apply(this.catalogs, response.boxes);
          if (!response.next) {
            this.endScroll = true
          }
        });
        break
      case 'terms':
        if (this.wordbookSearched) {
          this.baseService.wordbookSearch(this.folderData).subscribe((response: any) => {
            Array.prototype.push.apply(this.terms.documents, response.documents);
            if (!response.next) {
              this.endScroll = true
            }
          })
        } else {
          this.baseService.wordbookContent(this.folderData).subscribe((response: any) => {
            Array.prototype.push.apply(this.terms.documents, response.documents);
            if (!response.next) {
              this.endScroll = true
            }
          })
        }
        break
      case 'catalogs': {
        this.baseService.sectionCatalogContent(this.folderData).pipe(map((response: any) => {
          response.products.map((item) => {
            item.active = false
          })
          Array.prototype.push.apply(this.catalogs.products, response.products)
          if (!response.next) {
            this.endScroll = true
          }
        })).subscribe()
        break
      }
    }

  }

  getTerms(id = null, title = '') {
    if (this.wordbookSearched) {
      this.wordbookSearched = false
      this.searchTermsValue = ''
      this.folderData['searchValue'] = this.searchTermsValue
    }
    this.term.id = id;
    this.term.title = title;
    this.folderData.id = this.term.id;
    this.folderData['start_index'] = 0;
    this.folderData['limit'] = 30;
    this.baseService.wordbookContent(this.folderData).subscribe((response: any) => {
      this.terms.documents = response.documents
      this.terms.symbols = response.symbols
      this.endScroll = !response.next
    })
  }

  searchTerms() {
    this.wordbookSearched = true;
    this.folderData['searchValue'] = this.searchTermsValue
    this.term.id = null;
    this.term.title = '';
    this.folderData.id = this.term.id;
    this.folderData['start_index'] = 0;
    this.folderData['limit'] = 30;
    this.baseService.wordbookSearch(this.folderData).subscribe((response: any) => {
      this.terms.documents = response.documents;
      this.endScroll = !response.next
    })
  }

  onTerm(id) {
    this.router.navigate( [ this.sectionIo[this.folderSection]['wordbookLink'] + id ] );
  }

  showPreview(product) {
    if (product.files[0]) {
      product.active = true
      this.productPreview = product.files[0].file
    }
  }

  removePreview(product) {
    product.active = false
    this.productPreview = null
  }

  onProduct(product) {
    return 'catalogs/' + product.folder + '/list/' + product.catalog + '/card/' +  product.id
  }

  ngOnInit() {
    this.route.params.subscribe( params => {

      this.folderSection = params['section'];
      this.reset();
      this.resize();

      if (params['folderId']) {
        this.folderData['id'] = params['folderId'];

        this.baseService.documentsBoxChain(params['folderId'])
        .pipe(
          takeUntil(this.destroyRef)
        )
        .subscribe((response: any) => {
          this.breadcrumbs = response;

          if (this.sectionIo[this.folderSection]['homePage']['status']) {
            this.breadcrumbs.unshift(this.sectionIo[this.folderSection]['homePage']['breadcrumbs']);
          }
        });

        this.baseService.documentsContent(this.folderData)
        .pipe(
          takeUntil(this.destroyRef)
        )
        .subscribe((response: any) => {
          this.sectonInfo = response;

          if(response?.self.template !== 'template_homework') {
            if (!response.next) {
              this.endScroll = true
            }

            for (const box of response.boxes) {
              if (box.center && box.type === 'folder') {
                this.folderContent.push(box);
              } else if (!box.center && box.type === 'folder') {
                this.folderMenu.push(box);
              } else if (!box.center && box.type === 'section') {
                this.sections.push(box);
              } else if (!box.center && box.type === 'symbol') {
                if (!/[^а-я]/i.test(box.title)) {
                  this.symbols.ru.push(box)
                } else if (!/[^a-z]/i.test(box.title) ) {
                  this.symbols.en.push(box)
                } else if (!/[^0-9]/i.test(box.title)) {
                  this.symbols.num.push(box)
                }
              } else if (!box.center && box.type === 'catalog') {
                this.catalogs.push(box)
              }
            }

            if (this.folderMenu.length === 0 && response.self.parent && response.self.type !== 'section' && response.self.type !== 'catalog') {
              this.baseService.documentsContent({id: response.self.parent}).subscribe((parent: any) => {
                if (this.sections.length === 0) {
                  parent.boxes.map(item => {
                    if (item.center && item.type === 'folder') {
                      item.type = 'center_folder'
                    }
                    switch (item.type) {
                      case 'folder':
                        this.folderMenu.push(item)
                        break
                      case 'section':
                        this.sections.push(item)
                        break
                    }
                  })
                }
                if (this.sections.length !== 0) {
                  Array.prototype.push.apply(this.folderMenu, this.sections)
                }
                this.folderMenu.map(
                  item => {
                    if (item.id === Number(params['folderId'])) {
                      item.active = true;
                    }
                  }
                );

                const transformedRes = parent.boxes.map((v: any) => ({id: v.id, title: v.title, documents: [],start_index: 0, limit: 40, next: true}));

                this.homeworkSections = transformedRes;
              })
            }

            if (response.self.template === 'template_wordbook') {
              this.getTerms()
            }

            if (response.self.template === 'template_catalog') {
              this.folderData.id = response.self.id
              this.folderData.limit = 15
              this.baseService.sectionCatalogContent(this.folderData).pipe(map((response: any) => {
                response.products.map((item) => {
                  item.active = false
                })
                this.catalogs = response
                this.endScroll = !response.next
              })).subscribe()
            }

            this.sectonInfo = response;

            if (this.folderMenu.length !== 0 && this.sections.length !== 0) {
              Array.prototype.push.apply(this.folderMenu, this.sections)
            }
          }
        });
      }

    });
  }
}
