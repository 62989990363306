import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findThumbs',
})
export class FindThumbsPipe implements PipeTransform {
  transform(arr: any, index: number): any {
    return arr.find(x => x.index === index).event
  }
}
