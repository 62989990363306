import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

@Pipe({
  name: 'safeURL'
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(url: any) {
    if (url !== '' && url !== undefined) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url)
    } else {
      if (url == null) {
        url = ''
      }

      return url
    }
  }
}
