import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common'
import { animate, state, style, transition, trigger } from '@angular/animations'

import { BaseService } from 'services'

@Component({
  selector: 'app-game',
  templateUrl: 'game.component.html',
  styleUrls: ['game.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0
      })),
      state('show', style({
        opacity: 1
      })),
      transition('void => show', [
        style({
          opacity: 0
        }),
        animate(500, style({
          opacity: 1
        }))
      ])
    ])
  ]
})
export class GameComponent implements OnInit {
  gameInfo: any
  backgroundWord: string

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private baseService: BaseService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.baseService.baseGetGame(params.id).subscribe((response: any) => {
        this.gameInfo = response
        this.backgroundWord = response.background
      })
    })
  }

  goBack() {
    this.location.back()
  }

  goStages() {
    this.router.navigate(['game/' + this.gameInfo.id + '/' + this.gameInfo.brand + '/stages'])
  }
}
