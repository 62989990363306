import { Component, OnInit } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { Router } from '@angular/router'
import { ExtraService, GameModeratorService } from 'services'
import { Location } from '@angular/common'

@Component({
  selector: 'app-preview-test',
  templateUrl: 'preview-test.component.html',
  styleUrls: ['preview-test.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})
export class PreviewTestComponent implements OnInit {
  constructor(
    private router: Router,
    public extraService: ExtraService,
    private gameModerService: GameModeratorService,
    private location: Location,
  ) {
  }

  mobile = false;

  testDescription: any;

  goBack() {
    this.location.back()
  }

  goTest() {
    this.router.navigate(['game-moderator/stages/test/2'])
  }

  resize() {
    if (window.innerWidth <= 1024) {
      this.mobile = true;
      this.extraService.backgroundStore = '../assets/images/game/bg_game_2_mobile.png'
    } else {
      this.mobile = false;
      this.extraService.backgroundStore = '../assets/images/game/bg_game_2_desktop.jpg'
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1024) {
        this.mobile = true;
        this.extraService.backgroundStore = '../assets/images/game/bg_game_2_mobile.png'
      } else {
        this.mobile = false;
        this.extraService.backgroundStore = '../assets/images/game/bg_game_2_desktop.jpg'
      }
    });
  }

  ngOnInit() {
    this.resize()
    this.gameModerService.getLang()
    this.gameModerService.rewrite({second_stage: '', start_button: ''}).then((res: any) => {
      this.testDescription = res
    })
  }

}
