import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(style: any) {
    if (style !== '' && style !== undefined && style !== null) {
      return this.sanitizer.bypassSecurityTrustHtml(style)
    } else {
      if (style === null) {
        style = '—'
      }

      return style
    }
  }
}
