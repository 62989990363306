import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class BaseLayoutService {
  #scrollTo = new Subject<string>();
  scrollTo$: Observable<string> = this.#scrollTo.asObservable();

  set scrollTo(id: string) {
    this.#scrollTo.next(id);
  }
}
