import { Component, OnInit } from '@angular/core'

import { animate, state, style, transition, trigger } from '@angular/animations'
import { BaseService } from 'services/api'
import { ExtraService } from 'services'

@Component({
  selector: 'app-video',
  templateUrl: 'video.component.html',
  styleUrls: ['video.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class VideoComponent implements OnInit {

  showPrev = 'show';

  open = false;
  showMenu = true;

  constructor(
    private baseService: BaseService,
    public extraService: ExtraService
  ) {
  }

  folders = undefined;

  openMenuMobile() {
    this.open = !this.open;
  }

  openSideMenuMobile() {
    this.open = false;
  }

  ngOnInit() {
    if (window.innerWidth <= 1024) {
      this.showMenu = false;
    }
    this.baseService.baseGetSectionContentArticles('video').subscribe((response: any) => {
      this.folders = response.folders.map((item) => {
        return {
          id: item.id,
          typeSection: 'articles',
          link: 'video-list/' + item.id,
          title: item.title,
          count: item.news
        }
      });
    })
  }

}
