import { Component, OnInit, ViewChild } from '@angular/core'
import { Location } from '@angular/common'

import { animate, state, style, transition, trigger } from '@angular/animations'
import { BaseService } from 'services/api'
import { MatTabGroup } from '@angular/material/tabs'
import { ExtraService } from 'services'

@Component({
  selector: 'app-attempts',
  templateUrl: 'attempts.component.html',
  styleUrls: ['attempts.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class AttemptsComponent implements OnInit {

  @ViewChild('mtg') tg: MatTabGroup;

  showPrev = 'show';

  activeTab = 0;

  testBody = undefined;

  attempts = undefined;
  tabs = undefined;

  constructor(
    private location: Location,
    private baseService: BaseService,
    private extraService: ExtraService
  ) {

  }

  selectedIndexChange(evt: any) {
    this.baseService.baseFinalTestGetAttempt(this.tabs[evt].id).subscribe((responseAnswers: any) => {
      this.testBody = responseAnswers;
    });
  }

  goBack() {
    this.location.back();
  }

  ngOnInit() {
    this.tabs = this.extraService.attempts;
    if (this.tabs) {
      this.selectedIndexChange(0);
    }
  }

}
