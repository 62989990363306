<div *ngIf="product" class="catalog_card">
    <div *ngIf="product.nuances.length !== 0" id="nuances" class="window_body">
      <div class="window">
        <div class="cross" (click)="close()"></div>
        <div class="window_osn nano">
          <div class="nano-content">
            <div class="nuances_item"
              *ngFor="let nuan of product.nuances"
            >
              <div class="nuances_item_img"><img [src]="(filesMny + '' + nuan.file)" /></div>
              {{nuan.title}}
            </div>
          </div>
        </div>
      </div>
      <div class="window_fon"  (click)="close()"></div>
    </div>

    <div *ngIf="product.add_sales.length !== 0" id="additional" class="window_body">
      <div class="window">
        <div class="cross" (click)="close()"></div>
        <div class="window_osn nano">
          <div class="nano-content">
            <div
              class="additional_item"
              *ngFor="let sales of product.add_sales"
            >
              <a
                class="additional_item_img"
                href="/catalogs/{{sales.catalog_id}}/mny/{{sales.uid}}"
              >
                <img [src]="(filesMny + '' + sales.image)" />

              </a>
              {{sales.title}}
            </div>
          </div>
        </div>
      </div>
      <div class="window_fon" (click)="close()"></div>
    </div>

    <div class="wrapper">
      <div class="catalog_card_info">
        <div class="catalog_card_media">
          <div class="catalog_card_images">
            <div class="catalog_card_image">
              <div *ngIf="images.length > 1" (click)="pictureThumb('next')" class="catalog_card_image_bot next"></div>
              <div *ngIf="images.length > 1" (click)="pictureThumb('back')" class="catalog_card_image_bot back"></div>
              <img *ngIf="product.image && images.length === 0" [src]="(filesMny + '' + product.image)">
              <img *ngIf="images.length !== 0" [src]="(filesMny + '' + firstImage)">
            </div>
            <div *ngIf="images.length > 1" class="catalog_card_images_prs">
              <div *ngIf="images.length > number" [ngClass]="{'disabled':end === images.length}" class="catalog_card_image_bot next" (click)="scrollingPictures(1)"></div>
              <div *ngIf="images.length > number" [ngClass]="{'disabled':start === 0}" class="catalog_card_image_bot back" (click)="scrollingPictures(-1)"></div>
              <div
                [@show_preview]="showPrev"
                *ngFor="let image of pictures"
                (click)="pictureChange(image)"
                class="catalog_card_images_pr"
                [ngClass]="{'active':image === firstImage}"
              >
                <img [src]="(filesMny + '' + image)">
              </div>
            </div>
            <div class="catalog_card_videos_prs">
              <div class="catalog_card_videos_pr" *ngFor="let video of product.videos" (click)="openVideo(video)"></div>
            </div>
          </div>
          <div *ngIf="product.nuances.length !== 0" class="catalog_card_oter"  (click)="otherItem('nuances')">ОТТЕНКИ ({{product.nuances.length}})</div>
          <div *ngIf="product.add_sales.length !== 0" class="catalog_card_oter" (click)="otherItem('add_sales')">ДОП. ПРОДАЖИ</div>
        </div>
        <div class="catalog_card_text">
          <h2>{{product.title}}</h2>
          <h3 [innerHTML]="product.sub_title  | safeHtml"></h3>
          <p [innerHTML]="product.description  | safeHtml"></p>
          <div class="catalog_card_tabs">
            <div
              *ngIf="product.active_ingredients"
              (click)="onTab('active_ingredients')"
              [ngClass]="{'active_tab': activeTab === 'active_ingredients'}"
              class="catalog_card_tab"
            >Активные компоненты</div>
            <div
              (click)="onTab('composition')"
              [ngClass]="{'active_tab': activeTab === 'composition'}"
              class="catalog_card_tab"
            >Состав</div>
            <div
              *ngIf="product.application"
              (click)="onTab('application')"
              [ngClass]="{'active_tab': activeTab === 'application'}"
              class="catalog_card_tab"
            >Применение</div>
            <div
              (click)="onTab('info')"
              [ngClass]="{'active_tab': activeTab === 'info'}"
              class="catalog_card_tab"
            >Доп. информация</div>
          </div>
          <div *ngIf="activeTab === 'active_ingredients'" class="catalog_card_is" [innerHTML]="product.active_ingredients | safeHtml"></div>
          <div *ngIf="activeTab === 'composition'" class="catalog_card_is" [innerHTML]="product.composition  | safeHtml"></div>
          <div *ngIf="activeTab === 'application'" class="catalog_card_is" [innerHTML]="product.application | safeHtml"></div>
          <div *ngIf="activeTab === 'info'" class="catalog_card_is" [innerHTML]="product.info | safeHtml"></div>
        </div>
      </div>
    </div>
  </div>
