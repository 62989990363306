import { add } from 'core/api'
import { env } from 'env'

export function createQuestionsUrls() {
  const selectTests = add(env.rootApiUrl, 'select-tests/')

  return {
    getQuestions: add(selectTests, 'question/list/'),
    answerQuestion: add(selectTests, 'answer/save/')
  }
}
