import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { BaseService } from 'services/api'

@Component({
  selector: 'app-contests',
  templateUrl: 'contests.component.html',
  styleUrls: ['contests.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})
export class ContestsComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private baseService: BaseService,
  ) {}

  showPrev = 'show';

  documents = undefined;
  archive = undefined;

  goArchive() {
    this.router.navigate(['contests/archive/' + this.archive[0].id]);
  }

  goBack() {
    this.router.navigate(['main']);
  }

  goDocument(id) {
    this.router.navigate(['contests/document/article/' + id]);
  }

  like(event, info) {
    event.stopPropagation();
    if (!info.is_liked) {
      this.baseService.baseGetSectionDocumentLike('contests', info.id).subscribe(() => {
        document.getElementById('count' + info.id).innerHTML = String(info.likes + 1);
      });
    } else {
      this.baseService.baseGetSectionDocumentLike('contests', info.id).subscribe(() => {
        document.getElementById('count' + info.id).innerHTML = String(info.likes - 1);
      });
    }
  }

  ngOnInit() {
    this.baseService.baseGetSectionContentArticles('contests').subscribe((response: any) => {
      this.documents = response.documents;
      this.archive = response.folders;
    })
  }

  ngOnDestroy() {

  }

}
