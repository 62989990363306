import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AlertDialogComponent, GameModeratorPurchaseFinishDialogComponent} from "components/dialogs";
import {DialogService, StorageService} from "services";
import {BaseService} from "services/api";

@Component({
  selector: 'app-game-moderator-purchase-process-dialog',
  templateUrl: './game-moderator-purchase-process-dialog.component.html',
  styleUrls: ['./game-moderator-purchase-process-dialog.component.scss'],
})

export class GameModeratorPurchaseProcessDialogComponent implements OnInit {

  form = new FormGroup({
    office: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    address: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
  })

  shoppingList: any

  constructor(
    private dialogRef: MatDialogRef<GameModeratorPurchaseProcessDialogComponent>,
    private dialogService: DialogService,
    private baseService: BaseService,
    private storageService: StorageService,
    @Inject(MAT_DIALOG_DATA) private dialogData: {shoppingList: any},
  ) {
    ({
      shoppingList: this.shoppingList,
    } = this.dialogData)
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  submit() {
    if ((this.form.value.address === '' || this.form.value.office === '') && this.form.value.phone === '') {
      this.dialogService.openDialog(AlertDialogComponent, {title: 'Произошла ошибка', text: 'Заполните поля "Адрес" или "Офис", и поле "Телефон"!', confirmButtonText: 'ОК'})
      return;
    } else {
      this.baseService.basePurchaseCreate(this.shoppingList, this.form.value).subscribe( () => {
        this.dialogService.openDialog(GameModeratorPurchaseFinishDialogComponent, {}).subscribe(() => {
          this.storageService.setValue('cart', '');
          this.dialogRef.close(true);
        })
      }, () => {
        this.dialogService.openDialog(AlertDialogComponent, {title: 'Произошла ошибка', confirmButtonText: 'ОК'})
      })
    }
  }

  ngOnInit() {
  }
}
