<app-header *ngIf="sectonInfo" [color]="sectonInfo.self.head"></app-header>

<div
  *ngIf="sectonInfo"
  class="outer"
  [@show_preview]="showPrev"
  [ngStyle]="{'backgroundImage': 'url(' + (sectonInfo.self.ground ? (sectonInfo.self.ground) : '/assets/images/learning_bg.png') + ')'}"
  [ngClass]="{
    'green': sectonInfo.self.color === 'green',
    'white': sectonInfo.self.color === 'white'
  }"
>
  <div
    class="workspace"
    [ngClass]="{
      'menu_on': folderMenu.length > 0,
      'wordbook': sectonInfo.self.template === 'template_wordbook',
      'homework':sectonInfo.self.template === 'template_homework'
    }"
  >
    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>{{sectionIo[folderSection]['title']}}</span>
        </div>
      </div>
    </div>

    <div
      *ngIf="breadcrumbs.length > 0"
      class="breadcrumbs"
    >
      <span class="crumb"><span [routerLink]="['/main']" class="crumb_title link">Главная</span><span class="separator">&mdash;</span></span>
      <span
        *ngFor="let bread of breadcrumbs;let i = index"
        class="crumb"
      >
        <span *ngIf="i === breadcrumbs.length - 1" class="crumb_title">{{bread.title}}</span>
        <span *ngIf="i !== breadcrumbs.length - 1" class="crumb_title link" (click)="onFolder(bread)">{{bread.title}}<span class="separator">&mdash;</span></span>
      </span>
    </div>

    <section
      *ngIf="folderMenu.length > 0 && sectonInfo.self.template !== 'template_homework'"
      class="menu"
    >
      <div
        *ngIf="mobile"
        class="menu_open"
        (click)="openMenu()"
      >
        <span
          class="ar"
          [ngClass]="{
            'open': menuOpen
          }"
        ></span>Разделы
      </div>
      <div [@show_preview]="showPrev" *ngIf="menuOpen" class="menu_osn">
        <div
          class="menu_li"
          *ngFor="let info of folderMenu"
          (click)="onFolder(info)"
          [ngClass]="{
            'active': info.active
          }"
        >
          <div *ngIf="info.news" class="new">NEW: {{info.news}}</div>
          {{info.title}}
        </div>
      </div>
    </section>

    <section class="content">
      <div *ngIf="sectonInfo.self.template === 'template_loreal'">
        <div class="center_text"><img src="/assets/images/loreal_txt.png"></div>
        <div class="center">
          <div
            *ngIf="sectonInfo.documents.length > 0"
            class="button"
            (click)="onDocument(sectonInfo.documents[0])"
          >{{sectonInfo.documents[0].title}}</div>
          <div
            *ngIf="folderContent.length > 0"
            class="button"
            (click)="onFolder(folderContent[0])"
          >{{folderContent[0].title}}</div>
        </div>
      </div>
      <div *ngIf="sectonInfo.self.template === 'template_garnier'">
        <div class="center_text garnier_text"><img src="/assets/images/garnier_txt.png"></div>
        <div class="center garnier">
          <div
            *ngIf="sectonInfo.documents.length > 0"
            class="button"
            (click)="onDocument(sectonInfo.documents[0])"
          >{{sectonInfo.documents[0].title}}</div>
          <div
            *ngIf="folderContent.length > 0"
            class="button"
            (click)="onFolder(folderContent[0])"
          >{{folderContent[0].title}}</div>
        </div>
      </div>
      <div *ngIf="sectonInfo.self.template === 'template_folder'">
        <ng-scrollbar
          track="horizontal"
          style="height: 490px; width: 100%"
          *ngIf="sectonInfo.documents.length !== 0 || folderContent.length !== 0"
        >
          <div infiniteScroll
               appHorizontalScroll
               [horizontal]="true"
               [scrollWindow]="false"
               scrollViewport
               (scrolled)="paginationOn('documents')"
               [infiniteScrollDisabled]="endScroll"
               style="width: 100%; display: flex"
          >
            <div
              *ngIf="folderContent.length > 0"
              class="documents folder"
            >
            <div
              [@show_preview]="showPrev"
              *ngFor="let info of folderContent;let i = index"
              class="document"
              [ngStyle]="{backgroundImage: 'url('+ (info.preview) + ')'}"
              (click)="onFolder(info)"
            >
              <div *ngIf="info.news" class="new">NEW</div>
              <div class="document_text">
                <div class="document_text_title">{{info.title}}</div>
                <div
                  class="button"
                >
                  <span class="ar"></span>Перейти к изучению
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="sectonInfo.documents.length > 0"
            class="documents"
          >
            <div
            [ngStyle]="{cursor: !info?.is_available ? 'not-allowed' : 'pointer' }"  
            *ngFor="let info of sectonInfo.documents;let i = index">
            <div
              [@show_preview]="showPrev"
              (click)="onDocument(info)"
              class="document"
              [ngClass]="{locked: !info?.is_available}"
              [ngStyle]="{backgroundImage: 'url('+ (info.preview) + ')'}"
            >
              <div class="document_bg" *ngIf="!info?.is_available"></div>
              <div class="document_lock" *ngIf="!info?.is_available">
                <img loading="lazy" src="../../../../assets/images/lock.png">
              </div>
              <div *ngIf="info.is_new" class="new">NEW</div>
              <div class="document_text" [ngClass]="{locked: !info?.is_available}">
                <div class="document_text_title">{{info.title}}</div>
                <div
                  class="button"
                >
                  <span class="ar"></span>Перейти к изучению
                </div>
              </div>
          </div>
            </div>
          </div>

          </div>
        </ng-scrollbar>
      </div>
      <div *ngIf="sectonInfo.self.template === 'template_section'">
        <div
          *ngIf="sections.length !== 0"
          class="documents folder"
        >
          <div
            *ngFor="let info of sections;let i = index"
            class="document"
            [ngStyle]="{backgroundImage: 'url('+ (info.preview) + ')'}"
            (click)="onFolder(info)"
          >
            <div class="document_text">
              <div class="document_text_title">{{info.title}}</div>
              <div
                class="button"
              >
                <span class="ar"></span>Перейти к изучению
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="catalogs.length !== 0">
          <ng-scrollbar
            track="horizontal"
            style="height: 490px; width: 100%"
          >
            <div
              infiniteScroll
              appHorizontalScroll
              scrollViewport
              [horizontal]="true"
              [scrollWindow]="false"
              (scrolled)="paginationOn('section_catalog')"
              [infiniteScrollDisabled]="endScroll"
              style="width: 100%; display: flex"
            >
              <div
                class="documents"
              >
                <div
                  [@show_preview]="showPrev"
                  (click)="onFolder(info)"
                  *ngFor="let info of catalogs;let i = index"
                  class="document"
                  [ngStyle]="{backgroundImage: 'url('+ (info.preview) + ')'}"
                >
                  <div *ngIf="info.is_new" class="new">NEW</div>
                  <div class="document_text">
                    <div class="document_text_title">{{info.title}}</div>
                    <div
                      class="button"
                    >
                      <span class="ar"></span>Перейти
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </ng-scrollbar>
        </div>
      </div>
      <div *ngIf="sectonInfo.self.template === 'template_wordbook'" class="template_wordbook_container">
        <div class="wordbook_container">
          <div class="symbols">
            <div class="symbol" [ngClass]="{backlight: term.title === symbol.title}" *ngFor="let symbol of symbols.ru" (click)="getTerms(symbol.id, symbol.title)">{{symbol.title}}</div>
          </div>
          <div class="symbols">
            <div class="symbol" [ngClass]="{backlight: term.title === symbol.title}" *ngFor="let symbol of symbols.en" (click)="getTerms(symbol.id, symbol.title)">{{symbol.title}}</div>
          </div>
          <div class="symbols">
            <div class="symbol" [ngClass]="{backlight: term.title === symbol.title}" *ngFor="let symbol of symbols.num" (click)="getTerms(symbol.id, symbol.title)">{{symbol.title}}</div>
          </div>
          <div class="symbol" [ngClass]="{backlight: term.title === ''}" (click)="getTerms(null, '')">ВСЕ</div>
        </div>
        <div class="wordbook_container">
          <div class="input_container">
            <input type="text" placeholder="Введите слово" (keyup.enter)="searchTerms()" [(ngModel)]="searchTermsValue">
            <div class="icon_search" (click)="searchTerms()"></div>
          </div>
          <div class="terms_container">
            <div class="terms_letter">{{term.title}}</div>
            <ng-scrollbar
              track="vertical"
              style="height: 400px; width: 100%"
              *ngIf="terms.documents.length !== 0"
            >
              <div
                infiniteScroll
                [scrollWindow]="false"
                scrollViewport
                (scrolled)="paginationOn('terms')"
                [infiniteScrollDisabled]="endScroll"
                style="height: 400px"
              >
                <div class="terms">
                  <div class="term" *ngFor="let term of terms.documents">
                    <div class="term_title" (click)="onTerm(term.id)">{{term.title}}</div>
                  </div>
                </div>
              </div>
            </ng-scrollbar>
          </div>
        </div>
      </div>
      <div *ngIf="sectonInfo.self.template === 'template_catalog'" class="template_catalog_container">
        <div class="catalog_title">{{sectonInfo.self.title}}</div>
        <ng-scrollbar
          style="height: 490px"
          *ngIf="catalogs.length !== 0"
        >
          <div
            infiniteScroll
            [scrollWindow]="false"
            scrollViewport
            (scrolled)="paginationOn('catalogs')"
            [infiniteScrollDisabled]="endScroll"
          >
            <div class="catalog_content">

              <div class="line">
                <div class="line_bd" *ngFor="let product of catalogs.products; let i = index">
                  <a
                    class="line_bd_li"
                    [ngClass]="{'ecom': product.ecom && !product.is_new, 'new': product.is_new}"
                    (mouseenter)="showPreview(product)"
                    (mouseleave)="removePreview(product)"
                    [href]="onProduct(product)"
                  >{{product.title}}</a>
                  <div
                    *ngIf="productPreview && product.active"
                    class="line_pr"
                    [ngStyle]="{backgroundImage: 'url('+ (productPreview) + ')'}"
                  ></div>
                </div>
              </div>

            </div>
          </div>
        </ng-scrollbar>
      </div>
      <div *ngIf="sectonInfo.self.template === 'template_homework'" >
        <div class="template-homework" *ngIf="sectonInfo?.boxes?.length">
          <ng-scrollbar track="horizontal">
            <div 
              appHorizontalScroll
              scrollViewport
            >
              <div class="homework-container" >
                <app-folder-card 
                  [box]="box" 
                  *ngFor="let box of sectonInfo.boxes" 
                  (clicked)="onFolder(box)">
                </app-folder-card>
              </div>
            </div>
          </ng-scrollbar>
        </div>
        <div *ngIf="sectonInfo?.documents.length">
          <ng-scrollbar track="horizontal" style="height: 490px; width: 100%">
            <div 
              infiniteScroll
              appHorizontalScroll
              [horizontal]="true"
              [scrollWindow]="false"
              scrollViewport
            >
                <div class="documents homework">
                  <div
                    *ngFor="let card of sectonInfo.documents;let i = index"
                    class="document homework"
                    [ngStyle]="{backgroundImage: 'url('+ (card.preview) + ')'}"
                    (click)="onDocument(card)"
                  >
                    <div class="document_text">
                      <div class="document_text_title">{{card.title}}</div>
                      <div
                        class="button"
                      >
                        <span class="ar"></span>Перейти к изучению
                      </div>
                    </div>
                  </div>
                </div>
          </div>
          </ng-scrollbar>
        </div>  
      </div>
    </section>
  </div>
</div>



