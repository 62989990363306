import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { ApiService } from 'core/api'
import {
  GalleryPostPreview,
  GalleryPost,
  GalleryPostList,
  GalleryPostListFilters,
  GalleryPostReactionData,
  GalleryPostReactionName,
  NewPostData
} from 'core/eco-portal'
import { createGalleryUrls } from './gallery-urls'

@Injectable({
  providedIn: 'root'
})
export class GalleryService extends ApiService {
  private readonly urls = createGalleryUrls()

  /**
   * Gets a list of posts and tags.
   *
   * @param filters
   */
  getPostList(filters: GalleryPostListFilters): Observable<GalleryPostList> {
    return this.http.post<GalleryPostList>(this.urls.getPostList, filters)
  }

  /**
   * Gets a post.
   *
   * @param id
   */
  getPost(id: number): Observable<GalleryPost> {
    return this.http.post<GalleryPost>(this.urls.getPost, {id})
  }

  /**
   * Creates a new post.
   *
   * @param postData
   */
  createPost(postData: NewPostData): Observable<GalleryPostPreview> {
    const formData = new FormData()

    postData.files.forEach((file) => formData.append('files', file))

    formData.append('tags', postData.tags.join(','))
    formData.append('text', postData.text)

    return this.http.post<GalleryPost>(this.urls.createPost, formData)
  }

  /**
   * Toggles a reaction to the post.
   *
   * @param post_id
   * @param reactionName
   */
  toggleReaction(post_id: number, reactionName: GalleryPostReactionName): Observable<GalleryPostReactionData> {
    return this.http.post<GalleryPostReactionData>(this.urls.toggleReaction(reactionName), {post_id})
  }
}
