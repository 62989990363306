import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { BaseService } from 'services/api'

@Component({
  selector: 'app-learning-home',
  templateUrl: 'learning-home.component.html',
  styleUrls: ['learning-home.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})
export class LearningHomeComponent implements OnInit {

  showPrev = 'show';

  counter = undefined;

  section = undefined;

  catalogItemId: number;

  constructor(
    private router: Router,
    private baseService: BaseService
  ) {}

  goBack() {
    this.router.navigate(['main']);
  }

  onSection(section) {
    this.router.navigate([section + '/folder/' + this.section.boxes.find(x => x.reference === section).id]);
  }

  showCounter(section) {
    return this.section.boxes.find(x => x.reference === section).news;
  }

  ngOnInit() {
    this.baseService.documentsContent().subscribe((response: any) => {
      this.section = response;
    });

    this.baseService.catalogFolder().subscribe((response: any) => {
      this.catalogItemId = response.folders[0].id;
    });
  }
}
