import { Injectable, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable()
export class DestroyRef extends Subject<void> implements OnDestroy {
  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.next()
    this.complete()
  }
}
