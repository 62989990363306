<div class="custom_dialog">
  <div class="container_popup_test">
    <div class="container_test">
      <div class="container_button_cells popup">
        <div class="text" *ngIf="right">
          <p>{{lang === 'ru' ? 'Ответ верный' : 'This is right answer'}}</p>
        </div>
        <div class="text" *ngIf="!right">
          <p>{{lang === 'ru' ? 'Ответ неверный' : 'The answer is not correct.'}}</p>
        </div>
        <div class="container_coin">
          <div class="button_cells" (click)="close()">ОК</div>
        </div>
      </div>
    </div>
  </div>

</div>
