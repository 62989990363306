<app-header [logoWhite]="true"></app-header>

<div class="outer"  [ngStyle]="{'backgroundImage': 'url(' + extraService.backgroundStore + ')'}">
  <div class="workspace">

    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>BE IN TOUCH</span>
        </div>
      </div>
    </div>

  <router-outlet></router-outlet>

  </div>
</div>
