<app-header></app-header>

<div class="outer" [@show_preview]="showPrev">
  <div class="workspace contests">
    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>КОНКУРСЫ</span>
        </div>
        <div class="breadcrumbs">
          <span class="crumb"><span [routerLink]="['/contests']" class="crumb_title link">Конкурсы</span></span>
          <span
            *ngFor="let bread of breadcrumbs;let i = index"
            class="crumb"
          >
        <span *ngIf="i === breadcrumbs.length - 1" class="crumb_title">{{bread.title}}</span>
        <span *ngIf="i !== breadcrumbs.length - 1" class="crumb_title link" (click)="onCrumb(i,bread)">{{bread.title}}</span>
      </span>
        </div>
      </div>
    </div>

    <div *ngIf="info">
      <div class="folder_container">
        <div
          class="folder"
          (click)="onFolder(dat)"
          *ngFor="let dat of info.folders;let i = index;"
          [ngStyle]="{backgroundImage:'url(' + (dat.preview) + ')'}"
        >
          <div class="folder_title">{{dat.title}}</div>
          <div class="counter" *ngIf="dat.news">+{{dat.news}}</div>
        </div>
      </div>
      <div class="document_container">
        <div class="document" *ngFor="let dat of info.documents;let i = index;" (click)="onPage(dat)">
          <div>
            <div class="document_title">{{dat.title}}</div>
            <div class="document_preview" [ngStyle]="{backgroundImage:'url(' + (dat.preview) + ')'}">
              <div *ngIf="dat.is_new" class="new"></div>
            </div>
            <div class="document_description">{{dat.description}}</div>
          </div>
          <div class="views_and_like_container">
            <div class="views">
              <div class="icon eye"></div>
              <div class="count">{{dat.views}}</div>
            </div>
            <div class="likes">
              <div [ngClass]="{active: dat.is_liked}" class="icon like" (click)="likeOn(dat, i)"></div>
              <div class="count">{{dat.likes}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
