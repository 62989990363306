<app-header [showUser]="false"></app-header>

<div class="outer" [@show_preview]="showPrev">
  <div class="workspace">
    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>Библиотека и словарь</span>
        </div>
      </div>
    </div>

    <div
      class="breadcrumbs"
    >
      <span class="crumb"><span [routerLink]="['/main']" class="crumb_title link">Главная</span><span class="separator">&mdash;</span></span>
      <span class="crumb"><span [routerLink]="['/learning/learning-home']" class="crumb_title link">Учись</span><span class="separator">&mdash;</span></span>
      <span class="crumb"><span class="crumb_title">Библиотека и словарь</span><span class="separator"></span></span>
    </div>

    <section class="content" *ngIf="sectionInfo">
      <div
        class="documents folder"
        *ngFor="let section of sectionInfo.boxes"
      >
        <div
          class="document"
          [ngStyle]="{backgroundImage: 'url(' + section.preview + ')'}"
          (click)="goSection(section)"
        >
          <div class="document_text">
            <div class="document_text_title">{{section.title}}</div>
            <div
              class="button"
            >
              <span class="ar"></span>Перейти
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</div>
