<!-- <div class="menu" [@show_preview]="showPrev">
  <div *ngIf="buttons">
    <div
      class="button"
      routerLinkActive="active"
      *ngFor="let button of buttons"
      [routerLink]="[button.link]"
    >
      {{button.title}}
    </div>
  </div>
  <div *ngIf="folders">
    <div
      class="button"
      routerLinkActive="active"
      *ngFor="let folder of folders"
      [routerLink]="[folder.link]"
      (click)="changeTitle($event, bg)"
      #bg
      [id]="folder.id"
    >
      {{folder.title}}
      <div class="counter" *ngIf="folder.count">+{{folder.count}}</div>
    </div>
  </div>
</div> -->


<section class="menu">
  <div
    *ngIf="mobile"
    class="menu_open"
    (click)="openMenu()"
  >
    <span
      class="ar"
      [ngClass]="{
        'open': menuOpen
      }"
    ></span>Разделы
  </div>
  <div [@show_preview]="showPrev" *ngIf="menuOpen" class="menu_osn">
    <div
      class="menu_li"
      routerLinkActive="active"
      *ngFor="let button of buttons"
      [routerLink]="[button.link]"
    >{{button.title}}</div>
  </div>
</section>
