<div 
    class="collapse"  
    (click)="toggle()"
    [ngStyle]="{cursor: cursor}"
>
    <div 
        class="collapse-panel"
    >

        <p *ngIf="title">{{title}}</p>
        
        <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>

        <div 
            class="collapse-button" 
            [ngClass]="{open: open}" 
            *ngIf="!hideIcon"
        >
            <app-icon-loader 
                [name]="'down'" 
                [color]="'#ffffff'" 
                [size]="20">
            </app-icon-loader>
        </div>
    </div>
    <div 
        class="collapse-content" 
        [ngClass]="{open: open}" 
        (click)="$event.stopPropagation()"
    >
       <ng-content></ng-content>
    </div>
</div>
