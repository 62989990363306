<div class="wrapper notranslate">
  <section class="logo_osn">
      <div class="workspace">
        <div class="back-button" (click)="goBack()"></div>
        <img class="logo" src="assets/images/logo-nyx-catalog.png">
      </div>
  </section>
  <section class="content">
      <div class="workspace">
        <div class="card-nyx" *ngIf="arrSection">
          <div class="card_side card_left">
            <div class="card_row">
              <div class="card_img" *ngIf="arrSection.image">
                <img [src]="'https://nyx-old-api.evpportal.ru/files/cpd/' + arrSection.image + '?' + token" alt="" />
              </div>
              <div class="card_title">
                {{arrSection.title}}
              </div>
            </div>
          </div>
          <div class="card_side card_right">
            <div class="card_ul">
              <div class="card_ul_title">ВЫБОР ПРОДУКТА</div>
              <ngx-slick-carousel class="carousel"
                        #slickModal="slick-carousel"
                        [config]="slideConfig"
                        >
                  <div ngxSlickItem *ngFor="let item of items" class="slide">
                    <a
                      [routerLink]="['/catalogs/nyx/section/card']"
                      [queryParams]="{folderHome:folderHome,folder:arrSection.id,underfolder:item.id}"
                    >
                      {{item.title['rus']}}
                    </a>
                  </div>
              </ngx-slick-carousel>
            </div>
          </div>
        </div>
      </div>
  </section>
</div>
