import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'

import { AuthService } from 'services'
import { signed } from '../http-context-tokens'

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService) {}

  /**
   * Adds an authorization header to a signed request.
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req.context.get(signed)
      ? req.clone({setHeaders: {Authorization: `Token ${this.authService.token}`}})
      : req
    )
  }
}
