import { add } from 'core/api'
import { env } from 'env'

export function createEcoStatisticsUrls() {
  const score = add(env.rootApiUrl, 'eco-statistics/score/')

  return {
    getOverallScores: add(score, 'get/'),
    getScoreList: add(score, 'list/'),
  }
}
