import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { MatDialogModule } from '@angular/material/dialog'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatRadioModule } from '@angular/material/radio'
import { MatSliderModule } from '@angular/material/slider'
import { MatSelectModule } from '@angular/material/select'
import { MatTabsModule } from '@angular/material/tabs'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { SwiperModule } from 'swiper/angular'
import { NgScrollbarModule } from 'ngx-scrollbar'
import { LightgalleryModule } from 'lightgallery/angular'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { SlickCarouselModule } from 'ngx-slick-carousel'
import { VgCoreModule } from '@videogular/ngx-videogular/core'
import { VgControlsModule } from '@videogular/ngx-videogular/controls'
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play'
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering'
import { StoreModule } from '@ngrx/store'

import { SharedModule } from '../shared/shared.module'

import {
  AlertDialogComponent,
  BookingCreateDialogComponent,
  BookingDialogComponent,
  FinalTestChangeDialogComponent,
  GameFinishDialogComponent,
  GameModeratorAlertDialogComponent,
  GameModeratorAlertFinishDialogComponent,
  GameModeratorPurchaseFinishDialogComponent,
  GameModeratorPurchaseListDialogComponent,
  GameModeratorPurchaseProcessDialogComponent,
  GameModeratorTestDialogComponent,
  GameModeratorTestFinishDialogComponent,
  TeamDialogComponent,
  UserPasswordChangeDialogComponent,
  VideoDialogComponent,
  VideoTestDialogComponent
} from 'components/dialogs'

import {
  AttemptsComponent,
  AuthComponent,
  CatalogsComponent,
  CatalogsOldComponent,
  ContestsComponent,
  DocumentComponent,
  FinalTestComponent,
  FolderComponent,
  ForkPageComponent,
  GameComponent,
  GameModeratorComponent,
  ImportantComponent,
  LearningComponent,
  MainComponent,
  NewsComponent,
  VideoComponent,
  VideoTestComponent
} from 'components/pages'

import { ArchiveContestsComponent } from 'components/pages/contests'

import {
  LearningHomeComponent,
  LibraryWordbookHubComponent,
  LorealComponent,
  MakeupComponent,
  MarketingComponent,
  NewestComponent,
  TrainingsComponent,
  TrendsComponent
} from 'components/pages/learning'

import { WordbookComponent } from 'components/pages/learning/library-wordbook'

import { TrainingsListComponent } from 'components/pages/learning/trainings'

import { NewestListComponent } from 'components/pages/learning/newest'

import { TrendsListComponent } from 'components/pages/learning/trends'

import { MarketingListComponent } from 'components/pages/learning/marketing'

import { MakeupListComponent } from 'components/pages/learning/makeup'

import { ImportantListComponent } from 'components/pages/important'

import { VideoListComponent } from 'components/pages/video/video-list/video-list.component'

import { NewsListComponent } from 'components/pages/news'

import { CatalogsCardComponent, CatalogsComparisonComponent, CatalogsListComponent, CatalogsMainComponent, SubfolderDocumentListComponent, CatalogsSearchComponent, CatalogsTabComponent, CheckboxComponent } from 'components/pages/catalogs'

import { CatalogsOldListComponent, NyxCatalogComponent, OapMblCatalogComponent } from 'components/pages/catalogs_old'

import { OapMblCatalogCardComponent } from 'components/pages/catalogs_old/oap-mbl-catalog'

import { NyxCatalogCardComponent, NyxCatalogHomeComponent, NyxCatalogSectionComponent } from 'components/pages/catalogs_old/nyx-catalog'

import {
  GameDropImageComponent,
  GameDropWordComponent,
  GamePhotoComponent,
  GameStageComponent,
  GameStagesComponent,
  GameTestComponent
} from 'components/pages/game'

import {
  GameModeratorCellsComponent,
  GameModeratorLiteracyTestComponent,
  GameModeratorRulesComponent,
  GameModeratorStagesComponent,
  GameModeratorStoreComponent,
  GameModeratorTestComponent
} from 'components/pages/game-moderator'

import { BasketComponent, PreviewComponent, StoreComponent } from 'components/pages/game-moderator/game-moderator-store'

import { PreviewTestComponent } from 'components/pages/game-moderator/game-moderator-test'
import { CellsPreviewComponent } from 'components/pages/game-moderator/game-moderator-cells'
import { LiteracyTestPreviewComponent } from 'components/pages/game-moderator/game-moderator-literacy-test/literacy-test-preview/literacy-test-preview.component'

import { BreadcrumbsComponent, ContentBlocksChildrenComponent, ContentBlocksComponent, HeaderComponent, SideComponentMenuComponent } from 'components/sections'

import { AppState, AppStoreFeature } from 'core/store'
import { navigationReducer } from 'store/navigation'
import { httpInterceptorProviders } from 'core/api'
import { RootRoutingModule } from './root-routing.module'
import { RootComponent } from './root.component'
import {MatButtonModule} from '@angular/material/button';
import { DropdownDirective } from 'app/directives/dropdown.directive'
import { IconLoaderModule } from 'app/shared/components/icon-loader/icon-loader.module'
import { MainLayoutModule } from 'app/layout/main-layout/main-layout.module'
import { CollapseModule } from 'app/shared/components/collapse/collapse.module'
import { FolderCardModule } from 'app/shared/components/folder-card/folder-card.module'
import { BaseLayoutModule } from 'app/layout/base-layout/base-layout.module'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input';
import { PipesModule } from 'app/shared/pipes/pipes.module'
import { ScheduleCreateComponent } from 'components/dialogs/schedule-create/schedule-create.component'
import { MatRippleModule } from '@angular/material/core'
import { MatTableModule } from '@angular/material/table';
import { NgxMaskModule } from 'ngx-mask'
import { WelcomeLayoutModule } from 'app/layout/welcome-layout/welcome-layout.module'
import { ComplimentVideoModule } from 'app/shared/dialogs/compliment-video/compliment-video.module'
import { SelectModule } from 'app/shared/components/select/select.module'
import { MatIconModule } from '@angular/material/icon'
import { CatalogsFaqComponent } from 'components/pages/catalogs/catalogs-faq/catalogs-faq.component'
import { CatalogsComparingComponent } from 'components/pages/catalogs/catalogs-comparing/catalogs-comparing.component'
import { DirectivesModule } from 'app/shared/directives/directives.module'

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatCheckboxModule,
    DragDropModule,
    MatExpansionModule,
    MatRadioModule,
    MatSliderModule,
    MatSelectModule,
    MatDialogModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    SwiperModule,
    NgScrollbarModule,
    LightgalleryModule,
    InfiniteScrollModule,
    MainLayoutModule,
    BaseLayoutModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    MatIconModule,
    VgBufferingModule,
    SlickCarouselModule,
    SharedModule,
    RootRoutingModule,
    IconLoaderModule,
    FolderCardModule,
    MatButtonModule,
    CollapseModule,
    PipesModule,
    WelcomeLayoutModule,
    MatTableModule,
    MatRippleModule,
    ComplimentVideoModule,
    SelectModule,
    DirectivesModule,
    NgxMaskModule.forRoot(),
    StoreModule.forRoot<AppState>({
      [AppStoreFeature.navigation]: navigationReducer
    })
  ],
  declarations: [
    RootComponent,
    AuthComponent,
    MainComponent,
    ImportantComponent,
    ImportantListComponent,
    VideoComponent,
    VideoListComponent,
    ContestsComponent,
    ArchiveContestsComponent,
    LearningComponent,
    LearningHomeComponent,
    TrainingsComponent,
    TrainingsListComponent,
    NewestComponent,
    NewestListComponent,
    TrendsComponent,
    TrendsListComponent,
    LibraryWordbookHubComponent,
    WordbookComponent,
    MarketingComponent,
    MarketingListComponent,
    MakeupComponent,
    MakeupListComponent,
    LorealComponent,
    NewsComponent,
    NewsListComponent,
    FolderComponent,
    DocumentComponent,
    FinalTestComponent,
    VideoTestComponent,
    AttemptsComponent,
    HeaderComponent,
    BreadcrumbsComponent,
    ContentBlocksComponent,
    ContentBlocksChildrenComponent,
    SideComponentMenuComponent,
    AlertDialogComponent,
    TeamDialogComponent,
    UserPasswordChangeDialogComponent,
    VideoTestDialogComponent,
    FinalTestChangeDialogComponent,
    VideoDialogComponent,
    GameFinishDialogComponent,
    BookingDialogComponent,
    BookingCreateDialogComponent,
    GameModeratorTestDialogComponent,
    GameModeratorAlertDialogComponent,
    GameModeratorAlertFinishDialogComponent,
    GameModeratorPurchaseListDialogComponent,
    GameModeratorPurchaseProcessDialogComponent,
    GameModeratorPurchaseFinishDialogComponent,
    GameModeratorTestFinishDialogComponent,
    CatalogsComponent,
    CatalogsMainComponent,
    CatalogsListComponent,
    CatalogsCardComponent,
    CatalogsSearchComponent,
    CatalogsTabComponent,
    CatalogsFaqComponent,
    CatalogsComparingComponent,
    SubfolderDocumentListComponent,
    CheckboxComponent,
    CatalogsComparisonComponent,
    CatalogsOldComponent,
    CatalogsOldListComponent,
    OapMblCatalogComponent,
    OapMblCatalogCardComponent,
    NyxCatalogComponent,
    NyxCatalogSectionComponent,
    NyxCatalogHomeComponent,
    NyxCatalogCardComponent,
    GameComponent,
    GameStagesComponent,
    GameStageComponent,
    GameTestComponent,
    GameDropImageComponent,
    GamePhotoComponent,
    GameDropWordComponent,
    GameModeratorComponent,
    GameModeratorRulesComponent,
    GameModeratorCellsComponent,
    GameModeratorTestComponent,
    PreviewTestComponent,
    CellsPreviewComponent,
    LiteracyTestPreviewComponent,
    GameModeratorStoreComponent,
    GameModeratorStagesComponent,
    GameModeratorLiteracyTestComponent,
    StoreComponent,
    BasketComponent,
    PreviewComponent,
    ForkPageComponent,
    DropdownDirective,
    ScheduleCreateComponent
  ],
  providers: [httpInterceptorProviders],
  bootstrap: [RootComponent]
})
export class RootModule {}
