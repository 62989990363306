import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appToggle]',
  exportAs: 'appToggle'
})
export class ToggleDirective {
  @Input() isOpen: boolean;

  @HostListener('click')
  clicked(): void {
    this.isOpen = !this.isOpen;
  }

}
