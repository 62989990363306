import { Injectable } from '@angular/core';
import { User } from 'core/model/user';
import {BehaviorSubject, Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ExtraService {
  private profileInfoSubject = new BehaviorSubject<any>(null);
  profileInfo$: Observable<any> = this.profileInfoSubject.asObservable();

  get profileInfoVal () {
    return this.profileInfoSubject.getValue();
  }

  set profileInfoVal (data: any) {
    this.profileInfoSubject.next(data);
  }

  #documentContent = new BehaviorSubject<any>(null);
  documentContent$: Observable<any> = this.#documentContent.asObservable();

  get documentContent () {
    return this.#documentContent.getValue();
  }

  set documentContent (document: any) {
    this.#documentContent.next(document)
  }

  #userSubject = new BehaviorSubject<User>(null);
  user$: Observable<User> = this.#userSubject.asObservable();

  get user () {
    return this.#userSubject.getValue();
  }

  set user (user: User) {
    this.#userSubject.next(user)
  }

  // Активная кнопка для мобилки
  activeButton: any;

  // Title активной кнопки бокового меню
  activeButtonTitle: any;

  // Массив попыток (из "Статистики")
  attempts: any;

  backgroundTest: string

  // Фон для магазина игры модераторов
  backgroundStore: string

  // Состояние паузы игры
  gamePaused = false

  public gamePaused$ = new Subject()

  public changeGamePaused(data: any) {
    this.gamePaused$.next(data)
  }

  inDocument = 'document'; // Проверка на нахождение в документе (для метрики)
}
