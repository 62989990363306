import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { ComponentType } from '@angular/cdk/overlay'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private readonly matDialog: MatDialog) {}

  // TODO: Deprecated.
  openDialog(dialogComponent: any, data: any | null = null, panelClass?: string, disableClose: boolean = false): Observable<any> {
    return this.matDialog.open(dialogComponent, {data, panelClass, autoFocus: false, disableClose}).beforeClosed()
  }

  /**
   * Opens a modal dialog containing the passed component.
   *
   * @param dialogComponent
   * @param config
   */
  open<D = any, R = any>(dialogComponent: ComponentType<any>, config?: MatDialogConfig<D>): Observable<R> {
    return this.matDialog.open(dialogComponent, config).beforeClosed()
  }
}
