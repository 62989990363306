import { Component, OnInit } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { ActivatedRoute } from '@angular/router'
import { Location } from '@angular/common'
import { BaseService } from 'services/api'
import { DialogService } from 'services'
import { transferArrayItem } from '@angular/cdk/drag-drop'
import { AlertDialogComponent } from 'components/dialogs'

interface IDropEntity {
  file: string | undefined,
  id: number,
  order: number,
  text: string | undefined
}

@Component({
  selector: 'app-game-drop-image',
  templateUrl: 'game-drop-image.component.html',
  styleUrls: ['game-drop-image.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})
export class GameDropImageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private baseService: BaseService,
    private dialogService: DialogService
  ) {
  }

  stageId: number
  brand: string
  text: string
  backgroundWord: string
  boxes: IDropEntity[]
  items: IDropEntity[] | any

  currentArr = []
  idArr = []

  mobile = false

  goBack() {
    this.location.back()
  }

  drop(event, order?) {
    if (event.previousContainer === event.container) {
      return
    } else {
      if (event.item.data.order === order) {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
        if (!this.items.length) {
          const data = {
            title: 'Миссия пройдена!',
            confirmButtonText: 'OK'
          }
          this.dialogService.openDialog(AlertDialogComponent, data).subscribe(() => {
            let uploadData = {
              stage_id: this.stageId
            }
            this.baseService.baseGameResultCreate(uploadData).subscribe(() => {
              this.location.back()
            })
          })
        }
      } else {
        return
      }
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.brand = params.brand
      this.stageId = params.stageId
      this.baseService.baseGetGameStage(this.stageId).subscribe((response: any) => {
        this.text = response.text
        this.backgroundWord = response.background
        this.boxes = response.elements.boxes
        this.items = response.elements.items
        this.boxes.forEach((item: IDropEntity, index: number) => {
          this.currentArr.push(
            {
              id: 'currentDrop' + index,
              array: [],
              order: item.order,
              file: item.file,
              text: item.text
            }
          )
          this.idArr.push('currentDrop' + index)
        })
        this.mobile = window.innerWidth <= 1025;
      })
    })
  }

}
