import * as moment from "moment";

export enum BookingQuery {
    rooms = 'booking/rooms/',
    intervals = 'booking/intervals/',
    owners = 'booking/owners/',
    week = 'booking/week/',
    month = 'booking/month/',
    list = 'booking/reservation/list/'
}

export interface BookingRoom {
    id: number;
    title: string;
    color: string;
    address: string;
    room?: number;
    date?: number;
    start?: string;
    finish?: string;
    description?: string;
    capacity?: number;
    equipment?: boolean;
}


export interface BookingInterval {
    id: number;
    start: string;
    finish: string;
}

export interface BookingOwner {
    id: number;
    email: string;
    name: string;
    phone: string;
}

export interface BookingCreateData {
    title: string;
    date: number;
    room: number;
    owner: number;
    intervals: number[];
}

export interface BookingWeek {
    room: number;
    start: number;
    finish: number;
}

export interface RoomEvent {
    id: number;
    date: number | moment.Moment;
    intervals: BookingInterval[];
    room: BookingRoom;
    title: string;
    top?: string;
    height?: string;
}
