import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WelcomeLayoutService } from './welcome-layout.service';
import { Observable } from 'rxjs';
import { WelcomeNavigationItem } from 'app/typings/welcome-book';


@Component({
  selector: 'app-welcome-layout',
  templateUrl: './welcome-layout.component.html',
  styleUrls: ['./welcome-layout.component.scss']
})
export class WelcomeLayoutComponent implements OnInit {
  navigationTitle$: Observable<WelcomeNavigationItem>;
  isBreadcrumb$: Observable<boolean>;

  constructor(
    private router: Router, 
    private welcomeLayoutService: WelcomeLayoutService
  ) { }

  ngOnInit(): void {
    this.navigationTitle$ = this.welcomeLayoutService.navigationTitle$;
    this.isBreadcrumb$ = this.welcomeLayoutService.breadcrumb$;
  }

  onNavigate(): void {
    this.router.navigate(['main'])
  }

}
