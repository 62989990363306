<main class="base-layout">
  <app-header [showUser]="false"></app-header>

  <div class="base-layout-container"> 
    <div class="base-layout-box" [ngClass]="{mirror: mirror}">
      <ng-scrollbar #scrollbarRef class="box-scroll">
        <router-outlet></router-outlet>
      </ng-scrollbar>
    </div>
  </div>
  <nav class="menu">
    <div *ngIf="mobile" class="menu_open" (click)="openMenu()">
      <span class="ar" [ngClass]="{ 'open': menuOpen }"></span>Разделы
    </div>
    <div [@show_preview]="showPrev" *ngIf="menuOpen" class="menu_osn">
      <div
        class="menu_li"
        [ngClass]="{active: activeLink?.includes(button.link)}"
        (click)="onNavigate(button.link)"
        *ngFor="let button of buttons">
          {{button.title}}
      </div>
    </div>
  </nav>
</main>
