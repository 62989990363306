<app-header></app-header>

<div class="outer" [ngStyle]="{'backgroundImage': 'url(' + this.backgroundWord + ')'}">
  <div class="workspace">

    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>Назад</span>
        </div>
      </div>
    </div>

    <div class="text" [innerHTML]="text | safeHtml"></div>

    <section class="photo_container">
      <div class="photo" *ngIf="loadPhoto !== ''" [ngStyle]="{'backgroundImage': 'url(' + loadPhoto + ')'}">
        <div class="del_icon" (click)="loadPhoto = ''"></div>
      </div>
      <div class="no_show">
        <input type="file" #photoInput name="files[]" (change)="processWebImage($event)" />
      </div>
      <div class="button" (click)="getPicture()">{{loadPhoto === '' ? 'ЗАГРУЗИТЬ ФОТО' : 'ОТПРАВИТЬ'}}</div>
    </section>

  </div>
</div>
