export * from './auth/auth.component';
export * from './main/main.component';
export * from './important/important.component';
export * from './contests/contests.component';
export * from './learning/learning.component';
export * from './news/news.component';
export * from './video/video.component';
export * from './document/document.component';
export * from './folder/folder.component';
export * from './final-test/final-test.component';
export * from './video-test/video-test.component';
export * from './attempts/attempts.component';
export * from './catalogs/catalogs.component';
export * from './catalogs_old/catalogs_old.component';
export * from './game/game.component'
export * from './game-moderator/game-moderator.component'
export * from './fork/fork-page.component'
