import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {ActivatedRoute} from "@angular/router";
import {BaseService} from "services/api";

@Component({
  selector: 'app-wordbook',
  templateUrl: 'wordbook.component.html',
  styleUrls: ['wordbook.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0,
      })),
      state('show', style({
        opacity: 1,
      })),
      transition('void => show', [
        style({
          opacity: 0,
        }),
        animate(500, style({
          opacity: 1,
        })),
      ]),
    ]),
  ],
})

export class WordbookComponent implements OnInit {

  showPrev = 'show';

  constructor(
    private route: ActivatedRoute,
    private baseService: BaseService,
  ) {
  }

  sectionInfo = undefined

  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      this.baseService.documentsContent({id: params.id, start_index: 0, limit: 5}).subscribe((response: any) => {
        this.sectionInfo = response
      })
    })
  }

}
