import { Directive } from '@angular/core';

@Directive({
  selector: '[appEllipsis]'
})
export class EllipsisDirective {

  constructor() { }

}
