<div class="bread_and_title_container">
  <div class="bread_and_title">
    <div class="page_title" (click)="goBack()">
      <div class="icon back"></div>
      <span>Макияж</span>
    </div>
    <div class="breadcrumbs">
      <span class="crumb"><span [routerLink]="['/learning/learning-home']" class="crumb_title link">Учись</span></span>
      <span
        *ngFor="let bread of breadcrumbs;let i = index"
        class="crumb"
      >
        <span *ngIf="i === breadcrumbs.length - 1" class="crumb_title">{{bread.title}}</span>
        <span *ngIf="i !== breadcrumbs.length - 1" class="crumb_title link" (click)="onCrumb(i,bread)">{{bread.title}}</span>
      </span>
    </div>
  </div>
</div>



<div *ngIf="startDocument && startFolder">
  <div class="folder_container">

    <div
      class="vertical"
      (click)="onPage(dat)"
      *ngFor="let dat of startDocument;let i = index;"
      [ngStyle]="{backgroundImage:'url(' + (dat.preview) + ')'}"
    >
      <div class="title">{{dat.title}}</div>
    </div>

    <div
      class="vertical"
      (click)="onFolder(dat)"
      *ngFor="let dat of startFolder;let i = index;"
      [ngStyle]="{backgroundImage:'url(' + (dat.preview) + ')'}"
    >
      <div class="title">{{dat.title}}</div>
    </div>

  </div>
</div>

<div *ngIf="info">
  <div class="folder_container">

    <div
      [ngClass]="{vertical: dat.type === 'vertical', folder: dat.type !== 'vertical', block: !dat.is_available}"
      (click)="onFolder(dat)"
      *ngFor="let dat of info.folders;let i = index;"
      [ngStyle]="{backgroundImage:'url(' + (dat.preview) + ')'}"
    >
      <div class="lock" *ngIf="!dat.is_available"></div>
      <div class="title" *ngIf="dat.is_available">{{dat.title}}</div>
    </div>

  </div>

  <div class="folder_container">
    <div class="document"
         [ngStyle]="{backgroundImage:'url(' + (dat.preview) + ')'}"
         *ngFor="let dat of info.documents;let i = index;"
         (click)="onPage(dat)"
    >
      <div class="title">{{dat.title}}</div>
      <div class="views_and_like_container">
        <div class="views">
          <div class="icon eye"></div>
          <div class="count">{{dat.views}}</div>
        </div>
        <div class="likes">
          <div [ngClass]="{active: dat.is_liked}" class="icon like" (click)="likeOn(dat, i)"></div>
          <div class="count">{{dat.likes}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
