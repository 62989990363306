import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-compliment-video',
  templateUrl: './compliment-video.component.html',
  styleUrls: ['./compliment-video.component.scss']
})
export class ComplimentVideoComponent{
  link: string;
  isPlay: boolean;
  @ViewChild('media') media: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<ComplimentVideoComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: {link: string; is_viewed: boolean}
  ) {
    this.checkTerm();
  }

  onPlay(): void {
    this.isPlay = true;
  }

  private checkTerm(): void {
    if (this.dialogData.is_viewed) {
      this.onClose();
    } else {
      this.link = this.dialogData.link;
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
