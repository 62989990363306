import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {GameModeratorService, StorageService} from "services";

@Component({
  selector: 'app-game-moderator-alert-finish-dialog',
  templateUrl: './game-moderator-alert-finish-dialog.component.html',
  styleUrls: ['./game-moderator-alert-finish-dialog.component.scss'],
})

export class GameModeratorAlertFinishDialogComponent implements OnInit {

  question: any;
  right: boolean;

  lang: string

  constructor(
    private dialogRef: MatDialogRef<GameModeratorAlertFinishDialogComponent>,
    private storageService: StorageService,
    private gameModerService: GameModeratorService,
    @Inject(MAT_DIALOG_DATA) private dialogData: { question: any, right: boolean },
  ) {
    ({
      question: this.question,
      right: this.right
    } = this.dialogData);
  }

  close() {
    this.dialogRef.close()
  }

  ngOnInit() {
    this.gameModerService.getLang()
    this.lang = this.storageService.getValue('game_moder_lang')
  }

}
