<app-header></app-header>

<div class="outer" [@show_preview]="showPrev">
  <div class="workspace contests" *ngIf="documents">
    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>КОНКУРСЫ</span>
        </div>
      </div>
      <div class="button" (click)="goArchive()">АРХИВ</div>
    </div>
    <div class="document_container">
      <div class="document" (click)="goDocument(document.id)" *ngFor="let document of documents">
        <div class="document_title">{{document.title}}</div>
        <div class="document_preview" [ngStyle]="{'backgroundImage': 'url(' +  document.preview + ')'}">
          <div class="new" *ngIf="document.is_new"></div>
        </div>
        <div class="views_and_like_container">
          <div class="views">
            <div class="icon eye"></div>
            <div class="count">{{document.views}}</div>
          </div>
          <div class="likes">
            <div class="icon" [ngClass]="{liked: document.is_liked, like: !document.is_liked}" (click)="like($event, document)"></div>
            <div class="count" [id]="'count' + document.id">{{document.likes}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
