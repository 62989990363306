<vg-player>
  <vg-overlay-play></vg-overlay-play>
  <vg-buffering></vg-buffering>

  <vg-scrub-bar>
    <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
    <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
  </vg-scrub-bar>

  <vg-controls [vgAutohide]="true">
    <vg-play-pause></vg-play-pause>
    <vg-playback-button [playbackValues]="['0.5', '1.0', '1.25', '1.5', '2.0']"></vg-playback-button>

    <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

    <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

    <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>

    <vg-mute></vg-mute>
    <vg-volume></vg-volume>

    <vg-fullscreen></vg-fullscreen>
  </vg-controls>

  <video *ngFor="let video of videos" #media [vgMedia]="$any(media)" preload="auto">
    <source type="video/mp4" [src]="video.file">
    <source type="video/webm" [src]="video.file">
  </video>
</vg-player>
