<div class="team_dialog_container">
  <div class="close" (click)="close()"></div>
  <div class="user_info_container" *ngIf="profileInfo">
    <div class="info">
      <div class="name">{{profileInfo.last_name}} {{profileInfo.first_name}} {{profileInfo.middle_name}}</div>
      <div class="desc_container">
        <span>ДОЛЖНОСТЬ:</span>
        <div class="desc">{{profileInfo.position}}</div>
      </div>
      <div class="desc_container">
        <span>КАТЕГОРИЯ:</span>
        <div class="desc">{{profileInfo.category}}</div>
      </div>
      <div class="desc_container">
        <span>БРЕНД:</span>
        <div class="desc">{{profileInfo.brands | convertToString}}</div>
      </div>
      <div class="desc_container">
        <span>РЕГИОН:</span>
        <div class="desc">{{profileInfo.regions}}</div>
      </div>
      <div class="desc_container">
        <span>ГОРОД:</span>
        <div class="desc">{{profileInfo.cities | convertToString}}</div>
      </div>
      <div class="desc_container">
        <span>МАГАЗИН:</span>
        <div class="desc column">
          <span class="black" *ngFor="let shop of profileInfo.shops">{{shop}}</span>
        </div>
      </div>
      <div class="desc_container">
        <span>КАНАЛ:</span>
        <div class="desc column">
          <span class="black" *ngFor="let channel of profileInfo.channels">{{channel}}</span>
        </div>
      </div>
      <div class="desc_container">
        <span>ДАТА ТРУДОУСТРОЙСВА:</span>
        <div class="desc">{{profileInfo.date_employment | timestampToDate: 'shortDate'}}</div>
      </div>
      <div class="desc_container">
        <span>ПОЧТА:</span>
        <div class="desc blue"><a [href]="'mailto:'+profileInfo.email">{{profileInfo.email}}</a></div>
      </div>
      <div class="desc_container">
        <span>ТЕЛЕФОН:</span>
        <div class="desc blue"><a [href]="'tel:'+profileInfo.phone">{{profileInfo.phone}}</a></div>
      </div>
      <div class="desc_container" *ngIf="profileInfo.instagram !== ''">
        <span>ИНСТАГРАМ:</span>
        <div class="desc yellow">{{profileInfo.instagram}}</div>
      </div>
    </div>
    <div class="user_avatar_container">
      <div class="avatar" [ngStyle]="{'backgroundImage': 'url(' + (profileInfo.photo !== null ? (profileInfo.photo) : '/assets/images/icons/avatar.png') + ')'}">
      </div>
<!--      <div class="button">РЕДАКТИРОВАТЬ</div>-->
      <div class="button" (click)="addUserActivity()">{{!profileInfo.is_active ? 'РАЗБЛОКИРОВАТЬ' : 'ЗАБЛОКИРОВАТЬ'}}</div>
    </div>
  </div>
  <div class="tests_tables_container" *ngIf="courses">
    <div class="table">
      <span class="desc">Назначенные курсы и тесты</span>
      <div class="horizontal_line"></div>
      <div class="tr" *ngFor="let course of courses.not_started">
        <div class="td title">{{course.title}}</div>
        <div class="td deadline"><span>Дедлайн:&nbsp;</span>{{course.deadline | timestampToDate : 'shortDate'}}</div>
        <div class="td attempts"><span>Попыток:&nbsp;</span>{{course.num_attempts}}</div>
        <div class="td empty"></div>
          <div class="td button" (click)="changeDeadline(course.deadline, course.profile_id)">ПРОДЛИТЬ ДЕДЛАЙН</div>
          <div class="td button" (click)="changeAttempts(course.num_attempts, course.profile_id)">ДОБАВИТЬ ПОПЫТКИ</div>
      </div>
    </div>
    <div class="table">
      <span class="desc">Незавершенные курсы и тесты</span>
      <div class="horizontal_line"></div>
      <div class="tr" *ngFor="let course of courses.processed">
        <div class="td title">{{course.title}}</div>
        <div class="td deadline"><span>Дедлайн:&nbsp;</span>{{course.deadline | timestampToDate : 'shortDate'}}</div>
        <div class="td attempts"><span>Попыток:&nbsp;</span>{{course.attempts.length}} из {{course.num_attempts}}</div>
        <div class="td result"><span>Результат:&nbsp;</span>{{course.best_result}}%</div>
          <div class="td button" (click)="changeDeadline(course.deadline, course.profile_id)">ПРОДЛИТЬ ДЕДЛАЙН</div>
          <div class="td button" (click)="changeAttempts(course.num_attempts, course.profile_id)">ДОБАВИТЬ ПОПЫТКИ</div>
      </div>
    </div>
    <div class="table">
      <span class="desc">Пройденые курсы и тесты</span>
      <div class="horizontal_line"></div>
      <div class="tr" *ngFor="let course of courses.completed">
        <div class="td title">{{course.title}}</div>
        <div class="td empty_button"></div>
        <div class="td attempts_course"><span>Попыток:&nbsp;</span>{{course.attempts.length}} из {{course.num_attempts}}</div>
        <div class="td result"><span>Результат:&nbsp;</span>{{course.best_result}}%</div>
        <div class="td empty_button"></div>
      </div>
    </div>
  </div>
</div>
