import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { CatalogsComparingService } from './catalogs-comparing.service';
import { ActivatedRoute } from '@angular/router';
import { DestroyRef } from 'core/common';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
  delay,
  take
} from 'rxjs/operators';
import { EMPTY, Observable, combineLatest, fromEvent, of } from 'rxjs';
import {
  ComparisonItem,
  ComparisonRes,
  Product,
} from 'services/api/catalogs/catalogs-query';
import { FormGroup } from '@angular/forms';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-catalogs-comparing',
  templateUrl: './catalogs-comparing.component.html',
  styleUrls: ['./catalogs-comparing.component.scss'],
  providers: [CatalogsComparingService, DestroyRef],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogsComparingComponent implements AfterViewInit {
  @ViewChild('swiper') swiper: SwiperComponent;
  @ViewChild('productRef') card: ElementRef;

  selectedProducts = new Set<number>();
  columnSize: string;
  listType: string;
  allowedEl: number = 5;
  isLoading: boolean = true;
  currentProduct: number;

  products$: Observable<Product[]> = this.comparingService.products$;

  comparisonList$: Observable<ComparisonRes> =
    this.comparingService.comparisonList$;

  config$: Observable<SwiperOptions> = of({
    touchEventsTarget: 'container',
    spaceBetween: 24,
    breakpoints: {
      720: {
        slidesPerView: 3.5,
      },
      630: {
        slidesPerView: 3.2,
      },
      575: {
        slidesPerView: 2.9,
      },
      510: {
        slidesPerView: 2.6,
      },
      450: {
        slidesPerView: 2.4,
      },
      410: {
        slidesPerView: 2.1,
      },
      0: {
        slidesPerView: 1.8,
        spaceBetween: 16,
      },
    },
  });

  changeAmountOfCards$: Observable<string> = this.comparisonList$.pipe(
    map(({ products }) => {
      switch (products.length) {
        case 6:
          return 'single';
        case 5:
          return 'double';
        default:
          return 'triple';
      }
    }),
    delay(100),
    tap((value) => {
      this.listType = value;
      this.isLoading = false;
  
      this.cdr.markForCheck();
    })
  );

  comparisonProducts$: Observable<Product[]> = this.comparisonList$.pipe(
    map(({ products }) => products)
  );

  selectedCounter$: Observable<number> = this.comparisonList$.pipe(
    map(({products}) => products?.length)
  );

  comparison$: Observable<ComparisonItem[]> = this.comparisonList$.pipe(
    map(({ comparison }) =>
      comparison.map((item) => {
        item.values;

        return item;
      })
    )
  );

  get selectForm(): FormGroup {
    return this.comparingService.selectForm;
  }

  constructor(
    private comparingService: CatalogsComparingService,
    private route: ActivatedRoute,
    private destroy$: DestroyRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.initProducts();
  }

  onSelectProduct(id: number): void {
    this.selectedCounter$.pipe(
      tap((val) => {
        if (this.selectedProducts.has(id)) {
          this.selectedProducts.delete(id);

          this.selectForm.patchValue({
            ids: [...this.selectedProducts],
          });
        } else {
          if(val !== this.allowedEl) {
            this.selectedProducts.add(id);

            this.selectForm.patchValue({
              ids: [...this.selectedProducts],
            });
          }
        }
        this.cdr.markForCheck();
      }),
      take(1)
    ).subscribe();
  }

  trackById(index: number, item: Product): number {
    return item.id;
  }

  trackByTitle(index: number, item: ComparisonItem): string {
    return item.title;
  }

  private initResizer(): Observable<never> {
    return fromEvent(window, 'resize').pipe(
      startWith({}),
      tap(() => {
         if(window.innerWidth > 767 && window.innerWidth <= 1024) {
          this.allowedEl = 3;
        } else if(window.innerWidth > 1280 && window.innerWidth <= 1330) {
          this.allowedEl = 5;
        } else if(window.innerWidth > 1023 && window.innerWidth <= 1280) {
          this.allowedEl = 4;
        } 
        else {
          this.allowedEl = 6;
        }
      }),
      tap(() => {
        this.isLoading = true;

        this.cdr.markForCheck();
      }),
      filter(() => !!this.card),
      tap(() => {
        this.countCardSize();

        this.cdr.markForCheck();
      }),
      switchMap(() => this.changeAmountOfCards$),
      switchMap(() => EMPTY),
      takeUntil(this.destroy$)
    );
  }

  private countCardSize(): void {
    const width = `${this.card?.nativeElement.offsetWidth}px`;

    if (width) {
      this.columnSize = width;
    }

    this.countCardAmount();

    this.cdr.markForCheck();
  }

  private countCardAmount(): void {
    const list = this.selectForm.get('ids').value;
    const screenWidth = window.innerWidth;
    let limit = 0;

    if (screenWidth <= 1330 && screenWidth > 1280 && list.length > 5) {
      limit = 5;
    } else if (screenWidth <= 1280 && screenWidth > 1200 && list.length > 4) {
      limit = 4;
    } else if (screenWidth <= 1024 && screenWidth > 767 && list.length > 3) {
      limit = 3;
    }

    if (list.length > 1 && limit >= 3) {
      const deleteCount = list.length - limit;
      const newList = list.slice(0, -deleteCount);

      list
        .slice(-deleteCount)
        .forEach((item: number) => this.selectedProducts.delete(item));

      this.selectForm.patchValue({
        ids: newList,
      });
    }
  }

  private initComparing(): Observable<any> {
    return this.selectForm.valueChanges.pipe(
      startWith(this.selectForm.value),
      tap(() => {
        this.isLoading = true;

        this.cdr.markForCheck();
      }),
      debounceTime(300),
      switchMap(({ ids }) => this.comparingService.getCompare(ids)),
      tap((list) => {
        this.comparingService.comparisonList = list;
      }),
      delay(100),
      tap(() => this.countCardSize()),
      switchMap(() => this.changeAmountOfCards$),
      takeUntil(this.destroy$)
    );
  }

  private initProducts(): void {
    this.route.params
      .pipe(
        tap(({ product_id }) => {
          this.currentProduct = +product_id;
          this.selectedProducts.add(+product_id);
          this.selectForm.patchValue({ ids: [...this.selectedProducts] });

          this.cdr.markForCheck();
        }),
        switchMap(({ product_id, ref }) =>
          this.comparingService.getProducts(product_id, ref)
        ),
        tap((res) => {
          this.comparingService.products = res;
        }),
        switchMap(() =>
          combineLatest([this.initComparing(), this.initResizer()])
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
