import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'moment',
  pure: false
})
export class MomentPipe implements PipeTransform {

  transform(m: moment.Moment | number, format: string = 'MMMM'): string {
    if(typeof(m) === 'number') {
      return moment(m).format(format);
    } 

    return m.format(format);
  }

}
