<div class="main-layout">
    <header>
        <app-header [showUser]="true"></app-header>
    </header>
    <ng-scrollbar>
        <main 
            class="main-layout-container"
        > 
            <router-outlet></router-outlet>
        </main>
    </ng-scrollbar>
</div>