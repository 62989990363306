import { add } from 'core/api'
import { env } from 'env'

export function createCommentsUrls() {
  const comments_section = (section: string) => add(env.rootApiUrl, `comments/${section}/`)

  return {
    getComments: (section: string) => add(comments_section(section), 'list/'),
    createComment: (section: string) => add(comments_section(section), 'create/'),
  }
}
