<app-header [logoWhite]="true"></app-header>

<div class="outer" [ngStyle]="{'backgroundImage': 'url(assets/images/game/bg_game_1.png)'}">

  <div class="workspace">

    <div class="bread_and_title_container">
      <div class="bread_and_title">
        <div class="page_title" (click)="goBack()">
          <div class="icon back"></div>
          <span>BE IN TOUCH</span>
        </div>
      </div>
    </div>

    <div class="container_circle">
      <div class="circle_block">
        <div 
          class="circle" 
          [ngClass]="{purple: (counter$ | async) === i }" 
          *ngFor="let circle of circleArr; let i = index">
        </div>
      </div>
      <div class="circle_text">{{(counter$ | async) + 1 + '/' + circleArr.length}}</div>
    </div>

    <div class="container_desc">
      <div class="text">{{question?.text}}</div>
      <div class="timer_container">
        <div class="timer">{{timeleft | msToTime}}</div>
        <div class="button" (click)="pause()">{{lang === 'ru' ? 'сделать паузу' : 'pause'}}</div>
        <ng-container *ngIf="!isNext">
          <div class="button" (click)="onNext()">{{lang === 'ru' ? 'дальше' : 'next'}}</div>
        </ng-container>
        <div class="button" (click)="submit()">{{lang === 'ru' ? 'завершить' : 'end'}}</div>
      </div>
    </div>

    <div class="container_question" *ngIf="!extraService.gamePaused">
        <div class="options">
          <ng-container *ngFor="let option of question?.options">
              <div class="options-item" [ngClass]="{active: options.has(option.id)}" (click)="addAnswers(option.id)">
                {{option?.text}}
              </div>
          </ng-container>
        </div>
        <div class="image">
            <img [src]="question?.image" loading="lazy">
        </div>
    </div>
  </div>
</div>
