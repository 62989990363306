import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseService } from 'services/api';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

declare var $: any;

@Component({
  selector: 'app-oap-mbl-catalog',
  templateUrl: './oap-mbl-catalog.component.html',
  styleUrls: ['./oap-mbl-catalog.component.scss'],
  animations: [
    trigger('show_preview', [
      state('none', style({
        opacity: 0
      })),
      state('show', style({
        opacity: 1
      })),
      transition('void => show', [
        style({
          opacity: 0
        }),
        animate(200, style({
          opacity: 1
        }))
      ]),
      transition('none => show', [
        style({
          opacity: 0
        }),
        animate(200, style({
          opacity: 1
        }))
      ]),
      transition('show => void', [
        style({
          opacity: 1
        }),
        animate(200, style({
          opacity: 0
        }))
      ]),
      transition('show => none', [
        style({
          opacity: 1
        }),
        animate(200, style({
          opacity: 0
        }))
      ])
    ])
  ]
})
export class OapMblCatalogComponent implements OnInit {
  cpd_token: string;
  token: string = '';
  searchHappened = false;

  tabs = [];
  items = [];
  activeTab = null;
  catalogId = '';

  preview = null; //Превью товара
  uidHomePreview = null; //uid родителя товара
  showPrev = 'none';

  slideConfig = {
    'slidesToShow': 4,
    'slidesToScroll': 1,
    'responsive': [{
      'breakpoint': 1060,
      'settings': {
        slidesToShow: 2
      }
    },
    {
      'breakpoint': 600,
      'settings': {
        slidesToShow: 1
      }
    },
    {
      'breakpoint': 415,
      'settings': {
        slidesToShow: 1
      }
    }]
  };

  filesMny = 'https://mbl-old-api.evpportal.ru/files/';


  constructor(
    private api: BaseService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }


  goBack() {
    this.router.navigate( [ 'catalogs_old' ] );
  }

  tabOpen(uid,index) {
    this.activeTab = index;
    $(".catalog_osn").slideUp(200);
    setTimeout(() => {
      this.api.mnyCatalog(uid)
      .subscribe((response: any) => {
        $(".catalog_pod").fadeIn(300);
        $(".catalog_osn").slideDown(1000);
        this.items = response;
      });
    }, 0);

  }

  tabClose(){
    this.activeTab = null;
    $(".catalog_pod").fadeOut(300);
    $(".catalog_osn").slideUp(200);
  }

  showPreview(info,home){
    this.preview = info.image;
    this.uidHomePreview = home.uid;
    this.showPrev = 'show';
  }
  removePreview(){
    this.preview = null;
    this.uidHomePreview = null;
    this.showPrev = 'none';
  }

  onProduct(product){
    //this.activeTab = null;
    this.preview = null;
    this.uidHomePreview = null;
    this.showPrev = 'none';
    $(".catalog_pod").fadeOut(300);
    $(".catalog_osn").slideUp(200);

    this.router.navigate(['/catalogs_old/' + this.catalogId + '/mny/' + product.uid]);

  }

  ngOnInit() {
    this.activeTab = 0;
    this.route.params.subscribe( params => {
      this.catalogId = params['id'];
        this.api.mnyCatalog(this.catalogId)
        .subscribe((response: any) => {

          this.api.mnyCatalog(response[0].uid).subscribe( ( response_items: any ) => {
            console.log(response);
            console.log(response_items);
            this.tabs = response;
            this.items = response_items;
          });
        });

    });
  }


}
